<template>
    <div class="box-body">
        <div class="row" v-if="product">
            <div class="col-md-12" style="margin-top: -24px; margin-bottom: 8px;">
                <a class="nav-link text-muted hover-dark" title="Powrót" @click="$router.go(-1)">
                    <i class="material-icons md-18">keyboard_arrow_left</i>
                    <span class="text-sm">Powrót</span>
                </a>
                <!--    <router-link class="nav-link text-muted hover-dark" to="/products" title="Powrót do list produktów">
          <i class="material-icons md-18">keyboard_arrow_left</i>
          <span class="text-sm">Powrót</span>
        </router-link> -->
            </div>
            <div class="col-md-3">
                <ProductDetailsV2 :product="product" />
                <ProductionBuffer :product="product" />
                <ProductFiles :product="product"/>
                <!-- <ProductPrice v-bind:product="product"></ProductPrice> -->
            </div>
            <div class="col-md-6">
                <ContentTabs :product="product"/>
            </div>
            <div class="col-md-3">
                <ProductReceptsV3 :product="product"/>
                <ProductWFirma :product="product"/>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import ProductFiles from './ProductFiles.vue'
import ProductDetailsV2 from './ProductDetailsV2.vue'
import ProductReceptsV3 from './ProductReceptsV3.vue'
import ProductionBuffer from './ProductionBuffer.vue'
import ProductWFirma from './ProductWFirma.vue'
import ContentTabs from './ContentTabs.vue'

export default {
    name: 'ProductsList',
    components: {
        ProductDetailsV2,
        ProductReceptsV3,
        ProductWFirma,
        ProductionBuffer,
        // ProductHistoryAndTasks,
        ContentTabs,
        ProductFiles
    },
    data: function () {
        return {
            product: null
        }
    },
    created: function() {
        Api.product.getProduct(this.$route.params.id).then((product) => {
            this.product = product;
        }).catch((reson) => {
            console.log('error');
        });
    }
}
</script>
