<template>
<div>
    <div class="box-header d-gray">
        <h6 class="text-muted _400 inline">Analiza realizacji produktów</h6>

        <div class="pull-right minus-margin m-r" style="width:10%;">
            <div class="input-group">
                <div class="datepicker-trigger">
                <input
                    class="form-control form-control-sm p-x b-a"
                    type="text"
                    id="trigger-range"
                    placeholder="Wybierz daty"
                    :value="formatDates(dateOne, dateTwo)"
                >

                <AirbnbStyleDatepicker
                    :trigger-element-id="'trigger-range'"
                    :mode="'range'"
                    :date-one="dateOne"
                    :date-two="dateTwo"
                    :monthsToShow="1"
                    :showShortcutsMenuTrigger="false"
                    v-on:closed="getProductsSummary"
                    @date-one-selected="val => { dateOne = val }"
                    @date-two-selected="val => { dateTwo = val }"
                />
                </div>
            </div>
        </div>
        <div class="pull-right minus-margin m-r" style="width:20%;">
            <v-select label="name" :reduce="p => p.key"  :options="options" v-model="selectedOpt" v-on:input="getDaysRange">
            </v-select>
        </div>
        <div class="pull-right minus-margin m-r" style="width:30%;">
            <v-select multiple  :options="productCodes" v-model="codes" v-on:input="changeCodes">
            </v-select>
        </div>
        
    </div>
    <div class="box-divider m-a-0"></div>
    <Spinner v-if="isLoaded"></Spinner>
    <vuetable
        v-if="!isLoaded"
        :css="css.table"
        :api-mode="false"
        :data="arrRaports"
        :fields="fieldsDefs"
        :row-class="onRowClass"
    >
        <template slot="tableHeader">
            <tr >
                <th></th>
                <th :colspan="codes.length" class="l-blue text-center" style="border-bottom:solid 1px #333;">Status - Przyjęte na produkcję</th>
                <th :colspan="codes.length" class="d-blue text-center" style="border-bottom:solid 1px #333;">Status - Zrealizowane</th>
                <th :colspan="codes.length" class="c-green text-center" style="border-bottom:solid 1px #333;">Status - Spakowane</th>
                <th :colspan="codes.length" class="c-blue text-center" style="border-bottom:solid 1px #333;">Status - Wysłane</th>
            </tr>
            <vuetable-row-header ></vuetable-row-header>
        </template>
    </vuetable>

    <div class="row m-t-lg" v-if="!isLoaded">
        <div class="col-md-6">
            <p class="text-center _600 primary-color"> Status - W produkcji</p>
            <line-chart :chart-data="getLineChartData('production')" :options="optionsChart"></line-chart>
        </div>
        <div class="col-md-6">
            <p class="text-center _600 primary-color"> Status - Spakowane</p>
            <line-chart :chart-data="getLineChartData('packed')" :options="optionsChart"></line-chart>
        </div>
    </div>

        
</div> 
</template>
<script>
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
import Vuetable from "vuetable-2";
import getFieldsDef from './FieldsDef.js'
import TestData from './TestData.js'
import TableStyle from './TableStyle.js'
import VuetableRowHeader from 'vuetable-2/src/components/VuetableRowHeader.vue'
import LineChart from './LineChart.js'
import format from 'date-fns/format';
import Spinner from '../Spinner.vue';

const PRODUCTS_TYPES = ["FK", "FKA", "FKP", "WFKA", "WŁ", "FKM", "FP"];
const PRODUCTS_COLORS = ["#5bc0de", "#90c844", "#f0ad4e", "#de5b7f", "#0cc2aa", "#d7da09", "#363f44"];

export default {
    name: 'ProductRealizationAnalysiss',
    props: {
    },
    components: {
        Vuetable,
        VuetableRowHeader,
        Select2,
        LineChart,
        Spinner
    },
    data: function () {
        return {
            options: [
                {key: "daily", name: "Dzienna Analiza"},
                {key: "month", name: "Miesięczna Analiza"},
                {key: "3months", name: "Kwartalna Analiza"},
                {key: "year", name: "Roczna Analiza"}
            ],
            selectedOpt: "daily",
            data: TestData,
            css: TableStyle,
            productCodes: PRODUCTS_TYPES,
            codes:["ilosc","FK", "FKA", "FKP", "WFKA", "WŁ", "FKM", "FP"],
            arrRaports: [],
            datacollection: null,
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            isLoaded: false,
            optionsChart: {
                layout: {
                    padding: {
                        left: 50,
                        right: 50,
                        top: 0,
                        bottom: 50
                    }
                }
            }
        }
    },
    created: function() {
        let enabled = JSON.parse(localStorage.getItem("enableCodes"));
        if(enabled != null && enabled.length > 0) this.codes = enabled;
        let selectedDaysRange = localStorage.getItem("selectedDaysRange");
        if(selectedDaysRange != null) {
            this.selectedOpt = selectedDaysRange;

        }
        let enabledDates = JSON.parse(localStorage.getItem("enabledDates"));
        if(enabledDates != null && enabledDates.length == 2 && enabledDates[0] != null && enabledDates[1] != null && enabledDates[1].length > 0) {
            this.dateOne = enabledDates[0];
            this.dateTwo = enabledDates[1];
            this.formatDates(this.dateOne, this.dateTo);
            this.getProductsSummary();

        } else {

            this.getDaysRange();
        }
    },
    computed: {
        fieldsDefs: function() {
            return getFieldsDef(this.codes);
        }
    },
    methods: {
       getProductsSummary: async function() {
           try {
               let arr = [];
               arr.push(this.dateOne);
               if(this.dateTwo == null) this.dateTwo = new Date().toISOString().slice(0, 10);
                arr.push(this.dateTwo);
               localStorage.setItem("enabledDates", JSON.stringify(arr));
               if(this.dateTwo == null) return;
               this.isLoaded = true;
               let arrRaports =  await Api.raports.getProductsSummary(this.selectedOpt, this.dateOne, this.dateTwo);
               arrRaports.sort(function(a,b){return new Date(a.date) - new Date(b.date);});
               this.arrRaports = arrRaports;
               this.isLoaded = false;
            } catch (error) {
               this.isLoaded = false;
               console.log("error", error);
           }
       },
       getDaysRange() {
           localStorage.setItem("selectedDaysRange", this.selectedOpt);
            if(this.dateTwo == null || this.dateTwo.length == 0) this.dateTwo = new Date().toISOString().slice(0, 10);
           if(this.selectedOpt == "daily") this.getMinusMonth(1);
           if(this.selectedOpt == "month") this.getMinusMonth(6);
           if(this.selectedOpt == "3months") this.getMinusMonth(12);
           if(this.selectedOpt == "year") this.getMinusMonth(12);
       },
       getMinusMonth(month) {
            var minusMonth = new Date(); 
            minusMonth.setMonth(minusMonth.getMonth() - month);
            if(this.dateOne == null || this.dateOne.length == 0) this.dateOne = minusMonth.toISOString().slice(0, 10);
            this.getProductsSummary();
       },

       onRowClass(dataItem, index) {
            if (!dataItem.date) return '';
            if (typeof dataItem.date !== "string") return '';
            if (dataItem.date.length != 10) return '';

            let d = new Date(dataItem.date);
            if (d.getDay() == 6) return 'sobota'; // sobota
            else if (d.getDay() == 0) return 'niedziela'; // niedzielas
       },

       formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat);
            }
            return formattedDates;
        },
        getLineChartData(dataType) {
            let arrLabels = [];
            let datasets = [];

            PRODUCTS_TYPES.forEach(pType => {
                let set = { label: pType, fill: false, borderColor:'#ddd', data: []};
                datasets.push(set);   
            });

            for (let i = 0; i < this.arrRaports.length; i++) {
                const obj = this.arrRaports[i];
                arrLabels.push(obj.date);
                let productStats = obj[dataType] || {};
                for (let y = 0; y < PRODUCTS_TYPES.length; y++) {
                    const pType = PRODUCTS_TYPES[y];
                    let set = datasets.find(d => d.label == pType);
                    set.borderColor = PRODUCTS_COLORS[y];
                    set.data.push(productStats[pType] || 0);
                    
                }
            }

            return {labels: arrLabels, datasets: datasets};
        },
        changeCodes: function() {
            localStorage.setItem("enableCodes", JSON.stringify(this.codes));
        }
    }
}
</script>
<style  lang="scss">
#trigger-range {
    background-color: transparent;
}
.app .border-vuetable {
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
}
.table > tbody > tr > td {
    // padding-left: 6px;
    // padding-right: 6px;
    // text-align: center;
}

tr.sobota td {
    background-color: #cdcdcd;
}
tr.niedziela td {
    background-color: #f55c6b;
}

</style>