<template>
    <div class="box" v-if="task">
        <!-- <div class="l-gray p-x p-y" v-if="(!task.madeCount || task.madeCount != task.toMadeCount) && task.status != 'completed' && task.status != 'to-confirm' ">
            <button class="btn btn-xs white none-radius" @click.prevent="changeStatus('suspended')" v-if="task.status !='suspended' && task.status == 'in-progress' ">
                <span class="primary-color _400">Wstrzymaj</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" @click.prevent="changeStatus('in-progress')" v-if="task.status =='suspended'">
                <span class="primary-color _400">Przywróć</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" @click.prevent="changeStatus('canceled')" v-if="task.status !='canceled'">
                <span class="primary-color _400">Anuluj</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" @click.prevent="completeTask">
                <span class="primary-color _400">Zakończ</span>
            </button>&nbsp;
        </div> -->
        <div class="box-header">
            <h3>{{ task.taskDef.name }}</h3>
            <small> <TaskStatusSpan :task="task" /></small>
        </div>

        <div class="box-tool">
            <ul class="nav">
                
                <!-- <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li> -->
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="box-body">

            <!-- <div v-if="task.status == 'to-confirm'">
                <div class="grey-box m-b-sm">
                    <div class="p-y-sm p-x clear">
                        <div class="pull-left m-r">
                            <i class="material-icons text-yellow m-y-sm text-2x ">report</i>
                        </div>
                        <div>
                            <strong>Zadanie do potwierdzenia</strong><br/>
                            <span>To zadanie czeka na potwierdzenie przez pracownika biura</span>
                            <a class="pull-right btn btn-sm white none-radius minus-margin" @click.prevent="confirmTask"><span class="primary-color _400">Potwierdź</span></a>
                        </div>
                    </div>
                </div>
            </div> -->
            
            <TaskSummaryBox :task="task" />

            
            <div class="text-xs m-t-sm" v-if="task.startAfterTasks && task.startAfterTasks.length > 0">
                <span class="text-muted">Rozpocząć po:</span>
                <div v-for="t in task.startAfterTasks">
                    <span v-if="t.taskDef && t.taskDef.name">{{t.taskDef.name | truncate(60)}}</span>
                </div>
            </div>
            
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
            
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
import TaskSummaryBox from '../Tasks/TaskSummaryBox';
import TaskStatusSpan from './TaskStatusSpan.vue';
import {RotateSquare2} from 'vue-loading-spinner';

let noteSaveTimeout = null;

export default {
    name: 'TaskDetailsModal',
    props: {
        taskToEdit: Object,
        order: Object, // opcjonalnie zadanie 
        products: Array // opcjonalne tablica nazwa produktow
    },
    components: {
        RotateSquare2,
        TaskStatusSpan,
        TaskSummaryBox
    },
    data: function () {
        return {
            Api,
            isProcessing: false,
            isSavingNotes: false,
            task: null
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (this.taskToEdit) {
            this.task = JSON.parse(JSON.stringify(this.taskToEdit)); //deep-copy
        }

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.save();
            }
        },
       

        save: async function (s) {
             this.isProcessing = true;
            try {
                
                this.$notify({group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano zmiany'});
            } catch (error) {
                this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'});
            }
            this.isProcessing = false;
        },


        completeTask: async function() {
            this.isProcessing = true;
            try {
                await Api.tasks.complete([this.task._id]);
                if (this.order) this.order.status = "completed";
                this.$notify({group: 'global', type: 'notification-success', text: 'Zadanie zakończone'});
            } catch (error) {
                this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'});
            }
            this.isProcessing = false;
        },
       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.progress {
  margin-top: 5px;
  margin-bottom: -2px;
  border-radius: 0px;
  width: 50%;
  
}
.box-scroll {
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}
</style>
