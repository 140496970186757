<template>
    <div class="row">
        <div style="position: relative;" v-if="loading">
            <Spinner/>
        </div>
        <div class="col-sm-12" v-if="!loading">
            <div class="tab-content">
                <div class="tab-pane animated fadeIn active text-muted">
                    <div class="box-header primary-color d-gray">
                        <div class="row">
                            <!-- <div class="col-md-1" style="padding-right: 0px;">
                                <label class="md-check p-t-xs">
                                    <input type="checkbox" @change="toggleCheckbox()" v-model="toggle">
                                    <i class="blue m-r-xs"/>
                                </label>
                            </div> -->
                            <div class="col-md-3" style="padding-right: 0px;">
                                <div class="col-md-2">
                                    <label class="md-check">
                                        <input type="checkbox" @change="toggleCheckbox()" v-model="toggle">
                                        <i class="blue m-r-xs"/>
                                    </label>
                                </div>
                                <div class="col-md-10">
                                    <span class="primary-color _600"> Numer listu</span>
                                </div>
                            </div>
                            <div class="col-md-1" style="padding-left: 0px;"><span class="primary-color _600">Numer zam.</span></div>
                            <div class="col-md-1" style="padding-left: 0px;"><span class="primary-color _600">Nazwa</span></div>
                            <div class="col-md-2" style="padding-left: 0px;"><span class="primary-color _600">Odbiorca</span></div>
                            <div class="col-md-1" style="padding-left: 0px;"><span class="primary-color _600">Usługa</span></div>
                            <div class="col-md-1" style="padding-left: 0px;"><span class="primary-color _600">Data utw.</span></div>
                            <div class="col-md-1" ><span class="primary-color _600">Zawrtość</span></div>
                            <div class="col-md-2"><span class="primary-color _600">Status</span></div>
                        </div>
                    </div>

                    <div class="box-divider m-a-0"/>

                    <div class="row box-header" v-for="shipment in shipmentWithUSerName" :key="shipment._id">
                        <!-- <div class="col-md-1"  style="padding-right: 0px;">
                            <label class="md-check p-t-xs">
                                <input type="checkbox" v-model="shipment.isSelected">
                                <i class="blue m-r-xs"/>
                            </label>
                        </div> -->

                        <div class="col-md-3" style="padding-right:0px;">
                            <div class="col-md-2">
                                <label class="md-check p-t-xs">
                                    <input type="checkbox" v-model="shipment.isSelected">
                                    <i class="blue m-r-xs"/>
                                </label>
                              </div>
                            <div class="col-md-10">
                                
                                <i v-if="shipment.status == 'delivered'" class="material-icons md-18 text-green">check_circle</i>
                                <i v-else-if="shipment.status == 'delivery-problems'" class="material-icons md-18 text-red">error</i>

                                <a v-if="shipment.trackLink" target="_blank" class="m-r-sm" :href="shipment.trackLink">{{ shipment.parcelNo }} <i class="material-icons text-muted">open_in_new</i></a>
                                <span v-else>{{ shipment.parcelNo }}</span>
                                <!-- //dotyczy tylko innych przesyłek poczty polskiej -->
                                <span class="text-xs text-muted" v-if="shipment.extra && shipment.extra.firstCode">{{shipment.extra.firstCode}}</span>

                                <span style="font-size:80%;" class="label text-white none-radius inline prod-label c-yellow m-l-xs" v-if="shipment.extra && shipment.extra.cod">Pobranie</span>
                                <br>
                                <!-- //dotyczy tylko innych przesyłek poczty polskiej -->
                                <div v-if="shipment.extra && shipment.extra.shipsNo && shipment.extra.shipsNo.length > 0">
                                    <div class="text-xs text muted" v-for="obj in shipment.extra.shipsNo" :key ="obj.numerNadania">
                                        <a v-if="obj.numerNadania" target="_blank" class="m-r-sm" :href="'https://emonitoring.poczta-polska.pl/?numer='+obj.numerNadania">{{ obj.numerNadania }} <i class="material-icons text-muted">open_in_new</i></a>
                                        <!-- //kopatybilnosc wsteczna -->
                                        <a v-else target="_blank" class="m-r-sm" :href="'https://emonitoring.poczta-polska.pl/?numer='+obj">{{ obj }} <i class="material-icons text-muted">open_in_new</i></a>
                                        <span class="text-xs text-muted" v-if="obj.kodPaczki && obj.kodPaczki.length >0">{{obj.kodPaczki}}</span>
                                    </div>
                                </div>
                                <p class="m-b-0">
                                    <img v-if="shipment.status == 'in-road'" class="icon-delivery-shipment m-r-sm" src="../../assets/images/truck.png">
                                    <router-link target="_blank" class="text-xs text-blue" :to="{ name: 'order-details', params: { id: shipment.order._id }}" v-if="shipment.order">
                                        ({{ shipment.order.name }})
                                    </router-link>
                                    <router-link target="_blank" class="text-xs text-blue" :to="{ name: 'stock-order' }" v-if="shipment.stockOrder">
                                        Zamówienie odbioru
                                    </router-link>
                                </p>
                                <p class="text-xs text-muted m-b-0" v-if="shipment._createdBy">
                                    Dodał:
                                    <span v-if="shipment.userName">{{shipment.userName}}</span>
                                    <!-- <UserSpan :userId="shipment._createdBy"/> -->
                                </p>
                            </div>

                        </div>
                        <div class="col-md-1" style="padding-left: 0px;" >
                            <span v-if="shipment.order && shipment.order.clientNo">{{shipment.order.clientNo}}</span><br>
                            <span v-if="shipment.order && shipment.order.invoiceNo" class="text-blue text-xs">({{ shipment.order.invoiceNo }})</span>
                            <span v-if="shipment.order && !shipment.order.invoiceNo && !shipment.order.invoiceNo">----</span>
                        </div>
                        <div class="col-md-1" style="padding-left: 0px;" >
                            <span v-if="shipment.receiverAddress && shipment.receiverAddress.name">{{ shipment.receiverAddress.name }}</span>
                            <span v-else-if="shipment.receiverAddress && shipment.receiverAddress.contactPerson">{{ shipment.receiverAddress.contactPerson }}</span>
                            <span v-else>BRAK</span>
                        </div>
                        <div class="col-md-2" style="padding-left: 0px;">
                            <span>
                                <span v-if="shipment.receiverAddress && shipment.receiverAddress.contactPerson">{{ shipment.receiverAddress.contactPerson }}</span>
                                <span v-else-if="shipment.receiverAddress && shipment.receiverAddress.name">{{ shipment.receiverAddress.name }}</span>
                            </span>
                            <br>
                            <span v-if="shipment.receiverAddress" class="text-xs text-muted">{{ shipment.receiverAddress.street }}, {{ shipment.receiverAddress.postCode }} {{ shipment.receiverAddress.city }}</span><br>
                            <span v-if="shipment.receiverAddress"  class="text-xs text-muted">{{ shipment.receiverAddress.email || "Brak adresu email" }}</span>
                            <a class="text-xs text-blue m-l-sm" @click.prevent="setEmail(shipment)">edytuj</a>
                        </div>

                        <div class="col-md-1" style="padding-left: 0px;">
                            <span>{{ shipment.service.toUpperCase() }}</span>
                            <span class="text-xs text-muted" v-if="shipment.subservice"><br>{{ shipment.subservice }}</span>
                        </div>

                        <div class="col-md-1" style="padding-left: 0px; padding-right:0px;">
                            {{ shipment._created|moment('D-M-Y') }}
                            <span class="text-xs text-muted" v-if="shipment.handedOver && shipment.empName">
                                <br>
                                <span v-if="shipment.handedOver.type == 'user'">Oznaczone jako wydane przez:</span>
                                <span v-else>Wydał:</span>
                                <span>{{shipment.empName}}</span>
                                <span v-if="shipment.handedOver.at"><br>Dnia: {{shipment.handedOver.at | moment('D-M-Y')}}</span>
                            </span>
                        </div>
                        <div class="col-md-1"><span v-if="shipment.extra">{{ shipment.extra.content }}</span></div>
                        <div class="col-md-2">
                            <a :title="'Ostatnie sprawdzenie statusu: ' + $moment(shipment.lastStatusCheck).fromNow()">
                                <span v-if="shipment.status == 'waiting'" class="label text-white m-l-xs none-radius inline prod-label c-grey">Oczekująca</span>
                                <span v-else-if="shipment.status == 'sent'" class="label text-white m-l-xs none-radius inline prod-label c-yellow">Wysłane</span>
                                <span v-else-if="shipment.status == 'in-road'" class="label text-white m-l-xs none-radius inline prod-label c-blue">W drodze</span>
                                <span v-else-if="shipment.status == 'delivered'" class="label text-white m-l-xs none-radius inline prod-label c-green">Doręczono</span>
                                <span v-else-if="shipment.status == 'delivery-problems'" class="label text-white m-l-xs none-radius inline prod-label c-red">Problemy</span>
                            </a>
                           

                            <span v-if="shipment.extra && shipment.extra.wasWaybillGenerated" class="label text-white m-l-xs none-radius inline prod-label c-yellow">Wyg. list przewozowy</span>
                            <span v-if="shipment.extra && shipment.extra.wasProtocolGenerated" class="label text-white m-l-xs none-radius prod-label c-blue">Wyg. protokół</span>
                            <span v-if="shipment.extra && shipment.extra.wasNotifiedByEmail" class="label text-white m-l-xs none-radius prod-label c-warning">Wysł. email</span>
                            <span v-if="shipment.carrierStatus" class="text-sm text-muted"><br>
                                {{ shipment.carrierStatus.status }}<span v-if="shipment.carrierStatus.statusDate"> - {{ shipment.carrierStatus.statusDate|moment('from') }}</span>
                            </span>
                        </div>
                        
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>
<script>
import ShipmentEmailChangeModal from './ShipmentEmailChangeModal.vue';
// import UserSpan from '../../UserSpan.vue';
import Spinner from '../Spinner.vue';
import SystemUsersManager from "../../SystemUsersManager.js";
import Api from "../../Api.js";
export default {
    name: 'DeliveryTable',
    props: {
        shipments: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            page: 1,
            pages: 20,
            toggle: false,
            users: [],
            loading: false,
            employees: []
        }
    },
    created: async function() {
        this.users = await SystemUsersManager.getSystemUsers();
        this.employees = await Api.emp.getEmployees();
        if (this.users.length == 0) this.getusers();
    },
    destroyed: function() {
    },
    computed: {
        shipmentWithUSerName: function () {
            return this.shipments.map(s => {
                s.userName = (this.users.find(u => u._id == s._createdBy) || {}).name;
                if (s.handedOver && s.handedOver.by) {
                    if (s.handedOver.type == 'user') s.empName = (this.users.find(u => u._id == s.handedOver.by) || {}).name;
                    else s.empName = (this.employees.find(e => e._id == s.handedOver.by) || {}).name;
                }

                s.lastStatusCheck = s.carrierStatus ? s.carrierStatus.lastUpdate : new Date();

                if (s.service == 'pp') s.trackLink = 'https://emonitoring.poczta-polska.pl/?numer=' + s.parcelNo;
                else if (s.service == 'dpd') s.trackLink = 'https://tracktrace.dpd.com.pl/parcelDetails?p1=' + s.parcelNo;
                // else if (s.service == 'fedex') s.trackLink = 'https://poland.fedex.com/domestic-shipping/pub/tracktrace.do?action=track&packageId=' + s.parcelNo;
                else if (s.service == 'fedex') s.trackLink = 'https://www.fedex.com/pl-pl/online/domestic-tracking.html#/preview?packages='+s.parcelNo+'&trackingKey=' + s.parcelNo;
                else if (s.service == 'furgonetka') s.trackLink = 'https://furgonetka.pl/zlokalizuj/' + s.parcelNo;
                else if (s.service == 'geis') s.trackLink = 'https://www.geis.pl/pl/sledzenie-przesylki?p=' + s.parcelNo;
                else if (s.service == 'dhl') s.trackLink = 'https://www.dhl.com/pl-pl/home/tracking/tracking-parcel.html?submit=1&tracking-id=' + s.parcelNo;
                return s;
            });
        },
        userName: function (id) {
            return (this.users.find(u => u._id == id) || {}).name;
        }

    },
    methods: {
        getusers: function() {
            this.loading = true;

            Api.user.get().then((res) => {
                this.users = res;
                this.loading = false;
            }).catch((reason) => {
                this.loading = false;
            });
        },
        toggleCheckbox: function() {
            for (var i = this.shipments.length - 1; i >= 0; i--) {
                const sh = this.shipments[i];
                sh.isSelected = this.toggle;
            }
        },

        setEmail: function(shipment) {
            this.$modal.show(ShipmentEmailChangeModal, {
                shipmentToChange: shipment
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        }
    }
}
</script>

<style  scoped lang="scss">
.icon-delivery-shipment {
  width: 22px;
  height: auto;
}
</style>