<template>
    <div class="row">
      <div style="width: 40px; margin: 30px auto 20px auto;">
        <rotate-square2></rotate-square2>
      </div>
    </div>
</template>

<script>
import {RotateSquare2} from 'vue-loading-spinner'

export default {
  name: 'Spinner',
  
  components: {
    RotateSquare2
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
