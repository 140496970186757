<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Druk</h6>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <form class="form-horizontal m-b" v-if="isReady">
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Produkty</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="production.productsPrintTemplateId" :options="productTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Karton</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="production.labelsPrintTemplateId" :options="boxTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Paleta</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="production.palletesPrintTemplateId" :options="palletTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" @click="save" :disabled="procesing == 1">Zapisz</button>
            </form>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
export default {
    name: 'Notes',
    props: {
        orderId: String,
        production: Object
    },
    data: function () {
        return {
            templates: [],
            isReady: false,
            procesing: false
        }
    },
    components: {
    },

    created: function() {
        this.getAllTemplates();
    },

    computed: {
        productTemplates: function() {
            return this.templates.filter(t => t.dataScheme == "productsIdsWithQty");
        },

        boxTemplates: function() {
            return this.templates.filter(t => t.dataScheme == "taskBox");
        },

        palletTemplates() {
            return this.templates.filter(t => t.dataScheme == "pallet");
        }
    },

    methods: {
        getAllTemplates: async function() {
            this.isReady = false;
            try {
                this.templates = await Api.printing.templates.search({});
            } catch (error) {}
            this.isReady = true;
        },
        save: function() {
            const data = {
                productsPrintTemplateId: this.production.productsPrintTemplateId,
                labelsPrintTemplateId: this.production.labelsPrintTemplateId,
                palletesPrintTemplateId: this.production.palletesPrintTemplateId
            };
            this.procesing = true;
            Api.printing.templates.seveOrderTemplates(this.orderId, data).then((s) => {
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano' });
                this.procesing = false;
            }).catch((e) => {
                console.log('error', e)
                this.procesing = false;
            })
        }
    }
}
</script>
