<template>
    <li class="nav-item dropdown none-m-l text-color" v-if="notify.length > 0">
        <a href="" class="btn white b-a no-shadow none-radius btn-xs m-t" data-toggle="dropdown">
            <span class="text-muted" v-if="notify[0].code=='new_task_to_confirm'">Do potwerdzenia</span>
            <span class="text-muted" v-else-if="notify[0].code=='wfirma_order_removed'">wFirma usuniecie</span>
            <span class="text-muted" v-else-if="notify[0].code=='wfirma_order_edited'">wFirma modyfikacja</span>
            <span class="text-muted" v-else-if="notify[0].code=='task_ready_to_ship'">Do nadania</span>
            <span class="text-muted" v-else-if="notify[0].code=='new_application_freeday_to_confirm'">Wnioski o urlop</span>
            <span class="label rounded label-sm up danger" v-if="notify.length > 0" :class="{'pulse': notify.length > 0}" style="line-height:1rem;">
                {{notify.length}}
            </span>
        </a>
        <div class="dropdown-menu pull-right w-xl animated fadeInUp no-bg no-border no-shadow" v-if="notify.length > 0">
            <a style="background:#2196f3" class="btn b-a no-shadow none-radius m-t-sm btn-block text-white text-xs"
               @click.prevent="markAll">
                Oznacz wszystko jako przeczytane  <i class="material-icons md-18 m-l-sm">done_all</i>
            </a>
            <div class="scrollable notifications-box">
                <ul class="list-group list-group-gap m-a-0">
                    <li v-for="n in notify" class="notif-item list-group-item white lt box-shadow-z0 b" :key="n._id">
                        <span class="pull-right">
                            <a class="seen-btn" title="Oznacz jako przeczytane" @click.prevent="markAsSeen(n)">
                                <i class="material-icons md-18 text-blue">check</i>
                            </a>
                        </span>
                        <span class="clear block">
                            <span v-if="n.code=='new_task_to_confirm'">Zadanie do potwerdzenia</span>
                            <span v-else-if="n.code=='wfirma_order_removed'">Dokument po stronie wfirma został usuniety</span>
                            <span v-else-if="n.code=='wfirma_order_edited'">Zamówienie zostało zmodyfikowane po stronie wFirma</span>
                            <span v-else-if="n.code=='task_ready_to_ship'">Zlecenie spakowane i gotowe do nadania</span>
                            <span v-else-if="n.code=='new_application_freeday_to_confirm'">Nowy wniosek o urlop
                                <span v-if="n.data && n.data.employeeId">{{EmployeeManager.getName(n.data.employeeId)}}</span>
                            </span>
                            <br>
                            <a v-if="n.data && n.data.orderId" href="#" class="text-blue" @click.prevent="$router.push({ name: 'order-details', params: { id: n.data.orderId } })">przejdź do zamówienia</a>
                            <a v-if="n.code == 'new_application_freeday_to_confirm' && n.data && n.data.employeeId" href="#" class="text-blue" @click.prevent="$router.push({ name: 'employee-details', params: { id: n.data.employeeId } })">przejdź do karty pracownika</a>
                            <br>
                            <small class="text-muted">{{n.createdAt| moment('from')}}</small>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </li>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';

export default {
    name: 'NotifySpan',

    props: {
        notify: Array
    },
    data: function () {
        return {
            EmployeeManager: EmployeeManager
        }
    },
    created: async function() {
        await EmployeeManager.getEmployees();
    },
    methods: {
        markAsSeen: function(notif) {
            // console.log("notrify", notif._id);
            Api.user.setNotificationAsSeen(notif._id);
        },
        markAll: function() {
            Api.user.setManyNotificationsAsSeen({ code: this.notify[0].code }).then(() => {
            });
        }
    }
}
</script>

<style scoped lang="scss">
.notifications-box {
  max-height: 400px;
}
.notif-item {
  .seen-btn {
    opacity: 0;
  }
}
.notif-item:hover {
  .seen-btn {
    opacity: 1;
  }
}

.black {
    .seen-btn i {
        color: #eee;
    }
}
.label.up {
    top: 10px;
    left: 10px;
}
</style>
