<template>
    <div class="box-body">
        <div class="row">
            <Spinner v-if="isLoaded"/>
            <div class="col-md-3">
                <Details v-if="customer._id" :customerData="customer"/>
                <router-link class="nav-link text-muted hover-dark" to="/customers" v-if="user != null && UserManager.hasPermission('view.customers')">
                    <span class="text-sm">Powrót do list klientów</span>
                </router-link>
            </div>
            <div class="col-md-6">
                <div class="box" v-if="customer._id">
                    <div class="box-header d-gray">
                         <h6 class="text-muted _400 inline" style="vertical-align: middle;"></h6>
                    </div>
                    <div class="box-divider m-a-0"/>
                    <div>
                        <div class="l-gray p-b">
                            <ul class="btn-group nav nav-pills p-t p-l">
                                <li class="nav-item">
                                    <a class="btn btn-sm white none-radius primary-color"
                                       @click="currentTab = 'labels'" :class="{ 'active': currentTab == 'labels'}">
                                        <span class="_400">Etykiety klienta</span>
                                    </a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="btn btn-sm white none-radius primary-color" 
                                    v-on:click="currentTab = 'clientCodes'" v-bind:class="{ 'active': currentTab == 'clientCodes'}">
                                    <span class="_400">Wysyłka</span>
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="box-divider m-a-0"/>
                        <div class="tab-content m-b-md">
                            <div class="tab-pane animated fadeIn active text-muted">
                                <CustomerLabels v-if="currentTab == 'labels'" :customer="customer"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <DeliverAdresses v-if="customer._id" :customerData="customer"/>
                <WfirmaUnsettledPayments v-if="customer._id" :customer="customer"/>
                <PickupPoints v-if="customer._id" :customerData="customer"/>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import DeliverAdresses from './DeliverAdresses.vue';
import Details from './Details.vue';
import CustomerLabels from './CustomerLabels.vue';
import PickupPoints from './PickupPoints.vue';
import WfirmaUnsettledPayments from './WfirmaUnsettledPayments.vue';
import UserManager from '../../UserManager.js'

export default {
    name: 'CustomerCard',
    components: {
        Spinner,
        Details,
        DeliverAdresses,
        CustomerLabels,
        PickupPoints,
        WfirmaUnsettledPayments
    },
    data: function () {
        return {
            isLoaded: false,
            customer: {},
            currentTab: 'labels',
            user: UserManager.user,
            UserManager
        }
    },
    created: function() {
        this.isLoaded = true;
        Api.customer.get(this.$route.params.id).then((customer) => {
            this.customer = customer;
            this.isLoaded = false;
        }).catch((reson) => {
            console.log('error');
        });
        UserManager.events.on('userReady', () => {
            this.user = UserManager.user;
        });
    }
}
</script>
