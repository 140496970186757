<template>
  <div class="box">
    <div class="box-header">
      <h2 class="title-color m-b-sm inline">Dane stanowiska</h2>
    </div>
    <div class="m-a-0 custom-divider"></div>
    <ul class="list-group no-border m-t-sm">
      <li class="list-group-item no-border p-b-lg" v-if="!isChange">
        <div class="row m-b-xs">
          <h6 class="col-md-12 _400 m-b" v-if="workPlace.name">{{workPlace.name}}</h6>
          <span class="col-md-12" v-else>Brak nazwy</span>
        </div>
        <div class="row m-b-xs">
          <span class=" primary-color col-md-3">Opis:</span>
          <span class="col-md-9" v-if="workPlace.desc">{{workPlace.desc}}</span>
          <span class="col-md-9" v-else>- - -</span>
        </div>
        <div class="row m-b-xs">
          <span class=" primary-color col-md-3">Miejsce:</span>
          <span class="col-md-9" v-if="workPlace.hol">{{workPlace.hol}}</span>
          <span class="col-md-9" v-else>- - -</span>
        </div>
        <a href="#" class="pull-right text-blue text-sm m-t" v-on:click.prevent="isChange = true">Zmień dane</a>
      </li>

      <li class="list-group-item no-border p-b-lg" v-if="isChange">
        <form class="form-horizontal">
          <div class="form-group row">
            <div class="col-sm-12">
              <input class="form-control" type="text" v-model="workPlace.name">
            </div>
          </div>
          <div class="form-group row m-b-sm">
            <label class="col-sm-3 form-control-label primary-color">Opis</label>
            <div class="col-sm-9">
              <input class="form-control form-control-sm" type="text" v-model="workPlace.desc">
            </div>
          </div>
          
          <div class="form-group row m-b-sm">
            <label class="col-sm-3 form-control-label primary-color">Miejsce</label>
            <div class="col-sm-9">
              <select2 :options="places" class="form-control">
              </select2>
            </div>
          </div>
        </form>
        <div class="m-a-0 custom-divider p-t"></div>
        <a href="#" class="pull-left text-blue text-sm m-t" v-on:click.prevent="cancel">Cofnij</a>
        <a href="#" class="pull-right text-blue text-sm m-t" v-on:click.prevent="save">Zapisz zmiany</a>
      </li>

    </ul>
  </div>
</template>

<script>
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
export default {
  name: 'Detalis',
  props: {
    wPlace: Object
  },
  components: {
    Select2,
  },
  data: function () {
    return {
      workPlace: Object.assign({}, this.wPlace),
      isChange: false,
      places: [
        { id: 1, text: 'Hala 1' },
        { id: 2, text: 'Hala 2' },
        { id: 3, text: 'Biuro' },
      ],
    }
  },
  created: function() {
    
  },
  methods: {
    save: function() {
      Api.production.editWorkplace(this.workPlace).then((place) => {
        this.workPlace = place;
        this.$notify({group: 'global', type: 'notification-success', text: 'Pomyślnie zmieniono.'});
        this.isChange = false;
      })
    },
    cancel: function() {
      this.workPlace = this.wPlace;
      this.isChange = false;
    }
  }
}
</script>