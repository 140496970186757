<template>
    <div class="box" style="100%; min-height:460px; max-height:800px; overflow-y: auto;">
        <div class="box-header">
            <h3>Szczegóły pozyji</h3>
            <small>Co się dzieje z tym produktem na produkcji</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <div class="m-b">
                <div class="box" v-for="details in productionDetails" :key="details.part._id">
                    <div class="progress-sm progress progress-striped" :class="{'active': !details.part.isCompleted}">
                        <div class="progress-bar orange" :style="{'width': parseInt((details.part.progress || 0)*100)+'%'}">Postęp pozycji{{ parseInt((details.part.progress || 0)*100) }}%</div>
                    </div>
                    <div class="progress-sm progress progress-striped active">
                        <div class="progress-bar info" :style="{'width': parseInt((details.pr.progress || 0)*100)+'%'}">Postęp całego ZP {{ parseInt((details.pr.progress || 0)*100) }}%</div>
                    </div>
                    <div class="box-header">
                        <h3><a class="text-blue">ZP #{{ details.pr._id.substr(details.pr._id.length - 5).toUpperCase() }}</a></h3>
                        <small v-if="details.pr.status != 'errored'">(Postęp {{ parseInt((details.pr.progress || 0)*100) }}%)</small>
                    </div>
                    <div class="box-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Typ zadania</th>
                                    <th>Status</th>
                                    <th>Ilość</th>
                                    <th width="15%">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="t in details.tasks" :key="t._id">
                                    <td>
                                        <img v-if="t.taskDef && t.taskDef.iconUrl" class="icon m-r-sm" :src="t.taskDef.iconUrl">
                                        <span v-if="t.taskDef && t.taskDef.name">{{t.taskDef.name | truncate(30)}}</span>
                                        <span v-else>Brak nazwy</span>
                                        <span v-if="t.logMark && taskToEditMark != t._id" class="_600 m-l-sm" :style="{ color: t.taskDef.color }">{{t.logMark}}</span>
                                        <i v-if="taskToEditMark != t._id && t.status == 'completed'"
                                            class="material-icons md-18 m-l-xs text-muted" @click="editMark(t)">edit</i>
                                        <span v-if="taskToEditMark == t._id">
                                            <input class="form-control mark-input" type="text" v-model="t.logMark">
                                            <i class="material-icons md-18 text-green" @click="saveLogMark(t)">check</i>
                                        </span>

                                    </td>
                                    <td><TaskStatusSpan :task="t" /></td>
                                    <td>{{ t.madeCount }}/{{ t.toMadeCount }}szt.</td>
                                    <td style="padding-right:0px;" width="15%">
                                        <span style="width:33%; display: inline-block;">
                                            <a @click.prevent="forceRun(t)" v-if="t.status == 'unactive'" title="Wymuś rozpoczęcie produkcj" href="#">
                                            <i class="material-icons md-18">play_circle_outline</i>
                                        </a>
                                        </span>
                                        <span style="width:33%; display: inline-block;">
                                            <a class="m-l-sm" title="Zakończ zadanie" @click.prevent="confirmActionManualyEnd(t)" v-if="t.status == 'waiting' ||  t.status =='in-progress'">
                                                <i class="material-icons md-18">check</i>
                                            </a>
                                        </span>
                                        <span style="width:33%; display: inline-block;">
                                            <a class="m-l-sm" title="Zobacz szczegóły" @click.prevent="taskDetails(t)">
                                                <i class="material-icons md-18">search</i>
                                            </a>
                                        </span>
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <input class="form-control m-b" v-model="logsSearch" type="text" placeholder="Szukaj w logach">
                <div class="streamline b-l m-b m-l" v-if="worklogs.length">
                    <div class="sl-item" :class="worklog.classes" v-for="worklog in worklogs" :key="worklog._id">
                        <div class="sl-icon"><img v-if="worklog.taskIcon" class="icon m-r-sm" :src="worklog.taskIcon"></div>
                        <div class="sl-content">
                            <div class="sl-date text-muted">{{ worklog.createdAt | moment('DD/MM HH:mm:ss') }}</div>
                            <p>
                                <span>{{ worklog.employee }}</span>
                                <span>{{ worklog.user }}</span>
                                <span v-if="worklog.code == 'started_working_on'"> rozpoczął pracę nad {{ worklog.taskName }}</span>
                                <span v-else-if="worklog.code == 'cancel_working_on'"> porzucił pracę nad {{ worklog.taskName }}</span>
                                <span v-else-if="worklog.code == 'ended_working_on'">
                                    zakończył pracę nad {{ worklog.taskName }}<br>
                                    zrobił <strong>{{ worklog.madeQty}}szt.</strong>
                                    <span v-if="worklog.leftProductsAt">i nadał im znacznik <strong>{{ worklog.leftProductsAt.mark }}</strong></span>
                                </span>
                                <span v-else-if="worklog.code == 'manualy_end'"> zakończył ręcznie zadanie {{ worklog.taskName }}
                                     <span v-if="worklog.leftProductsAt">i nadał im znacznik <strong>{{ worklog.leftProductsAt.mark }}</strong></span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="text-muted text-center" v-else>
                    Na razie brak logów pracy
                </div>
            </div>
        </div>

        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import EmployeeManager from '../../../EmployeeManager.js';
import SystemUsersManager from '../../../SystemUsersManager.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import TaskStatusSpan from '../TaskStatusSpan.vue';
import TaskDetailsModal from '../TaskDetailsModal.vue';
import Datepicker from 'vuejs-datepicker';
export default {
    name: 'CartPosDetailsModal',
    props: {
        cartPos: Object,
        order: Object
    },
    components: {
        RotateSquare2,
        TaskStatusSpan
        // Datepicker
    },
    data: function () {
        return {
            isProcessing: false,
            isReady: false,
            selectedProdReqTasks: [],
            employees: [],
            prs: [],
            tasks: [],
            wlogs: [],
            logsSearch: "",
            taskToEditMark:""
        }
    },
    created: async function() {

    },
    mounted: async function() {
        this.isProcessing = true;
        try {
            this.employees = await EmployeeManager.getEmployees();
            await SystemUsersManager.getSystemUsers();
            const orderPRs = await Api.production.request.search({ orderId: this.order._id });
            this.prs = orderPRs.filter(pr => pr.parts.find(part => part.cartPos == this.cartPos._id) != null);
            const relatedParts = this.getRelatedZPParts().map(p => p._id);
            const r = await Api.tasks.v2.search({ productionRequestPart: relatedParts, withTaskDef: true });
            this.tasks = r.data;
            this.wlogs = await Api.worklogs.v2.search({ task: this.tasks.map(pr => pr._id) });
        } catch (error) {}
        this.isProcessing = false;
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        worklogs() {
            const relatedParts = this.getRelatedZPParts().map(p => p._id);
            const logs = [...this.wlogs].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(log => {
                const task = this.tasks.find(t => t._id == log.task);
                if (!task || !task.productionRequestPart) return null;
                if (relatedParts.indexOf(task.productionRequestPart) == -1) return null;
                const extraInfo = {
                    taskName: task.taskDef.name,
                    taskIcon: task.taskDef.iconUrl,
                    employee: EmployeeManager.getName(log.employeeId) || "",
                    classes: {
                        'b-success': log.code == 'ended_working_on',
                        'b-info': log.code == 'started_working_on',
                        'b-warning': log.code == 'cancel_working_on'
                    },
                    user: SystemUsersManager.getName(log.userId) || "",
                };

                if (this.logsSearch) {
                    let q = this.logsSearch.toUpperCase();
                    if (extraInfo.taskName.toUpperCase().indexOf(q) == -1 && extraInfo.employee.toUpperCase().indexOf(q) == -1) return null;
                }

                return Object.assign(extraInfo, log);
            }).filter(l => l != null);

            return logs;
        },

        productionDetails() {
            const r = [];
            for (let i = 0; i < this.prs.length; i++) {
                const PR = this.prs[i];
                const part = PR.parts.find(part => part.cartPos == this.cartPos._id);
                if (!part) continue;

                const tasks = part.tasks.map(tId => this.tasks.find(t => t._id == tId)).filter(t => t != null);

                for (let y = 0; y < tasks.length; y++) {
                    const t = tasks[y];
                    const log = this.worklogs.find(l => l.task == t._id);
                    if (log && log.leftProductsAt && log.leftProductsAt.mark) t.logMark = log.leftProductsAt.mark;
                    if (log) t.worklogId = log._id;
                }

                r.push({
                    pr: PR,
                    part,
                    tasks
                });
            }
            return r;
        }
    },
    methods: {

        getRelatedZPParts() {
            const parts = [];
            for (let i = 0; i < this.prs.length; i++) {
                const PR = this.prs[i];
                for (let x = 0; x < PR.parts.length; x++) {
                    const part = PR.parts[x];
                    if (part.cartPos == this.cartPos._id) parts.push(part);
                }
            }
            return parts;
        },

        async forceRun(task) {
            try {
                const t = await Api.tasks.v2.forceRun(task._id);
                task.status = t.status;
            } catch (error) {}
        },

        saveAndExit: async function() {

        },
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        editMark(task) {
            this.taskToEditMark = task._id;
        },
        async saveLogMark(task) {
            if(!task.worklogId) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Brak loga pracy do którego można przypisać znacznik!' });
                return;
            }
            await Api.worklogs.v2.save(task.worklogId, { mark: task.logMark.toUpperCase() });
            this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano.' });
            this.taskToEditMark = "";
        },
        taskDetails: async function(t) {
            const res = await Api.tasks.v2.search({ _id: t.canBeStartedAfter, withTaskDef: true });
            const taskToEdit = t;
            taskToEdit.startAfterTasks = res.data;
            this.$modal.show(TaskDetailsModal, {
                taskToEdit
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {

                }
            });
        },
        manualyCompletedTask(task) {
            Api.tasks.v2.manualyEnd(task._id).then((log) => {
                this.wlogs.push(log);
                task.status = 'completed';
                task.madeCount = task.toMadeCount;
                 this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano.' });
            }).catch((e) => {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się zakończyć ręcznie zadania.' });
            })
        },
        confirmActionManualyEnd(task) {
            const message = 'Czy napewno chcesz zakończyć ręcznie to zadanie?';
            this.confirmation(message).then(dialog => {
                this.manualyCompletedTask(task);
            }).catch(r => r);
        },
        confirmation: function (message) {
            const options = {
                view: 'my-unique-confirmation',
                customClass: 'custom-confirmation',
                message: message
            };
            return this.$dialog.confirm(options.message, options);
        },
       
    }
}
</script>

<style scoped lang="scss">
.icon {
  width: 15px;
  height: auto;
}

.sl-icon img {
    position: relative;
    left: 2px;
    top: -1px;
}

.progress {
    border-radius: 0;
    margin-bottom: 0;

    .progress-bar {
        border-radius: 0;
    }
}
.mark-input {
    width: 60px;
    display: inline;
    margin-left: 8px;
    height: 1.4rem;
    min-height: 1.4rem;
}
</style>
