<template>
    <div class="order-filters">
        <div class="box">
            <div class="box-body">
                <div v-if="value.service">
                    <p class="primary-color _500">Przewoźnik</p>
                    <ul class="list-unstyled m-t-n-sm">
                        <div class="row primary-color">
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="dpd" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">DPD</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="fedex" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">FedEx</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="furgonetka" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Furgonetka</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="rhenus" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Rhenus</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="geis" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Geis</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="pp" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Poczta Polska</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="marss" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">MARSS</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="suus" v-model="value.service">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">SUUS</span>
                                </label>
                            </div>
                        </div>
                    </ul>
                </div>

                <div v-if="value.status">
                    <p class="primary-color _500">Przesyłki</p>
                    <ul class="list-unstyled m-t-n-sm">
                        <div class="row primary-color">
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" :value="true" v-model="value.notHandedOver">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Nie wydane kurierowi</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="waiting" v-model="value.status">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Oczekujace na nadanie</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="sent" v-model="value.status">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Wysłane</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="in-road" v-model="value.status">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">W drodze</span>
                                </label>
                            </div>
                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="delivered" v-model="value.status">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Doręczone</span>
                                </label>
                            </div>

                            <div class="col-md-12 m-b-xs">
                                <label class="md-check">
                                    <input type="checkbox" @change="onFilterChange" value="delivery-problems" v-model="value.status">
                                    <i class="blue m-r-xs"/>
                                    <span class="m-l-xs">Z problemem</span>
                                </label>
                            </div>

                        </div>
                    </ul>
                </div>
                <hr/>
                <ul class="list-unstyled m-t-n-sm">
                    <div class="row primary-color">
                        <div class="col-md-12 m-b-xs">
                            <label class="md-check">
                                <input type="checkbox" @change="onFilterChange" value="delivery-problems" v-model="value.isOnlyStockOrder">
                                <i class="blue m-r-xs"/>
                                <span class="m-l-xs">Pokaż tylko zamówienia odbioru</span>
                            </label>
                        </div>
                    </div>
                </ul>

            </div>
        </div>
        <div class="box m-b-sm">
            <div class="box-body">
                <p class="primary-color _500">Data</p>
                <div class="input-group">
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger"
                            placeholder="Wybierz daty"
                            :value="formatDates(dateOne, dateTwo)"
                        >

                        <AirbnbStyleDatepicker
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'range'"
                            :date-one="dateOne"
                            :date-two="dateTwo"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @closed="onFilterChange"
                            @date-one-selected="val => { dateOne = val }"
                            @date-two-selected="val => { dateTwo = val }"
                        />
                    </div>
                </div>
                <a class="btn btn-xs text-xs btn-block info none-radius m-t" href="#" @click="getTodayDeliverys">Pokaż dzisiejsze</a>
            </div>
        </div>
        <div class="m-b">
            <a class="text-blue text-sm" href="#" @click="clear">Wyczyść</a>
        </div>
    </div>
</template>

<script>
import format from 'date-fns/format';

export default {
    name: 'Filters',
    props: {
        value: Object
    },
    data: function () {
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: ''
        }
    },
    created: function() {
    },
    destroyed: function() {
    },
    methods: {
        getTodayDeliverys: function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            this.dateOne = today;
            this.dateTwo = today;
            this.onFilterChange();
        },
        onFilterChange: function() {
            this.value.dateTo = "";
            this.value.dateFrom = "";

            if (this.dateOne.length > 0) this.value.dateFrom = this.dateOne + "T00:00:01";
            if (this.dateOne.length == 0 && this.dateTwo.length > 0) this.value.dateFrom = this.dateTwo + "T00:00:01";

            if (this.dateTwo.length == 0 && this.dateOne.length > 0) this.value.dateTo = this.dateOne + "T23:59:59";
            if (this.dateTwo.length > 0) this.value.dateTo = this.dateTwo + "T23:59:59";

            this.$emit('input', this.value);
            this.$emit('change', this.value);
        },
        clear: function() {
            if (Array.isArray(this.value.service)) {
                this.value.service = [];
            }

            if (Array.isArray(this.value.status)) {
                this.value.status = [];
            }
            this.dateOne = '';
            this.dateTwo = '';
            this.value.dateFrom = "";
            this.value.dateTo = "";
            this.onFilterChange();
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat);
            }
            return formattedDates;
        }
    }
}
</script>
