<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm">Punkty odbioru</h2>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body p-b-lg p-t-0">
            <div class="p-t">
                <div class="row m-b-xs" >
                    <span class=" primary-color col-md-12">Paczkomat (InPost): </span>
                </div>
                
                <v-select label="name" v-model="selected" :filterable="false" :options="pickupPoints" @search="onSearchPickup">
                    <template slot="no-options">
                        zacznij pisać, żeby zacząć szukać punktu odbioru
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.code }}<br>
                            <span class="text-muted text-sm">{{option.type}} {{option.description}}</span>
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="d-center">
                            {{ option.code }}
                        </div>
                    </template>
                </v-select>
                
                <a href="#" class="pull-right text-blue text-sm m-t" @click.prevent="save">Zapisz</a>
            </div>

        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';

export default {
    name: 'PickupPoints',
    props: {
        customerData: Object
    },
    components: {
    },
    data: function () {
        return {
            customer: Object.assign({}, this.customerData),
            pickupPoints: [],
            selected: {}
        }
    },
    created: function() {
        if (!this.customer.pickupPoints) this.customer.pickupPoints = {};
        if(this.customer.pickupPoints.inpost) this.selected.code = this.customer.pickupPoints.inpost;
        
    },
    methods: {
        async save() {
             try {
                this.customer.pickupPoints.inpost = this.selected.code;
                await Api.customer.edit(this.customer._id, this.customer);
                 this.$notify({group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.'});
            } catch (error) {
                  this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił błąd.'});
            }
        },
        onSearchPickup: async function(search, loading) {
            if (!search || search.length < 4) return;
            loading(true);
            const res = await Api.shipments.searchPointsFurgonetka({
                location: {
                    points_max_distance: 5.1,
                    search_phrase: search
                },
                filters: {
                    services: ["inpost"],
                    point_types: ["cod_only", "no_orlen"]
                }
            });
            this.pickupPoints = res.points || [];
            loading(false);
        },
    }
}
</script>
