<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm">Dane klienta</h2>
        </div>
        <div class="m-a-0 custom-divider"/>

        <ul class="list-group no-border m-t-sm">
            <li class="list-group-item no-border p-b-lg" v-if="!isChange">
                <div class="clear custom-color">
                    <h6 class="_400 m-b" v-if="order.customer.name">
                        {{order.customer.name}}
                        <span v-if="order.customer.payments && order.customer.payments.unsettledPayments > 0">

                            <span v-if="order.customer.payments.unsettledPayments <= order.customer.payments.limitUnsettledPayments && order.customer.payments.latePayments == 0">
                                <i v-tooltip="'Klient posiada nierozliczone płatności - '+order.customer.payments.unsettledPayments+' zł'" class="material-icons md-18 text-blue">report_problem</i>
                            </span>
                            <span v-if="order.customer.payments.latePayments > 0 && order.customer.payments.latePayments <= order.customer.payments.limitLatePayments && order.customer.payments.unsettledPayments <= order.customer.payments.limitUnsettledPayments">
                                <i v-tooltip="'Klient posiada nierozliczone płatności - '+order.customer.payments.unsettledPayments+' zł'" class="material-icons md-18 text-yellow">report_problem</i>
                            </span>
                            <span v-if="order.customer.payments.unsettledPayments > order.customer.payments.limitUnsettledPayments || order.customer.payments.latePayments > order.customer.payments.limitLatePayments">
                                <i v-tooltip="'Klient posiada nierozliczone płatności - '+order.customer.payments.unsettledPayments+' zł'" class="material-icons md-18 text-red">report_problem</i>
                            </span>

                        </span>
                    </h6>
                    <h6 class="_400 m-b" v-else>Brak nazwy</h6>

                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Email: </span>
                        <span class="col-md-8 col-sm-6" v-if="order.customer.email">{{order.customer.email}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Ulica: </span>
                        <span class="col-md-8 col-sm-6" v-if="order.customer.street">{{order.customer.street}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Miasto: </span>
                        <span class="col-md-8 col-sm-6" v-if="order.customer.postCode && order.customer.city">
                            {{order.customer.postCode}} {{order.customer.city}}
                        </span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Telefon: </span>
                        <span class="col-md-8 col-sm-6" v-if="order.customer.phone">{{order.customer.phone}}</span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-4 col-sm-6">Numer {{order.customer.taxNoType}}: </span>
                        <span class="col-md-8 col-sm-6" v-if="order.customer.taxNo">
                            {{order.customer.taxNo}}
                        </span>
                        <span class="col-md-8 col-sm-6" v-else>- - -</span>
                    </div>
                </div>
                <a v-if="user != null && UserManager.hasPermission('customer.edit')" href="#" class="pull-left text-blue text-sm m-t" @click.prevent="$router.push({ name: 'customer-details', params: { id: order.customer._id } })">Edytuj klienta</a>
                <a v-if="user != null && UserManager.hasPermission('customer.edit')" href="#" class="pull-right text-blue text-sm m-t" @click.prevent="change">Zmień klienta</a>
            </li>

            <li class="list-group-item no-border p-b-lg" v-if="isChange">
                <div>
                    <p class="text-muted">Baza klientów:</p>
                    <div class="form-group l-h m-a-0">
                        <div class="input-group search-input" style="width: 100%">
                            <input class="form-control form-control-sm p-x b-a" placeholder="Szukaj..." type="text" v-model="query" @input="onSearch">
                            <a class="close-btn" href="#" @click.prevent="clear"><i class="material-icons md-16">close</i></a>
                        </div>
                    </div>
                    <Spinner v-if="isLoaded"/>
                    <p class="text-muted m-t text-sm" v-if="isEmpty">Nie znaleziono klienta pasującego do frazy: "{{query}}"</p>
                    <div class="m-t" v-if="customers.length > 0">
                        <div class="box p-a-sm m-b" v-for="c in customers" :key="c._id">
                            <h6 class="_400 m-b-xs" v-if="c.name">{{c.name}}</h6>
                            <h6 class="_400 m-b-xs" v-else>Brak nazwy</h6>
                            <div class="row m-b-xs">
                                <span class=" primary-color col-md-4 col-sm-6">Email: </span>
                                <span class="col-md-8 col-sm-6" v-if="c.email">{{c.email}}</span>
                                <span class="col-md-8 col-sm-6" v-else>- - -</span>
                            </div>
                            <div class="row m-b-xs">
                                <span class=" primary-color col-md-4 col-sm-6">Adres: </span>
                                <span class="col-md-8 col-sm-6" v-if="c.street || c.postCode || c.city">
                                    {{c.street}}<br> {{c.postCode}} {{c.city}}
                                </span>
                                <span class="col-md-8 col-sm-6" v-else>- - -</span>
                            </div>
                            <div class="row m-b-xs">
                                <span class=" primary-color col-md-4 col-sm-6">Numer {{c.taxNoType}}: </span>
                                <span class="col-md-8 col-sm-6" v-if="c.taxNo">
                                    {{c.taxNo}}
                                </span>
                                <span class="col-md-8 col-sm-6" v-else>- - -</span>
                            </div>
                            <div class="text-right">
                                <button class="btn btn-xs blue none-radius" @click="setCustomer(c._id)">Wybierz</button>
                            </div>
                        </div>
                    </div>

                </div>
                <a href="#" class="pull-left text-blue text-sm m-t" @click.prevent="isChange = false, customers.length = 0">Cofnij</a>
            </li>
        </ul>

    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import EditCustomerModal from '../Customers/EditCustomerModal.vue';
import UserManager from '../../UserManager.js'
export default {
    name: 'Customer',
    components: {
        Spinner
    },
    props: {
        order: Object
    },
    data: function () {
        return {
            customers: [],
            isChange: false,
            isEmpty: false,
            isLoaded: false,
            query: '',
            user: UserManager.user,
            UserManager
        }
    },
    created: function() {
        if (!this.order.customer) {
            this.order.customer = {};
            this.isChange = true;
        }
        UserManager.events.on('userReady', () => {
            this.user = UserManager.user;
        });
    },
    methods: {
        editCustomer: function() {
            const customer = this.order.customer;
            this.$modal.show(EditCustomerModal, {
                customer
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                    this.setCustomer(this.order.customer._id)
                }
            });
        },
        onSearch: function() {
            this.isEmpty = false;
            if (this.query.length == 0) {
                this.customers.length = 0;
                return;
            }
            if (this.query.length < 3) return;
            this.isLoaded = true;
            Api.customer.getCustomers(this.query).then((customers) => {
                this.isLoaded = false;
                this.customers.length = 0;
                this.customers.push(...customers);
                if (this.customers.length == 0) this.isEmpty = true;
            });
        },
        clear: function() {
            if (this.query.length == 0) return;
            this.query = '';
            this.customers.length = 0;
            this.isEmpty = false;
        },
        setCustomer: function(id) {
            Api.order.setCustomer(this.order._id, id).then((order) => {
                this.order.customer.length = 0;
                this.order.customer = order.customer;
                this.isChange = false;
                this.clear();
            })
        },
        change: function() {
            this.isChange = true;
        }
    }
}
</script>
