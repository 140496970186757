<template>
    <div class="m-t">

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opis towaru</label>
            <div class="col-sm-5">
                <input class="form-control" type="text" v-model="descriptionOfGoods">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Uwaga dla odbiorcy</label>
            <div class="col-sm-5">
                <input class="form-control" type="text" v-model="remarks" >
            </div>
        </div>
        <hr>
        <strong>Palety w przesyłce</strong>
        <div class="m-t" v-for="(paczka, index) in packages" :key="index">
            <div class="row">
                <span class="pull-left m-x _600" style="margin-top:2.3rem;">#{{index+1}} </span>
                <div class="col-sm-2">
                    <label for=""/>
                    <div class="input-group m-t-sm">
                        <input type="text" class="form-control" v-model="paczka.symbol" placeholder="paleta">
                        <span class="input-group-addon">paleta</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label for="">Wysokość [cm]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.heightCm" placeholder="Wys [cm]">
                        <span class="input-group-addon text-sm p-a-xs">cm</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label for="">Szerokość [cm]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.widthCm" placeholder="Szer [cm]">
                        <span class="input-group-addon text-sm p-a-xs">cm</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label for="">Długość [cm]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.lenghtCm" placeholder="Głębo [cm]">
                        <span class="input-group-addon text-sm p-a-xs">cm</span>
                    </div>
                </div>
                <div class="col-sm-1 ">
                    <label for="">Waga [kg]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.weightKg" placeholder="kg">
                        <span class="input-group-addon text-sm p-a-xs">kg</span>
                    </div>
                </div>
                <div class="col-sm-1" style="margin-top:1.8rem;">
                    <button type="button" class="btn white" tabindex="-1" @click="packages.splice(index, 1)">Usuń</button>
                </div>
            </div>
        </div>

        <div class="form-group row m-t-md">
            <div class="col-sm-3">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(100,80,120,50,'EUR')">EUR - Paleta euro a 120x80x100, 50kg</button>
            </div>
            <div class="col-sm-3">
                <!-- <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(80,60,120,50,'DHP')">DHP - paleta DHP – 80x60, 50kg</button> -->
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(120,80,60,50,'DHP')">DHP - paleta DHP – 80x60, 50kg</button>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(120,100,120,50,'PLT')">PLT - paleta przemysłowa, 50kg</button>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(120,100,120,50,'PAL')">PAL - paleta inna, 50kg</button>
            </div>
        </div>

        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Planowana data załadunku</label>
            <div class="col-sm-3">
                <div class="input-group">
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger"
                            placeholder="Wybierz date"
                            :value="formatDate(dateOne)"
                        >

                        <AirbnbStyleDatepicker
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'single'"
                            :date-one="dateOne"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @date-one-selected="val => { dateOne = val }"
                        />
                    </div>
                </div>

            </div>

        </div>
        <hr>

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input type="checkbox" v-model="isCod" >
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input  type="checkbox" v-model="isPoj">
                        <i class="blue"/> Ubezpieczenie</label>
                </p>
                <p>
                    <label class="md-check">
                        <input  type="checkbox" v-model="isVdl">
                        <i class="blue"/> Dokumenty zwrotne</label>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-3">
                <input type="text" class="form-control"  @change="parsePrice" placeholder="Kwota pobrania [zl]" v-model="codePrice">
            </div>
        </div>
        <div class="form-group row" v-if="isPoj">
            <label class="col-sm-3 form-control-label">Kwota ubezpieczenia</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" @change="parsePojPrice"  placeholder="Kwota ubezpieczenia [zl]" v-model="pojPrice">
            </div>
        </div>

        <div class="form-group row" v-if="isVdl">
            <label class="col-sm-3 form-control-label">Numer dokumnetu zwrotnego</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" placeholder="Numer dokumnetu zwrotnego" v-model="vdlDocNumber">
            </div>
        </div>
        <div class="form-group row" v-if="isVdl">
            <label class="col-sm-3 form-control-label">Typ dokumnetu zwrotnego</label>
            <div class="col-sm-5">
                 <span >
                    <p class="m-t" >
                        <label class="md-check">
                            <input v-model="vdlType.type" type="radio" value="FK">
                            <i class="primary"/> FK - Faktura
                        </label>
                    </p>
                    <p>
                        <label class="md-check">
                            <input v-model="vdlType.type" type="radio" value="WZ">
                            <i class="primary"/> WZ - wz
                        </label>
                    </p>
                    <p>
                        <label class="md-check">
                            <input v-model="vdlType.type" type="radio" value="ZLEC">
                            <i class="primary"/> ZLEC – Zlecenie Spedycyjne
                        </label>
                    </p>
                    <p>
                        <label class="md-check">
                            <input v-model="vdlType.type" type="radio" value="SPEC">
                            <i class="primary"/> SPEC – Specyfikacja zamówienia
                        </label>
                    </p>
                </span>
            </div>
        </div>
        <div class="form-group row" v-if="isVdl">
            <label class="col-sm-3 form-control-label">Znacznik dokumnetu zwrotnego</label>
            <div class="col-sm-5">
                 <span >
                    <p class="m-t" >
                        <label class="md-check">
                            <input v-model="vdlZnacznik.type" type="radio" value="DZ">
                            <i class="primary"/> DZ - dokumenty zwrotne (zaznacza pole ROD)
                        </label>
                    </p>
                    <p>
                        <label class="md-check">
                            <input v-model="vdlZnacznik.type" type="radio" value="DT">
                            <i class="primary"/> DT - dokumenty towarzyszące (zaznacza pole eROD)
                        </label>
                    </p>
                    
                </span>
            </div>
        </div>
        <a  href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>

    </div>
</template>
<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
import format from 'date-fns/format';

export default {
    name: 'CarrierRhenus',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        stockOrder: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            packages: [],
            package: {},
            descriptionOfGoods: "Filtry powietrza",
            remarks: "",
            
            isCod: false,
            codePrice: 0,
            isPoj: false,
            pojPrice: 0,
            isVdl: false,
            vdlDocNumber:"",
            vdlType: { type: null },
            vdlZnacznik: { type: null },

            dateFormat: 'D MMM',
            dateOne: new Date(),
        }
    },
    created: function() {
        this.packages.push({
            symbol: "EUR",
            quantity: 1,
            weightKg: 100,
            lenghtCm: 120,
            widthCm: 80,
            heightCm: 100
        });

         if (this.order.invoiceNo != null) {
            this.vdlDocNumber = this.order.invoiceNo;
        }

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets();

        if (this.order.clientNo && this.order.clientNo.length > 0) {
            this.descriptionOfGoods = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }
    },
    methods: {
        parsePrice: function() {
            this.codePrice = this.codePrice.replace(/,/g, '.');
        },
        parsePojPrice: function() {
            this.pojPrice = this.pojPrice.replace(/,/g, '.');
        },
        addNewParcel: function(height, width, depth, weight, type) {
            this.packages.push({
                symbol: type,
                quantity: 1,
                weightKg: weight,
                lenghtCm: depth,
                widthCm: width,
                heightCm: height
            });
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA i SUUS
        setBoxesAndPallets() {
            this.packages.length = 0;

            for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                const box = this.order.shippingPalletes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                let symbol = "EUR";
                if(parseFloat(box.width / 10).toFixed(2) == 60) symbol = "DHP";
                this.packages.push({
                    symbol: symbol,
                    quantity: 1,
                    weightKg: box.mass,
                    lenghtCm: parseFloat(box.width / 10).toFixed(2),
                    widthCm: parseFloat(box.length / 10).toFixed(2),
                    heightCm: parseFloat(box.height / 10).toFixed(2)
                });
            }
        },
        setData: function() {
            let data = {};
            this.package.header = {
                loadingDate: this.dateOne,
                reference: Math.floor(Math.random() * Math.floor(Math.random() * Date.now())), //refenrcja musi być niepowtarzalna za kazda przesyłką!!!!
                descriptionOfGoods: this.descriptionOfGoods,
                remarks: this.remarks,
                orderType: "B2B"
            };
            this.package.loadingAddress = this.setSenderAddress();
            this.package.unloadingAddress = this.setDeliveryAddress();
            this.package.packages = {
                package: []
            };
            for (let i = 0; i < this.packages.length; i++) {
                const paczka = this.packages[i];
                paczka.quantity = 1;
                paczka.weightKg = parseFloat((paczka.weightKg + "").replace(/,/g, '.'));
                paczka.lenghtCm = parseFloat((paczka.lenghtCm + "").replace(/,/g, '.'));
                paczka.widthCm = parseFloat((paczka.widthCm + "").replace(/,/g, '.'));
                paczka.heightCm = parseFloat((paczka.heightCm + "").replace(/,/g, '.'));

                this.package.packages.package.push(paczka);
            }
            this.package.additionalServices = {
                additionalService: []
            };

            if (this.isCod) {
                const obj = {
                    symbol: "RohligCOD",
                    decimal1: parseFloat(this.codePrice)
                };
                this.package.additionalServices.additionalService.push(obj);
            }

            if (this.isPoj) {
                const obj = {
                    symbol: "RohligUbezpieczenie3",
                    decimal1: parseFloat(this.pojPrice),
                    decimal2: 0, //koszty dodakotwe tj akcyza czy cło
                    int01: 1 //świadczam, że przewożony ładunek nie należy do grup towarów wyłączonych
                };
                this.package.additionalServices.additionalService.push(obj);
            }

            if (this.isVdl) {
                const obj = {
                    symbol: "StdDokumentyZwrotneINiezwrotneGrid2",
                    int01: 1,  //świadczam, że przewożony ładunek nie należy do grup towarów wyłączonych
                    varchar1: this.vdlDocNumber,
                    varchar2: this.vdlZnacznik.type,
                    varchar3: this.vdlType.type
                };
                this.package.additionalServices.additionalService.push(obj);
            }

            data.package = this.package;

            return data;
        },
        save: async function() {
            this.isProcessing = true;
            const data = this.setData();
            data.order = this.order._id;

            try {
                const shipments = await Api.shipments.suusSent(data);
                // jeszcze do konca nie wiem czemu metoda sent zwraca tablice
                for (const shipment of shipments) this.$emit('shipment', shipment);
                this.$h.success('Dodano przesyłkę');
            } catch (e) {
                console.log("error suus", e)
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        setSenderAddress: function() {
            const arr = this.sender.street.split(' ');
            const numer = arr.pop();
            const street = arr.join(' ');
            
            const sender = {
                name: this.sender.company_name,
                street: street,
                streetNo: numer,
                postCode: this.sender.postcode,
                city: this.sender.city,
                country: "PL",
                email: this.sender.email,
                mobilePhone: this.sender.phone,
                person: this.sender.firstname + " " + this.sender.lastname
            }

            return sender;
        },
        setDeliveryAddress: function() {
            const arr = this.recipient.street.split(' ');
            const numer = arr.pop();
            const street = arr.join(' ');

            const r = {
                name: this.recipient.name,
                street: street,
                streetNo: numer,
                postCode: this.recipient.postCode,
                city: this.recipient.city,
                country:  this.recipient.countryCode ? this.recipient.countryCode : "PL",
                email:  this.recipient.email ? this.recipient.email : "",
                person: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name

            };
            if (this.recipient.phone.length > 0) {
                if (this.recipient.phone.includes("+")) r.phone = this.recipient.phone;
                else r.mobilePhone = this.recipient.phone;
            } else r.mobilePhone = this.recipient.phone;

            return r;
        },
        formatDate(dateOne) {
            let formattedDates = '';
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            return formattedDates;
        },
    }
}
</script>

<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
