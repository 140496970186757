<template>
    <div class="box-body">
        <div class="row">
            <Spinner v-if="isLoading"/>
            <div v-else class="col-md-3">
                <Details v-if="workPlace._id" :wPlace="workPlace"/>
                <WorkPlaceEmployees v-if="workPlace._id" :wPlace="workPlace"/>
                <TasksSummary :wPlace="workPlace" :day="currentlySelectedDay" :schedule="currentlySelectedSchedule"/>
                <router-link class="nav-link text-muted hover-dark" to="/production/workplaces">
                    <span class="text-sm">Powrót do list stanowisk</span>
                </router-link>
            </div>
            <div class="col-md-9">

                <TasksList  v-if="workPlace._id" :wPlace="workPlace" @schedule-changed="onSelectedScheduleChanged" />

            </div>

        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Details from './Details.vue'
import WorkPlaceEmployees from './WorkPlaceEmployees.vue'
import TasksList from './TasksList.vue'
import TasksSummary from './TasksSummary.vue'
import Spinner from '../Spinner.vue';

export default {
    name: 'ProductsList',
    components: {
        Details,
        WorkPlaceEmployees,
        TasksList,
        TasksSummary,
        Spinner
    },
    data: function () {
        return {
            isLoading: false,
            workPlace: {},
            currentlySelectedDay: "",
            currentlySelectedSchedule: null
        }
    },
    created: function() {
        this.isLoading = true;
        Api.production.getWorkplace(this.$route.params.id).then((workPlace) => {
            this.workPlace = workPlace;
            this.isLoading = false;
        }).catch((error) => {
            this.$h.commonApiError(error, true);
        });
    },

    methods: {
        onSelectedScheduleChanged(sch) {
            this.currentlySelectedSchedule = sch;
        }
    }
}
</script>
