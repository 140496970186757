<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Zmień adres</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Adres email</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="recive.email">
                  </div>
                </div>
            </form>           
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import countryCodes from "../../Common.js";

export default {
    name: 'ShipmentEmailChangeModal',
    props: {
        shipmentToChange: Object
    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            isProcessing: false,
            shipment: {},
            recive: {}
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (this.shipmentToChange) {
            this.shipment = JSON.parse(JSON.stringify(this.shipmentToChange)); //deep-copy
            this.recive = JSON.parse(JSON.stringify(this.shipmentToChange.receiverAddress));
        }

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        saveAndExit: async function () {
            this.isProcessing = true;
            try {
                let cust = await Api.shipments.setReciverEmail(this.shipment._id, this.recive.email);
                this.isProcessing = false;
                this.$set(this.shipmentToChange, "receiverAddress", cust.receiverAddress);
                this.$emit('close');
            } catch(e) {
                this.isProcessing = false;
                this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'+e});
            }
          
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

