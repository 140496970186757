<template>
    <div class="row">
        <div class="col-md-12">
            <div class="box-divider m-a-0"/>
            <div class="l-gray p-x p-y-sm" v-if="inProgress">
                <RotateSquare4 class="recipe-spinner" style="width: 20px; height: 20px; display: inline-block;" />
            </div>
            <div class="l-gray p-x p-y-sm" v-else>
                <div class="dropdown dropdown-submenu d-inline">
                    <button  data-toggle="dropdown" class="btn btn-sm white none-radius dropdown-toggle" :disabled="shipments.length == 0">
                        <span class="primary-color _400">Generuj</span>
                    </button>&nbsp;
                    <ul role="menu" class="dropdown-menu pull-down text-color" v-if="shipments.length > 0">
                        <li class="dropdown-item">
                            <a @click.prevent="generateShipLabels"><span class="primary-color _400">Listy przewozowe</span></a>
                        </li>
                        <li class="dropdown-item">
                            <a @click.prevent="generateShipProtocols"><span class="primary-color _400">Protokół odbioru</span></a>
                        </li>
                    </ul>
                </div>
                <button class="btn btn-sm white none-radius" @click.prevent="send" :disabled="waitingShipments.length == 0">
                    <span class="primary-color _400">Wyślij</span>
                </button>&nbsp;
                <button class="btn btn-sm white none-radius" @click="remove" :disabled="shipments.length == 0">
                    <span class="primary-color _400">Usuń</span>
                </button>&nbsp;
                <button class="btn btn-sm white none-radius" @click="notifByEmail" :disabled="shipmentsWithEmail.length == 0">
                    <span class="primary-color _400">Powiadom o nadaniu <span v-if="shipmentsWithEmail.length > 0">({{ shipmentsWithEmail.length }})</span></span>
                </button>&nbsp;
                <button class="btn btn-sm white none-radius" @click="handedOver" :disabled="shipments.length == 0">
                    <span class="primary-color _400">Oznacz jako wydane <span v-if="shipmentsWithEmail.length > 0">({{ shipments.length }})</span></span>
                </button>&nbsp;
            </div>
            <div class="box-divider m-a-0"/>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import FileSaver from 'file-saver';
import { RotateSquare2, RotateSquare4 } from 'vue-loading-spinner';

export default {
    name: 'BtnGroup',
    props: {
        shipments: Array
    },

    components: {
        RotateSquare4
    },

    data: function() {
        return {
            inProgress: false
        }
    },
    created: function() {

    },

    computed: {
        waitingShipments: function() {
            return this.shipments.filter(s => s.status == "waiting");
        },

        shipmentsWithEmail: function() {
            return this.shipments.filter(s => {
                if (!s.extra) s.extra = {};
                return s.receiverAddress && s.receiverAddress.email && !s.extra.wasNotifiedByEmail;
            });
        }
    },

    methods: {
        generateShipLabels: async function(cart) {
            if (this.shipments.filter(s => s.isSelected).length == 0) { return this.$notify({ group: 'global', type: 'notification-error', text: 'Wybierz przynajmniej jedną przesyłkę' }); }

            this.$notify({ group: 'global', type: 'notification-success', text: 'Trwa generowanie...' });

            try {
                const r = await Api.shipments.generateWaybills(this.shipments.filter(s => s.isSelected).map(s => s._id));
                FileSaver.saveAs(r, 'etykiety.zip');
            } catch (e) {
                if (e.error) this.$notify({ group: 'global', type: 'notification-error', text: e.error });
                else this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się wygenerować labelek.' });
            }
        },

        generateShipProtocols: async function(cart) {
            if (this.shipments.filter(s => s.isSelected).length == 0) { return this.$notify({ group: 'global', type: 'notification-error', text: 'Wybierz przynajmniej jedną przesyłkę' }); }

            this.$notify({ group: 'global', type: 'notification-success', text: 'Trwa generowanie...' });

            try {
                const r = await Api.shipments.generateProtocols(this.shipments.filter(s => s.isSelected).map(s => s._id));
                FileSaver.saveAs(r, 'protokoly.zip');
            } catch (e) {
                if (e.error) this.$notify({ group: 'global', type: 'notification-error', text: e.error });
                else this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się wygenerować protokołu.' });
            }
        },

        send: async function() {
            const furgonetkaWaiting = this.shipments.filter(s => s.status == "waiting" && s.service == "furgonetka")
            if (furgonetkaWaiting.length == 0) {
                return this.$notify({ group: 'global', type: 'notification-error', text: 'Wybierz przynajmniej jedną oczekującą przesyłkę z furgonetki!' });
            }

            try {
                const r = await Api.shipments.furgonetkaSend(furgonetkaWaiting.map(sh => sh._id));
                for (let i = 0; i < r.length; i++) {
                    const shipment = r[i];
                    const modelS = this.shipments.find(s => s._id == shipment._id);
                    if (!modelS) continue;
                    modelS.status = shipment.status;
                }
            } catch (error) {
                console.error(error);
                return this.$notify({ group: 'global', type: 'notification-error', text: 'Kurde... coś nie pykło... :(' });
            }
        },

        notifByEmail: async function() {
            if (this.shipmentsWithEmail.length == 0) return;
            this.inProgress = true;
            try {
                const r = await Api.shipments.sendEmailNotif(this.shipmentsWithEmail.map(sh => sh._id));
                this.$emit("changed", r);
            } catch (error) {
                console.error(error);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Kurde... coś nie pykło... :(' });
            }
            this.inProgress = false;
        },

        handedOver: async function() {
            if (this.shipments.length == 0) return;
            this.inProgress = true;
            try {
                const r = await Api.shipments.handedOverByUser(this.shipments.map(sh => sh._id));
                this.$emit("changed", r);
            } catch (error) {
                console.error(error);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Kurde... coś nie pykło... :(' });
            }
            this.inProgress = false;
        },

        remove: async function() {
            try {
                await this.$dialog.confirm("Uwaga! Usunięcie przesyłek w tym miejscu, NIE spowoduje ich usunięcia u przewoźnika. Musisz to zrobić ręcznie przez panel danego kuriera. Czy na pewno chcesz usunąć te przesyłki?", {
                    view: 'my-unique-confirmation',
                    customClass: 'custom-confirmation'
                });
            } catch (error) { return; }

            this.inProgress = true;
            try {
                const r = await Api.shipments.remove(this.shipments.map(sh => sh._id));
                this.$emit("changed", r);
            } catch (error) {
                console.error(error);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Kurde... coś nie pykło... :(' });
            }
            this.inProgress = false;
        }
    }
}
</script>
