<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Pliki</h6>
            <span class="form-file">
                <input type="file" ref="files" multiple @change="onAdd" style="display: none;">
                <a href="#" class="pull-right text-blue text-sm m-t" @click.prevent="$refs.files.click();">Dodaj plik</a>
            </span>
        </div>
        <div class="m-a-0 custom-divider"/>
        <p class="text-xs text-blue m-x m-y-xs">  <i class="material-icons md-18 text-blue"></i>Aby pliki były widoczne na aplikacji muszą być one w formacje .png lub .jpg</p>
        <div class="box-divider m-y-xs"></div>
        <div class="box-body">
            <div class="row">
                <div class="col-md-12">

                    <div class="pdf-box m-b-sm" v-for="uf in uploadedFiles" v-if="!uf.isSent">
                        <div class="p-y-sm p-x">
                            <div class="pull-left m-r">
                                <i class="material-icons text-d-blue m-y-sm text-2x ">photo_library</i>
                            </div>
                            <div class="clear">
                                <div class="text-muted">{{uf.file.name | truncate(25) }}</div>
                                <div class="progress m-t-xs" v-if="!isError">
                                    <div class="progress-bar blue" :style="{ width: uf.progress + '%' }">{{uf.progress}} %</div>
                                </div>
                                <div  class="upload-error p-a-xs m-t-xs" v-if="isError">
                                    <span class="text-white text-xs">Wystąpił błąd w trakcie wysyłania pliku. Spróbuj przesłać plik jeszcze raz lub zgłoś problem do swojego admina.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" v-if="product.attachments.length > 0 && isReady">
                    <div class="pdf-box m-b-sm" v-for="a in product.attachments">
                        <div class="p-y-sm p-x-sm">
                            <div class="pull-left m-r">
                                <i class="material-icons text-d-blue text-2x ">photo_library</i>
                            </div>
                            <div class="clear">
                                <div>
                                    <a class="text-blue" :href="Api.getFileUrl(a.key, a.name)" target="_blank">{{a.name | truncate(25) }}</a>
                                    <a class="text-muted pull-right text-red" href="#" @click.prevent="remove(a)">
                                        <i class="material-icons md-18"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 primary-color" v-if="product.attachments.length == 0 && isReady">Brak plików przypisanych do produktu.</div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';

export default {
    name: 'ProductFiles',
    props: {
        product: Object
    },
    data() {
        return {
            isReady: true,
            uploadedFiles: [],
            Api: Api,
            isError: false
        }
    },
    created: function() {
        if (!this.product.attachments) this.product.attachments = [];
    },
    methods: {
        onAdd: function() {
            const uploadedFiles = this.$refs.files.files;
            this.handleFilesUpload(uploadedFiles);
        },
        handleFilesUpload: function(uploadedFiles) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                const fObj = {
                    file: uploadedFiles[i],
                    progress: 0,
                    isSent: false
                };

                this.uploadedFiles.push(fObj);

                const formData = new FormData();
                formData.append('file', uploadedFiles[i]);

                this.isError = false;
                this.isEmpty = false;

                Api.uploadFile(formData, (progressEvent) => {
                    fObj.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                })
                    .then((ob) => {
                        fObj.isSent = true;
                        this.addAttachemt(ob.fileKey, ob.fileName);
                    }).catch((reson) => {
                        this.isError = true;
                    });
            }
        },
        addAttachemt: function(key, name) {
            this.product.attachments.push({ key: key, name: name });
            this.save();
        },
        remove: function(attach) {
            this.product.attachments = this.product.attachments.filter(a => a.key != attach.key);
            this.save();
        },
        async save() {
            try {
                await Api.product.modifyProduct(this.product)
                this.$helpers.success("Akcja przebiegła pomyślnie.");
            } catch (error) {
                this.$helpers.errorNotif('Wystąpił błąd.');
                console.log("err", error)
            }
        }
    }
}
</script>
<style scoped lang="scss">
.progress {
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
}
.progress-bar {
  border-radius: 0px;
}
.upload-error {
    display: inline-block;
    width: 100%;
    background-color: #f05050;
  }
</style>
