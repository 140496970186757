/**
 * 
 * @param {string[]} typFiltra 
 * @returns {any[]}
 */
function getTableDef(typFiltra) {
    let def = [];
    def.push({
        name: "date",
        title: 'Data',
    });

    // let typFiltra = ["FK", "FKA", "FKP", "WFKA", "WŁ", "FKM", "FP"];
    for (let i = 0; i < typFiltra.length; i++) {
        let key = "production."+typFiltra[i];
        def.push({
            name: key,
            title: typFiltra[i],
            titleClass: "l-blue border-vuetable",
            dataClass: "white"
        });
    }

    for (let i = 0; i < typFiltra.length; i++) {
        let key = "realized."+typFiltra[i];
        def.push({
            name: key,
            title: typFiltra[i],
            titleClass: "d-blue border-vuetable",
            dataClass: "white"
        });
    }
    for (let i = 0; i < typFiltra.length; i++) {
        let key = "packed."+typFiltra[i];
        def.push({
            name: key,
            title: typFiltra[i],
            titleClass: "c-green border-vuetable",
            dataClass: "white"
        });
    }

    for (let i = 0; i < typFiltra.length; i++) {
        let key = "sent."+typFiltra[i];
        def.push({
            name: key,
            title: typFiltra[i],
            titleClass: "c-blue border-vuetable",
            dataClass: "white"
        });
    }

    return def;
}


export default getTableDef;
