<template>
    <select><slot></slot></select>
</template>

<script>
import $ from 'jquery'
import 'select2'

export default {
  name: 'Select2',
  props: 
    ['options', 'value', 'template', 'disabled', 'tags'],
  components: {
    
  },
  data: function () {
    return {
      
    }
  },
  mounted: function () {
    var vm = this;
    var s = { data: this.options, tags: this.tags };
    if (this.template) s.templateSelection = this.template;

    $(this.$el)
      // init select2
      .select2(s)
      .val(this.value)
      .trigger('change')
      // emit event on change.
      .on('select2:select', function () {
        vm.$emit('input', $(this).val())
      })
      .on('select2:unselect', function () {
        vm.$emit('input', $(this).val())
      })
    ;

    if (this.disabled) {
      $(this.$el).prop("disabled", true);  
    }
  },
  watch: {
    value: function (value) {
      // update value
      $(this.$el)
        .val(value)
        .trigger('change')
    },
    options: function (options) {
      var vm = this;
      // update options
      var s = { data: options, tags: this.tags };
      if (this.template) s.templateSelection = this.template;

      $(this.$el).empty().select2(s);
      $(this.$el)
        .val(this.value)
        .trigger('change')
    }
  },
  destroyed: function () {
    $(this.$el).off().select2('destroy')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
