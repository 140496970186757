<template>
    <div class="pull-right m-r">
        <div class="m-t" v-if="timer == null">
            <a class="text-muted text-xs primary-color" @click.prevent="startTimer"><i class="material-icons md-18 primary-color"></i> Uruchom zegar pracy</a>
        </div>
        <div v-else>
            <div class="m-t-sm">
                <span class="time text-xs primary-color">Czas pracy: {{ time }}</span>
                <span class="m-x-sm primary-color">|</span>
                <span class="text-blue text-xs">Czas przerwy: {{breakerTimer}}</span>
            </div>
            <div class="btn-container">
                <a class="m-r-xs" v-if="!isBreakTimeOn" @click.prevent="startBreak"  v-tooltip="'Rozpocznij przerwę.'"><i class="material-icons md-18 primary-color"></i></a>
                <a class="m-r-xs" v-if="isBreakTimeOn" @click.prevent="endBreak" v-tooltip="'Zakończ przerwę.'"><i class="material-icons md-18 primary-color"></i></a>
                <a v-if="!isBreakTimeOn" @click.prevent="endWorking" v-tooltip="'Zakończ pracę.'"><i class="material-icons md-18 primary-color"></i></a>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js'
import Utils from '../../Utils.js';
import SystemSocket from '../../SystemSocket.js';
import GlobalTimerModal from './GlobalTimerModal.vue'
import EventBus from '../../event-bus.js';
let clockInterval = null;

export default {
    name: 'EmployeeTimer',
    props: {
        user: Object
    },
    components: {

    },
    data: function () {
        return {
            stoppedDuration: 0,

            time: '00:00:00',
            timer: null,
            breakerTimer: '00:00:00'
        }
    },
    created: function() {
        this.getEmployeeTimer();
        SystemSocket.socket.on('object', this.onObjectEvent);
        clockInterval = setInterval(this.updateClock, 1000);

        EventBus.$on('globalTimerOperationCreated', this.getEmployeeTimer);

        setTimeout(this.remindTrackTime, 1000);
    },
    destroyed: function() {
        SystemSocket.socket.off('object', this.onObjectEvent);
        clearInterval(clockInterval);

        EventBus.$off('globalTimerOperationCreated', this.getEmployeeTimer);
    },

    computed: {
        isBreakTimeOn() {
            if (!this.timer || !Array.isArray(this.timer.restTimes)) return false;
            return this.timer.restTimes.find(rest => !rest.endedAt) != null;
        }
    },

    methods: {
        remindTrackTime() {
            if (!this.timer) this.showModal();
        },
        async getEmployeeTimer() {
            try {
                this.timer = await Api.timer.getEmployeeTimer(this.user.employeeId);
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
        },

        updateClock() {
            if (!this.timer) {
                this.time = "00:00";
                return;
            }

            this.stoppedDuration = this.getAllBreaktimes();
            const timeBegan = new Date(this.timer.startedAt)
            const currentTime = new Date();
            let timeElapsedSec = Math.round((currentTime - timeBegan - this.stoppedDuration) / 1000);

            const hours = Math.floor(timeElapsedSec / 3600);
            timeElapsedSec %= 3600;
            const minutes = Math.floor(timeElapsedSec / 60);
            const seconds = timeElapsedSec % 60;

            if (hours) this.time = hours + 'h ' + minutes + 'min ' + seconds + 's';
            else this.time = minutes + 'min ' + seconds + 's';

            const d = new Date(this.stoppedDuration);
            const hour = d.getUTCHours();
            const min = d.getUTCMinutes();
            const sec = d.getUTCSeconds();

            this.breakerTimer =
                Utils.zeroPrefix(hour, 2) + ":" +
                Utils.zeroPrefix(min, 2) + ":" +
                Utils.zeroPrefix(sec, 2);
        },

        async startTimer() {
            try {
                this.timer = await Api.timer.createTimer(this.user.employeeId);
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
        },
        async startBreak() {
            this.timer = await Api.timer.coffeBreakStart(this.user.employeeId);
        },
        async endBreak() {
            this.timer = await Api.timer.coffeBreakEnd(this.user.employeeId);
        },

        async endWorking() {
            if (!await this.$h.confirm('Czy na pewno chesz zakończyć swoją prace na dziś?')) return;
            try {
                await Api.timer.stopTimer(this.user.employeeId);
                this.timer = null;
                this.time = '00:00:00';
                this.breakerTimer = '00:00:00';
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
        },
        getAllBreaktimes() {
            let rest = 0;
            for (let i = 0; i < this.timer.restTimes.length; i++) {
                const obj = this.timer.restTimes[i];
                if (obj.endedAt) {
                    rest += Math.round((new Date(obj.endedAt) - new Date(obj.startedAt)));
                } else {
                    rest += Math.round((new Date() - new Date(obj.startedAt)));
                }
            }
            return rest;
        },

        onObjectEvent(msg) {
            if (msg.object != 'employee_timer') return;
            this.getEmployeeTimer();
        },
        showModal() {
            this.$modal.show(GlobalTimerModal, {
                empId: this.user.employeeId
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true
            });
        },
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
