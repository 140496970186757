<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Zmień adres</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Nazwa</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.company_name">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Ulica</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.street">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Kod pocztowy</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.postcode">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Miasto</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.city">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Kraj</label>
                  <div class="col-sm-9 size-select">
                    <select2 :options="cauntries" v-model="address.countryCode" class="form-control">
                    </select2>
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Osoba kontaktowa</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.contactPerson">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Telefon</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.phone">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Email</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="address.email">
                  </div>
                </div>
            </form>           
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Select2 from '../Select2.vue';
import countryCodes from "../../Common.js";

export default {
    name: 'EditDeliveryCustomerModal',
    props: {
        form: String,
        who: String,
        addressToChange: Object,
        order: Object
    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            address: {
                countryCode: "PL"
            },
            Api,
            isProcessing: false,
            cauntries: countryCodes
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (this.addressToChange) {
            this.address = JSON.parse(JSON.stringify(this.addressToChange)); //deep-copy
        }

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        saveAndExit: async function () {
            if (this.form == "once" && this.who == "sender") {
                Object.assign(this.addressToChange, this.address);
                this.$notify({group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.'});
                this.$emit('close');
            }

            if (this.form == "save" && this.who == "sender") {
                this.isProcessing = true;
                try {
                    let data =  await Api.shipments.set({sender: this.address}); 
                    Object.assign(this.addressToChange, data.sender);
                    this.isProcessing = false;
                    this.$notify({group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.'});

                    this.$emit('close');
                } catch(e) {
                    this.isProcessing = false;
                    this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'});
                }
            }
            if (this.form == "save-first" && this.who == "recipient") {
                this.isProcessing = true;
                try {
                    let cust = await Api.customer.edit(this.order.customer._id, {deliveryAddresses:this.address});
                    this.isProcessing = false;
                    this.$set(this.order.customer, "deliveryAddresses", cust.deliveryAddresses);
                } catch(e) {
                    this.isProcessing = false;
                    this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'});
                }
            }
          
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

