<template>
    <div class="box">
        <div class="box-body">
            <p class="primary-color _500">Wyślij listę odbioru - Poczta Polska</p>
            <div>
                <small class="text-muted">
                    W celu wygenerowania protokołu, należy wysłać listę odbioru.
                    Po wysłaniu do każdej dziś nadanej przesyłki zostanie dodany numer 'idEnvelope' (numer zbioru).
                </small>
            </div>
            <a href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Wyślij</a>
        </div>
    </div>
</template>
<script>

import Api from "../../Api.js";
export default {
    name: 'PPSendevelope',
    data: function () {
        return {
            isProcessing: false
        }
    },
    methods: {
        async save() {
            this.isProcessing = true;

            try {
                const res = await Api.shipments.ppSendEnvelope();
                this.isProcessing = false;
                this.$notify({ group: 'global', type: 'notification-success', text: 'Dodano idEnvelope do dziś nadanych przesyłek.' });
            } catch (e) {
                if (e.response.data.error) this.$notify({ group: 'global', type: 'notification-error', text: e.response.data.error });
                else this.$notify({ group: 'global', type: 'notification-error', text: "wystapił błąd." });
            }
            this.isProcessing = false;
        }

    }
}
</script>
