import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import $ from 'jquery'
import './assets/libjs/bootstrap.js'
import Notifications from 'vue-notification'
import 'vue2-timepicker/dist/VueTimepicker.css'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import VueTruncate from 'vue-truncate-filter'
import VuejsDialog from "vuejs-dialog"
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import Confirmation from './components/Confirmation.vue';
const moment = require('moment');
const OP = require('object-path');
require('moment/locale/pl')
import VueMoment from 'vue-moment'
import Paginate from 'vuejs-paginate'

import Verte from 'verte';
import 'verte/dist/verte.css';
// register component globally
Vue.component(Verte.name, Verte);

import VJstree from 'vue-jstree'
Vue.use(VJstree);

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

Vue.config.productionTip = false

const datepickerOptions = {
    dateLabelFormat: 'dddd, MMMM D, YYYY',
    monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
    daysShort: ['Pon', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'N'],
    colors: {
        selected: '#2196f3',
        inRange: '#2296f3',
        selectedText: '#fff',
        text: '#58666e',
        inRangeBorder: '#0d8aee',
        disabled: '#fff',
        hoveredInRange: '#3aa1f4'
    },
    texts: {
        apply: 'Szukaj',
        cancel: 'Cofnij'
    }
}

Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.use(VueMoment, { moment });
Vue.use(Notifications);
Vue.use(VueTruncate);
Vue.use(VuejsDialog);
Vue.component('paginate', Paginate);

Vue.prototype.$helpers = {
    info: (text) => {
        Vue.notify({ group: 'global', type: 'notification-info', text });
    },
    success: (text) => {
        Vue.notify({ group: 'global', type: 'notification-success', text });
    },
    saveNotif: () => {
        Vue.notify({ group: 'global', type: 'notification-success', text: 'Zapisano zmiany' });
    },
    removeNotif: () => {
        Vue.notify({ group: 'global', type: 'notification-success', text: 'Obiekt usunięty' });
    },
    errorNotif: (text) => {
        Vue.notify({ group: 'global', type: 'notification-error', text: text || 'Coś poszło nie tak' });
    },

    /**
     * Metoda pomocnicza do obslugi typowych bledow API
     * @param {Error} error            error ktory zostal zlapany w catch
     * @param {boolean|string} [notif] tresc powiadmienia lub true dla domylsnego powadiomienia
     */
    commonApiError: (error, notif = true) => {
        console.warn(error.toString());
        if (typeof notif == 'boolean' && notif) {
            if (error.response && error.response.status == 403) {
                Vue.notify({ group: 'global', type: 'notification-error', text: "Nie masz uprawnień do wykonania tej akcji" });
            } else {
                Vue.notify({ group: 'global', type: 'notification-error', text: OP.get(error, "response.data.error", "Wystąpił bład komunikacji z serwerem") });
            }
        } else if (typeof notif == 'string' && notif) Vue.notify({ group: 'global', type: 'notification-error', text: notif });
        // @todo w przyszlosci wysylanie powiadomienia mailowego o wystapieniu bledu
    },

    async confirm(message) {
        try {
            await Vue.dialog.confirm(message, {
                view: 'my-unique-confirmation',
                customClass: 'custom-confirmation'
            });
            return true;
        } catch (error) {
            // confrm w przypadku anulowania rzuca blad...
            return false;
        }
    }
};

Vue.prototype.$h = Vue.prototype.$helpers;

import numeral from 'numeral'
Vue.filter("formatCurrency", function (value) {
    return numeral(value).format('0,0.00');
});

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
Vue.filter('money', function (value) {
    return moneyFormat.format(value);
})

const numericFormat = new Intl.NumberFormat('pl-PL', { maximumFractionDigits: 2 });
Vue.filter('numeric', function (value) {
    return numericFormat.format(value);
})

Vue.filter('split_two', function (value) {
    if (value.length < 2) return value;
    const n = parseInt(value.length / 2);
    const w1 = value.substr(0, n);
    const w2 = value.substr(n, value.length);

    return w1 + ' ' + w2;
});

Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
});

import { BaklavaVuePlugin } from "@baklavajs/plugin-renderer-vue";
import "@baklavajs/plugin-renderer-vue/dist/styles.css";
Vue.use(BaklavaVuePlugin);

const VIEW_NAME = 'my-unique-confirmation';
Vue.dialog.registerComponent(VIEW_NAME, Confirmation);

import VModal from 'vue-js-modal'
Vue.use(VModal, { dynamic: true, dynamicDefaults: { draggable: true, clickToClose: true, transition: 'nice-modal-fade', height: 'auto', scrollable: true } });

import DynamicModal from './plugins/DynamicModal/ModalPlugin';
Vue.use(DynamicModal);

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

Vue.prototype.$IS_DEV = !!process.env.VUE_APP_EXPERIMENTAL;

new Vue({
    router,
    render: function (h) { return h(App) }
}).$mount('#app')
