<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Dodaj karton</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row">
                    <label class="col-sm-6 col-md-4 form-control-label text-right">Karton z magazynu</label>
                    <div class="col-sm-6 col-md-8">
                        <v-select placeholder="Wybierz karton" label="name" v-model="box.basedOn" :options="productBoxes" :reduce="p => p._id" @input="setDimentions"/>
                    </div>
                </div>
                <hr>
                <p class="text-muted">Ostateczne wymiarty kartonu</p>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-6 col-md-4 form-control-label text-right">Długość</label>
                            <div class="col-sm-6 col-md-8">
                                <input class="form-control" type="text" style="max-height: 28px; min-height: 28px;" v-model="box.length">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-6 col-md-4 form-control-label text-right">Szerokość</label>
                            <div class="col-sm-6 col-md-8">
                                <input class="form-control" type="text" style="max-height: 28px; min-height: 28px;" v-model="box.width">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-6 col-md-4 form-control-label text-right">Wysokość</label>
                            <div class="col-sm-6 col-md-8">
                                <input class="form-control" type="text" style="max-height: 28px; min-height: 28px;" v-model="box.height">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label class="col-sm-6 col-md-4 form-control-label text-right">Waga</label>
                            <div class="col-sm-6 col-md-8">
                                <input class="form-control" type="text" style="max-height: 28px; min-height: 28px;" v-model="box.mass">
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-sm-12 text-right">
                                <label class="md-check">
                                    <span >Opakowanie folia strech</span>
                                    <input type="checkbox" v-model="box.isStretchFoil" >
                                    <i class="blue m-r-xs"/>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-sm-12 text-right">
                                <label class="md-check">
                                    <span >Nie pobieram kartonu z magazynu</span>
                                    <input type="checkbox" v-model="boxNotFromStock" >
                                    <i class="blue m-r-xs"/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            <hr>
            <p class="text-muted">Pozycje z koszyka</p>
            <div style="max-height:240px; overflow-y: auto; overflow-x: hidden;">
                <div class="row m-b-xs" v-for="c in cart" :key="c._id">
                    <div class="col-md-5">
                        {{c.product.code}}
                    </div>
                    <div class="col-md-3">
                        <span class="text-muted text-xs">(Spakowane: {{getArleadyPack(c._id)}}/{{c.qty}}szt)</span>
                    </div>
                    <div class="col-md-3">
                        <input class="form-control" type="text" style="max-height: 28px; min-height: 28px;" v-model="contents[c._id]">
                    </div>
                    <div class="col-md-1">
                        <a class="text-sm" @click.prevent="contents[c._id] = 0">
                            <i class="material-icons md-18">close</i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer" v-else/>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Select2 from '../../Select2.vue';
import { parse } from 'date-fns';

export default {
    name: 'EditDeliveryCustomerModal',
    props: {
        order: Object
    },
    components: {
        RotateSquare2,
        Select2
    },
    data: function () {
        return {
            isProcessing: false,
            productBoxes: [],
            boxNotFromStock: false,
            box: { isStretchFoil: false, width: 0, height: 0, length: 0, mass: 0, basedOn: null, contents: [] },
            cart: [],
            contents: {}
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        Object.assign(this.cart, this.order.cart);
        this.getBoxes();
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        saveAndExit: async function () {
            if (this.boxNotFromStock) this.box.basedOn = null;

            this.box.mass = parseFloat((this.box.mass + "").replace(/,/g, '.'));
            this.box.width = parseFloat((this.box.width + "").replace(/,/g, '.'));
            this.box.length = parseFloat((this.box.length + "").replace(/,/g, '.'));
            this.box.height = parseFloat((this.box.height + "").replace(/,/g, '.'));

            for (var key of Object.keys(this.contents)) {
                const qty = parseInt(this.contents[key]);

                const arledyPackQty = this.getArleadyPack(key);
                const c = this.cart.find(c => c._id == key);
                const val = arledyPackQty + qty;
                if (c && c.qty < val) {
                    this.$notify({ group: 'global', type: 'notification-error', text: 'Spakowano więcej produktu ' + c.product.code + ' niż jest w koszyku' });
                    return;
                }

                if (!qty || qty == 0) continue;
                this.box.contents.push({ cartPosId: key, qty: qty });
            }

            this.isProcessing = true;
            try {
                const createdBox = await Api.order.addBox(this.order._id);
                this.box.code = createdBox.code;
                await Api.order.editBox(this.order._id, this.box.code, this.box);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.' });
                this.$emit('close');
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian' });
            }
            this.isProcessing = false;
        },
        async getBoxes() {
            try {
                this.productBoxes = await Api.product.search({ type: "box" });
            } catch (error) {
                console.log("error", error);
            }
        },
        getArleadyPack(cartPosId) {
            let qty = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                for (let y = 0; y < box.contents.length; y++) {
                    if (box.contents[y].cartPosId == cartPosId) qty += box.contents[y].qty;
                }
            }
            return qty;
        },
        setDimentions() {
            const productBox = this.productBoxes.find(p => p._id == this.box.basedOn);
            if (!productBox) return
            this.box.width = parseInt((productBox.width + ""));
            this.box.length = parseInt((productBox.length + ""));
            this.box.height = parseInt((productBox.height + ""));
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
