import Api from './Api.js';
import SystemSocket from './SystemSocket.js';

let pendingPromise = null;

class SystemUsersManager {
    constructor() {
        this.systemUsers = [];
        this._isDataValid = true;

        SystemSocket.socket.on('object', (msg) => {
            if (msg.object != 'user') return;
            this.invalidate();
        });
    }

    invalidate() {
        this._isDataValid = false;
    }

    getSystemUsers() {
        if (pendingPromise != null) { return pendingPromise; }

        pendingPromise = new Promise((resolve, reject) => {
            if (this.systemUsers.length && this._isDataValid) {
                setTimeout(() => {
                    pendingPromise = null;
                }, 1);
                resolve(this.systemUsers);
                return;
            }

            Api.user.get()
                .then((res) => {
                    this.systemUsers = res;
                    this._isDataValid = true;
                    resolve(this.systemUsers);
                    pendingPromise = null;
                })
                .catch((reason) => {
                    resolve(this.systemUsers);
                    pendingPromise = null;
                });
        });

        return pendingPromise;
    }

    getName(id) {
        const ids = this.systemUsers.map((u) => { return u._id + "" });
        const idx = ids.indexOf(id + "");
        if (idx == -1) return;
        return this.systemUsers[idx].name;
    }
}

const instance = new SystemUsersManager();

export default instance;
