<template>
<div>
    <span class="label text-white m-r-xs none-radius inline" :class="statusClass">
        {{ translatedStatus }}
    </span>
    <span class="text-muted text-xs" v-if=lastStatusDate>({{lastStatusDate | moment("DD/MM/YY  HH:mm")}})</span>
</div>
</template>

<script>

export default {
    name: 'RelatedOrderStatusSpan',

    props: {
        order: Object,
        statusCode: String
    },

    computed: {
        statusClass: function () {
            const c = {};

            let status = "";
            if (this.order && this.order.status) status = this.order.status;
            if (this.statusCode) status = this.statusCode;

            if (status == 'new') c['c-yellow'] = true;
            else if (status == 'in-production') c['c-blue'] = true;
            else if (status == 'ready-to-pack') c['c-yellow'] = true;
            else if (status == 'packed-to-confirm') c['c-yellow'] = true;
            else if (status == 'pack') c.blue = true;
            else if (status == 'ready-to-send') c['d-blue'] = true;
            else if (status == 'sent') c['c-green'] = true;
            else if (status == 'cancel') c['c-red'] = true;
            return c;
        },

        translatedStatus: function () {
            let status = "";
            if (this.order && this.order.status) status = this.order.status;
            if (this.statusCode) status = this.statusCode;

            if (status == 'new') return 'Nowe';
            else if (status == 'in-production') return 'W produkcji';
            else if (status == 'ready-to-pack') return 'Got. do spakowania';
            else if (status == 'packed-to-confirm') return 'Spak. do potwierdzenia';
            else if (status == 'pack') return 'Spakowane';
            else if (status == 'ready-to-send') return 'Gotowe do wysłania';
            else if (status == 'sent') return 'Wysłane';
            else if (status == 'cancel') return 'Anulowany';
            else return 'Brak statusu';
        },
        lastStatusDate: function() {
            let history = this.order.statusHistory;
            history = history.sort(function(a,b){
                return new Date(b.time) - new Date(a.time);
            });
            const lastStatus = history[0];
            let status = "";
            if (this.order && this.order.status) status = this.order.status;
            if (this.statusCode) status = this.statusCode;
            if (lastStatus.code == status) return lastStatus.time;
            else return null;
        }
    }
}
</script>
<style scoped lang="scss">
.icon {
  width: 25px;
  height: auto;
}
</style>
