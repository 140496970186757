<template>
    <div>
        <div class="custom-modal modal fade" :class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body text-center p-lg">
                        <form class="form-horizontal m-b m-t">
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 form-control-label text-right">Nazwa</label>
                                <div class="col-sm-6 col-md-8">
                                    <input class="form-control" type="text" v-model="workPlace.name"  style="max-height: 28px; min-height: 28px;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 form-control-label text-right">Opis</label>
                                <div class="col-sm-6 col-md-8">
                                    <input class="form-control" type="text" v-model="workPlace.desc"  style="max-height: 28px; min-height: 28px;">
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <a href="#" class="pull-left text-blue text-sm" @click.prevent="cancel">Cofnij</a>
                        <a class="text-blue pull-right text-sm" @click.prevent="add">Dodaj</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">Stanowiska</h6>

            <div class="pull-right minus-margin">
                <a class="btn btn-sm white none-radius" @click="isActive = true">
                    <span class="primary-color _400">Dodaj</span>
                </a>&nbsp;
                <a class="btn btn-sm white none-radius" @click="remove">
                    <span class="primary-color _400">Usuń</span>
                </a>&nbsp;
            </div>
        </div>

        <div class="box-divider m-a-0"/>

        <table class="table b-t"  v-if="!isLoading && workplaces.length > 0" >
            <thead class="primary-color">
                <tr>
                    <th style="padding-right:0px;"/>
                    <th>Stanowisko</th>
                    <th>Pracownicy</th>
                    <th style="padding-right:0px;"/>
                </tr>
            </thead>
            <tbody >
                <tr v-for="w in workplaces" :key="w._id" class="custom-gray">
                    <td>
                        <label class="md-check">
                            <input type="checkbox" v-model="selected" :value="w._id">
                            <i class="blue m-r-xs"/>
                        </label>
                    </td>
                    <td @click.prevent="goToDetails(w._id)">
                        <span>{{w.name}}</span><br>
                        <span class="text-xs text-muted">{{w.desc}}</span>
                    </td>
                    <td @click.prevent="goToDetails(w._id)">
                        <span  v-if="w.employees" class="m-r-md" v-for="(emp, index) in w.employees" :key="index">
                            <span class="title-color workplace">
                                {{EmployeeManager.getEmpName(index)}} -
                                <span v-if="emp.efficiency">{{emp.efficiency*100}}%</span>
                                <span class="text-red" v-else>0%</span>
                            </span>

                        </span>
                        <span v-else>- - -</span>
                    </td>
                    <td>
                        <div v-if="productionSatisfyRaport[w._id]">
                            <a href="#" title="Brak zadań na dziś" v-if="productionSatisfyRaport[w._id].hasFreeTimeToday && !productionSatisfyRaport[w._id].blockingTasks.length">
                                <i class="text-muted material-icons md-18">timer_off</i>
                            </a>
                            <a href="#" title="Zobacz blokujące zadania" v-else-if="productionSatisfyRaport[w._id].hasFreeTimeToday" @click.prevent="showBlockingTasks(productionSatisfyRaport[w._id].blockingTasks, w.name)">
                                <i class="text-danger material-icons md-18">schedule</i>
                                <span class="text-muted text-sm">({{ productionSatisfyRaport[w._id].blockingTasks.length }})</span>
                            </a>
                            <span v-else class="text-green">
                                <i class="material-icons md-18">done</i>
                            </span>
                        </div>
                    </td>
                    <td>
                        <a href="#" class="pull-right text-blue text-sm m-t-xs m-r-xs" @click.prevent="goToDetails(w._id)">Zobacz szczegóły</a>
                    </td>
                </tr>
            </tbody>
        </table>

        <p class="text-center text-muted p-y" v-if="!isLoading && workplaces.length == 0">Brak dodanych stanowisk. Aby dodać pierwsze, kliknij w przycisk "Dodaj" umieszczony po prawej stronie w górnej belce.</p>
        <Spinner v-if="isLoading"/>
    </div>
</template>

<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import EmployeeManager from '../../EmployeeManager.js';
import BlockingTasksPreview from './Modals/BlockingTasksPreview.vue';

export default {
    name: 'WorkPlaces',
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoading: false,
            isActive: false,
            workplaces: [],
            workPlace: {},
            selected: [],
            EmployeeManager: EmployeeManager,
            employees: [],
            productionSatisfyRaport: null
        }
    },
    created: async function() {
        this.isLoading = true;
        try {
            this.employees = await EmployeeManager.getEmployees();
            this.workplaces = await Api.production.searchWorkplaces();
        } catch (error) {
            this.$helpers.commonApiError(error);
        }
        this.isLoading = false;

        try {
            this.productionSatisfyRaport = await Api.production.getWorkplacesProductionSatisfyRaport();
        } catch (error) {
            this.$helpers.commonApiError(error);
        }
    },

    computed: {
    },

    methods: {
        getName(id) {
            const idx = this.employees.map(e => e._id).indexOf(id);
            if (idx != -1) return this.employees[idx].name;
            else return "Brak nazwy";
        },
        add: function() {
            if (!this.workPlace.name || !this.workPlace.desc) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Podaj nazwę i opis, aby dodać stanowisko.' });
                return;
            }
            Api.production.addWorkplace(this.workPlace).then((place) => {
                this.workplaces.push(place);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie dodano.' });
                this.cancel();
            })
        },
        cancel: function() {
            this.isActive = false;
            this.workPlace = {};
        },
        remove: function() {
            if (this.selected.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Brak wybranych do wykonanian tej akcji.' });
                return;
            }

            const promises = [];

            for (var i = this.selected.length - 1; i >= 0; i--) {
                const data = { _id: this.selected[i], isRemoved: true };

                const p = Api.production.editWorkplace(data).then((removed) => {
                    const idx = this.workplaces.map(w => w._id + "").indexOf(removed._id + "");
                    if (idx != -1) {
                        this.workplaces.splice(idx, 1);
                    }
                })
                promises.push(p);
            }
            Promise.all(promises).then((cos) => {
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto wybrane.' });
            });
        },
        showBlockingTasks: function(tasks, wName) {
            this.$modal.show(BlockingTasksPreview, {
                blockingTasks: tasks,
                wName
            }, {
                draggable: true,
            });
        },
        goToDetails: function(id) {
            this.$router.push({ path: `/workplace/${id}` });
        }
    }
}
</script>
<style scoped lang="scss">
  .ramka {
    border: 1px solid rgba(0,0,0,0.6);
    padding: 2px 5px;
    display: inline-block;
    opacity: 0.6
  }
  .border-yellow {
      border: 1px solid #f0ad4e;
      background-color: #fff;
  }
  .border-green {
      border: 1px solid #90c844;
      background-color: #fff;
  }
</style>
