<template>
    <div>
        <div v-if="isError" class="alert alert-danger error login-error text-center" style="margin-bottom: 0px;">
            <a class="close" data-dismiss="alert" href="#">×</a>
            Podałeś niepoprawne hasło lub email.
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-4 left-column hidden-sm-down" style="padding-right: 0px;">
                <div class="box col-lg-offset-4 col-lg-8 col-md-offset-2 col-md-10 text-center ">
                    <div class="m-x-sm" v-if="$IS_DEV">
                        <h2 class="text-white _600 hidden-lg-down">System zarządzania produkcją</h2>
                        <h5 class="text-white _400 m-t-lg hidden-lg-down text-red">WERSJA TESTOWA</h5>
                    </div>
                    <div class="m-x-sm" v-else>
                        <h2 class="text-white _600 hidden-lg-down">System zarządzania produkcją</h2>
                        <h5 class="text-white _400 m-t-lg hidden-lg-down">System do automatyzacji linii produkcyjnej filtrów</h5>
                        <h5 class="text-white _400 m-t-md hidden-lg-down" style="font-size: 1.2rem">MARSS FILTRY - czyścimy powietrze dla ciebie. Filtry przeciwpyłowe, filtry kieszenie, filtry kasetowe, filtry kompaktowe, włókniny filtracyjne.</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-8 right-column" style="padding-left: 0px;">
                <div class="box col-lg-8 col-md-12">
                    <div v-if="isLogPage">
                        <h2 class="primary-color m-t-lg _600 m-b-md">Logowanie</h2>
                        <form class="login" @submit.prevent="login">
                            <div class="form-group m-t">
                                <input class="form-control" required="" id="email" type="email" v-model="email" placeholder="Twój adres email">
                            </div>
                            <div class="form-group m-b-xs">
                                <input class="form-control" required="" id="password" type="password" v-model="password" placeholder="Hasło">
                            </div>
                            <button type="submit" class="btn cutom-color-blue btn-block none-radius text-white m-t-md" :disabled="procesing == 1"> Zaloguj się
                            </button>
                        </form>
                        <div class="m-t-lg">
                            <a class="primary-color _600" href="#" @click="isLogPage = false">Nie pamiętasz hasła?</a>
                        </div>
                    </div>
                    <div v-else>
                        <h2 class="primary-color m-t-lg _600 m-b-md">Resetowanie hasła</h2>
                        <div v-if="!restSuccess && !newPassword">
                            <form class="login" @submit.prevent="reset">
                                <div class="form-group m-t">
                                    <input class="form-control" required="" id="email" type="email" v-model="email" placeholder="Twój adres email">
                                </div>
                                <button type="submit" class="btn cutom-color-blue btn-block none-radius text-white m-t-md" :disabled="procesing == 1"> Resetuj hasło
                                </button>
                            </form>
                            <div class="m-t-lg">
                                <a class="primary-color _600" href="#" @click="isLogPage = true">
                                    <i class="material-icons md-18"></i>
                                    Powrót do karty logowania
                                </a>
                            </div>
                        </div>

                        <div v-if="restSuccess && !newPassword && !restError">
                            <h4 class="primary-color _400">
                                Na podany adres email wysłalismy czterocyfrowy kod. Wpisz kod poniżej aby potwierdzic zmianę hasła.
                            </h4>
                            <form class="login" @submit.prevent="passwordConfirm">
                                <div class="form-group m-t">
                                    <input class="form-control" id="cod" type="text" v-model="code" placeholder="XXXX">
                                </div>
                                <button type="submit" class="btn cutom-color-blue btn-block none-radius text-white m-t-md" :disabled="procesing == 1"> Potwierdź kod
                                </button>
                            </form>
                        </div>

                        <div v-if="restError">
                            <p class="text-white _400 c-red m-t p-y-xs p-x-sm">
                                Wystąpił problem ze zmianą hasła. Zgłoś problem swojemu adminowi.
                            </p>
                        </div>
                        <div v-if="codeError">
                            <p class="text-white _400 c-red m-t p-y-xs p-x-sm">
                                Wystąpił problem. Upewnij się czy wprowadzony kod jest poprawny.
                            </p>
                        </div>

                        <div v-if="newPassword">
                            <h4 class="primary-color _400">
                                Resetowanie hasła przebiegło pomyślnie! Na twoj adres e-mail wysłaliśmy nowe hasło.
                            </h4>
                            <div class="m-t-lg">
                                <a class="primary-color _600" href="#" @click="isLogPage = true">
                                    <i class="material-icons md-18"></i>
                                    Powrót do karty logowania
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    <!-- <div class="footer white primary-color text-center _600 p-y-xs">MARSS Filtry Sp.J. Wojska Polskiego 15, 98-200 SIERADZ</div> -->
    </div>
</template>

<script>
import UserManager from './UserManager.js'
import Api from './Api.js'

export default {
    name: 'Login',
    data: function () {
        return {
            email: '',
            password: '',
            isError: false,
            procesing: false,
            isLogPage: true,
            restSuccess: false,
            restError: false,
            newPassword: false,
            codeError: false,
            code: ''
        }
    },
    created() { document.title = "Logowanie MarssFiltry" },
    destroyed() { document.title = "System MarssFiltry" },
    methods: {
        login: function() {
            this.procesing = true;
            UserManager.logIn(this.email, this.password).then((token) => {
                this.procesing = false;
            }).catch((reson) => {
                this.procesing = false;
                this.isError = true;
            })
        },
        reset: function() {
            this.procesing = true;
            Api.user.resetPassword(this.email).then((bolena) => {
                this.procesing = false;
                this.restSuccess = true;
            }).catch((reson) => {
                this.restError = true;
                this.procesing = false;
            });
        },
        passwordConfirm: function() {
            this.procesing = true;
            Api.user.resetPasswordConfirm(this.code).then((bolean) => {
                this.procesing = false;
                this.newPassword = true;
                this.code = '';
            }).catch((reson) => {
                this.codeError = true;
                this.procesing = false;
            });
        }
    }
}

</script>

<style scoped lang="scss">
.left-column {
  background-image: url('./assets/images/5.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  .box {
    margin-top: 10rem;
    border: 2rem solid white;
    padding: 10px;
    background-color: #375ebc;
    box-shadow: none;
    height: 450px;
    h2,h5 {
      line-height: 1.6;
    }

  }
}
.right-column {
  .box {
    margin-top: 10rem;
    padding: 60px 80px;
    box-shadow: none;
    height: 450px;
    a:hover {
      color:#375ebc;
    }
    h4 {
      line-height: 1.4;
    }
  }
}

</style>
