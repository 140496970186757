<template>
    <div>
        <div class="box">
            <div class="box-header d-gray">
                <h6 class="text-muted _400 inline">
                    Lista kontrahentów
                    <span v-if="totalFound" class="label up blue">{{totalFound}}</span>
                </h6>
                <form class="navbar-form form-inline pull-right pull-none-sm v-m minus-margin" role="search">
                    <div class="form-group l-h m-a-0">
                        <div class="input-group search-input">
                            <input class="form-control form-control-sm p-x b-a" placeholder="Szukaj..." type="text" v-model="query" @input="onFilterChange">
                            <a class="close-btn" href="#" @click.prevent="clear"><i class="material-icons md-16">close</i></a>
                        </div>
                    </div>
                </form>
            </div>
            <div class="box-divider m-a-0"/>
            <div class="box-body p-t-0 p-x-0">
                <div class="box-header l-gray" style="height: 53px;">
                    <div class="pull-right minus-margin">
                        <a class="btn btn-sm white none-radius" @click.prevent="editCustomer({})">
                            <span class="primary-color _400">Dodaj</span>
                        </a>&nbsp;
                        <a class="btn btn-sm white none-radius" @click.prevent="remove">
                            <span class="primary-color _400">Usuń</span>
                        </a>&nbsp;
                    </div>
                </div>
                <div class="box-divider m-a-0"/>
                <table class="table">
                    <thead class="primary-color l-gray">
                        <tr>
                            <th style="width: 5%;"/>
                            <th>Nazwa</th>
                            <th>Email</th>
                            <th>Ulica</th>
                            <th>Miasto</th>
                            <th>Telefon</th>
                            <th>Numer</th>
                            <th/>
                        </tr>
                    </thead>
                    <tbody class="primary-color">
                        <tr v-if="!isLoaded && customers.length > 0"
                            v-for="c in evenCustomers">
                            <td class="checkbox p-r-sm">
                                <label class="md-check">
                                    <input type="checkbox" v-model="selected" :value="c._id">
                                    <i class="blue m-r-xs"/>
                                </label>
                            </td>
                            <td>
                                <router-link class="router-link" target="_blank" :to="{ name: 'customer-details', params: { id: c._id }}">
                                    <span v-if="c.name">{{c.name | truncate(40)}}</span>
                                    <span v-else>- - -</span>
                                </router-link>
                            </td>
                            <td>
                                 <router-link class="router-link" target="_blank" :to="{ name: 'customer-details', params: { id: c._id }}">
                                    <span v-if="c.email">{{c.email | truncate(40)}}</span>
                                    <span v-else>- - -</span>
                                 </router-link>
                            </td>
                            <td>
                                <span v-if="c.street">{{c.street}}</span>
                                <span v-else>- - -</span>
                            </td>
                            <td>
                                <span v-if="c.city"><span v-if="c.postCode">{{c.postCode}}</span> {{c.city}}</span>
                                <span v-else>- - -</span>
                            </td>
                            <td>
                                <span v-if="c.phone">{{c.phone}}</span>
                                <span v-else>- - -</span>
                            </td>
                            <td>
                                <span v-if="c.taxNoType && c.taxNo">{{c.taxNoType}}: {{c.taxNo}}</span>
                                <span v-else>- - -</span>
                            </td>
                            <td>
                                <!-- <a class="btn btn-xs white none-radius" @click.prevent="goToCustomer(c._id)">
                                <span class="primary-color _400">Edytuj</span>
                            </a> -->
                                <a href="">
                                    <i class="material-icons text-blue md-16" style="font-size:16px;" @click.prevent="goToCustomer(c._id)">edit</i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="text-center m-t p-b text-muted" v-if="!isLoaded && customers.length == 0">Brak dodanych kontrahentów</p>
                <Spinner v-if="isLoaded"/>
            </div>
            <div class="row">
                <div class=" col-sm-12 text-right text-center-xs">
                    <paginate
                        v-model="page"
                        :page-count="pages"
                        :click-handler="changePage"
                        :prev-text="'<'"
                        :next-text="'>'"
                        :container-class="'pagination pagination-sm m-r'"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Spinner from '../Spinner.vue';
import Api from '../../Api.js';
import EditCustomerModal from './EditCustomerModal.vue';
const POS_PER_PAGE = 25;
let timeoutHandler = null;

export default {
    name: 'Customers',
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            customers: [],
            selected: [],
            query: '',
            pages: 0,
            page: 1,
            offset: 0,
            totalFound: 0
        }
    },
    computed: {
        evenCustomers: function () {
            return this.customers.sort(function(a, b) {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
            });
        }
    },
    created: function() {
        this.getCustomers();
    },
    methods: {
        editCustomer: function(customer) {
            this.$modal.show(EditCustomerModal, {
                customer
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                    console.log('event', event)
                    this.getCustomers();
                }
            });
        },
        goToCustomer(id) {
            this.$router.push({ path: `/customers/${id}` });
        },
        async getCustomers() {
            this.isLoaded = true;
            // const q = this.query.length ? this.query : undefined;
            const customers = await Api.customer.search({
                query: this.query,
                offset: this.offset,
                limit: POS_PER_PAGE
            });

            this.totalFound = customers.total;
            this.pages = Math.ceil(customers.total / POS_PER_PAGE);
            this.customers = customers.data;
            this.isLoaded = false;
        },
        onFilterChange: function() {
            if (this.query.length == 0) {
                this.getCustomers();
            }
            if (timeoutHandler) clearTimeout(timeoutHandler);

            if (this.query.length < 1) return;
            timeoutHandler = setTimeout(() => {
                this.getCustomers();
            }, 1000);
        },
        clear: function() {
            if (this.query.length == 0) return;
            this.query = '';
            this.getCustomers();
        },
        remove: function() {
            if (this.selected.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Brak wybranych do wykonania tej akcji.' });
                return;
            }

            const promises = [];
            const ids = this.selected;
            for (var i = ids.length - 1; i >= 0; i--) {
                const p = Api.customer.edit(ids[i], { isRemoved: true }).then(() => {});
                promises.push(p);
            }

            Promise.all(promises).then((d) => {
                for (var i = this.selected.length - 1; i >= 0; i--) {
                    const idx = this.customers.map((o) => { return o._id + "" }).indexOf(this.selected[i] + "");
                    if (idx != -1) this.customers.splice(idx, 1);
                }

                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto.' });
                this.selected = [];
            });
        },
        changePage(pageNum) {
            this.offset = 0;
            if (pageNum > 1) this.offset = (pageNum * POS_PER_PAGE) - POS_PER_PAGE;
            this.getCustomers();
        }
    }
}
</script>
<style lang="scss" scoped>
.router-link:hover {
    color: rgb(81, 173, 246);
}
</style>
