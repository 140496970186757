<template>
    <div class="box">
        <div class="box-body p-b-sm">
            <p class="primary-color _500 m-b-0">Potwierdzenie odbioru przesyłek</p>
        </div>
        <div class="m-a-0 custom-divider"/>
        <table class="table m-b-0">

            <tbody v-if="!isLoading" >
                <tr v-for="(r, key) in shipmentsRaports" :key=key>
                    <td class="primary-color">
                        <i class="text-green material-icons md-16 m-r-xs">done_all</i>
                        <span class="text-uppercase" v-if="r.service == 'pp'">poczta polska</span>
                        <span class="text-uppercase" v-else>{{r.service}}</span>
                        odebrał przesyłki
                    </td>
                    <td class="text-muted"> 
                        {{r.qty}}
                        <span v-if="r.service == 'rhenus' || r.service == 'geis' || r.service == 'suus'">/ {{r.boxes}}
                             <img style="width:20px; margin-left: 3px;"  src="../../assets/images/pallet.png">
                        </span>
                        <span v-else>/
                            {{r.boxes}}
                            <img style="width:20px"  src="../../assets/images/box-color.png">
                        </span>
                    </td>
                </tr>
                <tr class="text-xs text-muted" v-if="shipmentsRaports.length == 0">
                    <td class="m-x-sm">Brak dziś odebranych przesyłek przez kuriera</td>
                </tr>
            </tbody>

        </table>
        <Spinner v-if="isLoading" />
    </div>
</template>

<script>
import Spinner from '../Spinner.vue';
import Api from "../../Api.js";

export default {
    name: 'Filters',

    components: {
        Spinner
    },
    data: function () {
        return {
            isLoading: false,
            curiers: [],
            shipmentsRaports: [
                { service: "dpd", qty: 0, boxes: 0 },
                { service: "fedex", qty: 0, boxes: 0 },
                { service: "furgonetka", qty: 0, boxes: 0 },
                { service: "rhenus", qty: 0, boxes: 0 },
                { service: "geis", qty: 0, boxes: 0 },
                { service: "pp", qty: 0, boxes: 0 },
                { service: "suus", qty: 0, boxes: 0 }
            ]
        }
    },
    created: function() {
        this.getTodayHandedOver();
    },
    destroyed: function() {
    },
    methods: {
        getTodayHandedOver: async function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;

            const filters = {
                dateFrom: today + "T00:00:01",
                dateTo: today + "T23:59:59",
                todayHandedOver: true
            }
            this.isLoading = true;
            try {
                const tmpShip = await Api.shipments.search(filters);

                for (let i = 0; i < tmpShip.length; i++) {
                    const ship = tmpShip[i];
                    if (!ship.order) continue;
                    if (ship.service == 'dpd') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("dpd");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'fedex') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("fedex");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'furgonetka') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("furgonetka");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'rhenus') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("rhenus");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'geis') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("geis");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'pp') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("pp");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'suus') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("suus");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                }

                this.shipmentsRaports = this.shipmentsRaports.filter(s => s.qty != 0);
            } catch (error) {
                console.log("er", error);
            }
            this.isLoading = false;
        }
    }
}
</script>
