<template>
    <div class="box">
        <div class="box-header">
            <h3>Zmień status zamówienia</h3>
            <small v-if="order">{{ order.name }}</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <form role="form">
                <div class="form-group">
                    <label>Wybierz nowy status</label>
                    <select2 :options="statusesOptions" v-model="selectedStatus" class="form-control" style="width: 100%;"/>
                </div>

                <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" @click="save">Zmień</button>
            </form>

            <div class="box-body">
                <strong>Historia zamówienia: </strong>
                <div v-for="sh in statusesHistory" :key="sh.time" class="p-a-xs">
                    <span>{{ sh.time| moment('from') }}</span> &gt;
                    <OrderStatusSpan :statusCode="sh.code" />
                    przez {{ sh.author }}
                </div>
            </div>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer" v-else/>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Select2 from '../../Select2.vue';
import OrderStatusSpan from '../OrderStatusSpan.vue';
import EmployeeManager from '../../../EmployeeManager.js';
import SystemUsersManager from '../../../SystemUsersManager.js';

const STATUSES_MAP = {
    new: 'Nowe',
    'in-production': 'W produkcji',
    'ready-to-pack': 'Got. do spakowania',
    'ready-to-send': 'Gotowe do wysłania',
    pack: 'Spakowane',
    sent: 'Wysłane',
    cancel: 'Anulowany'
};

export default {
    name: 'ChangeOrderStatusModal',
    props: {
        order: Object
    },
    components: {
        RotateSquare2,
        OrderStatusSpan,
        Select2
    },
    data: function () {
        return {
            employees: [],
            systemUsers: [],
            selectedStatus: null,
            isProcessing: false
        }
    },
    created: async function() {
        this.employees = await EmployeeManager.getEmployees();
        this.systemUsers = await SystemUsersManager.getSystemUsers();
    },

    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        statusesOptions: function() {
            const o = [];
            for (const code in STATUSES_MAP) {
                if (this.order.status == code) continue;
                o.push({ id: code, text: STATUSES_MAP[code] });
            }
            return o;
        },

        statusesHistory: function() {
            if (!this.order || !Array.isArray(this.order.statusHistory)) return [];

            const history = [];
            for (let i = 0; i < this.order.statusHistory.length; i++) {
                const sh = this.order.statusHistory[i];

                let author = "System";
                if (sh.author == "user") {
                    const usr = this.systemUsers.find(e => e._id == sh.userId);
                    author = usr ? usr.name : "<< Brak użytykownika >>";
                }
                if (sh.author == "employee") {
                    const emp = this.employees.find(e => e._id == sh.userId);
                    author = emp ? (emp.name + " (pracownik)") : "<< Brak pracownika >>";
                };

                history.push({
                    code: sh.code,
                    time: new Date(sh.time),
                    author
                });
            }

            return history.sort((a, b) => { return b - a });
        }
    },
    methods: {

        save: async function() {
            this.isProcessing = true;
            await Api.order.setStatus(this.order._id, this.selectedStatus);
            this.order.status = this.selectedStatus;
            this.isProcessing = false;
            this.$emit('close');
        },

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
