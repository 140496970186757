function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomData() {
    return {
        "FK": getRandomInt(0, 100),
        "FKA": getRandomInt(0, 100),
        "FKP": getRandomInt(0, 100),
        "WFKA": getRandomInt(0, 100),
        "WŁ": getRandomInt(0, 100),
        "FKM": getRandomInt(0, 100),
        "FP": getRandomInt(0, 100),
    }
}


export default [
    {
        date: "2021-01-01",
        production: generateRandomData(),
        realized: generateRandomData(),
        sent: generateRandomData(),
        timeProduction: generateRandomData(),
    },
    {
        date: "2021-01-02",
        production: generateRandomData(),
        realized: generateRandomData(),
        sent: generateRandomData(),
        timeProduction: generateRandomData(),
    },
    {
        date: "2021-01-03",
        production: generateRandomData(),
        realized: generateRandomData(),
        sent: generateRandomData(),
        timeProduction: generateRandomData(),
    },
]