<template>
    <div class="box">
        <div class="box-header">
            <h2 class="title-color m-b-sm inline" v-if="!isLoading && !recipe">Recepta</h2>
            <div class="row" v-if="!isLoading && recipe">
                <span class="col-md-2">
                    <span>
                        <img v-if="recipe.iconUrl" class="product-icon" :src="recipe.iconUrl"
                             v-tooltip="'Nazwa: '+recipe.name">
                    </span>
                </span>
                <h6 class="col-md-10 _400 m-b-0">
                    <router-link :to="{ name: 'product-type-details', params: { code: product.productType } }">{{ recipe.name }}</router-link><br>
                    <span class="text-xs text-muted">Produkuje: {{recipe.produces}}</span>
                </h6>

            </div>
            <p v-if="!isLoading" class="text-xs pull-right text-blue">
                <a v-if="isUsingManualMode" @click.prevent="toggleManualMode(false)">Przełącz na tryb automatyczny</a>
                <a v-else @click.prevent="toggleManualMode(true)">Przełącz na tryb ręczny</a>
            </p>

        </div>
        <div class="box-divider m-a-0"/>
        <span class="text-yellow " v-if="ingredients && recipe">
            <i class="material-icons md-18 text-yellow"></i>
            Szacowany koszt wyprodukowanie ~{{ getPrice(ingredients) | money}}
        </span>
        <div class="box-divider m-a-0"/>
        <div class="m-t-sm" v-if="!isLoading && isUsingManualMode">
            <div class="p-x p-y-xs">
                <div class="ing-box" style="text-align: center;">
                    <a @click.prevent="addNewManualIngredient">Dodaj nowy składnik +</a>
                </div>
            </div>
        </div>

        <div class="m-t-sm" v-if="!isLoading">
            <div class="m-a p-x p-y-xs ing-box" v-if="notes.length">
                <strong>Pomocnicze notatki dla pracownika:</strong><br>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <span v-for="note in notes">
                    {{ note.name }}: {{ note.note }}<br>
                </span>
            </div>
            <IngredientBox :ingredient="ing" v-for="ing in keyedIngredients" :key="ing.id" @delete="removeManualIngredient" />
            <IngredientGroupBox :ingredient="ing" v-for="(ing, k) in ingredientGroups || []" :key="'g'+k" />
        </div>
        <p class="text-center text-muted p-y" v-if="!isLoading && !recipe && product.productType">Produkt nie jest produkowany</p>
        <p class="text-center text-red p-y" v-if="!isLoading && !product.productType">Brak wybranego typu dla produktu</p>
        <Spinner v-if="isLoading"/>
    </div>
</template>

<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import IngredientBox from '../ProductRecipe/Modals/IngredientBox.vue';
import IngredientGroupBox from '../ProductRecipe/Modals/IngredientGroupBox.vue';
export default {
    name: 'ProductRecepts',
    props: {
        product: Object
    },
    components: {
        Spinner,
        IngredientBox,
        IngredientGroupBox
    },
    data: function () {
        return {
            isLoading: false,
            featureNames: [],
            recipe: null,
            ingredients: [],
            notes: [],
            manualIngsList: null
        }
    },
    watch: {
        product: {
            deep: true,
            handler(newValue, oldValue) {
                this.getRecipesForProduct();
            }
        }
    },

    created: async function() {
        const f = await Api.product.getProductsFeatures();
        f.forEach(d => {
            this.featureNames.push({ id: d._id, name: d.code });
        });
        if (!this.product.productType) {
            this.$helpers.errorNotif('Brakuje typu dla produktu');
            return;
        }
        this.getRecipesForProduct();
    },

    computed: {
        keyedIngredients() {
            return this.ingredients.filter(ing => ing.product && !ing.group).map(ing => {
                return Object.assign({ id: Math.floor(Math.random() * 10000) }, ing);
            });
        },
        ingredientGroups() {
            // console.log("DUPA", this.ingredients);
            return this.ingredients.filter(ing => ing.group && Array.isArray(ing.products));
        },
        isUsingManualMode() {
            return this.manualIngsList && !this.manualIngsList.isDisabled;
        }
    },

    methods: {
        async getRecipesForProduct() {
            this.isLoading = true;
            try {
                this.manualIngsList = await Api.production.manualIngredients.get(this.product._id);
            } catch (error) {
                this.manualIngsList = null;
            }

            try {
                const recipes = await Api.production.recipes.v3.search({ produces: this.product.productType });
                if (recipes.data.length > 0) this.recipe = recipes.data[0];
                await this.simulate();
            } catch (error) {
                console.log("err", error)
            }

            this.isLoading = false;
        },

        getFeatureName(id) {
            let name = "";
            name = this.featureNames.find(x => x.id === id).name;
            name || (name = "Brak nazwy cechy");
            return name;
        },

        async toggleManualMode(enable) {
            const m = enable ? "enable" : "disable";
            this.isLoading = true;
            try {
                const r = await Api.production.manualIngredients[m](this.product._id);
                Object.assign(this.manualIngsList, r);
                await this.simulate();
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.isLoading = false;
        },

        async addNewManualIngredient() {
            const newProduct = await this.$modal2.show(() => import("./modals/ManualIngredientModal.vue"), {});
            if (!newProduct) return;

            this.isLoading = true;
            try {
                this.manualIngsList.ingredients.push(newProduct);
                await Api.production.manualIngredients.update(this.product._id, this.manualIngsList);
                this.$h.success("Zapisano");
                await this.simulate();
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.isLoading = false;
        },

        async removeManualIngredient(ing) {
            if (!await this.$h.confirm('Czy aby na pewno Sir?')) return;
            const mIng = this.manualIngsList.ingredients.find(mi => mi._id == ing._id);
            if (!mIng) return;

            this.isLoading = true;
            try {
                this.manualIngsList.ingredients.splice(this.manualIngsList.ingredients.indexOf(mIng), 1);
                await Api.production.manualIngredients.update(this.product._id, this.manualIngsList);
                this.$h.success("Usunięto");
                await this.simulate();
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.isLoading = false;
        },

        async simulate() {
            if (!this.recipe) return;
            const productFeatures = {};
            for (let i = 0; i < this.featureNames.length; i++) {
                const feat = this.featureNames[i];
                if (this.product.features[feat.id]) productFeatures[feat.name] = this.product.features[feat.id];
            }
            const res = await Api.production.recipes.v3.simulateProduct(this.product._id, 1);
            if (Array.isArray(res)) {
                this.ingredients = res;
            } else {
                this.ingredients = res.ingredients;
                this.notes = res.notes;
            }
        },

        getPrice(ingredients) {
            let sum = 0;
            if (!ingredients) return sum;

            for (const ingredient of ingredients) {
                if (ingredient.price) {
                    sum += (ingredient.amount * ingredient.price);
                } else {
                    sum += this.getPrice(ingredient.ingredients || []);
                }
            }

            return sum;
        }
    }
}
</script>
<style scoped lang="scss">
  .ramka {
    border: 1px solid rgba(0,0,0,0.6);
    padding: 2px 5px;
    display: inline-block;
    opacity: 0.6
  }
  .recipes {
    max-height: 150px;
    overflow: auto;

  }
</style>
