<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Powiązane zamówienia</h6>
            <a class="pull-right" title="Dodaj powiązanie" @click.prevent="addRelatedOrder">
                <i class="material-icons md-18 text-blue">add</i>
            </a>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <rotate-square2 style="margin: 15px;" v-if="isProcessing"/>
            <span class="text-muted text-xs" v-if="relatedOrders.length == 0 && !isProcessing">Brak powiązanych zamówień</span>
            <div v-else v-for="o in sortedOrders" :key="o._id">
                <div class="row">
                    <div class="col-md-6">
                        <router-link style="vertical-align: middle;" target="_blank" class="m-r _600 inline" :to="{ name: 'order-details', params: { id: o._id }}"  >
                            {{o.name}}
                        </router-link>
                        <RelatedOrderStatusSpan :order="o" />
                        
                        <span class="text-muted text-xs">(Utworzono: {{o.created | moment("DD/MM/YY  HH:mm")}})</span>
                    </div>
                    <div class="col-md-2" style="padding-left:0px;">
                        <DoughnutChart v-if="o.production.prs && o.production.prs.length > 0 && o.production.progress"
                                       :percent="parseInt(o.production.progress*100)" :width="53" :height="45" :strokeWidth="20"
                                       :visibleValue="true" :classValue="'chart'"/>

                        <DoughnutChart v-else-if="!o.production.prs || o.production.prs.length == 0"
                                       :percent="0" :width="45" :height="45" :strokeWidth="20"
                                       :visibleValue="true" :classValue="'chart'"/>

                        <DoughnutChart v-else-if="o.production.prs && o.production.prs.length > 0 && o.production.prs[0].status == 'errored'"
                                       backgroundColor="#f05050" foregroundColor="#f05050"
                                       :percent="parseInt(o.production.prs[0].progress*100)"
                                       :width="45" :height="45" :strokeWidth="20"
                                       :visibleValue="true" :classValue="'chart'"/>
                    </div>
                    <div class="col-md-3" style="padding-left:0px; padding-right:0px;">
                        <span v-if="o.computed && o.computed.totalPrice">
                            <span >{{o.computed.totalPrice.netto | formatCurrency}} zł</span><br>
                            <span class="text-xs text-muted">{{o.computed.totalPrice.brutto |formatCurrency}} zł (brutto)</span>
                        </span>

                    </div>
                    <div class="col-md-1" style="padding-left:0px;">
                        <a class="text-grey pull-right" href="" @click.prevent="removeRelatedOrders(o)">
                            <i class="material-icons md-20">highlight_off</i>
                        </a>
                    </div>
                </div>
                <hr class="m-y-sm">
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import EventBus from '../../event-bus.js';
import AddRelatedOrderModal from './Modals/AddRelatedOrderModal.vue';
import { RotateSquare2 } from 'vue-loading-spinner';
import RelatedOrderStatusSpan from './RelatedOrderStatusSpan.vue';
import DoughnutChart from 'vue-doughnut-chart'

export default {
    name: 'RelatedOrders',
    props: {
        order: Object
    },
    data: function () {
        return {
            isProcessing: false,
            relatedOrders: []
        }
    },
    components: {
        RotateSquare2,
        RelatedOrderStatusSpan,
        DoughnutChart
    },

    created: function() {
        if (!this.order.relatedOrders) this.order.relatedOrders = [];
      
        if (this.order.relatedOrders.length > 0) this.getRelatedOrders();
        EventBus.$on('newRelatedOrders', this.getOrder);
    },
    destroyed: function() {
        EventBus.$off('newRelatedOrders', this.getOrder);
    },

    computed: {
        sortedOrders: function () {
            return [...this.relatedOrders].sort((a, b) => {
                var dateA = new Date(a.created), dateB = new Date(b.created);
                return dateB - dateA;
            });
        }
    },

    methods: {
        async getOrder() {
            this.order = await Api.order.getOrder(this.order._id);
            this.getRelatedOrders();
        },
        async getRelatedOrders() {
            if (this.order.relatedOrders.length == 0) {
                this.relatedOrders = [];
                return;
            }
            this.isProcessing = true;
            try {
                const data = {
                    _id: this.order.relatedOrders,
                    projection: " "
                }
                data.computed = { totalPrice: true }
                this.relatedOrders = await Api.order.search(data);
            } catch (error) {
                console.log("er", error);
            }
            this.isProcessing = false;
        },
        addRelatedOrder() {
            this.$modal.show(AddRelatedOrderModal, {
                order: this.order,
                relatedOrders: this.order.relatedOrders
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                width: '800px',
                scrollable: true
            }, {
                closed: (event) => {
                }

            });
        },
        async removeRelatedOrders(orderRelated) {
            this.order.relatedOrders = this.order.relatedOrders.filter(function(item) {
                return item !== orderRelated._id
            });

            orderRelated.relatedOrders = orderRelated.relatedOrders.filter(i => {
                return i !== this.order._id;
            });

            try {
                await Api.order.save(this.order._id, { relatedOrders: this.order.relatedOrders });
                await Api.order.save(orderRelated._id, { relatedOrders: orderRelated.relatedOrders });
                this.getRelatedOrders();

                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto powiązanie.' });
            } catch (error) {
                console.log("er", error);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas usuwania.' });
            }
        }
    }
}
</script>
