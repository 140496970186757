let COMPANY_FREEDAYS = null;

function isAHoliday(day) {
    if (!COMPANY_FREEDAYS) return false;
    for (const fd of COMPANY_FREEDAYS) {
        if (fd.year == day.getFullYear() && fd.month == day.getMonth() && fd.day == day.getDate()) return true;
    }
    return false;
}

module.exports = {
    setCompanyFreedays(fd = []) {
        COMPANY_FREEDAYS = fd;
    },

    /**
     * Dodaje do podanej daty
     * @param {Number} xDays ile dni roboczych dodac
     * @param {Date} [toDate] do tej daty
     */
    addWorkingDays: function(xDays, toDate) {
        const d = toDate ? new Date(toDate) : new Date();
        for (let i = 0; i < xDays; i++) {
            d.setDate(d.getDate() + 1);
            // jesli sobota lub niedziela
            while (d.getDay() == 6 || d.getDay() == 0 || isAHoliday(d)) {
                d.setDate(d.getDate() + 1);
            }
        }

        return d;
    },

    /**
     * Konwertuje czas trwania w sekundach na czytelny string
     * @param {Number} totalSeconds   laczna liczba sekund
     * @param {boolean} [showSeconds] czy w koncowym strinug wyswietlac tez sekundy
     * @returns Czas trwania jako string w formacie HH:mm:ss
     */
    convertSecondsToHumanReadableString(totalSeconds, showSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const leftSeconds = totalSeconds % 3600;
        const minutes = Math.floor(leftSeconds / 60);
        const seconds = leftSeconds % 60;

        let readableString = String(hours).padStart(2, '0') + ":" + String(minutes).padStart(2, '0');
        if (showSeconds) readableString += ":" + String(seconds).padStart(2, '0');

        return readableString;
    },

    /**
     * Wyciaga zmienna z obiektu jako numer
     * @param {object} obj
     * @param {string} key
     * @returns {Number} integer, 0 if key does not exists
     */
    getIntFromObject(obj, key) {
        if (!obj[key]) return 0;
        return parseInt(obj[key]);
    },

    isEmail(email) {
        if (typeof email != 'string') return false;
        if (email.length == 0) return false;

        const parts = email.split('@');
        if (parts.length != 2) return false;

        const addr = parts[0];
        const host = parts[1];
        if (addr.length < 1 || host.length < 3) return false;
        if (host.indexOf('.') == -1) return false;

        return true;
    },

    zeroPrefix(num, digit) {
        let zero = '';
        for (let i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
    }
};
