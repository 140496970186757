<template>
<div class="box">
  <div class="box-header p-b-sm">
   <h2 class="title-color m-b-sm">Adresy dostawy</h2>
  </div>
  <div class="m-a-0 custom-divider"></div>
  <div class="box-body p-b-lg p-t-0" style="max-height: 300px; overflow-x: auto;">
    <p class="p-t" v-if="isEmpty">Brak dodanych adresów.</p>

    <div class="p-b-lg p-t custom-divider" v-if="!isEmpty && customer.deliveryAddresses.length > 0" v-for="deliveryAddress in customer.deliveryAddresses" :key="deliveryAddress._id">
      <div class="row m-b-xs" >
        <span class=" primary-color col-md-4 col-sm-6">Nazwa: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.name">{{deliveryAddress.name}}</span>
        <span class="col-md-8 col-sm-6" v-else>Brak nazwy</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Ulica: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.street">{{deliveryAddress.street}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Miasto: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.postCode && deliveryAddress.city">
          {{deliveryAddress.postCode}} {{deliveryAddress.city}}
          <span v-if ="deliveryAddress.countryCode">({{deliveryAddress.countryCode}})</span>
        </span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Telefon: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.phone">{{deliveryAddress.phone}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Email: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.email">{{deliveryAddress.email}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <div class="row m-b-xs">
        <span class=" primary-color col-md-4 col-sm-6">Osoba do kontaktu: </span>
        <span class="col-md-8 col-sm-6" v-if="deliveryAddress.contactPerson">{{deliveryAddress.contactPerson}}</span>
        <span class="col-md-8 col-sm-6" v-else>- - -</span>
      </div>
      <a href="#" class="pull-left text-blue text-sm m-t" v-on:click.prevent="remove(deliveryAddress)">Usuń</a>
      <a href="#" class="pull-right text-blue text-sm m-t" v-on:click.prevent="edit(deliveryAddress)">Zmień dane</a>
    </div>

  </div>
  <div class="box-body" style="padding-bottom:2rem;">
    <hr/>
    <a href="#" class="pull-left text-blue text-sm" v-on:click.prevent="add">Dodaj</a>
  </div>
</div>
</template>
<script>
import Api from '../../Api.js';
import EditDelvieryCustomerModal from './EditDelvieryCustomerModal.vue';
export default {
  name: 'DelvieryAdresses',
  props: {
    customerData: Object
  },
  components: {
  },
  data: function () {
    return {
      customer: Object.assign({}, this.customerData),
    }
  },
  computed: {
    isEmpty: function() {
      return this.customer.deliveryAddresses.length == 0;
    }
  },
  methods: {
    add: function() {
      this.$modal.show(EditDelvieryCustomerModal, {
        customer: this.customer
      }, {
          draggable: true,
          clickToClose: false,
          resizable: true,
          height: 'auto',
          scrollable: true
      }, {
          'closed': (event) => {
          }
      });
    },
    edit(adress) {
      this.$modal.show(EditDelvieryCustomerModal, {
        delivery: adress,
        customer: this.customer
      }, {
          draggable: true,
          clickToClose: false,
          resizable: true,
          height: 'auto',
          scrollable: true
      }, {
          'closed': (event) => {
          }
      });
    },
    remove: async function (address) {
      let idx = this.customer.deliveryAddresses.map(d => d._id+"").indexOf(address._id+"");
      if (idx != -1){
         this.customer.deliveryAddresses.splice(idx, 1);
         try {
            await Api.customer.edit(this.customer._id, {deliveryAddresses:this.customer.deliveryAddresses});
         } catch (error) {
            console.log(error);
            this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas usuwania.'});

         }
      }
    }
  }
}
</script>