<template>
  <div class="box">
    <div class="box-header">
      <h2 class="title-color m-b-sm inline">Dane produktu z WFirma</h2>
    </div>

    <div class="m-a-0 custom-divider"></div>

    <div v-if="!isLoaded && Object.keys(goodByProduct).length > 0">
      <ul class="list-group no-border m-t-sm">
        <li class="list-group-item no-border">
          <div class="row m-b-xs">
            <span class=" primary-color col-md-4">Nazwa:</span>
            <span class="col-md-8" v-if="goodByProduct.name">{{goodByProduct.name}}</span>
            <span class="col-md-8" v-else>- - -</span>
          </div>
          <div class="row m-b-xs">
            <span class=" primary-color col-md-4">Kod:</span>
            <span class="col-md-8" v-if="goodByProduct.code">{{goodByProduct.code}}</span>
            <span class="col-md-8" v-else>- - -</span>
          </div>
          <div class="row m-b-xs">
            <span class=" primary-color col-md-4">Netto:</span>
            <span class="col-md-8" v-if="goodByProduct.netto">{{goodByProduct.netto}} zł</span>
            <span class="col-md-8" v-else>- - -</span>
          </div>
          <div class="row m-b-xs">
            <span class=" primary-color col-md-4">Brutto:</span>
            <span class="col-md-8" v-if="goodByProduct.brutto">{{goodByProduct.brutto}} zł</span>
            <span class="col-md-8" v-else>- - -</span>
          </div>
          <div class="row m-b-xs">
            <span class=" primary-color col-md-4">Klasyfikacja:</span>
            <span class="col-md-8" v-if="goodByProduct.classification">{{goodByProduct.classification}}</span>
            <span class="col-md-8" v-else>- - -</span>
          </div>
        </li>
      </ul>
      <div class="m-a-0 custom-divider"></div>
    <div class="table-responsive">
      <table class="table" v-if="Object.keys(goodByProduct).length > 0">
        <thead class="primary-color">
          <tr>
            <th>Nazwa</th>   
            <th>Netto</th>   
            <th>Brutto</th>   
          </tr>
        </thead>
        <tbody>
          <tr class="custom-gray" v-for="(p, index) in goodByProduct.good_prices">
            <td>
              <span v-if="p.good_price.name">{{p.good_price.name}}</span>
              <span v-else>- - -</span>
            </td>
            <td>
              <span v-if="p.good_price.netto">{{p.good_price.netto}} zł</span>
              <span v-else>- - -</span>
            </td>
            <td>
              <span v-if="p.good_price.brutto">{{p.good_price.brutto}} zł</span>
              <span v-else>- - -</span>
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      <div class="text-right p-b-sm">
        <a href="#" class="text-blue text-sm m-r" v-on:click.prevent="">Aktualizuj cenę</a>
      </div>
    </div>


    <p class="text-center text-muted p-y" v-if="!isLoaded && Object.keys(goodByProduct).length === 0">
      Brak danych z wFirmy
    </p>
    <Spinner v-if="isLoaded"></Spinner>
  </div>
</template>

<script>
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
import Spinner from '../Spinner.vue';

export default {
  name: 'ProductWFirma',
  props: {
    product: Object
  },
  components: {
    Select2,
    Spinner
  },
  data: function () {
    return {
      goodByProduct: {},
      isLoaded: false,
    }
  },
  created: function() {
    this.getGoodByProduct();
  },
  methods: {
    getGoodByProduct: function() {
      this.isLoaded = true;
      Api.wFirma.getGoodByProduct(this.product._id).then((goodByProduct) => {
        this.goodByProduct = goodByProduct;
        this.isLoaded = false;
      }).catch(er => {
        this.goodByProduct = {};
        this.isLoaded = false;
      });
    }
  }
}
</script>