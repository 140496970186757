<template>
    <div>
        <!-- nagłówki -->
        <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">Lista szablonów wydruku</h6>

            <div class="pull-right minus-margin">
                <a class="btn btn-sm white none-radius" @click.prevent="addNewTpl">
                    <span class="primary-color _400">Dodaj nowy szablon</span>
                </a>&nbsp;
                <a class="btn btn-sm white none-radius" @click="remove">
                    <span class="primary-color _400">Usuń</span>
                </a>&nbsp;
            </div>

        </div>

        <table class="table" v-if="!isLoading && templates.length > 0">
            <thead class="primary-color">
                <tr>
                    <th style="padding-right:0px;"/>
                    <th>Nazwa</th>
                    <th>Format</th>
                    <th>Wymiar etykiety</th>
                </tr>
            </thead>
            <tbody>
                <tr class="custom-gray" v-for="tpl in templates" :key="tpl._id">
                    <td style="width:4%;">
                        <label class="md-check pull-right">
                            <input type="checkbox" v-model="selected" :value="tpl._id">
                            <i class="blue m-r-xs"/>
                        </label>
                    </td>
                    <td @click="goToDetails(tpl)">{{tpl.name}}</td>
                    <td @click="goToDetails(tpl)">{{tpl.format}}</td>
                    <td @click="goToDetails(tpl)">{{tpl.size}}</td>
                </tr>
            </tbody>
        </table>

        <p class="text-center text-muted p-y" v-if="!isLoading && templates.length == 0">Brak dodanych szablonów</p>
        <Spinner v-if="isLoading"/>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';

var timeoutHandler = null;

export default {
    name: 'PrintTemplatesList',
    components: {
        Spinner
    },
    data: function () {
        return {
            templates: [],
            isLoading: true,
            selected: []
        }
    },
    created: async function() {
        this.isLoading = true;
        try {
            this.templates = await Api.printing.templates.search({});
        } catch (error) {

        }
        this.isLoading = false;
    },
    methods: {
        addNewTpl: async function() {
            this.isLoading = true;
            const tpl = await Api.printing.templates.create({
                name: "Nowy szablon wydruku"
            });
            this.$router.push({ path: `/production/print-template/${tpl._id}` });
        },

        goToDetails: function(tpl) {
            this.$router.push({ path: `/production/print-template/${tpl._id}` });
        },
        remove: function(f, index) {
            if (this.selected.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Brak wybranych do wykonanian tej akcji.' });
                return;
            }

            const promises = [];
            for (var i = this.selected.length - 1; i >= 0; i--) {
                const data = { isRemoved: true };

                const p = Api.printing.templates.edit(this.selected[i], data).then((temps) => {
                    const idx = this.templates.map(t => t._id + "").indexOf(temps._id + "");
                    if (idx != -1) this.templates.splice(idx, 1);
                });
                promises.push(p);
            }

            Promise.all(promises).then((cos) => {
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto wybrane.' });
            });
        }
    }
}
</script>
