<template>
    <div class="box">
        <div class="box-header">
            <h2 class="title-color m-b-sm inline">Podsumowanie dnia na stanowisku</h2>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <h4 v-if="schedule">{{ schedule.day | moment('DD/MM') }} <span class="text-muted text-xs" v-if="isProcessing">Trwa liczenie...</span></h4>
            <div class="form-group row m-b-sm">
                <label class="col-sm-4 form-control-label">Cecha</label>
                <div class="col-sm-8 ">
                    <v-select v-model="currentFeature" :options="availableFeatures" />
                </div>
            </div>

            <table class="table">
                <thead>
                    <tr>
                        <th>Wartość</th>
                        <th># zadań</th>
                        <th># szt</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(entry, val) in raport[currentFeature]" :key="val">
                        <td v-if="val == '_missing'" class="text-muted text-xs">---</td>
                        <td v-else>{{ val }}</td>
                        <td>{{ entry.tasksCount }}</td>
                        <td>{{ entry.totalTodo }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <th>Razem</th>
                        <th>{{ raportSummary.totalTasks }}</th>
                        <th>{{ raportSummary.totalTodo }}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
export default {
    name: 'TasksSummary',
    props: {
        wPlace: Object,
        schedule: Object
    },
    components: {
    },
    watch: {
        schedule(newSch, oldSch) {
            this.loadTasks();
        }
    },
    data: function () {
        return {
            taskDefs: [],
            isProcessing: false,
            currentFeature: "",
            raport: {}
        }
    },
    computed: {
        availableFeatures() {
            return Object.keys(this.raport);
        },
        raportSummary() {
            const r = { totalTasks: 0, totalTodo: 0 };
            if (!this.currentFeature || !this.raport[this.currentFeature]) return r;
            for (const raportEntry of Object.values(this.raport[this.currentFeature])) {
                r.totalTasks += raportEntry.tasksCount;
                r.totalTodo += raportEntry.totalTodo;
            }
            return r;
        }
    },
    created: async function() {
        const r = await Api.tasks.definitions.search({ workplace: this.wPlace._id });
        this.taskDefs = r.data;
        this.loadTasks();
    },
    methods: {
        async loadTasks() {
            if (!this.schedule) return;
            if (this.isProcessing) return;

            this.raport = {};

            this.isProcessing = true;
            const processedTasks = new Set();
            const limit = 100;
            let offset = 0;
            let total = 0
            try {
                do {
                    const res = await Api.tasks.v2.search({
                        status: ["unactive", "waiting"],
                        taskDef: this.taskDefs.map(def => def._id),
                        productionRequest: Object.keys(this.schedule.schedule),
                        limit,
                        offset
                    });
                    total = res.total;
                    offset += limit;

                    for (const task of res.data) {
                        if (!task.sub_product || typeof task.sub_product.features != 'object') continue;

                        if (processedTasks.has(task._id + "")) continue;
                        processedTasks.add(task._id + "");

                        for (const key in task.sub_product.features) {
                            if (!this.raport[key]) this.$set(this.raport, key, {});

                            const fRaport = this.raport[key];
                            const value = task.sub_product.features[key] + "";
                            if (!fRaport[value]) this.$set(fRaport, value, { tasksCount: 0, totalTodo: 0 });
                            fRaport[value].tasksCount += 1;
                            fRaport[value].totalTodo += task.toMadeCount;
                        }
                    }
                } while (offset < total)
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.isProcessing = false;
        }
    }
}
</script>
