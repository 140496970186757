/* eslint-disable no-unused-expressions */
<template>
    <div class="box" >
        <div class="box-header">
            <h3 >Edytuj produkt</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <!-- <a class="text-blue" href="" title="Brak właściwości produktu" @click.prevent="setProductTypeAndFeatures">
                    <i class="material-icons md-24 text-blue">get_app</i> Automatyczne wykrywanie cech i typu
                </a> -->
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="setProductTypeAndFeatures()" title="Automatyczne wykrywanie cech i typu">
                        <i class="material-icons md-18">get_app</i>
                        <span class="icon-desc">AUTO WYK.</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <form role="form">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Nazwa</label>
                            <input class="form-control" type="text" v-model="product.name">
                            <!-- <input class="form-control" type="text" :disabled="productType._id" v-model="productType.name" @input="onFeatureNameChanged"> -->
                        </div>

                        <div class="form-group">
                            <label>Unikalny kod</label>
                            <!-- @todo Kod aby genrował sie unikalny!!! -->
                            <input class="form-control" type="text" v-model="product.code">
                        </div>

                        <hr>
                        <div class="form-group">
                            <label>EAN13</label>
                            <input class="form-control form-control-sm" type="text" v-model="product.ean13">
                        </div>
                        <div class="form-group">
                            <label>Kategoria</label>
                            <v-select placeholder="Kategoria" v-model="productCategory" :options="categories" />
                        </div>
                        <div class="form-group">
                            <label>Domyślny koszt produktu</label>
                            <div class="row">
                                <div class="col-md-6"><input class="form-control form-control-sm" type="text" v-model.number="product.purchasePrice"></div>
                            </div>
                        </div>
                        <div class="form-group m-y-md">
                            <label class="md-check">
                                <input type="checkbox" v-model="product.isPurchased">
                                <i class="blue m-r-xs"/>
                                <span class="m-l-xs">Produkt jest kupowany</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">

                        <div class="form-group">
                            <label>Typ produktu</label>
                            <v-select placeholder="Kategoria" @input="setRequiredFeatures" v-model="productType" :options="types" label="name" :reduce="t => t.code" />
                        </div>
                        <hr>
                        <div class="form-group">
                            <label>Bazowe cechy produktu</label>
                            <br>
                            <span v-if="!productType" class="text-xs text-red">Wybierz typ produktu, na którego podstawie wyświetlą się odpowiednie cechy.</span>
                            <div class="box-bordered" v-if="productType">
                                <div class="form-group row m-b-sm"  v-for="pFeat in requiredProductFeatures" :key="pFeat.feature._id">
                                    <label class="col-md-4 form-control-label">{{pFeat.feature.desc}}</label>
                                    <div class="col-md-7">
                                        <span v-if="pFeat.feature.type == 'text'">
                                            <v-select v-model="productFeatures[pFeat.feature._id]" :options="pFeat.feature.values" taggable />
                                        </span>
                                        <span v-else-if="pFeat.feature.type == 'number'">
                                            <input class="form-control form-control-sm" v-model.number="productFeatures[pFeat.feature._id]" type="number">
                                        </span>
                                        <span v-else-if="pFeat.feature.type == 'boolean'">
                                            <v-select v-model="productFeatures[pFeat.feature._id]" :options="booleanSelect" :reduce="p => p.id" label="text"/>
                                        </span>
                                        <span v-else>
                                            brak gotowej metody na inny typ cechy!
                                        </span>
                                    </div>
                                    <label class="col-md-1 form-control-label text-danger">
                                        <a @click="removeFeature(pFeat.feature)"><i class="material-icons md-18">highlight_off</i></a>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Dodatkowe cechy produktu</label>
                            <br>
                            <div class="box-bordered">
                                <div class="form-group row m-b-sm"  v-for="pFeat in optionalProductFeatures" :key="pFeat.feature._id">
                                    <label class="col-md-4 form-control-label">{{pFeat.feature.desc}}</label>
                                    <div class="col-md-7">
                                        <span v-if="pFeat.feature.type == 'text'">
                                            <v-select v-model="productFeatures[pFeat.feature._id]" :options="pFeat.feature.values" taggable/>
                                        </span>
                                        <span v-else-if="pFeat.feature.type == 'number'">
                                            <input class="form-control form-control-sm" v-model.number="productFeatures[pFeat.feature._id]" type="number">
                                        </span>
                                        <span v-else-if="pFeat.feature.type == 'boolean'">
                                            <v-select v-model="productFeatures[pFeat.feature._id]" :options="booleanSelect" :reduce="p => p.id" label="text"/>
                                        </span>
                                        <span v-else>
                                            brak gotowej metody na inny typ cechy!
                                        </span>
                                    </div>
                                    <label class="col-md-1 form-control-label text-danger">
                                        <a @click="removeFeature(pFeat.feature)"><i class="material-icons md-18">highlight_off</i></a>
                                    </label>
                                </div>
                                <div class="box-divider m-a-0 m-b-sm"/>

                                <div class="form-group row m-b-sm">
                                    <label class="col-md-4 form-control-label">Nowa cecha</label>
                                    <div class="col-md-7">
                                        <v-select v-model="featureToAdd" :options="availableFeatures" label="desc" class="top-menu" />
                                    </div>
                                    <label class="col-md-1 form-control-label">
                                        <a @click="addFeature(featureToAdd); featureToAdd = null"><i class="material-icons md-18">add</i></a>
                                    </label>
                                </div>

                                <div v-if="recipeExtraFeatures.length" class="m-b-1">
                                    <span>Recepta tego produktu używa opcjonalnych cech: </span><br>
                                    <span class="feature-label" v-for="feat in recipeExtraFeatures" :key="feat._id">{{ feat.desc }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <!-- <div class="box-footer" v-else>
            <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" @click="save">
                Zapisz
            </button>
        </div> -->
    </div>
</template>

<script>
import { parseProductCode } from '../../../ProductFeaturesTranslator';
import Api from '../../../Api.js';
import EventBus from '../../../event-bus.js';
import { RotateSquare2 } from 'vue-loading-spinner';

import OP from 'object-path';

export default {
    name: 'ProductTypeEditModal',
    props: {
        productObj: Object,
        types: Array,
        categories: Array
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            isProcessing: false,
            productType: "",
            productCategory: "",
            features: [],
            recipeExtraFeatures: [],
            currencies: [
                { id: 'PLN', text: 'PLN' },
                { id: 'EUR', text: 'EUR' }
            ],
            product: { productionValue: {} },
            recipe: null,
            productFeatures: {},
            featureToAdd: null,
            booleanSelect: [
                { id: true, text: 'Tak' },
                { id: false, text: 'Nie' }
            ],
            findedProductFeatures: {}
        }
    },
    computed: {
        availableFeatures() {
            return this.features.filter(f => typeof this.product.features[f._id] == 'undefined');
        },
        requiredProductFeatures() {
            const r = [];
            const productType = this.types.find(t => t.code == this.productType);
            if (!productType) return r;
            for (const fId in this.productFeatures) {
                const feature = this.features.find(f => f._id == fId);
                if (!feature) continue;
                if (!productType.features.includes(feature.code)) continue;
                const value = this.productFeatures[fId];
                r.push({
                    feature,
                    value
                });
            }
            return r;
        },

        optionalProductFeatures() {
            const r = [];
            const required = this.requiredProductFeatures;
            for (const fId in this.productFeatures) {
                const feature = this.features.find(f => f._id == fId);
                if (!feature) continue;
                if (required.find(rf => rf.feature._id == feature._id) != null) continue;
                const value = this.productFeatures[fId];
                r.push({
                    feature,
                    value
                });
            }
            return r;
        }
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    created: async function() {
        Object.assign(this.product, this.productObj);

        if (!this.product.features) this.product.features = {};
        this.productFeatures = Object.assign({}, this.product.features);

        try {
            this.features = await Api.product.getProductsFeatures();

            if (!this.types) {
                let res = await Api.product.types.search({ sort: { name: 1 }, limit: 1000 });
                this.types = res.data;
            }
        } catch (error) {
            this.$h.commonApiError(error);
        }

        this.productCategory = this.product.category || "";
        this.productType = this.product.productType || "";
        this.setRequiredFeatures();
        this.findExtraFeatures();
    },
    methods: {
        save: async function() {
            this.isProcessing = true;
            try {
                this.product.productType = this.productType;
                this.product.category = this.productCategory;
                // usuniecie pustych productFeatures
                for (const k in this.productFeatures) if (this.productFeatures[k] === '' || this.productFeatures[k] === null || this.productFeatures[k] === 0) delete this.productFeatures[k]
                this.product.features = this.productFeatures;
                const p = await Api.product.modifyProduct(this.product);
                EventBus.$emit('productChange', p);
                this.$helpers.success("Pomyślnie zapisano zmiany.");
                this.$emit('close', p);
            } catch (error) {
                if (OP.get(error, "response.data.error.code") == "same-product-exists") {
                    this.$h.errorNotif(`W systemie istnieje już produkt z takimi cechami o kodzie [${OP.get(error, "response.data.error.product.code")}]`);
                } else {
                    this.$h.commonApiError(error);
                }
            }
            this.isProcessing = false;
        },
        setRequiredFeatures() {
            const selectedType = this.types.find(t => t.code == this.productType);
            if (!selectedType) return;
            for (const featCode of selectedType.features) {
                const f = this.features.find(f => f.code == featCode);
                if (!f) continue;
                if (!this.productFeatures[f._id]) this.productFeatures[f._id] = "";
            }

            this.findExtraFeatures();
        },

        addFeature(feature) {
            this.$set(this.productFeatures, feature._id, 0);
        },

        removeFeature(feature) {
            this.$delete(this.productFeatures, feature._id);
        },

        // onFeatureNameChanged: function(e) {
        //     this.productType.code = this.productType.name.replace(/\s\s+/g, ' ').replace(/\s/g, '_').toLowerCase();
        //     this.productType.code = accents.remove(this.productType.code);
        // },

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.save();
            }
        },
        setProductTypeAndFeatures() {
            const codeParts = this.product.code.split('/');
            const c = codeParts[0];

            // autowykrywanie tylko po typach porduktu oznczonych jako autodetekted
            const autoDetectTypes = this.types.filter(t => t.isAutodetectionProductCode);
            if (c.match(/^W?F(K|P)\D?s?-(\d|MF|W|M)+/i)) {
                const cp = c.split('-');
                // nie wiem po co to było
                if (cp[0].slice(-1) == "s") { cp[0] = cp[0].slice(0, -1); }
                const prodCode = cp[0];
                const reg = new RegExp("^" + prodCode.trim() + "-");
                // const type = this.types.find(t => t.generatedCode.value.match(reg));

                const type = autoDetectTypes.find(t => t.generatedCode.value.match(reg));
                if (type) {
                    this.productType = type.code;
                }
                // szukamy typu dla formatki okrągłej czyli każdej co ma fi
                if (prodCode == 'WFKA' && codeParts[1].includes("fi")) {
                    const reg2 = new RegExp("^" + prodCode.trim() + "-{{klasa_filtracji}}/fi");
                    const findedType = autoDetectTypes.find(t => t.generatedCode.value.match(reg2));
                    // const findedType = this.types.find(t => t.generatedCode.value.match(reg2));
                    this.productType = findedType.code;
                }
            }

            const cp = c.split('-');
            const prodCode = cp[0];
            const reg = new RegExp("^" + prodCode.trim() + "-");
            const type = autoDetectTypes.find(t => {
                return t.generatedCode.value.match(reg)
            });

            if (type) { this.productType = type.code; }
            // WKFKA//
            // szukamy i ustawiamy dla wkfka i ustawiamy Wkład do filtra kasetowego plisowany
            if (c.match(/^WkFKA-.+/i)) this.productType = 'wklad_do_filtra_kasetowego_plisowany';
            this.findedProductFeatures = parseProductCode(this.product.code);
            // ale jesli kod jest wkfka i ma w cehcach lamelki to jest to Wkład lamelkowy
            if (this.findedProductFeatures.ilosc_lamelek && c.match(/^WkFKA-.+/i)) this.productType = 'wklad_lamelkowy';
            // reczne dodanie cechy ilosc siatek w przypadku wklad_do_filtra_kasetowego_plisowany
            if (c.match(/^WkFKA-.+/i) && !this.findedProductFeatures.ilosc_lamelek) this.findedProductFeatures.ilosc_siatek = 2;
            // WKFKA//

            // FK-liczba/4d/ lub samo d - filtr kieszeniowy na drucie
            if (c.match(/^FK-.+/i) && (codeParts[1] == 'd' || codeParts[1] == '4d')) {
                this.productType = 'filtr_kieszeniowy_na_drucie';
            }

            // FKP-liczba - filtr kasetowy płaski
            if (c.match(/^FKP-.+/i) && codeParts[0] != 'FKP-M') {
                this.productType = 'filtr_kasetowy_plaski';
            }
            // FKP-liczba - filtr kasetowy płaski

            // FKA-M lub FKP-M -f iltr kasetowy metalowy płaski
            if (c.match(/^FKA-.+/i) && codeParts[0] == 'FKA-M') {
                this.productType = 'filtr_kasetowy_metalowy_plaski';
            }
            // FKA-M lub FKP-M -f iltr kasetowy metalowy płaski

            for (const fCode in this.findedProductFeatures) {
                const findedFeat = this.features.find(t => t.code == fCode);
                if (!findedFeat) continue;
                if (fCode == 'typ_filtra') {
                    this.$delete(this.productFeatures, findedFeat._id);
                } else {
                    this.productFeatures[findedFeat._id] = this.findedProductFeatures[fCode];
                }
            }
            this.setRequiredFeatures();

            if (this.productType == 'filtr_kompaktowy') {
                const grubosc = this.findedProductFeatures.grubosc;
                // znalezlismy grubosc w productfeats ale czasem jej tam nie ma i itrzeba szukac bezposrednio przez id
                if (grubosc) {
                    this.addMaterialByFeature(grubosc);
                } else {
                    const f = this.features.find(f => f.code == 'grubosc');
                    if (this.productFeatures[f._id]) this.addMaterialByFeature(this.productFeatures[f._id]);
                }
            }
        },

        async findExtraFeatures() {
            this.recipe = null;
            if (!this.productType) return;
            try {
                const res = await Api.production.recipes.v3.search({ produces: this.productType });
                if (res.data.length > 0) {
                    this.recipe = res.data[0];
                    const feats = OP.get(this.recipe, "graph.nodes", [])
                        .filter(n => n.ntype == "feature" && n.properties.fcode)
                        .map(n => this.features.find(f => f.code == n.properties.fcode))
                        .filter(f => f != null);

                    this.recipeExtraFeatures.splice(0);
                    this.recipeExtraFeatures.push(...feats);
                } else {
                    this.recipeExtraFeatures.splice(0);
                }
            } catch (error) {
                this.$h.commonApiError(error);
            }
        },

        // Jeśli mamy fitr kompatowy to wzależności od jego grubości dodajemy cechę 'material'
        addMaterialByFeature(grubosc) {
            const material = this.availableFeatures.find(a => a.code == 'material');
            if (!material) return;
            if (grubosc == 25) this.$set(this.productFeatures, material._id, "stal");
            else if (grubosc == 46 || grubosc == 94) this.$set(this.productFeatures, material._id, "papier");
            else if (grubosc >= 26 && grubosc <= 100) this.$set(this.productFeatures, material._id, "stal");
        }

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-bordered {
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 6px 12px 0px 12px;
}
</style>
