import SystemSocket from './SystemSocket.js';
import Push from 'push.js';

let packIcon = require('./assets/images/box-color.png');

let isEnabled = false;
let $router = null;

SystemSocket.socket.on('service_exiting', (msg) => {
    if (!isEnabled) return;
    Push.create("System chwilowo niedostępny", {
        body: "Jedna z usług systemu jest właśnie przeładowywana. Przez ok 10sek mogą być problemy z korzystaniem z aplikacji",
        // icon: '/icon.png',
        timeout: 6000
    });
});

SystemSocket.socket.on('object', (msg) => {
    if (!isEnabled) return;

    if (msg.object == 'notification' && msg.verb == 'create' && msg.data) {
        if (msg.data.code == 'task_ready_to_ship') notfifTaskReadyToShip(msg.data);
        else if (msg.data.code == 'new_task_to_confirm') notfifTaskNeedConfirm(msg.data);
        else if (msg.data.code == 'wfirma_order_edited') notfifWfirmaOrderEdited(msg.data);
        else if (msg.data.code == 'wfirma_order_removed') notfifWfirmaOrderRemoved(msg.data);
    }
});

function notfifWfirmaOrderRemoved(notif) {
    Push.create("Wykryto usunięcie dokumetnu!", {
        body: "Dokument po stronie wfirma został usuniety. Kliknij tutaj aby przejść do zamówienia i sprawdzić zmiany",
        // icon: '/icon.png',
        timeout: 4000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'order-details', params: { id: notif.data.orderId } })
            this.close();
        }
    });
}

function notfifWfirmaOrderEdited(notif) {
    Push.create("Wykryto modyfikację zamówienia!", {
        body: "Dokument po stronie wfirma został zmodyfikowany. Kliknij tutaj aby przejść do zamówienia",
        // icon: '/icon.png',
        timeout: 4000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'order-details', params: { id: notif.data.orderId } })
            this.close();
        }
    });
}

function notfifTaskReadyToShip(notif) {
    Push.create("Ukończono pakowanie zlecenia!", {
        body: "Kliklnij tutaj aby przejść do zamówienia",
        // icon: '/icon.png',
        timeout: 4000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'order-details', params: { id: notif.data.orderId } })
            this.close();
        }
    });
}

function notfifTaskNeedConfirm(notif) {
    Push.create("Nowe zadanie wymaga potwierdzenia!", {
        body: "Kliklnij tutaj aby przejść do zamówienia",
        icon: packIcon,
        timeout: 4000,
        onClick: function () {
            window.focus();
            if ($router && notif.data) $router.push({ name: 'order-details', params: { id: notif.data.orderId } })
            this.close();
        }
    });
}



export default {
    /**
     * Uruchamia systemowe powiadomienia (w postaci dymkow)
     * @param {object} router Vue'owski router, zeby moc kierowac uzytkownikow po klikniecu w dymek
     */
    enable: function(router) {
        isEnabled = true;
        Push.Permission.request();
        if (router) $router = router;
    },
    disable: function() {
        isEnabled = false;
    }
};