<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Termin realizacji umówiony z klientem</h6>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <form class="form-horizontal m-b">
                <!-- <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Priorytet</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select v-model="order.priority" :options="priors" :reduce="p => p.v" @input="savePrior" />
                    </div>
                </div> -->

                <div class="form-group row">
                    <label class="col-sm-4 col-md-4 form-control-label">Termin realizacji</label>
                    <div class="col-sm-8 col-md-8">
                        <datepicker :language="pl" :monday-first="true" :bootstrap-styling="true" :typeable="true" format="yyyy-MM-dd" placeholder="Wybierz datę" v-model="order.customerDate" @input="saveDate" input-class="form-control custom-input" />
                    </div>
                </div>
            </form>

            <!-- <div v-if="order.production">
                <span>Obliczony postęp realizacji: {{ percentProgress }}%</span><br>
                <span class="text-muted text-sm">Ostatnio skanowane: {{ order.production._lastUpdate | moment('from') }}</span><br>
                <a class="text-muted text-sm" @click.prevent="recalculateProgress">(przelicz teraz)</a>
            </div> -->
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Datepicker from 'vuejs-datepicker';
import pl from 'vuejs-datepicker/dist/locale/translations/pl.js'

export default {
    name: 'CustomerDate',
    props: {
        order: Object
    },
    data: function () {
        return {
            priors: [
                { v: 0, label: 'Niski' },
                { v: 1, label: 'Normalny' },
                { v: 2, label: 'Wysoki' },
                { v: 3, label: 'Krytyczny' }
            ],
            pl: pl
        }
    },
    components: {
        Datepicker
    },

    created: function() {
    },

    computed: {
        percentProgress() {
            if (!this.order || !this.order.production) return 0;
            return parseInt(this.order.production.progress * 100);
        }
    },

    methods: {
        recalculateProgress: async function() {
            try {
                this.$helpers.info("Trwa przeliczanie...");
                const order = await Api.order.recalculateProgress(this.order._id);
                if (order && order.production) {
                    this.order.production.progress = order.production.progress;
                    this.order.production._lastUpdate = order.production._lastUpdate;
                }
                this.$helpers.success("Dane produkcyjne przeliczone poprawnie");
            } catch (e) {
                this.$helpers.errorNotif();
            }
        },

        saveDate: async function() {
            try {
                await Api.order.save(this.order._id, { customerDate: this.order.customerDate });
                this.$helpers.success("Pomyślnie zmieniono termin realizacji dla klienta");
            } catch (e) {
                this.$helpers.errorNotif();
            }
        },

        savePrior: async function() {
            try {
                await Api.order.save(this.order._id, { priority: this.order.priority });
                this.$helpers.success("Pomyślnie zmieniono priorytet zamówienia");
            } catch (e) {
                this.$helpers.errorNotif();
            }
        }
    }
}
</script>
