<template>
    <div class="p-x p-y-xs" v-if="isReady">

        <div class="ing-box">
            Grupa: <strong>{{ ingredient.group }}</strong><br>
            <span class="text-xs text-muted">pracownik będzie mógł wybrać jedno z poniższych:</span>
            <div v-for="(possibleProd, k) in possibleProducts" :key="k">
                <router-link :to="{ name: 'product-type-details', params: { code: possibleProd.productType.code } }">
                    <img :src="possibleProd.productType.iconUrl" class="product-icon">
                    {{ possibleProd.productType.name }}
                </router-link>

                <!-- //jeśli jednostka jest '100 szt.' to wtedy inne przedstawienie ilosci zużytej -->
                <span v-if="possibleProd.productType.unit && possibleProd.productType.unit== '100 szt.'">
                    <span class="label text-white m-r-xs none-radius inline p-t-xs p-x-xs blue" style="display: inline-block">
                        {{ (Math.round(parseFloat(possibleProd.amount * 10000 ))/10000) *100 }} szt.
                    </span>
                    <span class="text-muted" style="font-size:0.65rem">
                        ( {{ Math.round(parseFloat(possibleProd.amount * 10000 ))/10000 }} x {{ possibleProd.productType.unit || 'szt' }})
                    </span>
                </span>
                <span v-else>
                    <span class="label text-white m-r-xs none-radius inline p-t-xs p-x-xs blue" style="display: inline-block">
                        {{ Math.round(parseFloat(possibleProd.amount * 10000 ))/10000 }}  {{ possibleProd.productType.unit || 'szt' }}
                    </span>
                </span>
                <br>
                <span  v-for="(f, key) in possibleProd.features" :key="key">
                    <span v-if="features.find(f => f.code == key)" style="display: inline-block;" class="m-1 feature-label">{{features.find(f => f.code == key).desc}}: {{f}}</span>
                </span>
            </div>
        </div>

    </div>
</template>

<script>
import Api from '../../../Api.js';

export default {
    name: 'IngredientGroupBox',
    props: {
        ingredient: Object,
        productTypes: {
            type: Array,
            required: false
        }
    },
    components: {
    },
    data: function () {
        return {
            pTypes: [],
            features: [],
            isReady: false
        }
    },

    async created() {
        this.features = await Api.product.getProductsFeatures();
        if (this.productTypes) {
            this.pTypes.push(...this.productTypes);
        } else {
            const pTypes = await Api.product.types.getAll();
            this.pTypes.push(...pTypes);
        }
        this.isReady = true;
    },

    computed: {
        possibleProducts() {
            return (this.ingredient.products || []).map(p => {
                return {
                    productType: this.pTypes.find(type => type.code == p.productType),
                    amount: p.amount,
                    features: p.features
                };
            })
        }
    },
    methods: {
    }
}
</script>
