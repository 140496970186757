<template>
    <div class="box" >
        <div class="box-header">
            <h3 class="m-b" v-if="product._id">Edytuj produkt</h3>
            <span v-else>
                <h3>Dodaj produkt</h3>
                <small>Dodaj nową kartotekę produktu</small>
            </span>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc" v-if="product._id">
                    <a class="nav-link text-muted" @click="setProductTypeAndFeatures()" title="Automatyczne wykrywanie cech i typu">
                        <i class="material-icons md-18">get_app</i>
                        <span class="icon-desc">AUTO WYK.</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body p-t-0" v-if="!isProcessing" style="min-height:200px">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Nazwa</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" v-model="product.name">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Kod</label>
                        <div class="col-sm-9">
                            <input class="form-control" type="text" v-model="product.code">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Jednostka</label>
                        <div class="col-sm-9">
                            <v-select placeholder="Nie wybrano" label="name" v-model="product.unit" :options="unitOptions"  />
                        </div>
                    </div>
                    <div v-if="productAlterUnits.length > 0">
                        <label class="m-b-0 m-t-xs text-muted text-xs">Opakowania zbiorcze</label>
                        <hr class="m-t-xs">
                        <div class="form-group row m-b-sm"  v-for="alter in productAlterUnits" :key="alter.unit">
                            <label class="col-md-3 form-control-label">{{alter.unit}}</label>
                            <div class="col-md-8">
                                <input class="form-control form-control-sm" v-model="alter.mulitipler" type="number" placeholder="Mnożnik">
                            </div>
                            <label class="col-md-1 form-control-label text-danger">
                                <a @click="removeAlterUnit(alter)"><i class="material-icons md-18">highlight_off</i></a>
                            </label>
                        </div>
                        </hr/>
                    </div>
                    <div class="form-group m-y-md">
                        <label class="md-check">
                            <input type="checkbox" v-model="isAlterUnits">
                            <i class="blue m-r-xs"/>
                            <span class="m-l-xs" v-if="productAlterUnits.length > 0 && product._id">Dodaj kolejne opakowania zbiorcze</span>
                            <span class="m-l-xs" v-else>Dodaj opakowania zbiorcze</span>
                        </label>
                    </div>
                    
                    <div class="form-group row" v-if="isAlterUnits">
                        <label class="col-md-3 form-control-label">Opakowanie</label>
                        <div class="col-md-8">
                            <v-select v-model="alterToAdd" :options="alternateUnits" label="desc" class="top-menu" taggable placeholder="Opakowanie"/>
                        </div>
                        <label class="col-md-1 form-control-label">
                            <a @click="addAlterUnit(alterToAdd); alterToAdd = null"><i class="material-icons md-18">add</i></a>
                        </label>
                    </div>
                    <hr v-if="isAlterUnits" class="m-t-xs">

                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Minimalna ilość</label>
                        <div class="col-sm-9">
                            <input class=" form-control form-control-sm" type="text" v-model="product.minStockQty" placeholder="Minimalna ilość">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">EAN13</label>
                        <div class="col-sm-9">
                            <input class=" form-control form-control-sm" type="text" v-model="product.ean13" placeholder="Minimalna ilość">
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Kategoria</label>
                        <div class="col-sm-9">
                            <v-select placeholder="Kategoria" v-model="product.category" :options="categories" class="top-menu" />
                        </div>
                    </div>
                    <div v-if="product.category == 'Opakowania'">
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Szerokość</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="text" v-model="product.width">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Wysokość</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="text" v-model="product.height">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-sm-3 form-control-label">Długość</label>
                            <div class="col-sm-9">
                                <input class="form-control" type="text" v-model="product.length">
                            </div>
                        </div>
                    </div>
                    <div class="form-group m-y-md">
                        <label class="md-check">
                            <input type="checkbox" v-model="product.isPurchased">
                            <i class="blue m-r-xs"/>
                            <span class="m-l-xs">Produkt jest kupowany</span>
                        </label>
                    </div>
                    <div class="form-group row" v-if="product.isPurchased">
                        <label class="col-sm-3 form-control-label">Domyślny koszt</label>
                        <div class="col-sm-9">
                            <input class="form-control form-control-sm" type="text" v-model.number="product.purchasePrice">
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="form-group row">
                        <label class="col-sm-3 form-control-label">Typ produktu</label>
                        <div class="col-sm-9">
                            <v-select placeholder="Typ produktu" @input="setRequiredFeatures" v-model="productType" :options="types" label="name" :reduce="t => t.code" />
                        </div>
                    </div>

                    <div class="form-group" v-if="productType">
                        <label class="m-b-0 m-t text-muted text-xs">Bazowe cechy produktu</label>
                        <hr class="m-t-xs">
                        <div class="box-bordered" v-if="productType">
                            <div class="form-group row m-b-sm"  v-for="pFeat in requiredProductFeatures" :key="pFeat.feature._id">
                                <label class="col-md-4 form-control-label">{{pFeat.feature.desc}}</label>
                                <div class="col-md-7">
                                    <span v-if="pFeat.feature.type == 'text'">
                                        <v-select v-model="productFeatures[pFeat.feature._id]" :options="pFeat.feature.values" taggable />
                                    </span>
                                    <span v-else-if="pFeat.feature.type == 'number'">
                                        <input class="form-control form-control-sm" v-model.number="productFeatures[pFeat.feature._id]" type="number">
                                    </span>
                                    <span v-else-if="pFeat.feature.type == 'boolean'">
                                        <v-select v-model="productFeatures[pFeat.feature._id]" :options="booleanSelect" :reduce="p => p.id" label="text"/>
                                    </span>
                                    <span v-else>
                                        brak gotowej metody na inny typ cechy!
                                    </span>
                                </div>
                                <label class="col-md-1 form-control-label text-danger">
                                    <a @click="removeFeature(pFeat.feature)"><i class="material-icons md-18">highlight_off</i></a>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group" v-if="productType">
                        <label class="m-b-0 text-muted text-xs">Dodatkowe cechy produktu</label>
                        <hr class="m-t-xs">

                        <div class="box-bordered">
                            <div class="form-group row m-b-sm"  v-for="pFeat in optionalProductFeatures" :key="pFeat.feature._id">
                                <label class="col-md-4 form-control-label">{{pFeat.feature.desc}}</label>
                                <div class="col-md-7">
                                    <span v-if="pFeat.feature.type == 'text'">
                                        <v-select v-model="productFeatures[pFeat.feature._id]" :options="pFeat.feature.values" taggable/>
                                    </span>
                                    <span v-else-if="pFeat.feature.type == 'number'">
                                        <input class="form-control form-control-sm" v-model.number="productFeatures[pFeat.feature._id]" type="number">
                                    </span>
                                    <span v-else-if="pFeat.feature.type == 'boolean'">
                                        <v-select v-model="productFeatures[pFeat.feature._id]" :options="booleanSelect" :reduce="p => p.id" label="text"/>
                                    </span>
                                    <span v-else>
                                        brak gotowej metody na inny typ cechy!
                                    </span>
                                </div>
                                <label class="col-md-1 form-control-label text-danger">
                                    <a @click="removeFeature(pFeat.feature)"><i class="material-icons md-18">highlight_off</i></a>
                                </label>
                            </div>

                            <div class="form-group row m-b-sm">
                                <label class="col-md-4 form-control-label">Nowa cecha</label>
                                <div class="col-md-7">
                                    <v-select v-model="featureToAdd" :options="availableFeatures" label="desc" class="top-menu" />
                                </div>
                                <label class="col-md-1 form-control-label">
                                    <a @click="addFeature(featureToAdd); featureToAdd = null"><i class="material-icons md-18">add</i></a>
                                </label>
                            </div>

                            <div v-if="recipeExtraFeatures.length" class="m-b-1">
                                <label class="m-b-0 text-muted text-xs">Recepta tego produktu używa opcjonalnych cech</label>
                                <hr class="m-t-xs">
                                <!-- <span>Recepta tego produktu używa opcjonalnych cech: </span><br> -->
                                <span class="feature-label" v-for="feat in recipeExtraFeatures" :key="feat._id">{{ feat.desc }}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
    </div>
</template>

<script>
import { parseProductCode } from '../../ProductFeaturesTranslator';
import Api from '../../Api.js';
import EventBus from '../../event-bus.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import OP from 'object-path';

export default {
    name: 'NewProductModalV2',
    props: {
        prod: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            isProcessing: false,
            types: [],
            features: [],
            recipeExtraFeatures: [],
            recipe: null,
            productType: null,
            productFeatures: {},
            featureToAdd: null,
            booleanSelect: [
                { id: true, text: 'Tak' },
                { id: false, text: 'Nie' }
            ],
            product: {},
            isAlterUnits: false,
            alterToAdd: null,
            productAlterUnits: [],
            unitOptions: ['szt.', '100 szt.', '1000 szt.', 'm2', 'mb', 'kg'],
            alternateUnits: [],
            categories: []

        }
    },
    created: async function() {
        this.isProcessing = true;

        if (this.prod) {
            if (this.prod.alternateUnits) this.productAlterUnits = this.prod.alternateUnits;
            this.productType = this.prod.productType;
            this.product = Object.assign({}, this.prod);

            this.productFeatures = Object.assign({}, this.prod.features || {});
        }

        try {
            this.types = await Api.product.types.getAll();
            this.categories = await Api.product.getAvailableCategories();
            this.alternateUnits = await Api.product.getAlternateUnits();

            this.features = await Api.product.getProductsFeatures();
            await this.findExtraFeatures();
        } catch (error) {
            this.$h.commonApiError(error, true);
        }
        this.isProcessing = false;
    },
    mounted: async function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        availableFeatures() {
            return this.features.filter(f => typeof this.productFeatures[f._id] == 'undefined');
        },
        requiredProductFeatures() {
            const r = [];
            const productType = this.types.find(t => t.code == this.productType);
            if (!productType) return r;
            for (const fId in this.productFeatures) {
                const feature = this.features.find(f => f._id == fId);
                if (!feature) continue;
                if (!productType.features.includes(feature.code)) continue;
                const value = this.productFeatures[fId];
                r.push({
                    feature,
                    value
                });
            }
            return r;
        },
        optionalProductFeatures() {
            const r = [];
            const required = this.requiredProductFeatures;
            for (const fId in this.productFeatures) {
                const feature = this.features.find(f => f._id == fId);
                if (!feature) continue;
                if (required.find(rf => rf.feature._id == feature._id) != null) continue;
                const value = this.productFeatures[fId];
                r.push({
                    feature,
                    value
                });
            }
            return r;
        }
    },

    methods: {
        handleKeyUp: function(e) {
            if (this.isProcessing) return;
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        async saveAndExit() {
            this.isProcessing = true;
            this.setProductData();

            try {
                if (this.product._id) {
                    this.product.features = this.productFeatures;
                    const p = await Api.product.modifyProduct(this.product);
                    this.$helpers.success("Pomyślnie zapisano zmiany.");
                    EventBus.$emit('productChange', p);
                    // this.$emit('close', p);
                } else {
                    await Api.product.addProduct(this.product);
                    this.$helpers.success("Pomyślnie dodano");
                    EventBus.$emit('newProductCreated');
                }

                this.$emit('close');
            } catch (error) {
                if (OP.get(error, "response.data.error.code") == "same-product-exists") {
                    this.$h.errorNotif(`W systemie istnieje już produkt z takimi cechami o kodzie [${OP.get(error, "response.data.error.product.code")}]`);
                } else if (error.response && error.response.data && error.response.data.error == "code.duplicate") {
                    this.$helpers.errorNotif("Istnieje już produkt z takim kodem w systemie");
                } else {
                    this.$helpers.errorNotif();
                }
            }
            this.isProcessing = false;
        },
        setProductData() {
            if (this.product.category == 'Opakowania') {
                this.product.width = parseFloat(this.product.width);
                this.product.height = parseFloat(this.product.height);
                this.product.length = parseFloat(this.product.length);
            }
            if (this.productAlterUnits.length) {
                for (const a of this.productAlterUnits) {
                    parseFloat(a.mulitipler);
                }
                this.product.alternateUnits = this.productAlterUnits
            }
            if (this.product.purchasePrice) this.product.purchasePrice = parseFloat(this.product.purchasePrice);
            if (this.product.minStockQty) this.product.minStockQty = parseFloat(this.product.minStockQty);
            if (this.productType) this.product.productType = this.productType;
            for (const k in this.productFeatures) if (this.productFeatures[k] === '' || this.productFeatures[k] === null || this.productFeatures[k] === 0) delete this.productFeatures[k]
            this.product.features = this.productFeatures;
        },
        removeFeature(feature) {
            this.$delete(this.productFeatures, feature._id);
        },

        addFeature(feature) {
            this.$set(this.productFeatures, feature._id, 0);
        },
        addAlterUnit(alter) {
            this.productAlterUnits.push({ unit: alter, mulitipler: 0 });
        },
        removeAlterUnit(alter) {
            const index = this.productAlterUnits.map(u => u.unit).indexOf(alter.unit);
            if (index != -1) this.productAlterUnits.splice(index, 1);
        },
        setRequiredFeatures() {
            const selectedType = this.types.find(t => t.code == this.productType);
            if (!selectedType) return;
            for (const featCode of selectedType.features) {
                const f = this.features.find(f => f.code == featCode);
                if (!f) continue;
                if (!this.productFeatures[f._id]) this.productFeatures[f._id] = "";
            }
            this.findExtraFeatures();
        },
        async findExtraFeatures() {
            this.recipe = null;
            if (!this.productType) return;
            try {
                const res = await Api.production.recipes.v3.search({ produces: this.productType });
                if (res.data.length > 0) {
                    this.recipe = res.data[0];
                    const feats = OP.get(this.recipe, "graph.nodes", [])
                        .filter(n => n.ntype == "feature" && n.properties.fcode)
                        .map(n => this.features.find(f => f.code == n.properties.fcode))
                        .filter(f => f != null);

                    this.recipeExtraFeatures.splice(0);
                    this.recipeExtraFeatures.push(...feats);
                } else {
                    this.recipeExtraFeatures.splice(0);
                }
            } catch (error) {
                this.$h.commonApiError(error);
            }
        },
        setProductTypeAndFeatures() {
            const codeParts = this.product.code.split('/');
            const c = codeParts[0];

            // autowykrywanie tylko po typach porduktu oznczonych jako autodetekted
            const autoDetectTypes = this.types.filter(t => t.isAutodetectionProductCode);
            if (c.match(/^W?F(K|P)\D?s?-(\d|MF|W|M)+/i)) {
                const cp = c.split('-');
                // nie wiem po co to było
                if (cp[0].slice(-1) == "s") { cp[0] = cp[0].slice(0, -1); }
                const prodCode = cp[0];
                const reg = new RegExp("^" + prodCode.trim() + "-");
                // const type = this.types.find(t => t.generatedCode.value.match(reg));

                const type = autoDetectTypes.find(t => t.generatedCode.value.match(reg));
                if (type) {
                    this.productType = type.code;
                }
                // szukamy typu dla formatki okrągłej czyli każdej co ma fi
                if (prodCode == 'WFKA' && codeParts[1].includes("fi")) {
                    const reg2 = new RegExp("^" + prodCode.trim() + "-{{klasa_filtracji}}/fi");
                    const findedType = autoDetectTypes.find(t => t.generatedCode.value.match(reg2));
                    // const findedType = this.types.find(t => t.generatedCode.value.match(reg2));
                    this.productType = findedType.code;
                }
            }

            const cp = c.split('-');
            const prodCode = cp[0];
            const reg = new RegExp("^" + prodCode.trim() + "-");
            const type = autoDetectTypes.find(t => {
                return t.generatedCode.value.match(reg)
            });

            if (type) { this.productType = type.code; }
            // WKFKA//
            // szukamy i ustawiamy dla wkfka i ustawiamy Wkład do filtra kasetowego plisowany
            if (c.match(/^WkFKA-.+/i)) this.productType = 'wklad_do_filtra_kasetowego_plisowany';
            this.findedProductFeatures = parseProductCode(this.product.code);
            // ale jesli kod jest wkfka i ma w cehcach lamelki to jest to Wkład lamelkowy
            if (this.findedProductFeatures.ilosc_lamelek && c.match(/^WkFKA-.+/i)) this.productType = 'wklad_lamelkowy';
            // reczne dodanie cechy ilosc siatek w przypadku wklad_do_filtra_kasetowego_plisowany
            if (c.match(/^WkFKA-.+/i) && !this.findedProductFeatures.ilosc_lamelek) this.findedProductFeatures.ilosc_siatek = 2;
            // WKFKA//

            // FK-liczba/4d/ lub samo d - filtr kieszeniowy na drucie
            if (c.match(/^FK-.+/i) && (codeParts[1] == 'd' || codeParts[1] == '4d')) {
                this.productType = 'filtr_kieszeniowy_na_drucie';
            }

            // FKP-liczba - filtr kasetowy płaski
            if (c.match(/^FKP-.+/i) && codeParts[0] != 'FKP-M') {
                this.productType = 'filtr_kasetowy_plaski';
            }
            // FKP-liczba - filtr kasetowy płaski

            // FKA-M lub FKP-M -f iltr kasetowy metalowy płaski
            if (c.match(/^FKA-.+/i) && codeParts[0] == 'FKA-M') {
                this.productType = 'filtr_kasetowy_metalowy_plaski';
            }
            // FKA-M lub FKP-M -f iltr kasetowy metalowy płaski

            for (const fCode in this.findedProductFeatures) {
                const findedFeat = this.features.find(t => t.code == fCode);
                if (!findedFeat) continue;
                if (fCode == 'typ_filtra') {
                    this.$delete(this.productFeatures, findedFeat._id);
                } else {
                    this.productFeatures[findedFeat._id] = this.findedProductFeatures[fCode];
                }
            }
            this.setRequiredFeatures();

            if (this.productType == 'filtr_kompaktowy') {
                const grubosc = this.findedProductFeatures.grubosc;
                // znalezlismy grubosc w productfeats ale czasem jej tam nie ma i itrzeba szukac bezposrednio przez id
                if (grubosc) {
                    this.addMaterialByFeature(grubosc);
                } else {
                    const f = this.features.find(f => f.code == 'grubosc');
                    if (this.productFeatures[f._id]) this.addMaterialByFeature(this.productFeatures[f._id]);
                }
            }
        },

    }
}
</script>
