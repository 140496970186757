<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Zlecenia produkcyjne (ZP)</h6>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <rotate-square2 style="margin: 15px;" v-if="isProcessing"/>
            <div v-if="!isProcessing && zpGrup && zpGrup.qtyAll > 0" @click="showDetalis()">
                <div class="pdf-box m-b-sm">
                    <div class="p-t-sm p-x p-b">
                        <img class="product-icon" src="../../assets/images/production.png" >
                        <span >
                            <span class="">Zleceń produkcyjnych w zamówieniu </span>
                            <span class="label-md text-white p-t-xs p-x-sm c-green pull-right" style="border-radous:5px;">
                                {{zpGrup.qtyAll}}
                            </span><br>
                            <span class="text-xs text-muted" v-if="zpGrup.madeQty < zpGrup.qtyAll">Wykonano {{zpGrup.madeQty}} / {{zpGrup.qtyAll}}</span>
                            <span v-else>
                                <i class="text-green material-icons md-18">done_all</i>
                                <span class="text-green m-l-xs">Ukończono {{zpGrup.madeQty}} / {{zpGrup.qtyAll}}</span>
                            </span>
                            <span class="m-l text-xs text-blue">(Postęp {{ parseInt((zpGrup.progress || 0)*100) }}%)</span>
                        </span>
                        <br>
                        <hr class="m-y-sm">
                        <div v-if="zpGrup.plannedDeadlineMin == zpGrup.plannedDeadlineMax">
                            <span >Deadline: <span class="_600">{{zpGrup.plannedDeadlineMin | moment("DD MMM YYYY")}}</span></span>
                        </div>
                        <div class="m-b" v-else>
                            <span class="pull-left">Deadline min: <span class="_600">{{zpGrup.plannedDeadlineMin | moment("DD MMM YYYY")}}</span></span>
                            <span class="pull-right">Deadline max: <span class="_600">{{zpGrup.plannedDeadlineMax | moment("DD MMM YYYY")}}</span></span>
                        </div>
                    </div>
                </div>
            </div>
            <p class="text-muted m-b-xs" v-if="!isProcessing && (!zpGrup || zpGrup.qtyAll == 0)">Brak zleceń produkcji</p>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import ProductionZPsForOrderModal from './Modals/ProductionZPsForOrderModal.vue';

export default {
    name: 'Production',
    props: {
        order: Object
    },
    data: function () {
        return {
            isProcessing: false,
            zpGrup: null
        }
    },
    components: {
        RotateSquare2
    },

    created: function() {
        this.getGrupFororder();
    },
    destroyed: function() {
    },
    computed: {
    },

    methods: {
        async getGrupFororder() {
            try {
                const data = await Api.production.request.v3.getGrupForOrder(this.order._id);
                this.zpGrup = data.data;
                console.log("this.zpGrup", this.zpGrup)
            } catch (error) {
                console.log("err", error);
            }
        },
        showDetalis: function(pr) {
            this.$modal.show(ProductionZPsForOrderModal, {
                order: this.order
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                width: '800px',
                scrollable: true
            }, {
                closed: (event) => {
                    this.getProdReqs()
                }

            });
        }

    }
}
</script>

<style  scoped lang="scss">
.shadow {
  -webkit-box-shadow: 2px 2px 4px 5px #dedede;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    2px 2px 4px 5px #dedede;  /* Firefox 3.5 - 3.6 */
  box-shadow:         2px 2px 4px 5px #dedede;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}
.shadow-ended {
-webkit-box-shadow: 1px 1px 2px 3px #dedede;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
-moz-box-shadow:    1px 1px 2px 3px #dedede;  /* Firefox 3.5 - 3.6 */
box-shadow:         1px 1px 2px 3px #dedede;
}
.label-custom {
    padding: 4px 8px;
    font-size: 0.76rem;
}
.border-zo {
    border: 1px solid #58666e;
}
</style>
