<template>
    <div class="box" style="100%; min-height:460px; max-height:800px; overflow-y: auto">
        <div class="box-header">
            <h3>Blokujące zadania</h3>
            <small>Poniżej znajdują się blokujące zadania zaplanowane na dzisiaj. Ukończenie jakiekolwiek spowoduje że na stanowisku {{ wName }} pojawią sie nowe zadania</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <table class="table">
                <thead>
                    <tr>
                        <th>Typ zadania</th>
                        <th>Do zrobienia</th>
                        <th>Zamówienie</th>
                        <th>Postęp ZP</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="tRaport in blockingTasks" :key="tRaport.taskId">
                        <td>{{ tRaport.name }}</td>
                        <td>{{ tRaport.toMadeCount - tRaport.madeCount }}/{{ tRaport.toMadeCount }}szt</td>
                        <td><router-link :to="{ name: 'order-details', params: { id: tRaport.orderId }}">#{{tRaport.orderName}}</router-link></td>
                        <td>{{ Math.round(tRaport.progress*100) }}%</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'BlockingTasksPreview',
    props: {
        wName: String,
        blockingTasks: Array
    },
    components: {
    },
    data: function () {
        return {
            isProcessing: false
        }
    },
    created: async function() {

    },
    mounted: async function() {
        this.isProcessing = true;
        try {
        } catch (error) {}
        this.isProcessing = false;
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {

        saveAndExit: async function() {

        },

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        }
    }
}
</script>

<style scoped lang="scss">
.icon {
  width: 15px;
  height: auto;
}

.sl-icon img {
    position: relative;
    left: 2px;
    top: -1px;
}

.progress {
    border-radius: 0;
    margin-bottom: 0;

    .progress-bar {
        border-radius: 0;
    }
}
</style>
