<template>
    <div class="box">
        <div class="d-gray p-a">
            <span>Indywidualne etykiety dla klienta  </span>
        </div>
        <div class="box-divider m-a-0"/>
        <div class="box-body">
            <form class="form-horizontal m-b-lg" v-if="isReady">
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Produkty</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="customer.printLabels.productsPrintTemplateId" :options="productTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Karton</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="customer.printLabels.labelsPrintTemplateId" :options="boxTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4 col-md-3 form-control-label">Paleta</label>
                    <div class="col-sm-8 col-md-9">
                        <v-select placeholder="Brak - nie drukuj" label="name" v-model="customer.printLabels.palletesPrintTemplateId" :options="palletTemplates" :reduce="p => p._id" />
                    </div>
                </div>
                <a href="#" class="pull-right text-blue text-sm m-t" v-on:click.prevent="save" :disabled="procesing == 1">Zapisz</a>
                <!-- <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" @click="save" :disabled="procesing == 1">Zapisz</button> -->
            </form>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';

export default {
    name: 'CustomerLabels',
    props: {
        customer: Object
    },
    data: function () {
        return {
            isLoaded: false,
            templates: [],
            procesing: false,
            isReady: false,
        }
    },
    created: function() {
        if (!this.customer.printLabels) this.customer.printLabels = {};
        this.getAllTemplates();
    },
    computed: {
        productTemplates: function() {
            return this.templates.filter(t => t.dataScheme == "productsIdsWithQty");
        },

        boxTemplates: function() {
            return this.templates.filter(t => t.dataScheme == "taskBox");
        },

        palletTemplates() {
            return this.templates.filter(t => t.dataScheme == "pallet");
        }
    },
    methods: {
        getAllTemplates: async function() {
            this.isReady = false;
            try {
                this.templates = await Api.printing.templates.search({});
            } catch (error) {
                console.log("bład")
            }
            this.isReady = true;
        },
        async save() {
            try {
                await Api.customer.edit(this.customer._id, this.customer);
                 this.$notify({group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.'});
            } catch (error) {
                  this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił błąd.'});
            }
        }
    }
}
</script>
