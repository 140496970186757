<template>
    <div>
        <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">Lista cech</h6>
            <div class="pull-right minus-margin">
                <a class="btn btn-sm white none-radius" @click.prevent="edit({})">
                    <span class="primary-color _400">Dodaj</span>
                </a>
            </div>
        </div>
        <table class="table" v-if="!isLoaded && features.length > 0">
            <thead class="primary-color">
                <tr>
                    <th>Kod</th>
                    <th>Opis</th>
                    <th>Typ</th>
                    <th style="width: 5%;"/>
                </tr>
            </thead>
            <tbody>
                <tr class="custom-gray" v-for="f in features" :key="f._id">
                    <td><img v-if="f.iconUrl" class="feature-icon" :src="f.iconUrl">{{f.code}}</td>
                    <td>{{f.desc}}</td>
                    <td>{{f.type}}</td>
                    <td><a  class="text-blue text-sm" href="#" @click.prevent="edit(f)">edytuj</a></td>
                </tr>
            </tbody>
        </table>
        <p class="text-center text-muted p-y" v-if="!isLoaded && features.length == 0">Brak dodanych cech</p>
        <Spinner v-if="isLoaded"/>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import FeatureEditModal from "./FeatureEditModal.vue";

export default {
    name: 'Features',
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            features: []
        }
    },
    created: function() {
        this.getFeatures();
    },
    methods: {
        getFeatures: function() {
            this.isLoaded = true;
            Api.product.getProductsFeatures().then((features) => {
                this.isLoaded = false;
                this.features = features;
            })
        },

        edit: function(f) {
            this.$modal.show(FeatureEditModal, {
                feature: f
            }, { height: 'auto' }, {
                closed: () => { this.getFeatures() }
            });
        }
    }
}
</script>
