<template>
<div class="box">
  <div class="box-header">
    <h6 class="title-color inline">Pliki</h6>
    <span class="form-file">
      <input type="file" ref="files" multiple v-on:change="onAdd" style="display: none;">
      <a href="#" class="pull-right text-blue text-sm m-t" v-on:click.prevent="$refs.files.click();">Dodaj plik</a>
    </span>
  </div>
  <div class="m-a-0 custom-divider"></div>
  <div class="box-body">
    <div class="row">
      <div class="col-md-12">

        <div class="pdf-box m-b-sm" v-for="uf in uploadedFiles" v-if="!uf.isSent">
          <div class="p-y-sm p-x">
            <div class="pull-left m-r">
              <i class="material-icons text-d-blue m-y-sm text-2x ">photo_library</i>
            </div>
            <div class="clear">
              <div class="text-muted">{{uf.file.name | truncate(25) }}</div>
              <div class="progress m-t-xs" v-if="!isError">
                <div class="progress-bar blue" v-bind:style="{ width: uf.progress + '%' }">{{uf.progress}} %</div>
              </div>
              <div  class="upload-error p-a-xs m-t-xs" v-if="isError">
                <span class="text-white text-xs">Wystąpił błąd w trakcie wysyłania pliku. Spróbuj przesłać plik jeszcze raz lub zgłoś problem do swojego admina.</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" v-if="attachments.length > 0 && isReady">
        <div class="pdf-box m-b-sm" v-for="a in evenAttachments">
          <div class="p-y-sm p-x">
            <div class="pull-left m-r">
              <i class="material-icons text-d-blue m-y-sm text-2x ">photo_library</i>
            </div>
            <div class="clear">
              <div class="text-blue">
                <a v-bind:href="Api.getFileUrl(a.file.key, a.file.name)" target="_blank">{{a.file.name | truncate(25) }}</a>
                
                <a v-if="a.userId == userId" class="text-muted pull-right p-r-xs" href="#" v-on:click.prevent="remove(a._id)">
                  <i class="material-icons md-18"></i>
                </a>
              </div>
              <span class="text-muted text-sm">
                <span class="upper-text">{{a.file.meta.ext}}</span>, 
                {{a.formatSize}} <br/>
                <span v-if="a.author == 'user'">{{userNames[a.userId]}}</span>
                <span v-if="a.author == 'system'">Plik systemowy</span>
                | {{ a._created | moment("DD MMM YYYY, HH:MM") }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 primary-color" v-if="isEmpty">Brak plików w tym zamówieniu</div>
    </div>
  </div>
</div>
</template>

<script> 
import UserManager from '../../UserManager.js';
import SystemUsersManager from '../../SystemUsersManager.js';
import Api from '../../Api.js';

export default {
  name: 'Files',
  props: {
    attachments: Array,
    orderId: String
  },
  components: {

  },
  data(){
    return {
      isEmpty: false,
      isReady: false,
      systemUsers: [],
      uploadedFiles: [],
      Api: Api,
      isError: false,
      userId: '',
      userNames: {},
    }
  },
  computed: {
    evenAttachments: function () {
      return this.attachments.sort(function(a, b) {
        var dateA = new Date(a._created), dateB = new Date(b._created);
        return dateB - dateA;
      });
    }
  },
  created: function() {
    if (this.attachments.length == 0) {
      this.isEmpty = true; 
      return;
    }
    this.userId = UserManager.user.userId;
    
    SystemUsersManager.getSystemUsers().then((systemUsers) => {
      this.systemUsers = systemUsers;
      this.assignNames();
      this.formatAttach();
    });
    
  },
  methods: {
    assignNames: function() {
      this.systemUsers.map((u) => {
        this.userNames[u._id] = u.name;
      });
    },
    formatAttach: function() {
      let attachIds = this.attachments.map((attach) => {
        return attach.id+"";
      }); 

      for (var i = this.attachments.length - 1; i >= 0; i--) {
        if (attachIds.indexOf(this.attachments[i]._id+"") != -1) return;
        let formatSize = this.formatBytes(this.attachments[i].file.meta.size);
        this.attachments[i].formatSize = formatSize;
      }
      this.isReady = true;
    },
    formatBytes(bytes,decimals) {
      if(bytes == 0) return '0 Bytes';
      var k = 1024,
          dm = decimals <= 0 ? 0 : decimals || 2,
          sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    onAdd: function() {
      let uploadedFiles = this.$refs.files.files;
      this.handleFilesUpload(uploadedFiles);
    },
    handleFilesUpload: function(uploadedFiles) {
      for( var i = 0; i < uploadedFiles.length; i++ ){
        let fObj = {
          file: uploadedFiles[i],
          progress: 0,
          isSent: false,
        };

        this.uploadedFiles.push(fObj);

        let formData = new FormData();
        formData.append('file', uploadedFiles[i]);

        this.isError = false;
        this.isEmpty = false;

        Api.uploadFile(formData, (progressEvent) => {
          fObj.progress = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
        })
        .then((ob) => {
          fObj.isSent = true;
          this.addAttachemt(ob.fileKey, ob.fileName);
        }).catch((reson) => {
          this.isError = true;
        });
      }
    },
    addAttachemt: function(key, name) {
      Api.order.attachFile(this.orderId, key, name).then((attach) => {
        this.attachments.length = 0;
        this.attachments.push(...attach);
        this.formatAttach();
      })
    },
    remove: function(id) {
      Api.order.removeAttachment(this.orderId, id).then((attachments) => {
        this.attachments.length = 0;
        this.attachments.push(...attachments);
        if (this.attachments.length == 0) {
          this.isEmpty = true; return;
        }
        this.formatAttach();
      })
    }
  }
}
</script>
<style scoped lang="scss">
.progress {
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
}
.progress-bar {
  border-radius: 0px;
}
.upload-error {
    display: inline-block;
    width: 100%;
    background-color: #f05050;
  }
</style>