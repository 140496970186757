<template>
    <div class="box">
        <div class="box-header">
            <h3 v-if="feature._id">Edytuj cechę produktu</h3>
            <h3 v-else>Dodaj nową cechę produktu</h3>
            <small>Specyficzne cechy opisujące produkty w systemie</small>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li v-if="feature._id" class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="remove()">
                        <i class="material-icons md-18">delete</i>
                        <span class="icon-desc">CTRL+X</span>
                    </a>
                </li>
                <li v-if="feature._id" class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="save()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body" v-if="!isProcessing">
            <form role="form">
                <div class="form-group">
                    <label>Nazwa cechy</label>
                    <input class="form-control" type="text" :disabled="feature._id" v-model="feature.desc" @input="onFeatureNameChanged">
                </div>

                <div class="form-group">
                    <label>Unikalny kod (uzupelniany automatycznie)</label>
                    <input class="form-control" type="text" disabled="disabled" v-model="feature.code">
                </div>

                <div class="form-group">
                    <label>Typ</label>
                    <v-select class="" :options="['boolean', 'text', 'number']" v-model="feature.type"/>
                </div>

                <div class="form-group" v-if="feature.type == 'text'">
                    <label>Predefiniowane wartosci</label>
                    <v-select taggable multiple push-tags v-model="feature.values">
                        <template slot="no-options">
                            Zacznij pisać, żeby dodać predefiniowane wartosci
                        </template>
                    </v-select>
                </div>
                <div class="form-group">
                    <label>Ikona:</label>
                    <div>
                        <input class="form-control" type="text" v-model="feature.iconUrl"><br>
                        <input class="form-control" ref="iconFile" type="file" @change="onIconInputChange(feature)">
                    </div>
                    <div class="m-t-sm">
                        <span class="m-r">Podgląd:</span>
                        <img :src="feature.iconUrl" style="max-height: 40px;">
                    </div>
                </div>
            </form>
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-footer" v-else>
            <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" type="button" style="height: 32px;" @click="save">
                {{ feature._id ? "Zapisz" : "Dodaj "}}
            </button>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
const accents = require('remove-accents');

export default {
    name: 'FeatureEditModal',
    props: {
        feature: Object
    },
    components: {
        RotateSquare2
    },
    data: function () {
        return {
            isProcessing: false
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
        if (!this.feature) this.feature = {};
        // this.initLoad();
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        save: async function() {
            this.isProcessing = true;
            try {
                if (this.feature._id) {
                    await Api.product.editProductsFeature(this.feature);
                    this.$helpers.saveNotif();
                } else {
                    await Api.product.addProductsFeature(this.feature);
                    this.$helpers.success("Dodano nową cechę");
                }
                this.$emit('close');
            } catch (error) {
                this.$h.commonApiError(error);
            }
            this.isProcessing = false;
        },

        async remove() {
            try {
                await Api.product.deleteProductsFeature(this.feature.code);
                this.$emit('close');
            } catch (error) {
                this.$h.commonApiError(error);
            }
        },

        onFeatureNameChanged: function(e) {
            this.feature.code = this.feature.desc.replace(/\s\s+/g, ' ').replace(/\s/g, '_').toLowerCase();
            this.feature.code = accents.remove(this.feature.code);
        },

        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
                this.save();
            }
        },
        onIconInputChange: async function(definition) {
            const files = this.$refs.iconFile.files;
            const formData = new FormData();
            formData.append('file', files[0]);

            this.isSaving = true;
            const fileInfo = await Api.uploadFile(formData, (progressEvent) => {});
            definition.iconUrl = Api.getFileUrl(fileInfo.fileKey, fileInfo.fileName);
            this.isSaving = false;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
