<template>
    <div class="m-t">
        <strong>Kartony w przesyłce</strong>
        <div class="form-group row" v-for="(paczka, index) in parcels" :key="index">
            <label class="col-sm-2 form-control-label">#{{index}} </label>
            <div class="col-sm-3 input-group">
                <input type="text" class="form-control" v-model="paczka.customerData1" placeholder="Opis">
                <span class="input-group-addon">opis</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.sizeX" placeholder="Wys [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.sizeY" placeholder="Szer [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.sizeZ" placeholder="Głębo [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.weight" placeholder="kg">
                <span class="input-group-addon text-sm p-a-xs">kg</span>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn white" tabindex="-1" @click="parcels.splice(index, 1)">Usuń</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Dodaj karton</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(20,20,20,10)">+ 20x20x20, 10kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(30,30,30,20)">+ 30x30x30, 20kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(60,60,60,30)">+ 60x60x60, 30 kg</button>
            </div>
            <div class="col-sm-2"/>
        </div>

        <!-- <div class="form-group row" v-if="order.boxes && order.boxes.length">
            <label class="col-sm-2 form-control-label">Pobierz paczki z zamówienia</label>
            <div class="col-sm-2">
                <button type="button" class="btn white" @click="getOrderBoxes">Pobierz ({{ order.boxes.length }})</button>
            </div>
        </div> -->

        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Zawartość</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="content" placeholder="Zawartość">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Dodatkowe informacje</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="package.ref1" placeholder="Opcjonalnie">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input v-model="isCod" type="checkbox" class="">
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="rod" type="checkbox" class="">
                        <i class="blue"/> Dokumenty zwrotne
                    </label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="isGuaranteeTime" type="checkbox" class="">
                        <i class="blue"/> Gwarantowane doręcznie
                    </label>
                    <span v-if="isGuaranteeTime">
                        <p class="m-t" >
                            <label class="md-check">
                                <input v-model="guarantee.type" type="radio" name="gtime" value="SATURDAY">
                                <i class="primary"/> Doręcznie w sobotę</label>
                        </p>
                        <p>
                            <label class="md-check"><input v-model="guarantee.type" type="radio" name="gtime" value="TIME0930">
                                <i class="primary"/> Doręcznie do 9:30</label>
                        </p>
                        <p>
                            <label class="md-check"><input v-model="guarantee.type" type="radio" name="gtime" value="TIME1200">
                                <i class="primary"/> Doręcznie 12:00</label>
                        </p>
                    </span>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row ng-scope" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.amount" @change="parsePrice" placeholder="Kwota pobrania [zl]">
            </div>
        </div>
        <a v-if="!isStockOrderPickup" href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>
        <a v-if="isStockOrderPickup" href="#" class="btn primary none-radius m-t" @click.prevent="createPickUp" :class="{disabled: isProcessing}">Zamów odbiór</a>

    </div>
</template>
<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
export default {
    name: 'CarrierFedex',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        stockOrder: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            selected: [],
            parcels: [],
            isGuaranteeTime: false,
            isCod: false,
            amount: 0,
            content: "Filtry powietrza",
            package: { parcels: [], payerType: "SENDER", ref1: this.order.invoiceNo },
            rod: false,
            guarantee: { type: null },
            cod: { amount: 0, currency: "PLN" },
            services: {},
            qty: 1,
            isStockOrderPickup: false
        }
    },
    created: function() {
        if (this.order.clientNo && this.order.clientNo.length > 0) {
            this.content = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }

        if (Object.keys(this.order).length === 0 && Object.keys(this.stockOrder).length > 0) {
            this.isStockOrderPickup = true
            this.package.payerType = "THIRD_PARTY";
        }

        this.parcels.push({
            weight: 1,
            sizeX: 20,
            sizeY: 20,
            sizeZ: 20,
            customerData1: "Karton #1"
        });

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets()
    },
    methods: {
        addNewParcel: function(height, width, depth, weight) {
            this.parcels.push({
                sizeX: height,
                sizeY: width,
                sizeZ: depth,
                weight: weight,
                customerData1: "Karton #" + (this.parcels.length + 1)
            });
        },

        getOrderBoxes: function() {
            if (!this.order || !this.order.boxes || !this.order.boxes.length) { return this.$helpers.errorNotif("Brak spakowanych kartonów w zamówieniu"); }

            this.parcels.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                this.parcels.push({
                    sizeX: Math.round(box.height / 10),
                    sizeY: Math.round(box.width / 10),
                    sizeZ: Math.round(box.length / 10),
                    weight: box.mass,
                    customerData1: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.parcels.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.parcels.push({
                    sizeX: Math.round(box.height / 10),
                    sizeY: Math.round(box.width / 10),
                    sizeZ: Math.round(box.length / 10),
                    weight: box.mass,
                    customerData1: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        },

        parsePrice: function() {
            this.cod.amount = this.cod.amount.replace(/,/g, '.');
        },

        async createPickUp() {
            this.isProcessing = true;
            const data = this.setData();
            data.stockOrder = this.stockOrder._id;
            try {
                await Api.shipments.dpdSent(data);
                this.$h.success('Zlecono odbiór przesyłki');
                this.$router.push({ path: `/shipments` });
            } catch (e) {
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        save: async function() {
            this.isProcessing = true;
            const data = this.setData();
            data.order = this.order._id
            try {
                const shipments = await Api.shipments.dpdSent(data);
                this.$emit('shipments', shipments);

                this.$h.success('Dodano przesyłkę');
            } catch (e) {
                this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        setData: function() {
            const data = { qty: 1};

            this.package.parcels.splice(0);
            for (let i = 0; i < this.parcels.length; i++) {
                const paczka = this.parcels[i];
                paczka.content = this.content;
                this.package.parcels.push(paczka);
            }

            this.package.receiver = this.setReceiver();

            if (!this.isStockOrderPickup) this.package.sender = this.setSender();
            else this.package.sender = this.setSenderForPickup();

            this.package.services = {};
            if (this.rod) {
                this.services.rod = true;
            }
            if (this.guarantee.type) {
                this.services.guarantee = {};
                this.services.guarantee.type = this.guarantee.type;
            }
            if (this.cod.amount > 0) {
                this.services.cod = {};
                this.services.cod.amount = this.cod.amount;
                this.services.cod.currency = this.cod.currency;
            }
            this.package.services = this.services;
            data.package = this.package;

            return data;
        },
        setSender: function() {
            var pos = this.sender.postcode.replace('-', '');
            const s = {
                company: this.sender.company_name,
                name: this.sender.firstname + " " + this.sender.lastname,
                address: this.sender.street,
                city: this.sender.city,
                postalCode: pos,
                email: this.sender.email ? this.sender.email : "",
                countryCode: this.sender.countryCode ? this.sender.countryCode : "PL",
                phone: this.sender.phone ? this.sender.phone : ""
            };
            return s;
        },
        setSenderForPickup: function() {
            var pos = this.sender.postcode.replace('-', '');
            const s = {
                company: this.sender.company_name,
                name: this.sender.contactPerson ? this.sender.contactPerson : "",
                address: this.sender.street,
                city: this.sender.city,
                postalCode: pos,
                email: this.sender.email ? this.sender.email : "",
                countryCode: this.sender.countryCode ? this.sender.countryCode : "PL",
                phone: this.sender.phone ? this.sender.phone : ""
            };
            return s;
        },
        setReceiver: function() {
            var pos = this.recipient.postCode.replace('-', '');
            const r = {
                company: this.recipient.name,
                name: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
                address: this.recipient.street,
                city: this.recipient.city,
                postalCode: pos,
                email: this.recipient.email ? this.recipient.email : "",
                countryCode: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                phone: this.recipient.phone ? this.recipient.phone : ""
            };
            return r;
        }
    }
}
</script>

<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
