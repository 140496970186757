<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Kontrahent</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="saveAndExit()">
                        <i class="material-icons md-18">done</i>
                        <span class="icon-desc">CTRL+S</span>
                    </a>
                </li>
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Nazwa</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.name">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Email</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.email">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Ulica</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.street">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Miasto</label>
                  <div class="col-sm-3">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.postCode" placeholder="XX-XXX">
                  </div>
                  <div class="col-sm-6">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.city">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Telefon</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.phone">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Typ identyfikacji</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.taxNoType" placeholder="np. vat, nip">
                  </div>
                </div>
                <div class="form-group row m-b-xs">
                  <label class="col-sm-3 form-control-label">Numer identyfikacji</label>
                  <div class="col-sm-9">
                    <input class="form-control custom-input" type="text" v-model="editedCustomer.taxNo">
                  </div>
                </div>
            </form>           
        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"></rotate-square2>
        </div>
        <div class="box-footer" v-else>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import {RotateSquare2} from 'vue-loading-spinner';

export default {
    name: 'EditCustomerModal',
    props: {
        customer: Object,
    },
    components: {
        RotateSquare2,
    },
    data: function () {
        return {
            editedCustomer: {},
            Api,
            isProcessing: false,
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);

        if (this.customer) {
            this.editedCustomer = JSON.parse(JSON.stringify(this.customer)); //deep-copy
        }

    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { //CTRL+S
                e.preventDefault();
                this.saveAndExit();
            }
        },
        saveAndExit: async function () {
            if (!this.editedCustomer.name || this.editedCustomer.name.length == 0) {
                this.$notify({group: 'global', type: 'notification-error', text: 'Brak podanej nazwy.'});
                return;
            }
            this.isProcessing = true;
            try {
                if (!this.editedCustomer._id) {
                    Api.customer.add(this.editedCustomer);
                } else {
                    Api.customer.edit(this.editedCustomer._id, this.editedCustomer);
                }
                this.$notify({group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.'});
                this.$emit('close');
            } catch (error) {
                this.$notify({group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas wprowadzania zmian'});
            }
            this.isProcessing = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
