import { render, staticRenderFns } from "./CarrierGeis.vue?vue&type=template&id=75dd9611&scoped=true&"
import script from "./CarrierGeis.vue?vue&type=script&lang=js&"
export * from "./CarrierGeis.vue?vue&type=script&lang=js&"
import style0 from "./CarrierGeis.vue?vue&type=style&index=0&id=75dd9611&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75dd9611",
  null
  
)

export default component.exports