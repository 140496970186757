<template>
    <div class="box">
        <div class="box-header d-gray">
            <h2 class="text-muted _400 inline" v-if="wPlace.name">{{wPlace.name}}</h2>
            <h2 class="text-muted _400 inline" v-else>Brak nazwy stanowiska</h2>
            <div class="pull-right">
                <div class="btn-group primary-color order-btn-group"/>
            </div>
        </div>
        <div class="box-divider m-a-0"/>
        <div class="box-body" v-if="isLoadingSchedule">
            <p>Trwa ładowanie harmonogramu...</p>
        </div>
        <div class="box-body" v-else>
            <div class="l-gray p-a">
                <span class="m-r">Lista zaplanowanych zadań na: </span>
                <button class="btn btn-sm white none-radius primary-color m-r-sm"
                        v-for="(perDaySchedule, i) in schedule"
                        :key="i"
                        @click="changeSchedule(perDaySchedule)"
                        :class="{'text-green': perDaySchedule == selectedSchedule}">{{ perDaySchedule.day | moment('DD/MM') }}</button>
            </div>
            <div class="l-gray" v-if="isLoadingDaySchedule">
                <p>Trwa ładowanie harmonogramu na dzień...</p>
            </div>
            <div class="l-gray" v-else>
                <div class="box m-b-xs" v-for="pr in productionRequests" :key="pr._id">
                    <div class="row p-x p-y-xs">
                        <div class="col-md-3">
                            <span v-if="pr.intent && pr.intent.type == 'order'" class=" _600 primary-text" ><router-link :to="{ name: 'order-details', params: { id: pr.intent.id }}">#{{pr.intent.name}}</router-link></span>
                            <span v-if="pr.intent && pr.intent.type == 'stock'" class=" _500" >Magazyn: {{pr.intent.name}}</span>
                            <small class="text-muted m-l">(ZP: #{{pr._id.substr(pr._id.length - 5)}})</small>
                            <br>
                            <span class="text-muted text-sm"> Utworzono: {{pr.createdAt | moment('DD/MM HH:mm:ss')}}</span>
                        </div>
                        <div class="col-md-2">
                            <span v-if="pr.plannedDeadline" class="label text-white m-r-xs none-radius inline prod-label c-yellow">
                                {{ pr.plannedDeadline | moment('DD/MM/YYYY') }}
                            </span>
                            <span v-else class="label text-white m-r-xs none-radius inline prod-label c-red">
                                Brak deadline
                            </span>
                        </div>
                        <div class="col-md-3">
                            <span class="label text-white m-r-xs none-radius inline prod-label c-blue">
                                Do zrobienia {{ selectedSchedule.schedule[pr._id].made }} / {{ selectedSchedule.schedule[pr._id].todo }}szt
                            </span>
                        </div>
                        <div class="col-md-4">...</div>
                    </div>
                    <div class="box-divider m-a-0"/>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Api from '../../Api.js';
import SystemSocket from '../../SystemSocket';

export default {
    name: 'TasksList',
    props: {
        wPlace: Object
    },
    components: {
    },
    data: function () {
        return {
            schedule: [],
            selectedSchedule: null,
            isLoadingSchedule: false,
            isLoadingDaySchedule: false,
            productionRequests: []
        }
    },

    created: function() {
        this.loadSchedule();
        SystemSocket.socket.on('task-schedule.recalculated', this.loadSchedule);
    },

    destroyed: function() {
        SystemSocket.socket.off('task-schedule.recalculated', this.loadSchedule);
    },

    methods: {
        async loadSchedule() {
            // this.isLoadingSchedule = true;
            try {
                this.schedule = await Api.production.schedule.getForWorkplace(this.wPlace._id);
                if (this.schedule.length) this.changeSchedule(this.schedule[0]);
            } catch (error) {

            }
            // this.isLoadingSchedule = false;
        },

        async changeSchedule(sch) {
            // this.isLoadingDaySchedule = true;
            try {
                this.$emit('schedule-changed', sch);
                const ids = Object.keys(sch.schedule);
                const prs = await Api.production.request.search({ _id: ids });
                prs.sort((a, b) => new Date(a.plannedDeadline || "2100-01-01") - new Date(b.plannedDeadline || "2100-01-01"));
                this.productionRequests.splice(0);
                this.productionRequests.push(...prs);
            } catch (error) {
                console.error(error);
            }
            this.selectedSchedule = sch;
            // this.isLoadingDaySchedule = false;
        }
    }
}
</script>
<style scoped lang="scss">
</style>
