<template>
    <div class="box" style="width:800px; min-height:460px; max-height:800px; overflow-y: auto">

        <div class="l-gray p-x p-y">
            <button class="btn btn-xs white none-radius" @click="removeZP" :class="{'disabled': isProcessing}">
                <span class="primary-color _400">Anuluj ZP</span>
            </button>&nbsp;
            <button class="btn btn-xs white none-radius" @click="complete" :class="{'disabled': isProcessing}">
                <span class="primary-color _400">Zakończ ZP</span>
            </button>&nbsp;
            <div class="box-tool">
                <ul class="nav">
                    <li class="nav-item inline icon-with-desc">
                        <a class="nav-link text-muted" @click="$emit('close')">
                            <i class="material-icons md-18">clear</i>
                            <span class="icon-desc">ESC</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="box-divider m-a-0"/>
        <div class="box-header">
            <h3 class="inline">Szczegóły zleceń produkcyjnych dla {{order.name}}</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
            <!-- <datepicker v-model="productionReq.plannedDeadline" :monday-first="true" :bootstrap-styling="true" :typeable="true" format="yyyy-MM-dd" placeholder="Select Date"  input-class="form-control custom-input" @input="onChange" />
                    <small class="text-muted text-xs">Data do kiedy chcemy ZP zakończone. Nie ma wpływa w żaden sposób na mechanikę Mangera Zadań</small> -->
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body" v-if="!isProcessing">
            <hr/>
           <p class="primary-text">Wszystkie zlecenia produkcyjne:</p>

           <!-- <div class="box" v-for="pr in prs" :key="pr._id"> -->
                <!-- <div class="progress-sm progress progress-striped" :class="{'active': !pr.isCompleted}">
                    <div class="progress-bar orange" :style="{'width': parseInt((pr.progress || 0)*100)+'%'}">Postęp pozycji{{ parseInt((pr.progress || 0)*100) }}%</div>
                </div>
                <div class="progress-sm progress progress-striped active">
                    <div class="progress-bar info" :style="{'width': parseInt((pr.progress || 0)*100)+'%'}">Postęp całego ZP {{ parseInt((pr.progress || 0)*100) }}%</div>
                </div>
                <div class="box-header">
                    <h3><a class="text-blue">ZP #{{ pr._id.substr(pr._id.length - 5).toUpperCase() }}</a></h3>
                    <small v-if="pr.status != 'errored'">(Postęp {{ parseInt((pr.progress || 0)*100) }}%)</small>
                </div> -->
                <div class="box-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Typ zadania</th>
                                <th>Status</th>
                                <th>Ilość</th>
                                <th>Planowany deadline</th>
                                <th width="15%">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="pr in prs" :key="pr._id">
                                <td>
                                    <span class="m-r" v-if="types.find(t => t.code == pr._productType).iconUrl">
                                        <img class="feature-icon dense" :src="types.find(t => t.code == pr._productType).iconUrl" v-tooltip="types.find(t => t.code == pr._productType).name">
                                    </span>
                                    <span v-if="pr.name">{{pr.name | truncate(30)}}</span>
                                    <span v-else>Brak nazwy</span>
                                </td>
                                <td>{{pr.status}}</td>
                                <td>{{ pr.madeQty || 0}} /{{ pr.qty || 0 }} szt.</td>
                                <td>
                                    <datepicker v-model="pr.plannedDeadline" :monday-first="true" :bootstrap-styling="true" :typeable="true" format="yyyy-MM-dd" placeholder="Select Date"  input-class="form-control custom-input"/>
                                </td>
                                <td style="padding-right:0px;" width="15%">
                                   
                                    <span style="width:33%; display: inline-block;">
                                        <a class="m-l-sm" title="Zakończ zadanie" @click.prevent="confirmActionManualyEnd(pr)" v-if="pr.status == 'waiting' ||  pr.status =='in-progress'">
                                            <i class="material-icons md-18">check</i>
                                        </a>
                                    </span>
                                    <span style="width:33%; display: inline-block;">
                                        <a class="m-l-sm" title="Zobacz szczegóły">
                                            <i class="material-icons md-18">search</i>
                                        </a>
                                    </span>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
            <!-- </div> -->

        </div>
        <div class="box-footer" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>

    </div>
</template>

<script>
import Api from '../../../Api.js';
import EmployeeManager from '../../../EmployeeManager.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import Datepicker from 'vuejs-datepicker';
import CartPosDetailsModal from './CartPosDetailsModal.vue'
export default {
    name: 'CreateTasksModal',
    props: {
        order: Object
    },
    components: {
        RotateSquare2,
        Datepicker
    },
    data: function () {
        return {
            isProcessing: false,
            isReady: false,
            selectedProdReqTasks: [],
            employees: [],
            recipes: [],
            availableTags: [],
            productionReq: {},
            prs: [],
            types:[]
        }
    },
    created: async function() {
        try {
            const res = await Api.production.request.v3.search({ orderId: this.order._id });
            this.prs = res.data;
               this.types = await Api.product.types.getAll();
        } catch (error) { }
    },
    mounted: async function() {
        this.isProcessing = true;
        try {
            this.employees = await EmployeeManager.getEmployees();
            this.recipes = await Api.production.searchRecipesV2({});
        } catch (error) {}
        this.isProcessing = false;
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
       
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.ctrlKey && e.keyCode == 83) { // CTRL+S
                e.preventDefault();
            }
        },
        async removeZP() {
            this.isProcessing = true;
            console.log("removing")
            try {
                await Api.production.request.delete(this.productionReq._id);
                this.$emit('close');
            } catch (error) {
                console.log('errr', error)
            }
            this.isProcessing = false;
        },
        async complete() {
            this.isProcessing = true;
            try {
                await Api.production.request.complete(this.productionReq._id);
                this.$emit('close');
            } catch (error) {
                console.log('errr', error)
            }
            this.isProcessing = false;
        },
        async onChange() {
            try {
                const p = await Api.production.request.update(this.productionReq._id, this.productionReq);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zapisano.' });
            } catch (error) {
                console.log("err", error)
            }
        },
        openProductionDeatils: function(position) {
            const cartPos = this.order.cart.find(c =>c._id == position.cartPos);

            if(!cartPos) return;
            this.$modal.show(CartPosDetailsModal, {
                cartPos: cartPos,
                order: this.order
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                width: '750px'
            });
        },
        async removePosFromZP(part) {
            try {
                const PR = await Api.production.request.removePart(this.productionReq._id, part._id);
                const idx = this.productionRequest.parts.map(p => p._id).indexOf(part._id);
                if (idx != -1) this.productionRequest.parts.splice(idx, 1);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto pozycję w zleceniu.' });
            } catch (error) {
                console.log("er", error);
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił błąd.' });
            }
        },
        confirmActionManualyEnd(pr) {}
    }
}
</script>

<style scoped lang="scss">
.icon {
  width: 15px;
  height: auto;
}
.panel {
  // cursor: pointer;
  border-top: 1px solid #eceeef;
  margin-bottom: 0px;
}
.panel:hover {
  background-color: #E2E8EE;
}
.md-check {
    span {
        line-height: 19px;
        padding: 5px;
    }
}
.common-options {
    max-height: 600px;
    overflow: hidden;
    transition: max-height 0.7s cubic-bezier(0.075, 0.82, 0.165, 1);

    &.collapsed {
        max-height: 0px;
    }
}
.status-icon {
    position: absolute;
    right: 5px;
    top: 5px;

    .material-icons {
        font-size: 27px;
    }
}
.cnote-attachment {
    border: 1px dashed #ccc;
    padding: 2px 4px;
}
.grey-box {
    position: relative;

    .remove-note-btn {
        position: absolute;
        right: 6px;
        top: 6px;
        color: #cc5434;
        z-index: 10;
    }
}
</style>
