<template>
    <div class="box">
        <div class="custom-modal modal fade" :class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-body text-center p-lg">
                        <form class="form-horizontal m-b m-t">
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 form-control-label text-right">Nazwa</label>
                                <div class="col-sm-6 col-md-8">
                                    <input class="form-control" type="text" v-model="newRecept.name"  style="max-height: 28px; min-height: 28px;">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-6 col-md-4 form-control-label text-right">Opis</label>
                                <div class="col-sm-6 col-md-8">
                                    <v-select class="" :options="productTypes" label="name" :reduce="feat => feat.code" v-model="newRecept.produces"/>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <a href="#" class="pull-left text-blue text-sm" @click.prevent="cancel">Cofnij</a>
                        <a class="text-blue pull-right text-sm" @click.prevent="add">Dodaj</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="box-header d-gray">
            <h6 class="text-muted _400 inline">Lista recept</h6>

            <div class="pull-right minus-margin">
                <form class="navbar-form form-inline pull-right pull-none-sm v-m" role="search">
                    <div class="form-group l-h m-a-0">
                        <div class="input-group search-input">
                            <input class="form-control form-control-sm p-x b-a" placeholder="Szukaj..." type="text" v-model="query" @input="onFilterChange">
                            <a class="close-btn" href="#" @click.prevent="clear"><i class="material-icons md-16">close</i></a>
                        </div>
                    </div>
                </form>
                <a class="btn btn-sm white none-radius" @click="isActive = true">
                    <span class="primary-color _400">Dodaj</span>
                </a>&nbsp;
            </div>
        </div>

        <div class="row" v-if="!isLoading">
            <div class="col-md-12">
                <table class="table" v-if="recipes.length > 0">
                    <thead class="primary-color">
                        <tr>
                            <th style="width: 5%;"/>
                            <th>Nazwa</th>
                            <th>Proces</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="custom-gray" v-for="r in recipes" :key="r._id">
                            <td class="checkbox p-r-sm">
                                <label class="md-check m-t-xs">
                                    <input type="checkbox" :value="r._id" v-model="selected">
                                    <i class="blue m-r-xs"/>
                                </label>
                            </td>
                            <td style="padding-left: 0px;" @click="goToDetails(r)">
                                <img v-if="r.iconUrl" class="product-icon" :src="r.iconUrl">
                                <span>{{r.name}}</span><br>
                                <span class="text-xs text-muted">{{r.produces}}</span>
                            </td>
                            <td>
                                <span v-for="(productType, k) in r.needs" :key="productType._id">
                                    <strong v-if="k > 0"> + </strong>
                                    <img v-if="productType" class="feature-icon" :src="productType.iconUrl" v-tooltip="productType.name">
                                </span>
                                <img v-tooltip.top="'Produkuje'" title="Produkuje" class="feature-icon m-l-sm m-r-sm" src="../../assets/images/next.png">
                                <img v-if="r.productType && r.productType.iconUrl" class="feature-icon" :src="r.productType.iconUrl" v-tooltip="r.productType.name">
                            </td>
                            <td>
                                <i v-if="!r.configErrors || !r.configErrors.length" class="material-icons md-18 text-green">check</i>
                                <span v-else><i class="material-icons md-18 text-red">clear</i> {{ r.configErrors.length }} błędów</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="text-center text-muted p-y" v-else>Brak dodanych recept produktów</p>
                <Spinner v-if="isLoading"/>
            </div>
        </div>

    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';

var timeoutHandler = null;

export default {
    name: 'Recipes',
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoading: false,
            isActive: false,
            recipes: [],
            productTypes: [],
            selected: [],
            query: '',
            newRecept: {}
        }
    },
    async created() {
        const data = { query: this.query }
        this.productTypes = await Api.product.types.getAll();
        await this.getRecipes(data);
    },
    methods: {
        getRecipes: async function(data) {
            this.isLoading = true;
            try {
                const res = await Api.production.recipes.v3.search(data)
                for (const recipe of res.data) {
                    recipe.productType = this.productTypes.find(pType => pType.code == recipe.produces);

                    if (!recipe.graph) recipe.graph = {};
                    recipe.needs = [];
                    for (const ingNode of (recipe.graph.nodes || []).filter(n => n.ntype == "ingredient")) {
                        if (!ingNode.properties.ptype) continue;
                        const productType = this.productTypes.find(pType => pType.code == ingNode.properties.ptype);
                        if (!productType) continue;

                        recipe.needs.push(productType);
                    }
                }
                this.recipes = res.data;
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.isLoading = false;
        },
        add: async function() {
            if (!this.newRecept.name || !this.newRecept.produces) {
                return this.$h.errorNotif("Brak nazwy lub typu produktu");
            }

            const r = await Api.production.recipes.v3.create(this.newRecept);
            this.$router.push({ path: `/recipe-v3/${r._id}` });
        },

        cancel: function() {
            this.isActive = false;
            this.newRecept = {};
        },
        goToDetails: function(product) {
            this.$router.push({ path: `/recipe-v3/${product._id}` });
        },
        onFilterChange: function() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(() => {
                const data = { query: this.query };
                this.getRecipes(data);
            }, 1000);
        },
        clear: function() {
            if (this.query.length == 0) return;
            this.query = '';
            this.onFilterChange();
        },
        showError: function(reson) {
            if (reson.response.status == 403) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie masz uprawnień do wykonania tej akcji.' });
            } else {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład - zgłoś problem swojemu adminowi.' });
            }
        }

    }
}
</script>
