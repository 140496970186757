<template>
    <div>
        <div class="box">
            <div class="box-header">
                <h2 class="title-color m-b-sm inline">Dane produktu</h2>
                <a class="pull-right" title="Dodaj powiązanie" @click.prevent="edit">
                    <i class="material-icons md-18 text-blue">edit</i>
                </a>
            </div>

            <div class="m-a-0 custom-divider"/>

            <ul class="list-group no-border m-t-sm" v-if="!isLoading">
                <li class="list-group-item no-border" v-if="product.validationError">
                    <i class="material-icons md-24 text-red pulse">error</i>
                    <span class="text-red m-l-xs">{{product.validationError}}</span>
                </li>
                <li class="list-group-item no-border">
                    <div class="row m-b-xs">
                        <span class="col-md-2">
                            <span v-if="product.productType && product.productType !='unknown'">
                                <img v-if="types.find(t => t.code == product.productType).iconUrl" class="product-icon" :src="types.find(t => t.code == product.productType).iconUrl"
                                     v-tooltip="'Typ produktu: '+types.find(t => t.code == product.productType).name">
                            </span>
                            <span v-else>
                                <i class="material-icons md-24 text-red" v-tooltip="'Brak typu produktu! Kliknij aby dodać typ.'" @click.prevent="edit"></i>
                            </span>
                        </span>
                        <h6 class="col-md-10 _400 m-b">
                            {{product.code}}
                            <i v-if="product.isPurchased" class="material-icons md-14 text-blue m-l-xs" v-tooltip="'Produkt kupowany'">loyalty</i>
                            <br>
                            <span class="text-xs text-muted"> {{product.name}}</span>
                        </h6>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-6">Typ prod.</span>
                        <span class="col-md-6" v-if="product.productType && product.productType != 'unknown'">
                            <router-link class="text-blue" :to="{ name: 'product-type-details', params: { code: product.productType } }">
                                {{ types.find(t => t.code == product.productType).name }}
                            </router-link>
                        </span>
                        <span class="col-md-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-6">EAN13:</span>
                        <span class="col-md-6" v-if="product.ean13">{{ product.ean13 }}</span>
                        <span class="col-md-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-6">Kategoria:</span>

                        <span class="col-md-6" v-if="product.category">{{product.category}}</span>
                        <span class="col-md-6" v-else>- - -</span>
                    </div>
                    <div class="row m-b-xs">
                        <span class=" primary-color col-md-6">Dostawca:</span>

                        <span class="col-md-6" v-if="product.supplier">{{ product.supplier.name }}</span>
                        <span class="col-md-6" v-else>- - -</span>
                    </div>
                    <!-- <div class="row m-b-xs">
                        <span class=" primary-color col-md-6">Wartość wyprodukowania:</span>

                        <span class="col-md-6" v-if="product.productionValue && product.productionValue.price && product.productionValue.currency">{{product.productionValue.price}} {{product.productionValue.currency}}</span>
                        <span class="col-md-6" v-else>- - -</span>
                    </div> -->
                    <span v-if="product.features">
                        <hr>
                    </span>
                    <div class="row m-b-xs" v-for="(value, id) in product.features" :key="id">
                        <span v-if="features.find(f =>f._id == id)">
                            <span class=" primary-color col-md-7" v-if="features.find(f =>f._id == id).desc">
                                {{features.find(f =>f._id == id).desc}}
                            </span>
                        </span>
                        <span class="col-md-5" v-if="features.find(f =>f._id == id) && value">
                            <span v-if="features.find(f =>f._id == id).type == 'boolean'">
                                {{value ? 'Tak' : 'Nie'}}
                            </span>
                            <span v-else>{{value}}</span>
                        </span>
                    </div>
                    <hr>
                    <div class="comment-box">
                        <textarea class="form-control form-control-sm comment-box-input" placeholder="Dowolne dodatkowe informacje o tym produkcie" v-model="pNote" @input="onNotesChange" />
                    </div>
                </li>
            </ul>
            <Spinner v-if="isLoading"/>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import EditProductModal from './modals/EditProductModal.vue';
import EditProductModalV2 from '../Products/EditProductModalV2.vue';
import EventBus from '../../event-bus.js';
import Spinner from '../Spinner.vue';

let timeoutHandler = null;

export default {
    name: 'ProductDetails',
    props: {
        product: Object
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoading: false,
            features: [],
            types: [],
            categories: [],
            pNote: ""
        }
    },
    created: async function() {
        this.isLoading = true;
        try {
            this.categories = await Api.product.getAvailableCategories();
            this.features = await Api.product.getProductsFeatures();
            let res = await Api.product.types.search({ sort: { name: 1 }, limit: 1000 });
            this.types = res.data;
            this.pNote = this.product.notes || "";
        } catch (error) {
            this.$h.commonApiError(error);
        }
        this.isLoading = false;

        EventBus.$on('productChange', this.productChange);
    },
    destroyed: function() {
        EventBus.$off('productChange', this.productChange);
    },
    methods: {
        productChange(p) {
            Object.assign(this.product, p);
        },
        edit() {
            this.$modal.show(EditProductModalV2, {
                prod: this.product
            }, { height: 'auto', width: "80%" }, {
                closed: () => { }
            });
        },

        onNotesChange: function() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(async () => {
                try {
                    await Api.product.save(this.product._id, { notes: this.pNote });
                    this.$h.saveNotif();
                } catch (error) {
                    this.$h.commonApiError(error);
                }
            }, 1000);
        }
    }
}
</script>
<style lang="scss" scoped>
  .custom-i {
    padding: .275rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    min-height: 2rem;
    display: block;
  }

</style>
