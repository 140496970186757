<template>
    <span :class="statusClass">
        {{ translatedStatus }}
    </span>
</template>

<script> 

export default {
    name: 'TaskStatusSpan',

    props: {
        task: Object
    },

    computed: {
        statusClass: function () {
            let c = {};
            if (!this.task || !this.task.status) return c;
            let status = this.task.status;
            if (status == 'unactive') c['text-yellow'] = true;
            else if (status == 'waiting') c['text-coral'] = true;
            else if (status == 'in-progress') c['text-blue'] = true;
            else if (status == 'completed') c['text-green'] = true;
            else if (status == 'suspended') c['text-d-blue'] = true;
            else if (status == 'canceled') c['text-red'] = true;
            else if (status == 'to-confirm') c['text-red'] = true;
            return c;
        },

        translatedStatus: function () {
            if (!this.task || !this.task.status) return 'Brak statusu';
            else if (this.task.status == 'unactive') return 'Nieaktywny';
            else if (this.task.status == 'waiting') return 'Oczekujący';
            else if (this.task.status == 'in-progress') return 'W produkcji';
            else if (this.task.status == 'completed') return 'Zakończony';
            else if (this.task.status == 'suspended') return 'Zawieszony';
            else if (this.task.status == 'canceled') return 'Anulowany';
            else if (this.task.status == 'to-confirm') return 'Do potwierdzenia';
            else return 'Brak statusu';
        }
    },
}
</script>
<style scoped lang="scss">
.icon {
  width: 25px;
  height: auto;
}
</style>