<template>
    <div v-if="visible">
        <div class="v-overlay v-overlay--active theme--dark">
            <div class="v-overlay__scrim" style="opacity: 0.46; background-color: rgb(33, 33, 33); border-color: rgb(33, 33, 33);"/>
            <div class="v-overlay__content"/>
        </div>
        <div  class="v-dialog__content">
            <div class="v-dialog" :style="apperanceOptions">
                <component v-if="dialogComponent" :is="dialogComponent" v-bind="dialogProps" @close="onComponentClose" />
            </div>
        </div>
    </div>
</template>

<script>
import Modal from './ModalPlugin';

export default {
    props: {
    },
    data() {
        return {
            visible: false,
            resolve: null,
            reject: null,

            onConfirm: {},
            apperanceOptions: { width: '600px' },
            dialogComponent: null,
            dialogProps: {}
        }
    },

    methods: {
        show(component, params, dialogOptions) {
            this.dialogComponent = null;
            this.apperanceOptions = dialogOptions || { width: '600px' };
            this.$nextTick(() => {
                this.visible = true;
                this.dialogComponent = component;
                if (params) this.dialogProps = params;
            });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },

        cancel() {
            this.resolve(false);
            this.visible = false;
        },

        onComponentClose(result) {
            this.resolve(result);
            this.visible = false;
        }
    },

    mounted() {
        // Modal.instance = this;
    },

    beforeMount() {
        Modal.instance = this;
        // Modal.EventBus.$on('show', (component, params) => {})
    }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
    z-index: 1000;
    align-items: center;
display: flex;
height: 100%;
justify-content: center;
left: 0;
pointer-events: none;
position: fixed;
top: 0;
transition: .2s cubic-bezier(.25,.8,.25,1),z-index 1ms;
width: 100%;
}

.v-dialog {
    border-radius: 4px;
    margin: 24px;
    overflow-y: auto;
    pointer-events: auto;
    transition: .3s cubic-bezier(.25,.8,.25,1);
    width: 100%;
    z-index: inherit;
    outline: none;
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12);
    background-color: #fff;
}

.v-overlay {
    z-index: 999;
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: .3s cubic-bezier(.25,.8,.5,1),z-index 1ms;
}
.v-overlay__scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
}
</style>
