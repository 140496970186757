<template>
    <div class="box">
        <div class="box-header">
            <h2 class="title-color m-b-sm inline">Pracownicy przypisani do stanowiska</h2>
        </div>
        <div class="m-a-0 custom-divider"/>
        <table class="table" v-if="isReady && sortedEmployees">
            <thead class="primary-color">
                <tr>
                    <th>Pracownik</th>
                    <th>Skuteczność</th>
                    <th style="width: 5%;"/>
                </tr>
            </thead>
            <tbody>
                <tr class="custom-gray" v-for="(emp, index) in sortedEmployees" :key="index">
                    <td> {{EmployeeManager.getEmpName(index)}}</td>
                    <td>
                        <span v-if="emp.efficiency">{{emp.efficiency*100}}%</span>
                        <span class="text-red" v-else>0%</span>
                    </td>
                    <td>
                        <a class="text-blue text-sm" href="#" @click.prevent="remove(index)">usuń</a>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="text-center text-muted p-y" v-if="isReady && !sortedEmployees">Brak dodanych pracowników</p>
        <div class="box-divider m-a-0"/>
        <div class="row p-x-md p-b p-t">
            <div class="form-group row m-b-sm">
                <label class="col-sm-4 form-control-label">Pracownik</label>
                <div class="col-sm-8 ">
                    <v-select v-model="emp" :options="empNames" :reduce="p => p.v" />
                </div>
            </div>
            <div class="form-group row m-b-sm">
                <label class="col-sm-4 form-control-label">Skuteczność</label>
                <div class="col-sm-8">
                    <input class="form-control form-control-sm" type="text" v-model="eff">
                </div>
            </div>
            <a class="text-blue text-sm pull-right m-t-sm" @click.prevent="add" v-if="!isProgess">dodaj</a>
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import EmployeeManager from '../../EmployeeManager.js';
export default {
    name: 'WorkPlaceEmployees',
    props: {
        wPlace: Object
    },
    components: {
    },
    data: function () {
        return {
            workPlace: Object.assign({}, this.wPlace),
            EmployeeManager: EmployeeManager,
            empNames: [],
            isReady: false,
            emp: null,
            eff: 100,
            isProgess: false,
        }
    },
    created: async function() {

        try {
            const employees = await EmployeeManager.getEmployees();
            employees.forEach(d => {
                this.empNames.push({ v: d._id, label: d.name });
            });
            this.isReady = true;
        } catch (error) {
            this.isReady = true;
        }
    },
    computed: {
        sortedEmployees: function () {
            let emps = this.workPlace.employees;
            return emps;
        }
    },
    methods: {
        add: async function() {
            try {
                this.isProgess = true;
                const objWorkplace = await Api.production.setEmpToWorkPlace(this.workPlace._id, this.emp, (this.eff / 100));
                this.workPlace = objWorkplace;
                this.emp = null;
                this.eff = 100;
                 this.$notify({ group: 'global', type: 'notification-success', text: 'Akcja przebiegła pomyślnie.' });
                 this.isProgess = false;
            } catch (error) {
                console.log(error);
                this.isProgess = false;
            }
        },
        remove: async function(id) {
          try {
          const objWorkplace = await Api.production.deleteEmpFromWorkPlace(this.workPlace._id, id);
          this.workPlace = objWorkplace;
          this.$notify({ group: 'global', type: 'notification-success', text: 'Akcja przebiegła pomyślnie.' });
          } catch (error) {
              console.log(error)
          }
        }

    }
}
</script>
<style scoped lang="scss">
  .ramka {
    border: 1px solid rgba(0,0,0,0.6);
    padding: 2px 5px;
    display: inline-block;
    opacity: 0.6
  }
  .border-yellow {
      border: 1px solid #f0ad4e;
      background-color: #fff;
  }
  .border-green {
      border: 1px solid #90c844;
      background-color: #fff;
  }
</style>
