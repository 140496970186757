<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Notatki</h6>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <form>
                <div class="comment-box">
                    <textarea id="comment-mesage-area" class="form-control form-control-sm comment-box-input" placeholder="Dodaj notatkę..." @keyup="autoGrow" v-model="content" style="overflow: hidden; overflow-wrap: break-word;"
                    />
                </div>
                <label class="md-check m-t">
                    <input type="checkbox" value="a" v-model="addPrintTag">
                    <i class="primary m-r-xs"/>
                    <span class="m-l-xs" :class="{'text-muted': !addPrintTag}">Umieść notatkę na wydruku</span>
                </label><br>
                <label class="md-check m-t">
                    <input type="checkbox" value="a" v-model="isProductionNote">
                    <i class="primary m-r-xs"/>
                    <span class="m-l-xs" :class="{'text-muted': !isProductionNote}">Przekaż notatkę na produkcję</span>
                </label><br>
                <button class="btn white b-a no-shadow text-muted none-radius btn-sm m-t" type="button" style="height: 32px;" @click="add" :disabled="procesing">Dodaj</button>
            </form>
            <div class="box-divider m-a-0 m-t"/>
            <ul class="list inset text-color" v-if="notes.length > 0 && isReady">
                <li class="list-item p-x-xs" v-for="n in evenNotes">
                    <!-- Data i nazwisko tego kto wystawił komentarz -->
                    <small class="block text-muted m-b-sm">
                        <span v-if="n.author == 'user'">{{userNames[n.userId]}}</span>
                        <span v-if="n.author == 'system'">Komentarz systemowy</span>
                        <a v-if="n.userId == userId" class="nav-link primary-color m-l-xs" title="Usuń notatkę" @click="remove(n._id)" :disabled="procesing">
                            <i class="material-icons md-16 text-red">close</i>
                        </a>
                        <span class="pull-right">
                            <span v-if="n.created">{{n.created | moment("DD MMM YYYY | HH:mm")}}</span>
                        </span>
                    </small>
                    <div class="content-comment p-y-sm p-x-sm primary-color">
                        <nl2br tag="span" :text="n.content" />
                        <p class="text-xs text-blue m-b-0 m-t-xs" v-if="n.isProductionNote"><i class="material-icons md-18">check</i> Notatka przekazana na produkcję</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import UserManager from '../../UserManager.js';
import SystemUsersManager from '../../SystemUsersManager.js';
import Api from '../../Api.js';
import Nl2br from 'vue-nl2br';

export default {
    name: 'Notes',
    props: {
        notes: Array,
        orderId: String
    },
    components: {
        Nl2br
    },
    data: function () {
        return {
            content: '',
            procesing: false,
            userId: null,
            isReady: false,
            systemUsers: [],
            userNames: {},
            addPrintTag: false,
            isProductionNote: false
        }
    },
    computed: {
        evenNotes: function () {
            return this.notes.sort(function(a, b) {
                var dateA = new Date(a.created), dateB = new Date(b.created);
                return dateB - dateA;
            });
        }
    },
    created: function() {
        this.userId = UserManager.user.userId;

        SystemUsersManager.getSystemUsers().then((systemUsers) => {
            this.systemUsers = systemUsers;
            this.assignNamesToIds();
        });
    },
    methods: {
        assignNamesToIds: function() {
            this.systemUsers.map((u) => {
                this.userNames[u._id] = u.name;
            });
            this.isReady = true;
        },
        add: function() {
            this.procesing = true;
            let txt = this.content;
            if (this.addPrintTag) txt = "[[Druk-etykieta]]\n" + txt;
            Api.order.addNote(this.orderId, {content: txt, isProductionNote: this.isProductionNote}).then((notes) => {
                this.notes.length = 0;
                this.notes.push(...notes);
                this.content = '';
                this.procesing = false;
            })
        },
        remove: function(noteId) {
            this.procesing = true;
            Api.order.removeNote(this.orderId, noteId).then((notes) => {
                this.notes.length = 0;
                this.notes.push(...notes);
                this.procesing = false;
            });
        },
        autoGrow: function() {
            const element = document.getElementById('comment-mesage-area');
            element.style.height = "5px";
            element.style.height = (element.scrollHeight) + "px";
        }
    }
}
</script>
<style scoped lang="scss">
.comment-box {
  position: relative;
  .comment-box-input {
    background: #FFFFFF;
    box-shadow: none;
    height: 73px;
    min-height: 73px;
    width: 100%;
    resize: none;
  }
}
.content-comment {
  border: 1px solid rgba(158, 158, 158, 0.1);
  position: relative;
  .essence {
    b {
      color: #0cc2aa;
    }
  }
}
.md-16 {
  font-size: 16px;
}
</style>
