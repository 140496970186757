<template>
    <div class="row">
        <div class="box">
            <div class="box-header d-gray">
                <h6 class="text-muted _400 inline">Produkcja</h6>
            </div>
            <div class="box-divider m-a-0"/>
            <div class="l-gray p-b">
                <ul class="btn-group nav nav-pills p-t p-l">
                    <!-- <li class="nav-item">
          <router-link class="btn btn-sm white none-radius primary-color" to="/production/semi">
            <span class="_400">Półprodukty</span>
          </router-link>
        </li> -->
                    <!-- <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/semi-category">
                            <span class="_400">Kategorie półproduktów</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/materials">
                            <span class="_400">Materiały</span>
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/recipes">
                            <span class="_400">Recepty</span>
                        </router-link>
                    </li> -->
                    <!-- <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/workplaces">
                            <span class="_400">Stanowiska</span>
                        </router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/employees">
                            <span class="_400">Pracownicy</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/archived-employees">
                            <span class="_400">Archiwum pracowników</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/features">
                            <span class="_400">Cechy</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/product_types">
                            <span class="_400">Typy prod.</span>
                        </router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/task-definitions">
                            <span class="_400">Definicje zadań</span>
                        </router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/print-templates">
                            <span class="_400">Drukowanie</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/calendar">
                            <span class="_400">Kalendarz</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/buffor">
                            <span class="_400">Produkty buforwane</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color" to="/production/production-materials">
                            <span class="_400">Materiały produkcyjne</span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="btn btn-sm white none-radius primary-color d-gray" to="/production/recipesv2">
                            <span class="_400">Recepty <span class="text-muted">(old)</span></span>
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="box-divider m-a-0"/>
            <div class="tab-content m-b-md">
                <div class="tab-pane animated fadeIn active text-muted">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: 'Production',
    components: {

    },
    methods: {
    }
}
</script>
