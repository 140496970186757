<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm">Wfirma - płatności</h2>
            <span v-if="customer.payments.unsettledPayments > 0">
                <span :class="{'text-blue': customer.payments.unsettledPayments <= customer.payments.limitUnsettledPayments && customer.payments.latePayments == 0,
                'text-yellow': customer.payments.latePayments > 0 && customer.payments.latePayments <= customer.payments.limitLatePayments && customer.payments.unsettledPayments <= customer.payments.limitUnsettledPayments ,
                'text-red': customer.payments.unsettledPayments > customer.payments.limitUnsettledPayments || customer.payments.latePayments > customer.payments.limitLatePayments}">
                   <i class="material-icons md-18">report_problem</i> Klient posiada nierozliczone płatności!
                </span>
            </span>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <form class="form-horizontal p-b">

                <div class="form-group row m-y-sm">
                    <label class="col-sm-8 form-control-label">Suma nierozliczonych płatności</label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" v-model="customer.payments.unsettledPayments" placeholder="Suma nierozliczonych płatności">
                    </div>
                </div>
                <div class="form-group row m-y-sm">
                    <label class="col-sm-8 form-control-label">Suma płatności po terminie</label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" v-model="customer.payments.latePayments" placeholder="Suma płatności po terminie">
                    </div>
                </div>

                <hr>

                <div class="form-group row m-y-sm">
                    <label class="col-sm-8 form-control-label">Limit nierozliczonych płatności (Kredytowy)</label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" v-model="customer.payments.limitUnsettledPayments" placeholder="Limit nierozliczonych płatności">
                    </div>
                </div>
                <div class="form-group row m-y-sm">
                    <label class="col-sm-8 form-control-label">Limit płatności po terminie (Przeterminowane)</label>
                    <div class="col-sm-4">
                        <input class="form-control" type="text" v-model="customer.payments.limitLatePayments" placeholder="Limit płatności po terminie">
                    </div>
                </div>
                <small class="text-muted">Po przekorczeniu ustalonego limitu przed rozpisaniem zamówień na produkcje system zapyta czy na pewno rozpisać. </small>
                <br>
                <p>
                    <small>Legenda:</small><br>
                    <span class="text-blue text-xs">
                        <i class="material-icons md-18">report_problem</i> Niebieski - Klient posiada nierozliczone płatności, ale nie posiada płatności po terminie
                    </span><br>
                    <span class="text-yellow text-xs">
                        <i class="material-icons md-18">report_problem</i> Żółty - Klient posiada płatności po terminie i nie przekracza ich limitu
                    </span><br>
                      <span class="text-red text-xs">
                        <i class="material-icons md-18">report_problem</i> Czerwony - Klient posiada płatności po terminie i przekracza ich limity
                    </span><br>
                </p>
                <a href="#" class="pull-right text-blue text-sm" @click.prevent="save">Zapisz</a>
            </form>
        </div>
    </div>
    </div>
</template>
<script>
import Api from '../../Api.js';

export default {
    name: 'WfirmaUnsettledPayments',
    props: {
        customer: Object
    },
    data: function () {
        return {
            isLoaded: false,
            procesing: false
        }
    },
    created: function() {
        if (!this.customer.payments) {
            this.customer.payments = {
                unsettledPayments: 0,
                latePayments: 0,
                limitUnsettledPayments: 0,
                limitLatePayments: 0
            }
        }
    },
    methods: {
        async save() {
            this.isReady = false;
            try {
                if (typeof this.customer.payments.unsettledPayments === 'string') this.customer.payments.unsettledPayments = this.customer.payments.unsettledPayments.replace(/,/g, '.');
                if (typeof this.customer.payments.latePayments === 'string') this.customer.payments.latePayments = this.customer.payments.latePayments.replace(/,/g, '.');
                if (typeof this.customer.payments.limitUnsettledPayments === 'string') this.customer.payments.limitUnsettledPayments = this.customer.payments.limitUnsettledPayments.replace(/,/g, '.');
                if (typeof this.customer.payments.limitLatePayments === 'string') this.customer.payments.limitLatePayments = this.customer.payments.limitLatePayments.replace(/,/g, '.');

                this.customer.payments.unsettledPayments = parseFloat(this.customer.payments.unsettledPayments);
                this.customer.payments.latePayments = parseFloat(this.customer.payments.latePayments);
                this.customer.payments.limitUnsettledPayments = parseFloat(this.customer.payments.limitUnsettledPayments);
                this.customer.payments.limitLatePayments = parseFloat(this.customer.payments.limitLatePayments);
                await Api.customer.edit(this.customer._id, this.customer);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Akcja wykonana pomyślnie.' });
            } catch (error) {
                console.log("error", error)
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił błąd.' });
            }
            this.isReady = true;
        }
    }
}
</script>
