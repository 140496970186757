<template>
    <div class="m-t">
        <strong>Kartony w przesyłce</strong>
        <div class="form-group row" v-for="(paczka, index) in paczki" :key="index">
            <label class="col-sm-2 form-control-label">#{{index}} </label>
            <div class="col-sm-3 input-group">
                <input type="text" class="form-control" v-model="paczka.nrExtPp" placeholder="Opis">
                <span class="input-group-addon">opis</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.gab1" placeholder="Wys [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.gab2" placeholder="Szer [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.gab3" placeholder="Głębo [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.waga" placeholder="kg">
                <span class="input-group-addon text-sm p-a-xs">kg</span>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn white" tabindex="-1" @click="paczki.splice(index, 1)">Usuń</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Dodaj karton</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(20,20,20,10)">+ 20x20x20, 10kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(30,30,30,20)">+ 30x30x30, 20kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(60,60,60,30)">+ 60x60x60, 30 kg</button>
            </div>
            <div class="col-sm-2"/>
        </div>

        <!-- <div class="form-group row" v-if="order.boxes && order.boxes.length">
            <label class="col-sm-2 form-control-label">Pobierz paczki z zamówienia</label>
            <div class="col-sm-2">
                <button type="button" class="btn white" @click="getOrderBoxes">Pobierz ({{ order.boxes.length }})</button>
            </div>
        </div> -->

        <hr>

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Zawartość</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="parcel.zawartosc" placeholder="Zawartość">
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Kwota ubezpieczenia</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="parcel.ubezpieczenie" placeholder="Kwota ubezpieczenia [zl]">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input v-model="isCod" type="checkbox" class="">
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="rod" type="checkbox" class="">
                        <i class="blue"/> Dokumenty zwrotne
                    </label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="isGuaranteeTime" type="checkbox" class="">
                        <i class="blue"/> Gwarantowane doręcznie
                    </label>
                    <span v-if="isGuaranteeTime">
                        <p class="m-t">
                            <label class="md-check"><input v-model="guaranteeTime" type="radio" name="gtime" value="10">
                                <i class="primary"/> Doręcznie do 10:00</label>
                        </p>
                        <p>
                            <label class="md-check"><input v-model="guaranteeTime" type="radio" name="gtime" value="12">
                                <i class="primary"/> Doręcznie 12:00</label>
                        </p>
                    </span>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod" @change="parsePrice" placeholder="Kwota pobrania [zl]">
            </div>
        </div>
        <div class="form-group row" v-if="isCod">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Numer konta bankowego do pobrań</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="iban" placeholder="Numer konta bankowego do pobrań">
            </div>
        </div>
        <a href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>
    </div>
</template>

<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
const OP = require('object-path');

export default {
    name: 'CarrierFedex',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            selected: [],
            isGuaranteeTime: false,
            isCod: false,
            paczki: [],
            parcel: { zawartosc: "Filtry powietrza", uwagi: this.order.invoiceNo, ubezpieczenie: 5000 },
            qty: 1,
            guaranteeTime: "",
            cod: null,
            iban: "",
            rod: false
        }
    },
    created: function() {
        if (this.order.clientNo && this.order.clientNo.length > 0) {
            this.parcel.zawartosc = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }
        this.iban = this.sender.iban;
        this.paczki.push({
            waga: 1,
            gab1: 20,
            gab2: 20,
            gab3: 20,
            nrExtPp: "Karton #1"
        });

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets()
    },
    methods: {
        addNewParcel: function(height, width, depth, weight) {
            this.paczki.push({
                gab1: height,
                gab2: width,
                gab3: depth,
                waga: weight,
                nrExtPp: "Karton #" + (this.paczki.length + 1)
            });
        },

        parsePrice: function() {
            this.cod = this.cod.replace(/,/g, '.')
        },

        getOrderBoxes: function() {
            if (!this.order || !this.order.boxes || !this.order.boxes.length) { return this.$helpers.errorNotif("Brak spakowanych kartonów w zamówieniu"); }

            this.paczki.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                this.paczki.push({
                    gab1: Math.round(box.height / 10),
                    gab2: Math.round(box.width / 10),
                    gab3: Math.round(box.length / 10),
                    waga: box.mass,
                    nrExtPp: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        },

        // save: async function() {
        //     this.isProcessing = true;
        //     try {
        //         const shipments = await Api.shipments.fedexSent(this.collectData());
        //         // jeszcze do konca nie wiem czemu metoda sent zwraca tablice
        //         for (const shipment of shipments) this.$emit('shipment', shipment);
        //         this.$h.success('Dodano przesyłkę');
        //     } catch (e) {
        //         const descError = OP.get(e, "response.data.error.root.Envelope.Body.Fault.faultstring");
        //         if (descError) this.$h.errorNotif(descError);
        //         else this.$h.commonApiError(e, true);
        //     }
        //     this.isProcessing = false;
        // },
        async save() {
            this.isProcessing = true;
            try {
                const allShips = [];

                for (let i = 0; i < this.paczki.length; i++) {
                    const paczka = this.paczki[i];
                    const data = this.setData();
                    data.package.paczki = [paczka];
                    // Doklejenie numeru kartonu do opisu na etykiecie
                    data.package.zawartosc += " | "+paczka.nrExtPp;
                    const shipments = await Api.shipments.fedexSent(data);
                    allShips.push(...shipments);
                }
                this.$emit('shipments', allShips);
                this.$h.success('Dodano przesyłki');
            } catch (e) {
                const descError = OP.get(e, "response.data.error.root.Envelope.Body.Fault.faultstring");
                if (descError) this.$h.errorNotif(descError);
                else this.$h.commonApiError(e, true);
            }

            this.isProcessing = false;
        },
        setData() {
            const data = {
                qty: 1,
                order: this.order._id,
                package: {
                    odbiorca: this.setReceiver(),
                    nadawca: this.setSender(),
                    zawartosc: this.parcel.zawartosc,
                    uwagi: this.parcel.uwagi,
                    ubezpieczenie: this.parcel.ubezpieczenie
                },
            };
            // this.parcel.odbiorca = this.setReceiver();
            // this.parcel.nadawca = this.setSender();

            if (this.rod) {
                // this.parcel.rod = true;
                data.package.rod = true;
            }
            if (this.guaranteeTime.length > 0) {
                // this.parcel.guaranteeTime = this.guaranteeTime;
                data.package.guaranteeTime = this.guaranteeTime;
            }
            if (this.cod != null) {
                // this.parcel.cod = this.cod;
                data.package.cod = this.cod;
                // this.parcel.iban = this.iban;
                data.package.iban = this.iban;

                //aby tylko w jednej przesyłce była kwota pobrania to czyscimy wartosc kod po pierwszej paczce
                this.cod = null;
            }
            // data.package = this.parcel;
            return data;
        },

        // collectData: function() {
        //     const data = { qty: 1, order: this.order._id };
        //     this.parcel.paczki = this.paczki;

        //     this.parcel.odbiorca = this.setReceiver();
        //     this.parcel.nadawca = this.setSender();

        //     if (this.rod) {
        //         this.parcel.rod = true;
        //     }
        //     if (this.guaranteeTime.length > 0) {
        //         this.parcel.guaranteeTime = this.guaranteeTime;
        //     }
        //     if (this.cod != null) {
        //         this.parcel.cod = this.cod;
        //         this.parcel.iban = this.iban;
        //     }
        //     data.package = this.parcel;

        //     return data;
        // },
        setSender: function() {
            const s = {
                nazwa: this.sender.company_name,
                imie: this.sender.firstname,
                nazwisko: this.sender.lastname,
                emailKontakt: this.sender.email,
                telKontakt: this.sender.phone
            };

            return s;
        },
        setReceiver: function() {
            const arr = this.recipient.street.split(' ');
            const numer = arr.pop();
            const street = arr.join(' ');
            let names = [];
            if (this.recipient.contactPerson) {
                names = this.recipient.contactPerson.split(' ');
            } else {
                names = this.recipient.name.split(' ');
            }

            const r = {
                nazwa: this.recipient.name,
                czyFirma: 1,
                miasto: this.recipient.city,
                kod: this.recipient.postCode,
                kodKraju: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                ulica: street,
                nrDom: numer,
                telKontakt: this.recipient.phone ? this.recipient.phone : "",
                emailKontakt: this.recipient.email ? this.recipient.email : "",
                imie: names[0],
                nazwisko: names[1]
            };

            return r;
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.paczki.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.paczki.push({
                    gab1: Math.round(box.height / 10),
                    gab2: Math.round(box.width / 10),
                    gab3: Math.round(box.length / 10),
                    waga: box.mass,
                    nrExtPp: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
  .select2-container .select2-selection--single {
    height: 44px;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px;
  }
</style>
