<template>
    <div class="box">
        <div class="box-header d-gray">
            <h2 class="text-muted _400 inline" v-if="product.name">{{product.name}}</h2>
            <div class="pull-right">
                <div class="btn-group primary-color order-btn-group"/>
                <a class="nav-link btn btn-xs white none-radius" title="Usuń produkt" @click="remove">
                    <i class="material-icons md-18">close</i>
                </a>
            </div>
        </div>
        <div class="box-divider m-a-0"/>

        <div class="l-gray p-b">
            <ul class="btn-group nav nav-pills p-t p-l">
                <li class="nav-item" v-for="tab in tabs" :key="tab.name">
                    <a class="btn btn-sm white none-radius primary-color"
                       :class="{active: selectedTab == tab}" @click="selectedTab = tab">
                        <span class="_400">{{ tab.name }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-divider m-a-0"/>
        <div class="tab-content m-b-md">
            <div class="tab-pane animated fadeIn active text-muted">
                <component v-if="selectedTab" :is="selectedTab.component" :product="product" />
            </div>
        </div>

    </div>

</template>
<script>
import Api from '../../Api.js';
export default {
    name: 'ContentTabs',
    props: {
        product: Object
    },
    components: {
    },
    data: function () {
        return {
            selectedTab: null
        }
    },

    watch: {
    },

    created: function() {
        this.selectedTab = this.tabs[0];
    },

    computed: {
        tabs() {
            const t = [{
                name: "Analiza",
                component: () => import("./Tabs/Analytics.vue")
            }, {
                name: "Historia",
                component: () => import("./Tabs/ProductHistory.vue")
            }, {
                name: "Zamówienia",
                component: () => import("./Tabs/Orders.vue")
            }, {
                name: "Serie",
                component: () => import("./Tabs/ProductSeries.vue")
            }, {
                name: "Stan",
                component: () => import("./Tabs/ProductStockInfo.vue")
            }, {
                name: "Produkcja",
                component: () => import("./Tabs/ProductionRequests.vue")
            }, {
                name: "Kody klientów",
                component: () => import("./Tabs/ClientCodes.vue")
            }];

            return t;
        }
    },

    methods: {
        async remove() {
            if (!await this.$h.confirm("Czy na pewno chcesz usunąć ten produkt?")) return;

            const res = await Api.product.save(this.product._id, { isRemoved: true });
            Object.assign(this.product, res);
        }
    }
}
</script>
<style scoped lang="scss">
</style>
