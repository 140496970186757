<template>
    <div class="box">
        <div class="box-header d-gray" :class="{'p-b-xs': order.customTags && order.customTags.length >0}">
            <h6 class="text-muted _400 inline" style="vertical-align: middle;">
                <span v-if="order.name">{{order.name}}</span>&nbsp;
                <span v-if="!order.name">Brak nazwy</span>

            </h6>
            <span class="label text-white m-r-xs none-radius inline" v-if="order.tags.indexOf('own-pickup') != -1" >Odbiór własny</span>
            <a href="" @click.prevent="changeStatus" title="Zmień status">
                <OrderStatusSpan :order="order" />
            </a>

            <div class="pull-right">
                <div class="btn-group primary-color order-btn-group">
                    <a class="nav-link btn btn-xs white none-radius m-r-md" title="Edytuj" :class="{ 'active': isChange}"
                       @click="edit">
                        <i class="material-icons md-18">edit</i> Edytuj
                    </a>
                    <a class="nav-link btn btn-xs white none-radius" title="Oznacz jako spakowane" :class="{ 'active disabled': order.status == 'pack'}" @click.prevent="confirmStatus('pack')">
                        <i class="material-icons md-18">archive</i>
                    </a>
                    <a class="nav-link btn btn-xs white none-radius" title="Oznacz jako gotowe do wysyłki" :class="{ 'active disabled': order.status == 'ready-to-send'}" @click.prevent="confirmStatus('ready-to-send')">
                        <i class="material-icons md-18">done_all</i>
                    </a>
                    <a class="nav-link btn btn-xs white none-radius" title="Oznacz jako wysłane" :class="{ 'active disabled': order.status == 'sent'}" @click.prevent="confirmStatus('sent')">
                        <i class="material-icons md-18">call_made</i>
                    </a>
                    <a class="nav-link btn btn-xs white none-radius" title="Oznacz jako anulowane" :class="{ 'active disabled': order.status == 'cancel'}" @click.prevent="confirmCancel('cancel')">
                        <i class="material-icons md-18">highlight_off</i>
                    </a>
                </div>
            </div>

        </div>
        <div class="m-l d-gray" v-if="order.customTags && order.customTags.length >0">
            Tagi: <span class="m-r-sm text-muted" v-for="t in order.customTags" :key=t>{{t}}</span>
        </div>
        <hr class="m-t-0 m-b-0">
        <div class="box-header d-gray p-b-sm p-t-sm p-x-0">

            <div class="row p-x" v-if="(order.clientNo && order.clientNo.length > 0) || (order.invoiceNo && order.invoiceNo.length > 0)">
                <div class="col-md-6" v-if="order.clientNo && order.clientNo.length > 0">
                    <div class="box m-b-0 p-a-sm client-no" style="line-height: 1;">
                        {{order.clientNo}}
                        <br><span class="text-muted" style="font-size: 0.7rem;">Numer zam. klienta</span>
                    </div>
                </div>
                <div class="col-md-6" v-if="order.invoiceNo && order.invoiceNo.length > 0">
                    <div class="box m-b-0 p-a-sm invoice-no" style="line-height: 1;">
                        {{order.invoiceNo}}
                        <br><span class="text-muted" style="font-size: 0.7rem;">Numer FV/PF</span>
                    </div>
                </div>
            </div>
            <div v-if="(order.clientNo && order.clientNo.length > 0) || (order.invoiceNo && order.invoiceNo.length > 0)">
                <hr class="m-b-sm m-t-sm">
            </div>
            <div class="row p-x">
                <div class="col-md-12">
                    <span class="pull-right primary-color m-l" v-if="order.customerNotifactionDate">
                        Data wysłana w mailu:
                        <span class="_500">{{order.customerNotifactionDate | moment("DD/MM/YYYY")}}</span>
                    </span>
                    <span class="pull-right primary-color">
                        Data zamówienia:
                        <span class="_500">{{order.created | moment("DD/MM/YYYY")}}</span>
                    </span>

                    <span class=" pull-right primary-color m-r">
                        <span class="m-r-xs"> Szacowany termin:</span>
                        <span @click.prevent="deadlineModal" title="Zmień" v-if="order.softDeadline" class="_500 deadline-link">{{order.softDeadline | moment("DD/MM/YYYY")}}</span>
                        <span @click.prevent="deadlineModal" title="Dodaj" v-else class="deadline-link">brak</span>
                    </span>

                    <span class="pull-right primary-color m-r">
                        <span class="m-r-xs"> Termin dla klienta:</span>
                        <span @click.prevent="deadlineModal" title="Zmień" v-if="order.hardDeadline" class="_500 deadline-link">{{order.hardDeadline | moment("DD/MM/YYYY")}}</span>
                        <span @click.prevent="deadlineModal" title="Dodaj" v-else class="deadline-link">brak</span>
                    </span>
                </div>

            </div>
        </div>
        <hr class="m-t-0 m-b-0">

        <div class="order-btn-group">
            <button v-for="tab in tabs" :key="tab.name" class="tab btn btn-sm none-radius" :class="{active: selectedTab == tab}" @click="selectedTab = tab">
                <span class="primary-color _400">{{ tab.name }}</span>
            </button>
        </div>

        <component v-if="selectedTab" :is="selectedTab.component" :order="order" />
    </div>
</template>
<script>
import OrderStatusSpan from './OrderStatusSpan.vue'
import ChangeOrderStatusModal from './Modals/ChangeOrderStatusModal.vue'
import Api from '../../Api.js';
import EventBus from '../../event-bus.js';

import Datepicker from 'vuejs-datepicker';
import pl from 'vuejs-datepicker/dist/locale/translations/pl.js'
export default {
    name: 'OrderDetalis',
    props: {
        order: Object
    },
    components: {
        OrderStatusSpan,
        Datepicker
    },
    data: function () {
        return {
            pl: pl,
            isChange: false,
            procesing: false,

            selectedTab: null,
            tabs: [{
                name: "Treść zamówienia",
                component: () => import("./DetailsTab/OrderCart/OrderCart.vue")
            }, {
                name: "Produkcja zamówienia",
                component: () => import("./DetailsTab/OrderProduction/OrderProduction.vue")
            }, {
                name: "Zgłoszone zapotrzebowania",
                component: () => import("./DetailsTab/OrderDemands.vue")
            }, {
                name: "Spakowane pozycje",
                component: () => import("./DetailsTab/OrderPackInfo/CartPosesPacked.vue")
            }]
        }
    },
    created: function() {
        EventBus.$on('newZPAdded', this.getProdReqs);

        // jesli zam jest w produkcji to na start wyswietlamy wlasnie tą zakładkę
        if (this.order.status == "in-production") this.selectedTab = this.tabs[1];
        else this.selectedTab = this.tabs[0];
    },
    destroyed: function() {
        EventBus.$off('newZPAdded', this.getProdReqs);
    },

    methods: {
        change: function() {
            if (!this.order.name || this.order.name.length == 0) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Podaj nazwę zamówienia.' });
                return;
            }
            this.procesing = true;
            Api.order.setName(this.order._id, this.order.name).then((order) => {
                this.order.name = order.name;
                this.procesing = false;
                this.isChange = false;
            })
        },

        changeStatus: function(task) {
            this.$modal.show(ChangeOrderStatusModal, {
                order: this.order
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            });
        },

        toogleTag: async function(tag) {
            if (!Array.isArray(this.order.tags)) this.order.tags = [];
            const idx = this.order.tags.indexOf(tag);
            if (idx == -1) this.order.tags.push(tag);
            else this.order.tags.splice(idx, 1);

            await Api.order.setTags(this.order._id, this.order.tags);
        },
        confirmCancel: async function(status) {
            const p = parseInt(this.order.production.progress * 100);
            const message = 'Czy na pewno chcesz oznaczyć te zamówienie jako anulowane? Zamówienie jest zrealizowane w ' + p + '%.' +
            'Razem z zamówioniem zostaną równiez usuniete "Zlecenia Produkcyjne", które nie zostały ukończone. Czy na pewno chesz wykonać tą akcje?';
            if (await this.$helpers.confirm(message)) {
                this.setStatus(status);
                // EventBus.$emit('removeAllZP');
                this.removePRs();
            };
        },

        confirmStatus: async function(status) {
            let confirmMsg = null;
            if (status == 'sent' && this.order.status != 'ready-to-send') {
                confirmMsg = "Czy na pewno chcesz oznaczyć te zamówienie jako 'Wysłane'?";
            } else if (status == 'ready-to-send' && this.order.status == 'in-production') {
                confirmMsg = "Czy na pewno chcesz oznaczyć te zamówienie jako 'Gotowe do wysłania' mimo, że ma status 'W produkcji'?";
            } else if (status == 'pack') {
                confirmMsg = "Czy na pewno chcesz oznaczyć te zamówienie jako 'Spakowane'?";
            }

            if (confirmMsg != null) {
                if (await this.$helpers.confirm(confirmMsg)) {
                    this.setStatus(status);
                };
            } else {
                this.setStatus(status);
            }
        },

        setStatus: function(status) {
            Api.order.setStatus(this.order._id, status).then((order) => {
                this.order.status = order.status;
            });
        },

        async edit() {
            this.isChange = true;
            const changedOrder = await this.$modal2.show(() => import('./Modals/ChangeOrderNameModal.vue'), { orderToChange: this.order });
            if (changedOrder) Object.assign(this.order, changedOrder);
            this.isChange = false;
        },
        async removePRs() {
            try {
                const res = await Api.production.request.v3.search({
                    orderId: this.order._id,
                    getInfoAboutReservedIngredientsForPR: true,
                    open: true, // tylko nieukończone. Ukończone zostawiamy do celów historycznych
                    ingredientFor: null // tylko koncowe PRsy
                });
                const rawPRs = res.data;

                for (const pr of rawPRs) {
                    const deletePrs = await Api.production.request.v3.remove(pr._id);
                }
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
        },
        async deadlineModal() {
            const isSuccess = await this.$modal2.show(() => import("./Modals/DeadlineModal.vue"), {
                order: this.order
            });
        }

    }
}
</script>
<style scoped lang="scss">
.order-btn-group {
    display: flex;
    .tab {
        flex-grow: 1;
        background-color: #f6f8f8;
        &.active {
            // color: rgba(255, 255, 255, 0.87);
            background-color: #fff;
            box-shadow: none;
        }
    }
}

.deadline-link {
    cursor: pointer;
}
.deadline-link:hover {
    color: #2196f3;
}
.client-no {
    border: 1px solid #90c844;
    font-size: 0.8rem;
    background: rgba(144, 200, 68, 0.02);
}
.invoice-no {
    border: 1px solid #5bc0de;
    font-size: 0.8rem;
    background: rgba(91, 192, 222, 0.02);
}

</style>
