<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Produkt buforowany</h6>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body">
            <div class="form-group row" >
                <label class="col-sm-5 text-right form-control-label">
                    
                </label>
                <div class="col-sm-7">
                    <label class="md-check">
                        <input type="checkbox" v-model="isBuff" @change="setBuff">
                        <i class="blue m-r-xs"/>
                        <span class="m-l-xs">Produkt jest buforowany</span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 text-right form-control-label">Minimalna ilość</label>
                <div class="col-sm-7">
                    <input class="form-control" type="text" v-model="buff.minQty">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 text-right form-control-label">Ilość do której dążymy</label>
                <div class="col-sm-7">
                    <input class="form-control" type="text" v-model="buff.targetQty">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 text-right form-control-label">Max ilośc per zlecenie (PR)</label>
                <div class="col-sm-7">
                    <input class="form-control" type="text" v-model="buff.maxQtyPerOnePR">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 text-right form-control-label">Zleć </label>
                <div class="col-sm-7">
                    <v-select placeholder="Nie wybrano" label="name" v-model="buff.deadlineOffset" :options="deadlineOffsets" :reduce="t => t.number" />
                </div>
            </div>
            <div class="my-spin" style="position: absolute; left: 50%; top: 80%;">
            <Spinner v-if="isProgress" />
            </div>
        </div>
        <div class="box-footer p-b-lg">
            <button :disabled="isProgress" type="button" class="pull-right btn white b-a no-shadow text-muted none-radius btn-sm" style="height: 32px;" @click.prevent="save">Zapisz</button>
           
        </div>
    </div>
</template>

<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
export default {
    name: 'ProductFiles',
    props: {
        product: Object
    },
    components: {
        Spinner
    },
    data() {
        return {
            isProgress: false,
            buff: {
                productId: this.product._id,
                minQty: 0,
                targetQty: 0,
                maxQtyPerOnePR: 0,
                deadlineOffset: 0
            },
            isBuff: false,
            deadlineOffsets: [
                { number: 0, name: "Tego samego dnia" },
                { number: 1, name: "1 dzień po" },
                { number: 2, name: "2 dni po" },
                { number: 3, name: "3 dni po" }
            ]
        }
    },
    created: function() {
        this.getBuff();
    },
    methods: {
        async getBuff() {
            try {
                const databuff = await Api.productionBuffer.get(this.product._id);
                if (databuff) {
                    this.isBuff = true;
                    this.buff = databuff;
                } 
            } catch (error) {
                // console.log("error", error);
            }
        },
        async setBuff() {
            if (this.isBuff || !this.buff._id) return;
            if (!await this.$h.confirm('Czy na pewno chcesz usunąć wybrany produkt z bufforwanych?')) { return; } 
            try {
                await Api.productionBuffer.remove(this.buff._id);
                this.buff.minQty = 0;
                this.buff.targetQty = 0;
                this.buff.maxQtyPerOnePR = 0;
                this.buff.deadlineOffset = 0;
                this.$h.success("Pomyślnie usunięto produkt z buforowanych");
            } catch (error) {
                 console.log("error", error);
                this.$h.commonApiError(error, true);
            }
        },
        async save() {
            if(!this.isBuff) {
                this.$h.errorNotif('Zaznacz checkbox aby zapisać produkt jako buforwany.');
                return;
            }
            this.buff.minQty = parseInt(this.buff.minQty);
            this.buff.targetQty = parseInt(this.buff.targetQty);
            this.buff.maxQtyPerOnePR = parseInt(this.buff.maxQtyPerOnePR);
            this.buff.deadlineOffset = parseInt(this.buff.deadlineOffset);
            this.isProgress = true;
            try {
                if (this.buff._id) await Api.productionBuffer.edit(this.buff._id, this.buff);
                else await Api.productionBuffer.add(this.buff);
                this.$h.success("Pomyślnie zapisano");
            } catch (error) {
                console.log("error", error);
                this.$h.commonApiError(error, true);
            }
            this.isProgress = false;
        }
    }
}
</script>
<style scoped lang="scss">
.progress {
  display: inline-block;
  width: 100%;
  border-radius: 0px;
  margin-bottom: 0px;
}
.progress-bar {
  border-radius: 0px;
}
.upload-error {
    display: inline-block;
    width: 100%;
    background-color: #f05050;
  }
</style>
