<template>
    <div class="p-x p-y-xs" v-if="pType">

        <div class="ing-box" :class="{manual: isManualIng || ingredient.isManual}">
            <router-link :to="{ name: 'product-type-details', params: { code: pType.code } }">
                <img :src="pType.iconUrl" class="product-icon">
                {{ pType.name }}
            </router-link>

            <a v-if="isManualIng || ingredient.isManual" @click.prevent="$emit('delete', ingredient)" style="position: absolute; right: 5px; top: 5px" title="Usuń produkt" class="text-red"><i data-v-6f2a2cd7="" class="material-icons md-18">close</i></a>

            <!-- //jeśli jednostka jest '100 szt.' to wtedy inne przedstawienie ilosci zużytej -->
            <span v-if="pType.unit && pType.unit== '100 szt.'">
                <span class="label text-white m-r-xs none-radius inline p-t-xs p-x-xs blue" style="display: inline-block">
                    {{ (Math.round(parseFloat(ingredient.amount * 10000 ))/10000) *100 }} szt.
                </span>
                <span class="text-muted" style="font-size:0.65rem">
                    ( {{ Math.round(parseFloat(ingredient.amount * 10000 ))/10000 }} x {{ pType.unit || 'szt' }})
                </span>
            </span>
            <span v-else>
                <span class="label text-white m-r-xs none-radius inline p-t-xs p-x-xs blue" style="display: inline-block">
                    {{ Math.round(parseFloat(ingredient.amount * 10000 ))/10000 }}  {{ pType.unit || 'szt' }}
                </span>
            </span>
            <br>
            <span  v-for="(f, key) in ingredient.features" :key="key">
                <span v-if="features.find(f => f.code == key)" style="display: inline-block;" class="m-1 feature-label">{{features.find(f => f.code == key).desc}}: {{f}}</span>
            </span>

            <div class="" v-if="!ingredient.ingredients && !isManualIng">
                <router-link v-if="ingredient.stockProduct" class="m-1 feature-label text-blue" :to="'/product/'+ingredient.stockProduct._id">Materiał: {{ ingredient.stockProduct.code }}<br></router-link>
                <span v-else class="m-1 feature-label">Brak odpowiedniego produktu</span>
                <span class="m-1 feature-label" v-if="ingredient.price">
                    Koszt ~ {{ ingredient.amount * ingredient.price | money }}
                    <span class="text-muted">({{ingredient.price | money}}/{{ pType.unit || 'szt' }})</span>
                </span>
                <span class="m-1 feature-label" v-else>brak wartości</span>
            </div>

            <div v-if="ingredient.useProductionBuffer">
                <span class="m-1 feature-label yellow" style="display: inline-block;">Z magazynu buforowego</span>
                <router-link v-if="ingredient.stockProduct" class="m-1 feature-label text-blue" style="display: inline-block;" :to="'/product/'+ingredient.stockProduct._id">{{ ingredient.stockProduct.code }}<br></router-link>
                <span v-else class="m-1 feature-label" style="display: inline-block;">Brak odpowiedniego produktu</span>
            </div>

            <div class="m-l m-t-sm" v-if="ingredient.alternatives">
                <span class="text-muted">Alternatywnie można użyć</span>
                <IngredientBox :product-types="pTypes" :ingredient="ing" v-for="(ing, k) in ingredient.alternatives" :key="'alt_' + k" class="text-muted"/>
            </div>
            <div class="m-a" v-if="ingredient.notes && ingredient.notes.length">
                <strong>Pomocnicze notatki dla pracownika:</strong><br>
                <!-- eslint-disable-next-line vue/require-v-for-key -->
                <span v-for="note in ingredient.notes">
                    {{ note.name }}: {{ note.note }}<br>
                </span>
            </div>
        </div>

        <div class="m-l m-t-sm" v-if="subIngredients.length">
            <IngredientBox :product-types="pTypes" :ingredient="ing" v-for="ing in subIngredients" :key="ing._id" style="margin-left:0.2rem; border-left: 1px dashed #222;"/>
        </div>

        <div class="m-l m-t-sm" v-if="subingredientGroups.length">
            <IngredientGroupBox :product-types="pTypes" :ingredient="ing" v-for="(ing, k) in subingredientGroups" :key="'g'+k" style="margin-left:0.2rem; border-left: 1px dashed #222;"/>
        </div>

    </div>
</template>

<script>
import Api from '../../../Api.js';
import IngredientGroupBox from './IngredientGroupBox.vue'

export default {
    name: 'IngredientBox',
    props: {
        ingredient: Object,
        productTypes: {
            type: Array,
            required: false
        },
        isManualIng: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    components: {
        IngredientGroupBox
    },
    data: function () {
        return {
            pTypes: [],
            pType: null,
            features: []
        }
    },

    async created() {
        this.features = await Api.product.getProductsFeatures();
        if (this.productTypes) {
            this.pTypes.push(...this.productTypes);
        } else {
            const pTypes = await Api.product.types.getAll();
            this.pTypes.push(...pTypes);
        }

        this.pType = this.pTypes.find(p => p.code == (this.ingredient.product || this.ingredient.productType));
    },

    computed: {
        subingredientGroups() {
            // console.log("DUPA", this.ingredients);
            return (this.ingredient.ingredients || []).filter(ing => ing.group && Array.isArray(ing.products));
        },

        subIngredients() {
            return (this.ingredient.ingredients || []).filter(ing => ing.product && !ing.group);
        }
    },
    methods: {
    }
}
</script>
