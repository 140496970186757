/**
 * Konwertuje klase EN na odpowiednik ISO
 * @param {number} numer klasy EN
 * @returns {string}     klasa w ISO
 */
function translateEN2ISO(enClass) {
    if (enClass == 'G2') return "ISO Coarse 40%";
    else if (enClass == 'G3') return "ISO Coarse 50%";
    else if (enClass == 'G4') return "ISO Coarse 65%";
    else if (enClass == 'M5') return "ePM10 50%";
    else if (enClass == 'M6') return "ePM2,5 50%";
    else if (enClass == 'F7') return "ePM2,5 65%";
    else if (enClass == 'F8') return "ePM1 70%";
    else if (enClass == 'F9') return "ePM1 80%";
    else return null;
}

function unifikujKlase(klasa) {
    let k = null;
    if      (klasa+'' == '1') k = 'G1';
    else if (klasa+'' == '2') k = 'G2';
    else if (klasa+'' == '3') k = 'G3';
    else if (klasa+'' == '4') k = 'G4';
    else if (klasa+'' == '5') k = 'M5';
    else if (klasa+'' == '6') k = 'M6';
    else if (klasa+'' == '7') k = 'F7';
    else if (klasa+'' == '8') k = 'F8';
    else if (klasa+'' == '9') k = 'F9';
    else if (klasa+'' == '10') k = 'E10';
    else if (klasa+'' == '11') k = 'E11';
    else if (klasa+'' == '12') k = 'E12';
    else if (klasa+'' == '13') k = 'H13';
    else if (klasa+'' == '14') k = 'H14';
    else if (klasa == 'nawegla') k = 'W';
    else k = klasa;

    return k;
}

/**
 * Wyciaga z kodu wlasciwosci produktu
 * @param {string} code kod produktu np FK-4/6/592/592/500
 * @returns {object} obiekt z wartosciami typu {szerokosc: 400, wysokosc: 300, ...}
 */
export function parseProductCode(code) {
    const codeParts = code.split('/');
    const features = {};

    for (let i = 0; i < codeParts.length; i++) {
        const c = codeParts[i];
        const isNumericOnly = !c.match(/\D+/);
        const numericVal = parseInt(c);

        if (c.match(/^W?F(K|P)\D?s?-(\d|MF|W|M)+/i)) {
            const cp = c.split('-');
            if (cp[0].slice(-1) == "s") {
                features.rodzaj_wlokniny = "szklana";
                cp[0] = cp[0].slice(0, -1);
            }

            if (cp[0].toUpperCase() == "FK") features.typ_filtra = 'kieszeniowy';
            else if (cp[0].toUpperCase() == "WFKA") features.typ_filtra = 'formatka';
            else if (cp[0].toUpperCase() == "FP") features.typ_filtra = 'płaski na pręcie';
            else if (cp[0].toUpperCase() == "FKA") features.typ_filtra = 'kasetowy';
            else if (cp[0].toUpperCase() == "FKP") features.typ_filtra = 'kasetowy płaski';
            else if (cp[0].toUpperCase() == "FKM") features.typ_filtra = 'kompaktowy';

            if (cp[1].toUpperCase() == 'W') features.klasa_filtra = 'nawegla';
            else if (cp[1].toUpperCase() == 'MF') features.klasa_filtra = 'MF';
            else features.klasa_filtra = unifikujKlase(cp[1]);
         

            if (features.klasa_filtra) {
                const iso = translateEN2ISO(features.klasa_filtra);
                if (iso) features.klasa_filtra_iso = iso;
            }

            continue;
        }

        if (c.match(/^WŁ-.+/i)) {
            const cp = c.split('-');
            features.typ_wlokniny = "syntetyczna";

            if (cp[1].toUpperCase() == 'SZK') features.typ_wlokniny = 'szklana';
            else features.klasa_wlokniny = parseInt(cp[1]);

            continue;
        }

        if (c.match(/^WkFKA-.+/i)) { // WkFKA-4/310/270/45-20
            const cp = c.split('-');
            features.typ_filtra = 'wkład';

            if (cp[1].toUpperCase() == 'SZK') features.typ_wlokniny = 'szklana';
            else features.klasa_wlokniny = parseInt(cp[1]);
            //DLA LATWGO WYPELNIANIA CECH BAZOWYCH O KLASE
            if (cp[1].toUpperCase() != 'SZK') features.klasa_filtracji = parseInt(cp[1]);

            continue;
        }

        if (c == "DF") {
            features.typ_filtra = 'DF';
            continue;
        }

        if (features.typ_filtra == "kieszeniowy" && numericVal < 50) {
            features.ilosc_kieszeni = numericVal;
            continue;
        }

        const srednica = c.match(/fi(\d+)/i);
        if (srednica) {
            features.srednica = parseInt(srednica[1]);
            continue;
        }

        const ramka = c.match(/r(\d+)/i);
        if (ramka) {
            features.grubosc_ramki = parseInt(ramka[1]);
        }

        if (isNumericOnly && numericVal > 100 && !features.szerokosc) {
            features.szerokosc = numericVal;
            continue;
        }

        if (isNumericOnly && numericVal > 100 && !features.wysokosc) {
            features.wysokosc = numericVal;
            continue;
        }

        if (isNumericOnly && numericVal > 0 && features.wysokosc && features.szerokosc) {
            if (features.typ_filtra == "kieszeniowy") features.dlugosc_kieszeni = numericVal;
            else features.grubosc = numericVal;
            continue;
        }

        // Taka koncowka oznacza ze to typ lamelki
        // WkFKA-4/310/270/45-20
        if ((features.typ_filtra == "formatka" || features.typ_filtra == "wkład") && features.wysokosc && features.szerokosc && c.match(/\d{2,}\-\d+/)) {
            // features.typ_filtra = 'lamelki';
            const gruboscAndLamelki = c.split("-");

            features.grubosc = parseInt(gruboscAndLamelki[0]);
            features.ilosc_lamelek = parseInt(gruboscAndLamelki[1]);
            continue;
        }

        if (c.toUpperCase() == "TP") {
            features.termicznie_formowany = "tak";
            continue;
        }
    }

    return features;
}
