<template>
    <div class="box m-b-0">
        <div class="box-header">
            <h3>Globalny zegar pracy</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <hr class="m-y-0"/>
        <div class="box-body p-t-0" v-if="isProcessing">
            <rotate-square2 style="margin: 15px;"/>
        </div>
        <div class="box-body" v-else>
            <span v-if="startTimer">
                <span class="primary-color text-muted m-r">Zegar pracy jest nie aktywny. </span>
                <a class="text-blue primary-color" @click.prevent="startWorkTmer"><i class="material-icons md-18 text-blue"></i> Uruchom zegar pracy</a>
            </span>
            <span v-else>
                <span class="primary-color text-muted m-r">
                Zager pracy jest aktywny.
                Czy przy wylogowaniu zakończyć prace, czy uruchomić przerwę?
                </span><br/>
                 <a class="text-blue primary-color " @click.prevent="endWork"><i class="material-icons md-18 text-blue"></i>zakończyć pracę</a>
                 <span class="m-x"> |</span>
                 <a class="text-blue primary-color" @click.prevent="startBreak"><i class="material-icons md-18 text-blue"></i>uruchom przerwę</a>
            </span>
        </div>
        
       
    </div>
</template>
<script>
import EventBus from '../../event-bus.js';
import Api from '../../Api.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import UserManager from '../../UserManager.js'

export default {
    name: 'GlobalTimerModal',
    props: {
        empId: String
    },
    components: {
        RotateSquare2,
    
    },
    data: function () {
        return {
            isProcessing: false,
            startTimer: false,
            UserManager: UserManager
            
        }
    },
    created: async function() {
        this.timer = await Api.timer.getEmployeeTimer(this.empId);
        if (!this.timer) this.startTimer = true;
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    methods: {
        
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        },
        async startWorkTmer() {
            try {
                 this.timer = await Api.timer.createTimer(this.empId);
                 this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie uruchomiono zegar pracy.' });
                EventBus.$emit('globalTimerOperationCreated');
                this.$emit('close');
            } catch (error) {
                console.log("error", error)
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystapił problem.' });
            }
        },
        async endWork() {
            if(this.timer.restTimes.find(rest => !rest.endedAt)) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie można zakończyć pracy bez zakończenia przerwy' });
                return
            }
            try {
                await Api.timer.stopTimer(this.empId);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie zatrzymano zegar pracy.' });
                UserManager.logOut();
            } catch (error) {
                
            }
        },
        async startBreak() {
             if(this.timer.restTimes.find(rest => !rest.endedAt)) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie można rozpocząć przerwy bedąć na przerwie' });
                return
            }
            await Api.timer.coffeBreakStart(this.empId);
             this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie rozpoczeto przerœ.' });
                EventBus.$emit('globalTimerOperationCreated');
                UserManager.logOut();
        }
        
    }
}
</script>

<style scoped lang="scss">
.pos-row {
    border-top: 1px solid #eee;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-left: 2px;
    padding-top: 5px;
}
</style>
