<template>
<div class="box">
  <div class="box-header">
    <h6 class="title-color inline">WFirma</h6>
  </div>
  <div class="m-a-0 custom-divider"></div>
  <div class="box-body">
    <div class="row">
      <div class="col-md-12">
        <div class="pdf-box m-b-sm">
          <div class="p-t-sm p-x p-b">
            <div>
              <span class="text-blue _600">{{invoice.fullnumber}}</span> 
              <span class="pull-right text-muted text-xs">{{invoice.created}}</span>
            </div>
            <div class="m-t-sm">
              <span class="text-muted">Kwota faktury:</span><span class="m-l-xs">{{invoice.total}}</span>
            </div>
            <div>
              <span class="text-muted">Status płatności:</span>
              <span class="m-l-xs text-red" v-if="invoice.paymentstate == 'unpaid'">Nie zapłacono</span>
              <span class="m-l-xs text-green" v-if="invoice.paymentstate == 'paid'">Zapłacono</span>
              <span class="m-l-xs text-blue" v-if="invoice.paymentstate == 'undefined'">Niezdefiniowany</span>
            </div>
            <p class="m-t-sm">
              <a v-bind:href="Api.wFirma.getFileUrl(invoice.id)" target="_blank" class="pull-left text-blue text-sm">Pobierz</a>
              <a :href="'https://www.wfirma.pl/invoices/view/'+invoice.id" target="_blank" class="pull-right text-blue text-sm">Zobacz w wFirma</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Api from '../../Api.js';

export default {
  name: 'WFirma',
  props: {
    invoice: Object,
  },
  data: function () {
    return {
      Api: Api,
    }
  },
  created: function() {
  },
  methods: {

  }
}
</script>