<template>
    <div class="box">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm">Adresy dostawy</h2>
            <div class="row">
                <label class="col-sm-6 col-md-6 form-control-label">Preferowana dostawa</label>
                <div class="col-sm-6 col-md-6">
                    <v-select placeholder="Nie wybrano" label="name" v-model="order.deliveryType" :options="deliveryTypes" @input="saveDelivery" />
                </div>
            </div>
            <div class="row">
                <label class="col-sm-6 col-md-6 form-control-label">Preferowany przewoźnik</label>
                <div class="col-sm-6 col-md-6">
                    <v-select placeholder="Nie wybrano" label="name" v-model="order.deliveryCompany" :options="deliveryCompanies" @input="saveDelivery" />
                </div>
            </div>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body p-t-0" v-if="isEmpty">
            <p class="p-t">
                Brak dodanych adresów dla tego klienta<br>
                <a href="#" class="text-blue text-sm m-t" @click.prevent="addDeliveryAddress">Dodaj pierwszy</a>
            </p>
        </div>
        <div class="box-body p-t-0" v-else>
            <div class="p-y custom-divider" v-for="deliveryAddress in order.customer.deliveryAddresses" :key="deliveryAddress._id">

                <div class="row m-b-xs" >
                    <span class="col-sm-10"  v-if="deliveryAddress.name">
                        <h6 class="_400">{{deliveryAddress.name}}</h6>
                    </span>
                    <span class="col-sm-2" v-if="deliveryAddress.name">
                        <label class="md-check p-t-xs pull-right">
                            <input type="checkbox" @change="setDeliverAddress(deliveryAddress._id)" v-model="selected" :value="deliveryAddress._id">
                            <i class="blue m-r-xs"/>
                        </label>
                    </span>
                    <span class="col-sm-12" v-if="!deliveryAddress.name">Brak nazwy</span>

                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.street">{{deliveryAddress.street}}</span>
                    <span class="col-sm-12" v-else>Brak ulicy</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.postCode && deliveryAddress.city">
                        {{deliveryAddress.postCode}} {{deliveryAddress.city}}
                        <span v-if ="deliveryAddress.countryCode">({{deliveryAddress.countryCode}})</span>
                    </span>
                    <span class="col-sm-12" v-else>Brak miasta</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.phone">{{deliveryAddress.phone}}</span>
                    <span class="col-sm-12" v-else>Brak telefonu</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.email">{{deliveryAddress.email}}</span>
                    <span class="col-sm-12" v-else>Brak email</span>
                </div>
                <div class="row m-b-xs">
                    <span class="col-sm-12" v-if="deliveryAddress.contactPerson">
                        <small class="primary-color ">Osoba do kontaktu:</small> {{deliveryAddress.contactPerson}}</span>
                    <span class="col-md-12" v-else>Brak osoby do kontaktu</span>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import EditDelvieryCustomerModal from '../Customers/EditDelvieryCustomerModal.vue'

export default {
    name: 'Customer',
    props: {
        order: Object
    },
    data: function () {
        return {
            selected: [],
            deliveryTypes: ['kurier', 'paczkomaty', 'paleta', 'dowoz', 'brak', 'odbior osobisty', 'kurier klienta'],
            deliveryCompanies: ["dpd", "dhl", "fedex", "rhenus", "inpost", "geis", "poczta polska", "suus"]
        }
    },
    created: function() {
        if (this.order.deliveryAddressId && this.order.deliveryAddressId.length > 0) this.selected.push(this.order.deliveryAddressId);
    },
    computed: {
        isEmpty: function() {
            return this.order.customer.deliveryAddresses.length == 0;
        }
    },
    methods: {
        setDeliverAddress: function(addressId) {
            if (this.order.deliveryAddressId == addressId) addressId = null;
            Api.order.setDeliverAddress(this.order._id, addressId).then((order) => {
                this.order.deliveryAddressId = addressId;
                this.selected.length = 0;
                this.selected.push(addressId);
                this.$notify({ group: 'global', type: 'notification-success', text: 'Zapisano adres dostawy dla zlecenia.' });
            });
        },

        saveDelivery: async function() {
            try {
                await Api.order.save(this.order._id, { deliveryType: this.order.deliveryType, deliveryCompany: this.order.deliveryCompany });
                this.$helpers.success("Pomyślnie zmieniono preferowany rodzaj dostawy");
            } catch (e) {
                this.$helpers.errorNotif();
            }
        },

        addDeliveryAddress: function() {
            this.$modal.show(EditDelvieryCustomerModal, {
                customer: this.order.customer
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        }
    }
}
</script>
