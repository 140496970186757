<template>
    <div class="m-t">
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Odbiorca</label>
            <div class="col-sm-8">
                <select2 :options="formShiping" class="form-control" v-model="isPrivateShipping"/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Typ paczki</label>
            <div class="col-sm-8">
                <select2 :options="parcelTypeOpt" class="form-control" v-model="package.type"/>
            </div>
        </div>

        <strong>Kartony w przesyłce</strong>
        <div class="form-group row" v-for="(paczka, index) in parcels" :key="index">
            <label class="col-sm-2 form-control-label">#{{index}} </label>
            <div class="col-sm-3 input-group">
                <input type="text" class="form-control" v-model="paczka.description" placeholder="Opis">
                <span class="input-group-addon">opis</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.width" placeholder="Wys [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.depth" placeholder="Szer [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.height" placeholder="Głębo [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.weight" placeholder="kg">
                <span class="input-group-addon text-sm p-a-xs">kg</span>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn white" tabindex="-1" @click="parcels.splice(index, 1)">Usuń</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Dodaj karton</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(20,20,20,10)">+ 20x20x20, 10kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(30,30,30,20)">+ 30x30x30, 20kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(60,60,60,30)">+ 60x60x60, 30 kg</button>
            </div>
            <div class="col-sm-2"/>
        </div>

        <!-- <div class="form-group row" v-if="order.boxes && order.boxes.length">
            <label class="col-sm-2 form-control-label">Pobierz paczki z zamówienia</label>
            <div class="col-sm-2">
                <button type="button" class="btn white" @click="getOrderBoxes">Pobierz ({{ order.boxes.length }})</button>
            </div>
        </div> -->

        <hr>

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Zawartość</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="parcelInfo.description" placeholder="Zawartość">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Numer tel. nadawcy</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="senderPhone" placeholder="Telefon">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Kod referencyjny</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="package.user_reference_number" placeholder="Kod referencyjny">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Wartość</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="parcelInfo.value" placeholder="Opcjonalnie">
            </div>
        </div>
        <!-- <div class="form-group row">
    <label class="col-sm-3 form-control-label">Nr dokumentu sprzedaży</label>
    <div class="col-sm-8">
      <input type="text" class="form-control" v-model="parcelInfo.userReferenceNumber" placeholder="Opcjonalnie">
    </div>
  </div> -->

        <div class="form-group row" v-if="isPickup">
            <label class="col-sm-3 form-control-label">Punkt odbioru</label>
            <div class="col-sm-3">
                <v-select v-model="pickupType" :options="pickupTypeOpt" :reduce="op => op.code" @input="clearPickupPoints" />
            </div>
            <div class="col-sm-5">
                <v-select label="name" v-model="selectedPickupPoint" :filterable="false" :options="pickupPoints" @search="onSearchPickup">
                    <template slot="no-options">
                        zacznij pisać, żeby zacząć szukać punktu odbioru
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.code }}<br>
                            <span class="text-muted text-sm">{{option.type}} {{option.description}}</span>
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="d-center">
                            {{ option.code }}
                        </div>
                    </template>
                </v-select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input v-model="isPickup" type="checkbox" class="">
                        <i class="blue"/> Odbiór w punkcie</label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="isCod" type="checkbox" class="">
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <!--  <p>
        <label class="md-check">
          <input v-model="isDop" v-on:change="toggleDop" type="checkbox" class="">
          <i class="blue"></i> Planowana data nadania</label>
      </p> -->
                <p>
                    <label class="md-check">
                        <input v-model="rod" type="checkbox" class="">
                        <i class="blue"/> Dokumenty zwrotne
                    </label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="fragile" type="checkbox">
                        <i class="blue"/> Ostrożnie (PocztaPolska)
                    </label>
                </p>
                <p>
                    <label class="md-check">
                        <input v-model="isGuaranteeTime" type="checkbox" class="">
                        <i class="blue"/> Gwarantowane doręcznie
                    </label>
                    <span v-if="isGuaranteeTime">
                        <p class="m-t" >
                            <label class="md-check">
                                <input v-model="guaranteeTime" type="radio" name="gtime" value="saturday_delivery">
                                <i class="primary"/> Doręcznie w sobotę</label>
                        </p>
                        <p>
                            <label class="md-check"><input v-model="guaranteeTime" type="radio" name="gtime" value="guarantee_0930">
                                <i class="primary"/> Doręcznie do 9:30</label>
                        </p>
                        <p>
                            <label class="md-check"><input v-model="guaranteeTime" type="radio" name="gtime" value="guarantee_1200">
                                <i class="primary"/> Doręcznie 12:00</label>
                        </p>
                    </span>
                </p>
            </div>
        </div>

        <!-- <div class="form-group row ng-scope" v-if="isDop">
    <label class="col-sm-3 form-control-label">Planowania data nadania</label>
    <div class="col-sm-4">

    </div>
    <div class="col-sm-2">

    </div>
    <div class="col-sm-2">

    </div>
  </div> -->

        <div class="form-group row ng-scope" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.amount" @change="parsePrice" placeholder="Kwota pobrania [zl]">
            </div>
        </div>

        <div class="form-group row" v-if="isCod">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Numer konta bankowego do pobrań</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.iban" placeholder="Numer konta bankowego do pobrań">
            </div>
        </div>
        <div class="form-group row" v-if="isCod">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Właściciel rachunku bankowego</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" v-model="cod.name" placeholder="Imię i nazwisko">
            </div>
        </div>

        <div class="form-group row" v-if="carriers.length > 0">
            <label for="inputEmail3" class="col-sm-3 form-control-label">Dostępni przewoźnicy</label>
            <div class="col-sm-8">
                <p class="m-t" v-for="c in carriers" :key="c.service_id">
                    <label class="md-check">
                        <input v-model="selectedCarrier" type="radio" :value="c.service_id">
                        <i class="primary"/> {{c.service}} - {{c.pricing.price_gross}}zł
                    </label>
                </p>
            </div>
        </div>
        <p class="text-red m-t-lg text-center" v-if="isEmpty">Nie znaleziono dostępnych przewoźników</p>
        <Spinner v-if="isProcessing"/>
        <div class="row">
            <a href="#" class="btn primary none-radius m-t pull-right m-r" @click.prevent="estimate" :class="{disabled: isProcessing}">Wyceń</a>
            <a v-if="carriers.length > 0" href="#" class="btn info none-radius m-t m-l" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>

        </div>
    </div>
</template>
<script>
import Select2 from '../../Select2.vue';
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';

const OP = require('object-path');

export default {
    name: 'CarrierFurgonetka',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Select2,
        Spinner

    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            isGuaranteeTime: false,
            isCod: false,
            parcels: [],
            parcelInfo: { width: null, depth: null, height: null, weight: null, value: 1000, description: "Filtry powietrza" },
            package: { type: "package", parcels: [], user_reference_number: this.order.invoiceNo },
            isPrivateShipping: 0,
            formShiping: [{ id: 1, text: "Osoba prywantna" }, { id: 0, text: "Firma" }],
            parcelTypeOpt: [{ id: "package", text: "Paczka" }, { id: "dox", text: "Koperta" }, { id: "pallet", text: "Paleta" }],
            wrappingOpt: [{ id: 0, text: 'Karton' }, { id: 1, text: "Kontener metalowy" }, { id: 2, text: "Kontener drewniany" }, { id: 3, text: "Folia" }, { id: 4, text: "Guma" }, { id: 5, text: "Stretch" }, { id: 6, text: "Tekura falista" }, { id: 7, text: "Inne" }],
            wrapping: 0,
            shapeOpt: [{ id: 0, text: "O regularnym kształcie" }, { id: 1, text: "O nieregularnym kształcie, przedmioty cylindryczne" }],
            shape: 0,
            isPickup: false,
            pickupTypeOpt: [{ code: "inpost", label: "Paczkomat InPost" }, { code: "ruch", label: "Kiosk RUCHu" }, { code: "poczta", label: "Placówka PP" }],
            pickupType: "",
            isDop: false,
            cod: { amount: 0, iban: "", name: "" },
            rod: false,
            guaranteeTime: "",
            fragile: false,
            carriers: [],
            selectedCarrier: "",
            dataToSave: {},
            isEmpty: false,
            pickupLocation: "",
            pickupPoints: [],
            selectedPickupPoint: {},
            isSearchPickup: false,
            senderPhone: null
        }
    },
    created: async function() {
        this.pickupLocation = this.recipient.postCode + " " + this.recipient.city;
        if (this.order.invoiceNo && this.order.invoiceNo.length > 0 && this.order.clientNo && this.order.clientNo.length > 0) {
            this.parcelInfo.description = "Filtry powietrza - numer zamówienia " + this.order.clientNo;
        }
        if (!this.order.invoiceNo || (this.order.invoiceNo.length == 0 && this.order.clientNo && this.order.clientNo.length > 0)) {
            this.package.user_reference_number = this.order.clientNo;
            this.parcelInfo.description = "Filtry powietrza";
        }
        this.cod.iban = this.sender.iban;
        this.cod.name = this.sender.company_name;

        this.addNewParcel(20, 20, 20, 5);

        const shipmentConf = await Api.shipments.getCofiguration();

        this.senderPhone = this.sender.phone.replace(/\s/g, '');
        if (shipmentConf.furgonetka && shipmentConf.furgonetka.phone) {
            this.senderPhone = shipmentConf.furgonetka.phone.replace(/\s/g, '');
        }

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets()
    },

    methods: {
        addNewParcel: function(height, width, depth, weight) {
            this.parcels.push({
                height: height,
                width: width,
                depth: depth,
                weight: weight,
                description: "Karton #" + (this.parcels.length + 1)
            });
        },

        getOrderBoxes: function() {
            if (!this.order || !this.order.boxes || !this.order.boxes.length) { return this.$helpers.errorNotif("Brak spakowanych kartonów w zamówieniu"); }

            this.parcels.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                this.parcels.push({
                    height: Math.round(box.height / 10),
                    width: Math.round(box.width / 10),
                    depth: Math.round(box.length / 10),
                    weight: box.mass,
                    description: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        },

        clearPickupPoints: function() {
            // console.log("czyscimy", this.pickupType);
            this.selectedPickupPoint = { code: "" };
            if (this.pickupType == 'inpost' && this.order.customer.pickupPoints && this.order.customer.pickupPoints.inpost) {
                this.selectedPickupPoint.code = this.order.customer.pickupPoints.inpost;
            }
            this.pickupPoints = [];
        },
        onSearchPickup: async function(search, loading) {
            if (!search || search.length < 4) return;
            loading(true);
            const res = await Api.shipments.searchPointsFurgonetka({
                location: {
                    points_max_distance: 5.1,
                    search_phrase: search
                },
                filters: {
                    services: [this.pickupType],
                    point_types: ["cod_only", "no_orlen"]
                }
            });
            this.pickupPoints = res.points || [];
            loading(false);
        },

        parsePrice() { this.cod.amount = this.cod.amount.replace(/,/g, '.'); },
        estimate: async function() {
            this.isProcessing = true;
            this.carriers = [];
            this.isEmpty = false;
            this.dataToSave = {};
            const data = this.setData();

            try {
                const options = await Api.shipments.checkpriceFurgonetka(data);
                for (var i = 0, l = options.length; i < l; i++) {
                    if (options[i].available) this.carriers.push(options[i]);
                }
                this.isProcessing = false;
                this.dataToSave = data;
                // console.log("this.dataToSave", this.dataToSave)
                if (this.carriers.length == 0) this.isEmpty = true;
            } catch (e) {
                console.log("errror", e);
                this.isProcessing = false;
            }
        },
        save: async function() {
            this.dataToSave.package.service_id = this.selectedCarrier;
            this.dataToSave.qty = 1;
            this.dataToSave.order = this.order._id;

            if (this.isPickup && this.pickupType == "inpost") {
                // chwilow zmiana paczkomatu na inny bo stary sie popsuł???
                // this.dataToSave.package.pickup.point = "SRD06M";
                this.dataToSave.package.pickup.point = "SRD03A";
                // this.dataToSave.package.sender.point = "SRD06M";
                // this.dataToSave.package.sender.point = "SRD06M";
                delete this.dataToSave.package.sender;
            }
            try {
                const shipments = await Api.shipments.furgonetkaAdd(this.dataToSave);
                this.$h.success('Dodano przesyłkę');
                this.carriers = [];
                this.isEmpty = false;
                this.dataToSave = {};
                // jeszcze do konca nie wiem czemu metoda sent zwraca tablice
                for (const shipment of shipments) this.$emit('shipment', shipment);
            } catch (e) {
                if (OP.has(e, "response.data.error.response.body.errors")) {
                    const array = e.response.data.error.response.body.errors;
                    for (let index = 0; index < array.length; index++) {
                        const path = array[index].path;
                        const msg = array[index].message;
                        this.$notify({ group: 'global', type: 'notification-error', text: "Error: " + path + " - " + msg });
                    }
                } else {
                    this.$h.commonApiError(e, true);
                }
            }
            this.isProcessing = false;
        },
        setData: function() {
            this.package.parcels.length = 0;
            for (let i = 0; i < this.parcels.length; i++) {
                const parcel = this.parcels[i];
                parcel.value = this.parcelInfo.value
                this.package.parcels.push(parcel);
            }
            this.package.pickup = this.setSender();
            this.package.receiver = this.setReceiver();
            if (this.isPickup && this.selectedPickupPoint) this.package.receiver.point = this.selectedPickupPoint.code;
            this.package.sender = this.setSender();

            this.package.additional_services = {};
            this.package.description = this.parcelInfo.description;
            if (this.isCod && this.cod.amount > 0) this.package.additional_services.cod = this.cod;
            if (this.rod) this.package.additional_services.rod = true;
            if (this.isPrivateShipping == 1) this.package.additional_services.private_shipping = true;
            if (this.isGuaranteeTime && this.guaranteeTime.length > 0) this.package.additional_services[this.guaranteeTime] = true;
            if (this.fragile) this.package.additional_services.fragile = true;

            return { package: this.package };
        },
        setSender: function() {
            const s = {
                name: this.sender.firstname + " " + this.sender.lastname,
                company: this.sender.company_name,
                street: this.sender.street,
                postcode: this.sender.postcode,
                city: this.sender.city,
                country_code: "PL",
                email: this.sender.email,
                phone: this.senderPhone
            };

            return s;
        },
        setReceiver: function() {
            const r = {
                company: this.recipient.name,
                name: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
                street: this.recipient.street,
                postcode: this.recipient.postCode,
                city: this.recipient.city,
                country_code: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                email: this.recipient.email ? this.recipient.email : "",
                phone: this.recipient.phone.replace(/\s/g, '')
            };

            return r;
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.parcels.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.parcels.push({
                    height: Math.round(box.height / 10),
                    width: Math.round(box.width / 10),
                    depth: Math.round(box.length / 10),
                    weight: box.mass,
                    description: "#" + (i + 1) + " KOD: " + box.code.toUpperCase()
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
  .app .select2-container--default .select2-selection--single{
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
