<template>
    <div class="m-b">
        <small class="m-l text-muted">Sortuj po:</small>
        <div class="btn-group dropdown">
            <button class="btn dropdown-toggle btn-sm custom-dropdown primary-color" data-toggle="dropdown" aria-expanded="false">
                {{sortElement}}:</button>
            <div class="dropdown-menu dropdown-menu-scale">
                <a class="dropdown-item" href="#" @click.prevent="sortBy({'_id': -1})">Data (od najnowszych)</a>
                <a class="dropdown-item" href="#" @click.prevent="sortBy({'_id': 1})">Data (od najstarszych)</a>
            </div>
        </div>
        <small class="m-l text-muted">Pasuje do filtrów:</small>
        <span class="c-blue text-white m-l-sm none-radius label">
            {{total}}
        </span>
        <!-- szukaj numeru przesyłki, odboircy, zawartosci -->
        <form class="navbar-form form-inline pull-right pull-none-sm v-m" role="search">
            <div class="form-group l-h m-a-0">
                <div class="input-group search-input">
                    <input class="form-control form-control-sm p-x b-a" placeholder="Szukaj..." type="text" v-model="value.query" @input="onFilterChange">
                    <a class="close-btn" href="#" @click.prevent="clear"><i class="material-icons md-16">close</i></a>
                </div>
            </div>
        </form>
    </div>
</template>
<script>

var timeoutHandler = null;

export default {
    name: 'SortList',
    props: {
        value: Object
    },
    data: function () {
        return {
            sortElement: 'Data (od najnowszych)',
            query: '',
            total: ''
        }
    },
    created: function() {

    },
    destroyed: function() {

    },
    methods: {
        changeTotal: function(total) {
            this.total = total;
        },
        sortBy: function(sortElement) {
            if (sortElement._id == 1) {
                this.sortElement = 'Data (od najstarszych)'
            } else if (sortElement._id == -1) {
                this.sortElement = 'Data (od najnowszych)'
            } else if (sortElement.status == 1) {
                this.sortElement = 'Statusach'
            }
        },
        onFilterChange: function() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(() => {
                this.$emit('input', this.value);
                this.$emit('change', this.value);
            }, 1000);
        },
        clear: function() {
            if (this.value) {
                this.value.query = '';
                this.onFilterChange();
            }
        }
    }
}
</script>
