import axios from 'axios';
import config from './config.js';

class Api {
    constructor() {
        this.baseUrl = config.apiProtocol + '://' + config.apiUrl + '/';
        this.token = null;
        this.axiosConfig = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        };

        this.getFileUrl = (key, name) => {
            return this.baseUrl + 'files/' + key + '/' + name;
        }

        this.uploadFile = (file, progresCallback) => {
            return axios.post(this.baseUrl + 'files', file, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: this.axiosConfig.headers.Authorization
                },
                onUploadProgress: progresCallback
            })
                .then((response) => {
                    return response.data;
                })
                .catch(error => {
                    throw error.response;
                });
        }

        this.mailer = {
            template: {
                get: (code) => this.callCommonEndpoint(`mailer/templates/${code}`),
                save: (code, data) => this.callCommonEndpoint(`mailer/templates/${code}`, 'put', data)
            }
        }

        this.spreadsheet = {
            get: (id) => this.callCommonEndpoint('raports/spreadsheets/' + id, 'get'),
            search: (filters = {}) => this.callCommonEndpoint('raports/spreadsheets/search', 'post', filters),
            create: (data = {}) => this.callCommonEndpoint('raports/spreadsheets/', 'post', data),
            save: (id, data) => this.callCommonEndpoint('raports/spreadsheets/' + id, 'put', data)
        }

        this.raports = {
            getProductsSummary: (agregacja, dateOne, dateTwo) => { return this.callCommonEndpoint('stats/productsSummary/' + agregacja + '/' + dateOne + '/' + dateTwo) },
            getYearlyProductionSummary: () => { return this.callCommonEndpoint('stats/yearlyProductionSummary') },
            getCurrenlyInProduction: () => { return this.callCommonEndpoint('stats/currenlyInProduction') },
            getTodaySummary: () => { return this.callCommonEndpoint('stats/todaySummary') },
            searchMadeProductsFromWorklogs: () => { return this.callCommonEndpoint('production/summary/work-day-summary-products') },
            searchMadeProductsFromWorklogsV3: () => { return this.callCommonEndpoint('production/summary/work-day-summary-products-v3') },
            getEmpMonthlyRaport: (data) => {
                let l = this.baseUrl + 'production/raports/empMonthlyRaport/?token=' + this.token;
                if (data.empId) l += '&empId=' + data.empId;
                if (typeof data.month != 'undefined') l += '&month=' + data.month;
                if (data.year) l += '&year=' + data.year;
                return l;
            },
            getworkingTimeReportMonthlyRaport: (data) => {
                let l = this.baseUrl + 'employee-raports/workingTimeReport/?token=' + this.token;
                if (typeof data.month != 'undefined') l += '&month=' + data.month;
                if (data.year) l += '&year=' + data.year;
                return l;
            },

            generateWorkingTimeReportMonthlyRaport: (data) => { return this.downloadAsBlob('employee-raports/workingTimeReport', 'post', data); },
            generateSummaryEmployeesMonthlyRaport: (data) => { return this.downloadAsBlob('employee-raports/summaryEmployeesMonthlyRaport', 'post', data); },
            getnerateFreeDaysMonthlyRaport: (data) => { return this.downloadAsBlob('employee-raports/generateFreeDaysMonthlyRaport', 'post', data); },

            getSummaryEmployeesMonthlyRaport: (data) => {
                let l = this.baseUrl + 'employee-raports/summaryEmployeesMonthlyRaport/?token=' + this.token;
                if (typeof data.month != 'undefined') l += '&month=' + data.month;
                if (data.year) l += '&year=' + data.year;
                return l;
            },
            getProductProductionRaport: (data) => {
                let l = this.baseUrl + 'production/raports/productProductionRaport.csv?token=' + this.token;
                if (data.productType) l += '&productType=' + data.productType;
                if (data.feature) l += '&feature=' + data.feature;
                if (data.range) l += '&range=' + data.range;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                return l;
            },
            getPalletAndBoxesRaport: (data) => {
                let l = this.baseUrl + 'stats/raport.csv?token=' + this.token;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                return l;
            },
            getZPTimeRealizationRaport: (data) => {
                let l = this.baseUrl + 'production/raports/ZPTimeRealization.csv?token=' + this.token;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                if (data.compareTo) l += '&compareTo=' + data.compareTo;
                return l;
            },
            getZPProductionTimeRaport: (data) => {
                let l = this.baseUrl + 'production/raports/ZPProductionTimeRaport.csv?token=' + this.token;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                if (data.getZONames) l += '&getZONames=' + true;
                return l;
            },
            getShipmentsRaport: (data) => {
                let l = this.baseUrl + 'stats/shipmentsRaport.csv?token=' + this.token;
                if (data.range) l += '&range=' + data.range;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                return l;
            }

        }

        this.printing = {
            prepare: (templateId, data) => { return this.callCommonEndpoint('production/print/prepare', 'post', { templateId, data }) },
            getPreparedPrintUrl: (printKey) => { return this.baseUrl + 'production/print/prepared/' + printKey + '?token=' + this.token; },
            templates: {
                search: (data) => { return this.callCommonEndpoint('production/print/template/search', 'post', data) },
                get: (tplId) => { return this.callCommonEndpoint('production/print/template/' + tplId) },
                edit: (tplId, data) => { return this.callCommonEndpoint('production/print/template/' + tplId, 'put', data) },
                create: (data) => { return this.callCommonEndpoint('production/print/template', 'post', data) },
                seveOrderTemplates: (orderId, data) => { return this.callCommonEndpoint('orders/' + orderId + '/printTemplate', 'put', data) }
            }
        }

        this.summary = {
            getCompletedProductTypesPerDay: (data) => { return this.callCommonEndpoint('production/summary/completedProductTypesPerDay_agregation', 'post', data) },
            getCompletedPRsProductTypePerEmployee: (data) => { return this.callCommonEndpoint('production/summary/completedPRsProductTypePerEmployee_agregation', 'post', data) },
            completedProductTypesForEmployee: (data) => { return this.callCommonEndpoint('production/summary/completedProductTypesForEmployee_agregation', 'post', data) },
            productionTimePerProductType: (data) => { return this.callCommonEndpoint('production/summary/productionTimePerProductType_agregation', 'post', data) },
            getProductionProductTypesV3: (data) => { return this.callCommonEndpoint('production/summary/productionProductTypes_agregation', 'post', data) },
            getProductionProductTypes: (data) => { return this.callCommonEndpoint('production/summary/production-product-types', 'post', data) },
            getCompletedTasksPerDay: (data) => { return this.callCommonEndpoint('production/summary/completedTasksPerDay', 'post', data) },
            getCompletedTasksPerEmployee: (data) => { return this.callCommonEndpoint('production/summary/completedTasksPerEmployee', 'post', data) },
            completedTasksForEmployee: (data) => { return this.callCommonEndpoint('production/summary/completedTasksForEmployee', 'post', data) },
            productionTimePerTask: (data) => { return this.callCommonEndpoint('production/summary/productionTimePerTask', 'post', data) }

            // statystyki po orderze
            // getProductionForProductTypes: (data) => { return this.callCommonEndpoint('stats/productionProductTypes', 'post', data) }
        }

        this.device = {
            search: (data) => { return this.callCommonEndpointWithTotal('devices/search', 'post', data) },
            getAll: () => { return this.callCommonEndpoint('devices/all') },
            edit: (deviceId, data) => { return this.callCommonEndpoint('devices/' + deviceId, 'put', data) }
        }

        this.user = {
            verifyUser: (token) => { return this.callCommonEndpoint('auth/verify', 'post', { token: token }) },
            logIn: (email, pass) => { return this.callCommonEndpoint('auth/signin', 'post', { user: email, pass: pass }) },
            logOut: () => { return this.callCommonEndpoint('auth/signout', 'post', {}) },

            resetPassword: (email) => { return this.callCommonEndpoint('users/reset-password', 'post', { email: email }) },
            resetPasswordConfirm: (code) => { return this.callCommonEndpoint('users/reset-password-confirm', 'post', { code: code }) },
            get: () => { return this.callCommonEndpoint('users/all') },
            getOne: (id) => { return this.callCommonEndpoint('users/' + id) },
            getUsersHisory: (filters) => { return this.callCommonEndpointWithTotal('users/history/search', 'post', filters) },
            getUserLoginRaport: (data) => { return this.callCommonEndpoint('users/login-history', 'post', data) },

            register: (emp) => { return this.callCommonEndpoint('users/register', 'post', { email: emp.email, name: emp.name, password: emp.password, roles: emp.roles }) },
            remove: (id) => { return this.callCommonEndpoint('users/' + id, 'put', { isRemoved: true }) },
            edit: (emp) => { return this.callCommonEndpoint('users/' + emp._id, 'put', emp) },
            putInStorage: (key, value) => { return this.callCommonEndpoint('storage/', 'post', { key: key, value: value }) },
            getFromStorage: (key) => { return this.callCommonEndpoint('storage/' + key) },
            getNotifications: () => { return this.callCommonEndpoint('notifications/not-seen') },
            setNotificationAsSeen: (notifId) => { return this.callCommonEndpoint('notifications/setSeen/' + notifId, 'post') },
            setManyNotificationsAsSeen: (where) => { return this.callCommonEndpoint('notifications/setSeenMany', 'post', where) }
        }

        this.emp = {
            get: (id) => { return this.callCommonEndpoint('employees/' + id) },
            getEmployees: () => { return this.callCommonEndpoint('employees/all') },
            searchWithTotal: (filters) => this.callCommonEndpointWithTotal('employees/search', 'post', filters),
            register: (emp) => { return this.callCommonEndpoint('employees/register', 'post', { name: emp.name, pin: emp.pin, workPlaces: emp.workPlaces, workingHours: { from: emp.workingHours.from, to: emp.workingHours.to } }) },
            remove: (id) => { return this.callCommonEndpoint('employees/' + id, 'put', { isRemoved: true }) },
            edit: (emp) => { return this.callCommonEndpoint('employees/' + emp._id, 'put', emp) },
            findWorkingStatus: (data) => { return this.callCommonEndpoint('production/employee/findWorkingStatus/', 'post', data) },

            getAplicationsRapots: (data) => {
                let l = this.baseUrl + 'employee-raports/getApplicationFreeDaysRaport/?token=' + this.token;
                if (data.empId) l += '&empId=' + data.empId;
                if (typeof data.from != 'undefined') l += '&from=' + data.from;
                if (data.to) l += '&to=' + data.to;
                return l;
            },
            getAplicationsFreeDaysForEmpRapots: (data) => {
                let l = this.baseUrl + 'employee-raports/getApplicationFreeDaysV3Raport/?token=' + this.token;
                if (data.empId) l += '&empId=' + data.empId;
                if (typeof data.from != 'undefined') l += '&from=' + data.from;
                if (data.to) l += '&to=' + data.to;
                return l;
            },
            getFreeDaysMonthlyRaport: (data) => {
                let l = this.baseUrl + 'employee-raports/getFreeDaysMonthlyRaport/?token=' + this.token;
                if (typeof data.month != 'undefined') l += '&month=' + data.month;
                if (data.year) l += '&year=' + data.year;
                return l;
            }
        }

        this.timer = {
            search: (data) => { return this.callCommonEndpoint('employee-timer/search', 'post', data) },
            getEmployeeTimer: (employeeId) => { return this.callCommonEndpoint('employee-timer/' + employeeId) },
            createTimer: (id) => { return this.callCommonEndpoint('employee-timer/', 'post', { employeeId: id }) },
            coffeBreakStart: (id) => { return this.callCommonEndpoint('employee-timer/coffe-break-start', 'put', { employeeId: id }) },
            coffeBreakEnd: (id) => { return this.callCommonEndpoint('employee-timer/coffe-break-end', 'put', { employeeId: id }) },
            stopTimer: (id) => { return this.callCommonEndpoint('employee-timer/stop-timer', 'put', { employeeId: id }) }
        }

        this.order = {
            getOrders: (filters) => this.callCommonEndpointWithTotal('orders/search', 'post', filters),
            search: (data) => { return this.callCommonEndpoint('orders/search', 'post', data) },
            getOrder: (id) => { return this.callCommonEndpoint('orders/' + id) },

            getConfig: () => { return this.callCommonEndpoint('orders/configuration') },
            setConfig: (conf) => { return this.callCommonEndpoint('orders/configuration', 'post', conf) },

            remove: (id) => { return this.callCommonEndpoint('orders/' + id, 'delete') },
            setTags: (id, tags) => { return this.callCommonEndpoint('orders/' + id, 'put', { tags }) },
            add: () => { return this.callCommonEndpoint('orders/', 'post') },
            addNote: (id, data) => { return this.callCommonEndpoint('orders/' + id + '/notes', 'post', data) },
            removeNote: (id, idNote) => { return this.callCommonEndpoint('orders/' + id + '/notes/' + idNote, 'delete') },
            attachFile: (id, key, name) => { return this.callCommonEndpoint('orders/' + id + '/attachments', 'post', { key: key, name: name }) },
            removeAttachment: (id, idAttach) => { return this.callCommonEndpoint('orders/' + id + '/attachments/' + idAttach, 'delete') },
            addProduct: (id, data) => { return this.callCommonEndpoint('orders/' + id + '/cart', 'post', data) },
            removeProduct: (id, idPosition) => { return this.callCommonEndpoint('orders/' + id + '/cart/' + idPosition, 'delete') },
            editCarPos: (id, idPosition, data) => { return this.callCommonEndpoint('orders/' + id + '/cart/' + idPosition, 'put', data) },
            massEditCart: (id, data) => { return this.callCommonEndpoint('orders/' + id + '/cart/mass-edit/', 'put', data) },
            editCarPosSerialNumbers: (id, idPosition, data) => { return this.callCommonEndpoint('orders/' + id + '/cart/' + idPosition + '/serial-numbers', 'put', data) },
            setCustomer: (id, idCustomer) => { return this.callCommonEndpoint('orders/' + id, 'put', { customer: idCustomer }) },
            setDeliverAddress: (id, addressId) => { return this.callCommonEndpoint('orders/' + id, 'put', { deliveryAddressId: addressId }) },
            setName: (id, name) => { return this.callCommonEndpoint('orders/' + id, 'put', { name: name }) },
            setStatus: (id, status) => { return this.callCommonEndpoint('orders/' + id, 'put', { status: status }) },
            save: (id, data) => { return this.callCommonEndpoint('orders/' + id, 'put', data) },
            massConfirm: () => { return this.callCommonEndpoint('orders/mass-confirm', 'post', {}) },

            recalculateProgress: (id) => { return this.callCommonEndpoint('orders/' + id + '/recalculateProgress', 'post', {}) },
            getAvailableTags: () => { return this.callCommonEndpoint('orders/availableTags') },

            addBox: (orderId) => { return this.callCommonEndpoint('orders/' + orderId + '/box/', 'post', {}) },
            removeBox: (orderId, boxCode) => { return this.callCommonEndpoint('orders/' + orderId + '/box/' + boxCode, 'delete', {}) },
            editBox: (id, boxCode, data) => { return this.callCommonEndpoint('orders/' + id + '/box/' + boxCode, 'put', data) },
            editPalette: (id, paletteId, data) => { return this.callCommonEndpoint('orders/' + id + '/paletes/' + paletteId, 'put', data) }
        }

        this.cart = {
            completePos: (cartItemId) => { return this.callCommonEndpoint('production/tasks/complete/byCartItemId/' + cartItemId, 'post', {}) },
            changeStatusPos: (cartItemId, data) => { return this.callCommonEndpoint('production/tasks/changeStatus/byCartItemId/' + cartItemId, 'post', data) }
        }

        this.wFirma = {
            getByOrderId: (id) => { return this.callCommonEndpoint('wfirma/invoices/byOrderId/' + id) },
            getFileUrl: (id) => { return this.baseUrl + 'wfirma/invoices/print/' + id + '?token=' + this.token; },
            set: (data) => { return this.callCommonEndpoint('wfirma/configuration', 'put', data) },
            get: () => { return this.callCommonEndpoint('wfirma/configuration') },
            getGoodByProduct: (id) => { return this.callCommonEndpoint('wfirma/goods/byProductId/' + id) },
            searchStockOrders: () => { return this.callCommonEndpoint('wfirma/stock-orders/search', 'post', {}) },
            importStockOrder: (stockOrderId) => { return this.callCommonEndpoint('wfirma/stock-orders/import/' + stockOrderId, 'post', {}) }

        }

        this.customer = {
            get: (id) => { return this.callCommonEndpoint('customers/' + id) },
            getCustomers: (query) => { return this.callCommonEndpoint('customers/search', 'post', { query: query }) },
            edit: (id, data) => { return this.callCommonEndpoint('customers/' + id, 'put', data) },
            add: (data) => { return this.callCommonEndpoint('customers/', 'post', data) },
            search: (filters) => this.callCommonEndpointWithTotal('customers/search', 'post', filters)
        }

        this.shipments = {
            readShipmentLabel: (data) => {
                return this.callCommonEndpoint('shipments/read-shipment-label', 'post', data)
            },
            getRaportCsvLink: (data) => {
                let l = this.baseUrl + 'shipments/stats/raport.csv?token=' + this.token;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                if (data.agregation) l += '&agregation=' + data.agregation;
                return l;
            },
            add: (data) => { return this.callCommonEndpoint('shipments/', 'post', data) },
            getPriceRanges: () => this.callCommonEndpoint('shipments/price-ranges'),
            getCofiguration: () => { return this.callCommonEndpoint('shipments/configuration') },
            set: (data) => { return this.callCommonEndpoint('shipments/configuration', 'post', data) },
            sendEmailNotif: (shipmentIds) => { return this.callCommonEndpoint('shipments/notifyByEmail', 'post', { shipments: shipmentIds }) },
            handedOverByUser: (shipmentIds) => { return this.callCommonEndpoint('shipments/handedOverByUser', 'post', { shipments: shipmentIds }) },
            ppSent: (data) => { return this.callCommonEndpoint('shipments/poczta-polska/send', 'post', data) },
            ppSendEnvelope: () => { return this.callCommonEndpoint('shipments/poczta-polska/send-envelope') },
            dpdSent: (data) => { return this.callCommonEndpoint('shipments/dpd/send', 'post', data) },
            suusSent: (data) => { return this.callCommonEndpoint('shipments/suus/send', 'post', data) },
            rhenusSent: (data) => { return this.callCommonEndpoint('shipments/rhenus/send', 'post', data) },
            geisSent: (data) => { return this.callCommonEndpoint('shipments/geis/send', 'post', data) },
            geisCreatePickUp: (data) => { return this.callCommonEndpoint('shipments/geis/create-pickup', 'post', data) },
            fedexSent: (data) => { return this.callCommonEndpoint('shipments/fedex/send', 'post', data) },

            marssSent: (data) => { return this.callCommonEndpoint('shipments/marss/send', 'post', data) },

            furgonetkaAdd: (data) => { return this.callCommonEndpoint('shipments/furgonetka/add-parcel', 'post', data) },
            furgonetkaSend: (shipmentIds) => { return this.callCommonEndpoint('shipments/furgonetka/send', 'post', { shipments: shipmentIds }) },
            remove: (shipmentIds) => { return this.callCommonEndpoint('shipments', 'delete', { shipments: shipmentIds }) },
            checkpriceFurgonetka: (data) => { return this.callCommonEndpoint('shipments/furgonetka/checkprice', 'post', data) },
            searchPointsFurgonetka: (data) => { return this.callCommonEndpoint('shipments/furgonetka/searchPoints', 'post', data) },
            search: (data) => { return this.callCommonEndpoint('shipments/search', 'post', data) },
            searchWithTotal: (data) => { return this.callCommonEndpointWithTotal('shipments/search', 'post', data) },
            generateWaybills: (shipments) => {
                return this.downloadAsBlob('shipments/generate/waybills', 'post', { shipments });
            },
            generateProtocols: (shipments) => {
                return this.downloadAsBlob('shipments/generate/protocols', 'post', { shipments });
            },
            setReciverEmail: (id, email) => { return this.callCommonEndpoint('shipments/' + id, 'put', { receiverEmail: email }) }
        }

        this.applicationForFreeDays = {
            searchWithTotal: (filters) => this.callCommonEndpointWithTotal('application-for-freedays/search', 'post', filters),
            change: (id, data) => { return this.callCommonEndpoint('application-for-freedays/' + id, 'put', data) }
        }

        this.mds = {
            addDeliveryPlan: (data) => this.callCommonEndpoint('mds/delivery-plan', 'post', data),
            editDeliveryPlan: (planId, data) => this.callCommonEndpoint('mds/delivery-plan/' + planId, 'put', data),
            setPlanRealized: (planId, data) => this.callCommonEndpoint('mds/delivery-plan/set-realized/' + planId, 'put', data),
            getDeliveryPlan: (planId) => this.callCommonEndpoint('mds/delivery-plan/' + planId),
            addShipment: (data) => this.callCommonEndpoint('mds/shipments', 'post', data),
            editShipment: (shipId, data) => this.callCommonEndpoint('mds/shipments/' + shipId, 'put', data),
            searchShipmentsWithTotal: (filters) => this.callCommonEndpointWithTotal('mds/shipments/search', 'post', filters),
            searchPlansWithTotal: (filters) => this.callCommonEndpointWithTotal('mds/delivery-plan/search', 'post', filters),
            getDrivers: () => { return this.callCommonEndpoint('mds/drivers/all') },
            addDriver: (data) => this.callCommonEndpoint('mds/drivers', 'post', data),
            editDriver: (driverId, data) => this.callCommonEndpoint('mds/drivers/' + driverId, 'put', data),
            getCurrentLocationForDrivers: (data) => this.callCommonEndpoint('mds/drivers/current-location', 'post', data),
            logs: {
                search: (data) => this.callCommonEndpointWithTotal('mds/driver-logs/search', 'post', data)
            }

        }

        this.system = {
            raports: {
                generateLabel: (key, data) => this.callCommonEndpoint('system/raports/' + key, 'post', data),
                getRaport: (processId) => {
                    let l = this.baseUrl + 'system/raports/'+processId+'?token=' + this.token;
                    return l;
                },
                getStatus: (processId) => this.callCommonEndpoint('system/raports/status/' + processId),
                getAllPosibleRaports: () => this.callCommonEndpoint('system/raports/all-raports', 'get'),
            },
            storage: {
                get: (key) => this.callCommonEndpoint('system/client_storage/global/' + key),
                set: (key, val) => this.callCommonEndpoint('system/client_storage/global/' + key, 'put', { value: val })
            },
            entites: {
                get: (filters = {}) => this.callCommonEndpointWithTotal('system/entites', 'get', filters),
                getOne: (entityId) => this.callCommonEndpoint('system/entites/' + entityId),
                generateHistory: (entityIds, from = null, group = 'day', method = "average") => this.callCommonEndpoint('system/entites/generateEnitiesHistory', 'post', { entityIds, from, group, method })
            },

            info: () => this.callCommonEndpoint('system/system/info')
        }

        this.systemMessages = {
            remove: (id) => this.callCommonEndpointWithTotal('sysmsg/messages/'+id, 'delete', {}),
            search: (filters) => this.callCommonEndpointWithTotal('sysmsg/messages/search', 'post', filters),
            setSeen: (id, data) => this.callCommonEndpoint('sysmsg/messages/set-seen/' + id, 'post', data),
            getUnseenCount: () => this.callCommonEndpoint('sysmsg/messages/unseen-count/')
        }

        this.employeeLogEntry = {
            edit: (id, data) => this.callCommonEndpoint('employee-logs/' + id, 'put', data),
            createForEmployee: (data) => this.callCommonEndpoint('employee-logs/createForEmployee', 'post', data),
            createCorrection: (id, data) => this.callCommonEndpoint('employee-logs/createCorrection/' + id, 'post', data),
            search: (data) => this.callCommonEndpoint('employee-logs/search', 'post', data),
            remove: (id) => this.callCommonEndpoint('employee-logs/removeLogEntry/' + id, 'delete'),
            getMonthlyRaport: (data) => {
                let l = this.baseUrl + 'employee-logs/empMonthlyRaport/?token=' + this.token;
                if (data.empId) l += '&empId=' + data.empId;
                if (typeof data.month != 'undefined') l += '&month=' + data.month;
                if (data.year) l += '&year=' + data.year;
                return l;
            }
        }
        this.productionCard = {
            search: (data) => this.callCommonEndpoint('production/production-card/search', 'post', data)
        }

        this.productionBuffer = {
            get: (productId) => this.callCommonEndpoint('production/production-buffer/' + productId),
            add: (data) => this.callCommonEndpoint('production/production-buffer', 'post', data),
            edit: (id, data) => this.callCommonEndpoint('production/production-buffer/' + id, 'put', data),
            massEdit: (data) => this.callCommonEndpoint('production/production-buffer/mass-edit', 'put', data),
            remove: (id) => this.callCommonEndpoint('production/production-buffer/' + id, 'delete'),
            searchWithTotal: (data) => this.callCommonEndpointWithTotal('production/production-buffer/search', 'post', data)
        }

        this.stock = {
            serie: {
                searchWithTotal: (filters) => this.callCommonEndpointWithTotal('stock/series/search', 'post', filters),
                create: (data) => this.callCommonEndpoint('stock/series/', 'post', data),
                edit: (id, data) => this.callCommonEndpoint('stock/series/'+id, 'put', data),
                remove: (id) => this.callCommonEndpoint('stock/series/'+id, 'delete')
            },
            searchWithTotal: (filters) => this.callCommonEndpointWithTotal('stock/search', 'post', filters),
            searchHistory: (filters) => this.callCommonEndpointWithTotal('stock/history/search', 'post', filters),
            searchStockOrder: (filters) => this.callCommonEndpointWithTotal('stock/order/search', 'post', filters),
            searchDemandProducts: (filters) => this.callCommonEndpointWithTotal('stock/demand/search', 'post', filters),
            searchBulkDemandProducts: (filters) => this.callCommonEndpointWithTotal('stock/demand/search_bulk', 'post', filters),
            addStockOrder: (data) => { return this.callCommonEndpoint('stock/order', 'post', data) },
            getDocument: (id) => { return this.callCommonEndpoint('stock/document/' + id) },
            editDocument: (id, data) => { return this.callCommonEndpoint('stock/document/' + id, 'put', data) },
            getCsvLink: (data) => {
                let l = this.baseUrl + 'stock/raport.csv?token=' + this.token;
                if (data.category) l += '&category=' + data.category;
                if (data.type) l += '&type=' + data.type;
                if (data.query) l += '&query=' + data.query;
                if (data.minStock) l += '&minStock=' + data.minStock;
                return l;
            },
            getNeededProductBilansCsvLink: (data) => {
                let l = this.baseUrl + 'stock/history/analiza.csv?token=' + this.token;
                if (data.category) l += '&category=' + data.category;
                if (data.type) l += '&type=' + data.type;
                if (data.back) l += '&back=' + data.back;
                if (data.reserve) l += '&reserve=' + data.reserve;
                if (data.stan) l += '&stan=' + data.stan;
                return l;
            },
            getDocumentBilansCsvLink: (data) => {
                let l = this.baseUrl + 'stock/history/bilans.csv?token=' + this.token;
                if (data.dateFrom) l += '&dateFrom=' + data.dateFrom;
                if (data.dateTo) l += '&dateTo=' + data.dateTo;
                if (data.category) l += '&category=' + data.category;
                if (data.type) l += '&type=' + data.type;
                if (data.agregation) l += '&agregation=' + data.agregation;
                if (data.withoutWarehouseMove) l += '&withoutWarehouseMove=' + true;
                if (data.stan) l += '&stan=' + data.stan;
                return l;
            },
            removeOrder: (id) => { return this.callCommonEndpoint('stock/order/' + id, "delete") },
            getOrder: (id) => { return this.callCommonEndpoint('stock/order/' + id) },
            removeDemandsByProductId: (data) => { return this.callCommonEndpoint('stock/demand/remove-by-products/', "delete", data) },
            removeDemand: (id) => { return this.callCommonEndpoint('stock/demand/' + id, "delete") },
            closeOrder: (id) => { return this.callCommonEndpoint('stock/order/' + id + '/close', "put") },
            editOrder: (id, data) => { return this.callCommonEndpoint('stock/order/' + id, "put", data) },

            addDemandProduct: (data) => { return this.callCommonEndpoint('stock/demand/', "post", data) },
            seveDemandProduct: (id, data) => { return this.callCommonEndpoint('stock/demand/' + id, "put", data) },

            removeDocument: (id) => { return this.callCommonEndpoint('stock/document/' + id, "delete") },
            search: (data) => { return this.callCommonEndpoint('stock/search', 'post', data) },
            income: (data) => { return this.callCommonEndpoint('stock/income', 'post', data) },
            outcome: (data) => { return this.callCommonEndpoint('stock/outcome', 'post', data) },
            addDocument: (data) => { return this.callCommonEndpoint('stock/document', 'post', data) },
            getStockPosHistory: (itemId) => this.callCommonEndpointWithTotal('stock/history/' + itemId),
            getWherhouses: () => { return this.callCommonEndpoint('stock/warehouse') },

            warehouse: {
                get: (id) => this.callCommonEndpoint('stock/warehouse/'+id),
                getMain: () => this.callCommonEndpoint('stock/warehouse/base'),
                getChildsOf: (parentId) => this.callCommonEndpoint('stock/warehouse/childOf/' + parentId),
                getParents: (id) => this.callCommonEndpoint('stock/warehouse/' + id + '/parents'),
                add: (data) => this.callCommonEndpoint('stock/warehouse', 'post', data),
                save: (id, data) => this.callCommonEndpoint('stock/warehouse/' + id, 'put', data),
                delete: (id) => this.callCommonEndpoint('stock/warehouse/' + id, 'delete'),
                getProducts: (id, filters) => this.callCommonEndpointWithTotal('stock/warehouse/' + id+"/products", 'get', filters),
            },

            searchBox: (data) => { return this.callCommonEndpoint('stock/box/search', 'post', data) },
            addBox: (data) => { return this.callCommonEndpoint('stock/box', 'post', data) },
            getBoxes: () => { return this.callCommonEndpoint('stock/box') },

            analytics: {
                forProduct: (pId, opt) => this.callCommonEndpoint("stock/analytics/forProduct/" + pId, 'get', opt),
                forProductType: (pType, opt) => this.callCommonEndpoint("stock/analytics/forProductType/" + pType, 'get', opt)
            },

            importCSVFile: (file, options) => {
                const form = new FormData();
                form.append('file', file);
                for (const key in options || {}) {
                    form.append(key, options[key]);
                }

                return axios.post(this.baseUrl + 'stock/import', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: this.axiosConfig.headers.Authorization
                    }
                }).then((response) => { return response.data; });
            }
        }

        this.semiProductCategory = {
            search: (data) => { return this.callCommonEndpoint('semi-product-category/search', 'post', data) },
            add: (data) => { return this.callCommonEndpoint('semi-product-category', 'post', data) },
            edit: (id, data) => { return this.callCommonEndpoint('semi-product-category/' + id, 'put', data) }
        }

        this.product = {
            searchWithTotal: (filters) => this.callCommonEndpointWithTotal('products/search', 'post', filters),
            search: (data) => { return this.callCommonEndpoint('products/search', 'post', data) },
            searchProductByFeatures: (data) => { return this.callCommonEndpoint('products/searchProductByFeatures', 'post', data) },
            getAvailableCategories: () => { return this.callCommonEndpoint('products/available-categories', 'get') },
            getAlternateUnits: () => { return this.callCommonEndpoint('products/alternate-units', 'get') },
            getProducts: (data) => { return this.callCommonEndpoint('products/search', 'post', data) },
            addProduct: (data) => { return this.callCommonEndpoint('products/', 'post', data) },
            addSemiProductForCategory: (semiCatId, features) => { return this.callCommonEndpoint('products/based-on-template/' + semiCatId, 'post', { features: features }) },
            modifyProduct: (data) => { return this.callCommonEndpoint('products/' + data._id, 'put', data) },
            save: (prodId, data) => this.callCommonEndpoint('products/' + prodId, 'put', data),
            massEdit: (data) => { return this.callCommonEndpoint('products/mass-edit', 'put', data) },
            getProduct: (id) => { return this.callCommonEndpoint('products/' + id) },
            getSimilarProducts: (id) => { return this.callCommonEndpoint('products/' + id + '/similarProducts') },
            setFeature: (id, fId, value, method) => { return this.callCommonEndpoint('products/' + id + '/feature/' + fId, method, { value: value }) },
            getSemiProduct: (id) => {
                return new Promise((resolve, reject) => {
                    const d = { _id: 1, name: 'Ramka 100/100', typ: 'ramki', stanowisko: 'Nazwa stanowiska', wh: '100/100', other: [], qty: 10, w: 100, h: 100 };
                    resolve(d);
                });
            },
            searchProductsFeatures: (data) => { return this.callCommonEndpointWithTotal('product-feature-types/search', 'post', data) },
            getProductsFeatures: () => { return this.callCommonEndpoint('product-feature-types/search', 'post', {}) },
            addProductsFeature: (feature) => { return this.callCommonEndpoint('product-feature-types', 'post', feature) },
            editProductsFeature: (feature) => { return this.callCommonEndpoint('product-feature-types/' + feature._id, 'put', feature) },
            deleteProductsFeature: (code) => { return this.callCommonEndpoint('product-feature-types/' + code, 'delete') },
            getSemiProductDef: () => { return this.callCommonEndpoint('products/semi/def/search', 'post', {}) },
            modifySemiProductDef: (data) => { return this.callCommonEndpoint('products/semi/def/' + data._id, 'put', data) },
            addNewSemiProductDef: (data) => { return this.callCommonEndpoint('products/semi/def/', 'post', data) },
            getStock: (id) => { return this.callCommonEndpoint('stock/' + id) },
            modifyStock: (data) => { return this.callCommonEndpoint('stock/', 'post', data) },

            types: {
                get: (code) => this.callCommonEndpoint('products/v3/product-types/' + code, 'get'),
                getAll: () => this.callCommonEndpoint('products/v3/product-types', 'get', {}),
                search: (where) => this.callCommonEndpointWithTotal('products/v3/product-types/search', 'post', where || {}),
                create: (data) => this.callCommonEndpoint('products/v3/product-types', 'post', data),
                save: (code, data) => this.callCommonEndpoint('products/v3/product-types/' + code, 'put', data),
                delete: (code) => this.callCommonEndpoint('products/v3/product-types/' + code, 'delete'),
                getAvailableEWICategories: () => { return this.callCommonEndpoint('products/v3/product-types/available-ewi-categories', 'get') }
            }
        }

        this.settings = {
            getPrintUrls: () => { return this.callCommonEndpoint('production/print/configuration') },
            setPrintUrls: (data) => { return this.callCommonEndpoint('production/print/configuration', 'post', data) }
        }

        this.production = {

            searchMaterials: (data) => { return this.callCommonEndpoint('materials/search', 'post', data) },
            addMaterials: (data) => { return this.callCommonEndpoint('materials', 'post', data) },
            editMaterials: (id, data) => { return this.callCommonEndpoint('materials/' + id, 'put', data) },
            searchWorkplaces: () => { return this.callCommonEndpoint('production/workplaces/search', 'post', {}) },
            searchDailyWorkLogs: (data) => { return this.callCommonEndpoint('production/worklogs/search', 'post', data) },
            addWorkplace: (data) => { return this.callCommonEndpoint('production/workplaces', 'post', data) },
            editWorkplace: (data) => { return this.callCommonEndpoint('production/workplaces/' + data._id, 'put', data) },
            getWorkplace: (id) => { return this.callCommonEndpoint('production/workplaces/' + id) },
            getWorkplacesProductionSatisfyRaport: () => { return this.callCommonEndpoint('production/workplaces/production-satisfy-raport') },

            searchRecipes: (data) => { return this.callCommonEndpoint('production/recipes/search', 'post', data) },
            searchRecipesByConditions: (data) => { return this.callCommonEndpoint('production/recipes/searchByConditions', 'post', data) },
            searchRecipesByConditionsV2: (data) => { return this.callCommonEndpoint('production/recipes_v2/searchByConditions', 'post', data) },
            addRecipes: (data) => { return this.callCommonEndpoint('production/recipes', 'post', data) },
            duplicateRecipe: (id) => { return this.callCommonEndpoint('production/recipes/' + id + '/copy', 'post', {}) },
            editRecipe: (data) => { return this.callCommonEndpoint('production/recipes/' + data._id, 'put', data) },
            getRecipe: (id) => { return this.callCommonEndpoint('production/recipes/' + id) },
            simulateRecipe: (recipeId, data) => { return this.callCommonEndpoint('production/recipes/' + recipeId + '/simulate', 'post', data) },

            searchRecipesV2: (data) => { return this.callCommonEndpoint('production/recipes_v2/search', 'post', data) },
            getRecipeV2: (id) => { return this.callCommonEndpoint('production/recipes_v2/' + id) },
            addRecipesV2: (data) => { return this.callCommonEndpoint('production/recipes_v2', 'post', data) },
            editRecipeV2: (data) => { return this.callCommonEndpoint('production/recipes_v2/' + data._id, 'put', data) },
            simulateRecipeV2: (recipeId, data) => { return this.callCommonEndpoint('production/recipes_v2/' + recipeId + '/simulate', 'post', data) },

            getSharedFunctions: () => { return this.callCommonEndpoint('production/recipes/sharedFunctions') },
            saveSharedFunctions: (content) => { return this.callCommonEndpoint('production/recipes/sharedFunctions', 'post', { content }) },

            addFreeDays: (data) => { return this.callCommonEndpoint('production/freedays/range', 'post', data) },
            getFreeDays: (data) => { return this.callCommonEndpoint('production/freedays/search', 'post', data) },
            getFreeDaysWithTotal: (data) => this.callCommonEndpointWithTotal('production/freedays/search', 'post', data),
            removeFreeDay: (freeDayId) => { return this.callCommonEndpoint('production/freedays/' + freeDayId, 'put') },

            setEmpToWorkPlace: (workplaceId, empId, eff) => { return this.callCommonEndpoint('production/workplaces/' + workplaceId + '/employee/' + empId, 'put', { efficiency: eff }) },
            deleteEmpFromWorkPlace: (workplaceId, empId) => { return this.callCommonEndpoint('production/workplaces/' + workplaceId + '/employee/' + empId, 'delete') },

            summary: {
                getCurrenlyInProduction: () => this.callCommonEndpoint('production/summary/currenlyInProduction', 'get'),
                getDailyProductionRateFor: (pTypeCode) => this.callCommonEndpoint('production/summary/dailyProductionRateFor/' + pTypeCode, 'get')
            },

            queue: {
                get: () => { return this.callCommonEndpoint('production/production-queue/') },
                move: (prId, newIdx) => { return this.callCommonEndpoint('production/production-queue/move', 'post', { prId: prId, toIdx: newIdx }) }
            },

            schedule: {
                getForWorkplace: (workplaceId) => { return this.callCommonEndpoint('production/production-schedule/atWorkplace/' + workplaceId) },
                getCofiguration: () => { return this.callCommonEndpoint('production/production-schedule/configuration/') },
                setConfiguration: (data) => { return this.callCommonEndpoint('production/production-schedule/configuration/', 'post', data) }
            },

            request: {
                complete: (zpId) => { return this.callCommonEndpoint('production/production-request/' + zpId + '/complete', 'post', {}) },

                estimateProductionTime: (data) => { return this.callCommonEndpoint('production/v3/production-request/estimate-production-time', 'post', data) },

                create: (data) => { return this.callCommonEndpoint('production/production-request/', 'post', data) },
                update: (idReq, data) => { return this.callCommonEndpoint('production/production-request/' + idReq, 'put', data) },
                search: (data) => { return this.callCommonEndpoint('production/production-request/search', 'post', data) },
                removePart: (prId, partId) => { return this.callCommonEndpoint('production/production-request/' + prId + '/remove-parts', 'put', { partId: partId }) },
                get: (id) => { return this.callCommonEndpoint('production/production-request/' + id) },
                getTags: () => { return this.callCommonEndpoint('production/v3/production-request/availableTags') },
                delete: (id) => { return this.callCommonEndpoint('production/production-request/' + id, 'delete') },
                searchWithCount: (filters) => {
                    return axios({
                        method: 'post',
                        url: this.baseUrl + 'production/production-request/search',
                        headers: this.axiosConfig.headers,
                        data: JSON.stringify(filters)
                    }).then((response) => {
                        const arrayRes = {};
                        arrayRes.data = response.data;
                        arrayRes.total = parseInt(response.headers['x-total-found']);
                        return arrayRes;
                    });
                },
                moveAll: (daysCount) => { return this.callCommonEndpoint('production/production-request/move-all-deadlines', 'post', { daysCount: daysCount }) },

                v3: {
                    searchAvailable: (data) => { return this.callCommonEndpoint('production/v3/production-request/search-available', 'post', data) },
                    forceProduction: (id) => { return this.callCommonEndpoint('production/v3/production-request/'+id+'/force-production', 'post', {}) },
                    addIngredientForPr: (id, data) => { return this.callCommonEndpoint('production/v3/production-request/' + id + '/add-ingredient', 'post', data) },
                    removeIngredientForPr: (id, data) => { return this.callCommonEndpoint('production/v3/production-request/' + id + '/remove-ingredient', 'post', data) },
                    searchGrupsPrByOrders: (data) => { return this.callCommonEndpoint('production/v3/production-request/search-groups', 'post', data) },
                    changeDedlineForPrsGrup: (prsIds, deadline) => { return this.callCommonEndpoint('production/v3/production-request/batch/change_deadline', 'post', { _ids: prsIds, deadline: deadline }) },
                    changeDedlineForPrsGrupByOrderId: (orderId, deadline) => { return this.callCommonEndpoint('production/v3/production-request/batch/change_deadline_by_order_id', 'post', { orderId: orderId, deadline: deadline }) },
                    get: (id) => this.callCommonEndpoint('production/v3/production-request/' + id),
                    remove: (id) => this.callCommonEndpoint('production/v3/production-request/' + id, 'delete', {}),
                    getGrupForOrder: (id) => this.callCommonEndpointWithTotal('production/v3/production-request/get-grup-by-order/' + id),
                    search: (data) => this.callCommonEndpointWithTotal('production/v3/production-request/search', 'post', data),
                    create: (data) => { return this.callCommonEndpoint('production/v3/production-request/batch', 'post', data) },
                    createOne: (data) => { return this.callCommonEndpoint('production/v3/production-request/', 'post', data) },
                    completePR: (prId, data = { leftProductsAt: {} }) => this.callCommonEndpoint(`production/v3/production-request/${prId}/complete`, 'post', data),
                    adjustProductionDateToShipDate: (orderId, shipDate) => this.callCommonEndpoint(`production/v3/production-request/adjust-production-date-to-ship-date`, 'post', {orderId, shipDate}),
                    moveAll: (daysCount) => { return this.callCommonEndpoint('production/v3/production-request/move-all-deadlines', 'post', { daysCount: daysCount }) }
                }
            },

            recipes: {
                searchByProducts: (data) => { return this.callCommonEndpoint('production/recipes/searchByProducts', 'post', data) },

                v3: {
                    search: (filters) => this.callCommonEndpointWithTotal('production/recipes_v3/search', 'post', filters),
                    get: (id) => this.callCommonEndpoint('production/recipes_v3/' + id),
                    create: (data) => this.callCommonEndpoint('production/recipes_v3/', 'post', data),
                    save: (id, data) => this.callCommonEndpoint('production/recipes_v3/' + id, 'put', data),
                    simulate: (recipeId, data) => this.callCommonEndpoint(`production/recipes_v3/${recipeId}/simulate`, 'post', data),
                    simulateProduct: (productId, qty) => this.callCommonEndpoint(`production/recipes_v3/simulateProduct`, 'post', { productId, qty })
                },

                graphNodes: {
                    search: (filters) => this.callCommonEndpointWithTotal('production/graph_nodes/search', 'post', filters),
                    create: (data) => this.callCommonEndpointWithTotal('production/graph_nodes/', 'post', data),
                    update: (id, data) => this.callCommonEndpointWithTotal('production/graph_nodes/' + id, 'put', data),
                    remove: (id) => this.callCommonEndpointWithTotal('production/graph_nodes/' + id, 'delete', {})
                }
            },

            manualIngredients: {
                get: (productId) => this.callCommonEndpoint('production/manual-ingredients/' + productId),
                enable: (productId) => this.callCommonEndpoint(`production/manual-ingredients/${productId}/enable`, 'post', {}),
                disable: (productId) => this.callCommonEndpoint(`production/manual-ingredients/${productId}/disable`, 'post', {}),
                update: (productId, data) => this.callCommonEndpoint(`production/manual-ingredients/${productId}`, 'put', data)
            },

            cnotes: {
                search: (where) => { return this.callCommonEndpoint('production/construction-notes/search', 'post', where) },
                create: (data) => { return this.callCommonEndpoint('production/construction-notes', 'post', data) },
                update: (id, data) => { return this.callCommonEndpoint('production/construction-notes/' + id, 'put', data) },
                remove: (id) => { return this.callCommonEndpoint('production/construction-notes/' + id, 'delete', {}) }
            },

            materials: {
                searchProductionProductMinQty: (filters) => this.callCommonEndpointWithTotal('production/minimum-production-qty/search', 'post', filters),
                createProductionMinQty: (data) => { return this.callCommonEndpoint('production/minimum-production-qty', 'post', data) },
                massEdit: (data) => this.callCommonEndpoint('production/minimum-production-qty/mass-edit', 'put', data),
                updateProductionMinQty: (id, data) => { return this.callCommonEndpoint('production/minimum-production-qty/' + id, 'put', data) },
                removeProductionMinQty: (id) => { return this.callCommonEndpoint('production/minimum-production-qty/' + id, 'delete', {}) }
            },

            materialUsage: {
                getForProduct: (productId) => this.callCommonEndpoint('production/material-usage/forProduct/' + productId),
            }
        }

        this.worklogs = {
            v2: {
                search: (data) => { return this.callCommonEndpoint('production/v2/worklogs/search', 'post', data) },
                searchWithTotal: (data) => { return this.callCommonEndpointWithTotal('production/v2/worklogs/search', 'post', data) },
                save: (id, data) => { return this.callCommonEndpoint('production/v2/worklogs/' + id, 'put', data) },
                createLog: (data) => { return this.callCommonEndpoint('production/v2/worklogs/create-worklog', 'post', data) },
                createDayLog: (data) => { return this.callCommonEndpoint('production/v2/worklogs/create-day-worklog', 'post', data) }
            }
        }

        this.tasks = {
            v2: {
                search: (filters) => this.callCommonEndpointWithTotal('production/v2/tasks/search', 'post', filters),
                forceRun: (taskId) => { return this.callCommonEndpoint('production/v2/tasks/' + taskId + '/force-run', 'post', {}) },
                manualyEnd: (taskId) => { return this.callCommonEndpoint('production/v2/tasks/' + taskId + '/manualy-end', 'post', {}) }
            },

            definitions: {
                search: (filters) => this.callCommonEndpointWithTotal('production/task-defs/search', 'post', filters)
            },

            addDef: (data) => { return this.callCommonEndpoint('production/task-defs', 'post', data) },
            getPackingTaskDefs: () => { return this.callCommonEndpoint('production/task-defs/search', 'post', { isPacking: true }) },
            searchDef: (query) => { return this.callCommonEndpoint('production/task-defs/search', 'post', { query: query }) },
            modifyDef: (data) => { return this.callCommonEndpoint('production/task-defs/' + data._id, 'put', data) },
            createbasedOnRecipeBatch: (data) => { return this.callCommonEndpoint('production/tasks/basedOnRecipeBatch ', 'post', data) },
            createbasedOnRecipe: (data) => { return this.callCommonEndpoint('production/tasks/basedOnRecipe ', 'post', data) },
            complete: (ids) => { return this.callCommonEndpoint('production/tasks/complete ', 'post', { tasks: ids }) },
            changeStatus: (ids, status) => { return this.callCommonEndpoint('production/tasks/changeStatus ', 'post', { tasks: ids, status }) },
            searchTasks: (data) => { return this.callCommonEndpoint('production/tasks/search', 'post', data) },
            searchWithCount: (data) => {
                return axios({
                    method: 'post',
                    url: this.baseUrl + 'production/tasks/search',
                    headers: this.axiosConfig.headers,
                    data: JSON.stringify(data)
                }).then((response) => {
                    return {
                        data: response.data,
                        total: parseInt(response.headers['x-total-found'])
                    };
                });
            },
            create: (data) => { return this.callCommonEndpoint('production/tasks/', 'post', data) },
            edit: (taskId, data) => { return this.callCommonEndpoint('production/tasks/' + taskId, 'put', data) },
            massConfirm: () => { return this.callCommonEndpoint('production/tasks/mass-confirm', 'post', {}) }
        }
    }

    setToken(token) {
        this.token = token;
        this.axiosConfig.headers.Authorization = 'Bearer ' + token;
    }

    callCommonEndpoint(endpoint, method, data) {
        if (!method) method = 'get';
        return axios({
            method: method,
            url: this.baseUrl + endpoint,
            headers: this.axiosConfig.headers,
            data: JSON.stringify(data),
            params: method == 'get' ? data : undefined
        }).then((response) => { return response.data; });
    }

    callCommonEndpointWithTotal(endpoint, method, data) {
        if (!method) method = 'get';
        return axios({
            method: method,
            url: this.baseUrl + endpoint,
            headers: this.axiosConfig.headers,
            data: JSON.stringify(data),
            params: method == 'get' ? data : undefined
        }).then((response) => {
            return {
                data: response.data,
                total: parseInt(response.headers['x-total-found']) || 0
            }
        });
    }

    downloadAsBlob(endpoint, method, data) {
        if (!method) method = 'get';
        return axios({
            method: method,
            url: this.baseUrl + endpoint,
            headers: this.axiosConfig.headers,
            responseType: 'arraybuffer',
            data: JSON.stringify(data)
        }).then(r => {
            const file = new Blob([r.data], {
                type: r.headers['content-type'] ? r.headers['content-type'] : 'application/zip'
            });
            return file;
        }).catch(e => {
            if (!e.response || !e.response.data) throw e;
            const enc = new TextDecoder("utf-8");
            const errorDecoded = JSON.parse(enc.decode(e.response.data));
            throw errorDecoded;
        });
    }
}

const instance = new Api();

export default instance;
