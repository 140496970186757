<template>
    <div class="m-t">
        <strong>Kartony w przesyłce</strong>
        <div class="form-group row" v-for="(paczka, index) in package.parcels" :key="index">
            <label class="col-sm-1 form-control-label">#{{index}} </label>
            <div class="col-sm-2 m-t-xs">
                <input type="text" class="form-control" v-model="paczka.ref" placeholder="Kod kartonu">
            </div>
            <div class="col-sm-2 m-t-xs">
                <v-select placeholder="Nie wybrano" label="name"  v-model="paczka.type"  :options="types" :reduce="p => p.id" />
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.height" placeholder="Wys [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.width" placeholder="Szer [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.length" placeholder="Głębo [cm]">
                <span class="input-group-addon text-sm p-a-xs">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input type="text" class="form-control" v-model="paczka.mass" placeholder="kg">
                <span class="input-group-addon text-sm p-a-xs">kg</span>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn white" tabindex="-1" @click="package.parcels.splice(index, 1)">Usuń</button>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 form-control-label">Dodaj karton</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(20,20,20,10, 'box')">+ 20x20x20, 10kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(30,30,30,20, 'box')">+ 30x30x30, 20kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(60,60,60,30, 'box')">+ 60x60x60, 30 kg</button>
            </div>
            <div class="col-sm-2"/>
        </div>
        <hr>
        <a v-if="!isStockOrderPickup" href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>
        <a v-if="isStockOrderPickup" href="#" class="btn primary none-radius m-t" @click.prevent="createPickUp" :class="{disabled: isProcessing}">Zamów odbiór</a>
    </div>
</template>
<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
export default {
    name: 'CarrierFedex',
    props: {
        order: Object,
        recipient: Object,
        selectedBoxAndPallets: Array,
        sender: Object,
        stockOrder: Object
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            isLoaded: false,
            isProcessing: false,
            package: { parcels: [], deliveryAddress: {}, externalReferences: [], isPickup: false },
            types: [
                { id: "box", name: "Karton" },
                { id: "foil", name: "Folia" },
                { id: "pallet", name: "Paleta" }
            ],
            isStockOrderPickup: false
        }
    },
    created: function() {
        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets();
        if (Object.keys(this.order).length != 0) {
            this.package.externalReferences.push(
                {
                    type: "order",
                    id: this.order._id,
                    name: this.order.name
                }
            );
        }
        if (Object.keys(this.order).length != 0 && this.order.clientNo) {
            this.package.externalReferences.push(
                {
                    type: "customer_num",
                    id: this.order._id,
                    name: this.order.clientNo
                }
            );
        }

        if (Object.keys(this.order).length === 0 && Object.keys(this.stockOrder).length > 0) {
            this.isStockOrderPickup = true;
            this.package.externalReferences.push(
                {
                    type: "stock_order",
                    id: this.stockOrder._id,
                    name: this.stockOrder.documentNo
                }
            );
            this.package.isPickup = true;
        }
    },
    methods: {
        addNewParcel: function(height, width, length, weight, type) {
            this.package.parcels.push({
                height: height,
                width: width,
                length: length,
                mass: weight,
                type: type,
                ref: "#XYZ"
            });
        },
        setBoxesAndPallets() {
            this.package.parcels.length = 0;
            for (let i = 0; i < this.order.boxes.length; i++) {
                const box = this.order.boxes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.package.parcels.push({
                    height: Math.round(box.height / 10),
                    width: Math.round(box.width / 10),
                    length: Math.round(box.length / 10),
                    mass: box.mass,
                    type: box.isStretchFoil ? "foil" : "box",
                    ref: "#" + box.code.toUpperCase()
                });
            }
        },
        async createPickUp() {
            this.isProcessing = true;
            const data = this.setData();
           
            try {
                await Api.shipments.marssSent(data);
                this.$h.success('Utworzono zlecenie odbioru przesyłki');
                this.$router.push({ path: `/shipments` });
            } catch (e) {
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        save: async function() {
            this.isProcessing = true;
            const data = this.setData();
            try {
                const ship = await Api.shipments.marssSent(data);
                // console.log("dodany ship", ship);
                this.$emit('shipment', ship);

                this.$h.success('Dodano przesyłkę');
            } catch (e) {
                console.log("error", e);
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        setData: function() {
            const data = {};
            if (Object.keys(this.order).length != 0) data.order = this.order._id;
            if (Object.keys(this.stockOrder).length != 0) data.stockOrder = this.stockOrder._id;
            if (!this.isStockOrderPickup) this.package.deliveryAddress = this.setReceiver();
            if (this.isStockOrderPickup) this.package.deliveryAddress = this.setSenderAsReceiver();
            data.package = this.package;
            return data;
        },
        setReceiver: function() {
            var pos = this.recipient.postCode.replace('-', '');
            const r = {
                name: this.recipient.name,
                contactPerson: this.recipient.contactPerson ? this.recipient.contactPerson : "",
                street: this.recipient.street,
                city: this.recipient.city,
                postCode: this.recipient.postCode,
                email: this.recipient.email ? this.recipient.email : "",
                countryCode: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                phone: this.recipient.phone ? this.recipient.phone : ""
            };

            return r;
        },
        setSenderAsReceiver: function() {
            const r = {
                name: this.sender.company_name,
                contactPerson: this.sender.contactPerson ? this.sender.contactPerson : "",
                street: this.sender.street,
                city: this.sender.city,
                postCode: this.sender.postcode,
                email: this.sender.email ? this.sender.email : "",
                countryCode: this.sender.countryCode ? this.sender.countryCode : "PL",
                phone: this.sender.phone ? this.sender.phone : ""
            };

            return r;
        }

    }
}
</script>

<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
