<template>
    <div class="row">
        <div class="col-md-3" >
            <div class="box" v-if="!isPickup">
                <div class="box-header p-b-sm">
                    <h2 class="title-color m-b-sm">Dziś nadane przesyłki</h2>
                </div>
                <div class="m-a-0 custom-divider"/>
                <table class="table m-b-0">
                    <thead class="primary-color d-gray">
                        <tr>
                            <th>Przewoźnik</th>
                            <th>Ilość</th>
                        </tr>
                    </thead>

                    <tbody class="primary-color" v-if="!isLoading" >
                        <tr v-for="(r, key) in shipmentsRaports" :key=key>
                            <td>
                                <span v-if="r.service == 'pp'">poczta polska</span>
                                <span v-else>{{r.service}}</span>
                            </td>
                            <td style="padding-right:0px;">{{r.qty}}</td>
                            <td style="padding-right:0px; padding-left:0px;">
                                <span class="text-muted pull-left" v-if="r.service == 'geis' || r.service == 'suus'">(palet:  {{r.boxes}})</span>
                                <span class="text-muted pull-left" v-else-if="r.service == 'rhenus'">(palet/kartonów:  {{r.boxes}})</span>
                                <span class="text-muted pull-left" v-else>(kartonów:  {{r.boxes}})</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Spinner v-if="isLoading" />
            </div>
            <!-- NADAWCA -->
            <div class="box">
                <div class="box-header p-b-sm">
                    <h2 class="title-color m-b-sm">Dane nadawcy</h2>
                </div>
                <div class="m-a-0 custom-divider"/>
                <div class="box-body p-t-0">
                    <div class="row m-b-xs" >
                        <ul class="list-group no-border">
                            <li class="list-group-item no-border" v-if="!isProcessing">
                                <div class="clear custom-color">
                                    <h6 class="_400 m-b" v-if="sender.company_name">{{sender.company_name}}</h6>
                                    <div class="row m-b-xs"  v-if="sender.street">
                                        <span class="col-md-8 col-sm-6">{{sender.street}}</span>
                                    </div>
                                    <div class="row m-b-xs" v-if="sender.postcode && sender.city">
                                        <span class="col-md-8 col-sm-6">
                                            {{sender.postcode}} {{sender.city}}
                                            <span v-if="sender.countryCode">({{sender.countryCode}})</span>
                                        </span>
                                    </div>
                                    <div class="row m-b-xs" v-if="sender.phone">
                                        <span class="col-md-8 col-sm-6">{{sender.phone}}</span>
                                    </div>
                                    <div class="row m-b-xs" v-if="sender.email">
                                        <span class="col-md-8 col-sm-6">{{sender.email}} </span>
                                    </div>
                                </div>
                                <div class="m-x-0 m-t-sm box-divider"/>
                                <a href="#" class="pull-right text-blue text-sm m-t" @click.prevent="changeAdress('once', 'sender', sender)">Zmień jednorazowo</a>
                                <!-- <a href="#" class="pull-left text-blue text-sm m-t" v-on:click.prevent="changeAdress('save', 'sender', sender)">Zmień i zapisz</a> -->
                            </li>
                        </ul>

                    </div>

                </div>
            </div>
            <!-- ./END NADAWCA -->
            <!-- DOSTAWA -->
            <div class="box">
                <div class="box-header p-b-sm">
                    <h2 class="title-color m-b-sm">Adres dostawy</h2>
                </div>
                <div class="m-a-0 custom-divider"/>
                <div class="box-body p-t-0">
                    <Spinner v-if="isLoaded"/>
                    <p class="p-t" v-if="isEmpty">Brak dodanych adresów.</p>
                    <div class="row m-b-xs" >
                        <ul class="list-group no-border">
                            <li class="list-group-item no-border" v-if="!isEmpty">
                                <div class="row m-b-xs" >
                                    <span class="col-sm-12"  v-if="deliveryAddress.name">
                                        <h6 class="_400">{{deliveryAddress.name}}</h6>
                                    </span>
                                    <span class="col-sm-12" v-if="!deliveryAddress.name">Brak nazwy</span>
                                </div>
                                <div class="row m-b-xs">
                                    <span class="col-sm-12" v-if="deliveryAddress.street">{{deliveryAddress.street}}</span>
                                    <span class="col-sm-12" v-else>Brak ulicy</span>
                                </div>
                                <div class="row m-b-xs">
                                    <span class="col-sm-12" v-if="deliveryAddress.postCode && deliveryAddress.city">
                                        {{deliveryAddress.postCode}} {{deliveryAddress.city}}
                                        <span v-if ="deliveryAddress.countryCode">({{deliveryAddress.countryCode}})</span>
                                    </span>
                                    <span class="col-sm-12" v-else>Brak miasta</span>
                                </div>
                                <div class="row m-b-xs">
                                    <span class="col-sm-12" v-if="deliveryAddress.phone">{{deliveryAddress.phone}}</span>
                                    <span class="col-sm-12" v-else/>
                                </div>
                                <div class="row m-b-xs">
                                    <span class="col-sm-12" v-if="deliveryAddress.email">{{deliveryAddress.email}}</span>
                                    <span class="col-sm-12" v-else/>
                                </div>
                                <div class="row m-b-xs">
                                    <span class="col-sm-12" v-if="deliveryAddress.contactPerson">
                                        <small class="primary-color ">Osoba do kontaktu:</small> {{deliveryAddress.contactPerson}}</span>
                                    <span class="col-md-12" v-else/>
                                </div>
                            </li>
                            <div class="m-x-0 m-t-sm box-divider"/>
                            <li class="list-group-item no-border" v-if="!isEmpty">
                                <a href="#" class="pull-right text-blue text-sm " @click.prevent="changeRecipent()">Zmień jednorazowo</a>
                            </li>
                        </ul>

                    </div>
                    <ul class="list-group no-border" v-if="isEmpty">
                        <li class="list-group-item no-border">
                            <a  href="#" class="pull-right text-blue text-sm m-l" @click.prevent="changeRecipent()">Dodaj</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- ./END DOSTAWA   -->
            <a class="nav-link text-muted hover-dark" title="Powrót" @click="$router.go(-1)" v-if="!isLoaded">
                <span class="text-sm">Powrót</span>
            </a>
        </div>

        <div class="col-md-9">
            <div class="box">
                <div class="box-header p-b-sm">
                    <h2 class="title-color m-b-sm" v-if="!isPickup">
                        Nadaj przesyłkę <span v-if="order && order.name">dla zamówienia {{ order.name }}</span>
                        <span style="float: right; font-size: 14px; font-weight: 400;">
                            <label class="md-check">
                                <input v-model="setOrderStatusSent" type="checkbox" class="">
                                <i style="margin-top: -2px;" class="blue"/>Po nadaniu przesyłki oznacz zamówienie jako <strong>wysłane</strong>
                            </label>
                        </span>
                    </h2>
                    <h2 class="title-color m-b-sm" v-else>Zleć odbiór przesyłki</h2>
                </div>
                <div class="m-a-0 custom-divider"/>
                <div class="box-body">
                    <form class="form-horizontal">
                        <div class="form-group row m-b">
                            <label class="col-sm-3 form-control-label">Firma nadawcza</label>
                            <div class="col-sm-9">
                                <select2 :options="carrier" class="form-control" v-model="selectedCarrier"/>
                            </div>
                        </div>
                        <div class="m-a-0 box-divider"/>

                        <CarrierDpd v-if="selectedCarrier == 'DPD'"
                                    :order="order"
                                    :sender="sender"
                                    :selectedBoxAndPallets ="selectedBoxAndPallets"
                                    :stockOrder="stockOrder"
                                    :recipient="deliveryAddress"
                                    @shipments="onMultipleShipmentsCreated" />
                        <CarrierFedex v-else-if="selectedCarrier == 'FedEx'"
                                      :order="order"
                                      :sender="sender"
                                      :selectedBoxAndPallets ="selectedBoxAndPallets"
                                      @shipments="onMultipleShipmentsCreated"
                                      :recipient="deliveryAddress"/>
                        <CarrierFurgonetka v-else-if="selectedCarrier == 'Furgonteka.pl'"
                                           :order="order"
                                           :sender="sender"
                                           :selectedBoxAndPallets ="selectedBoxAndPallets"
                                           @shipment="onShipmentCreated"
                                           :recipient="deliveryAddress"/>
                        <CarrierRhenus v-else-if="selectedCarrier == 'Rhenus'"
                                       :order="order"
                                       :sender="sender"
                                       :selectedBoxAndPallets ="selectedBoxAndPallets"
                                       @shipment="onShipmentCreated"
                                       :recipient="deliveryAddress"/>
                        <CarrierGeis v-else-if="selectedCarrier == 'Geis'"
                                     :order="order"
                                     :sender="sender"
                                     :stockOrder="stockOrder"
                                     :selectedBoxAndPallets ="selectedBoxAndPallets"
                                     @shipment="onShipmentCreated"
                                     :recipient="deliveryAddress"/>
                        <CarrierPP v-else-if="selectedCarrier == 'Poczta Polska'"
                                   :order="order"
                                   :sender="sender"
                                   :recipient="deliveryAddress"
                                   @shipment="onShipmentCreated"
                                   :selectedBoxAndPallets ="selectedBoxAndPallets"/>
                        <CarrierMarss v-else-if="selectedCarrier == 'Marss'"
                                      :order="order"
                                      :sender="sender"
                                      :stockOrder="stockOrder"
                                      :recipient="deliveryAddress"
                                      @shipment="onShipmentCreated"
                                      :selectedBoxAndPallets ="selectedBoxAndPallets"/>
                        <CarrierSuus v-else-if="selectedCarrier == 'Suus'"
                                     :order="order"
                                     :sender="sender"
                                     :stockOrder="stockOrder"
                                     :selectedBoxAndPallets ="selectedBoxAndPallets"
                                     @shipment="onShipmentCreated"
                                     :recipient="deliveryAddress"/>

                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import Spinner from '../Spinner.vue';
import Select2 from '../Select2.vue';
import CarrierPP from './CarrierTemplates/CarrierPP.vue';
import CarrierGeis from './CarrierTemplates/CarrierGeis.vue';
import CarrierDpd from './CarrierTemplates/CarrierDpd.vue';
import CarrierFedex from './CarrierTemplates/CarrierFedex.vue';
import CarrierRhenus from './CarrierTemplates/CarrierRhenus.vue';
import CarrierFurgonetka from './CarrierTemplates/CarrierFurgonetka.vue';
import CarrierSuus from './CarrierTemplates/CarrierSuus.vue';
import CarrierMarss from './CarrierTemplates/CarrierMarss.vue';
import ChangeAddressModal from './ChangeAddressModal.vue';
import EditDelvieryModal from './EditDelvieryModal.vue';

export default {
    name: 'AddShipment',
    props: {
        orderId: String
    },
    components: {
        Spinner,
        Select2,
        CarrierDpd,
        CarrierFedex,
        CarrierRhenus,
        CarrierFurgonetka,
        CarrierGeis,
        CarrierPP,
        CarrierMarss,
        CarrierSuus
    },
    data: function () {
        return {
            selectedBoxAndPallets: [],
            order: {},
            sender: {},
            isLoaded: false,
            isLoading: false,
            isProcessing: false,
            selected: [],
            carrier: ["DPD", "FedEx", "Furgonteka.pl", "Rhenus", "Geis", "Poczta Polska", "Marss", "Suus"],
            selectedCarrier: "",
            deliveryAddress: { name: "", street: "", postCode: "", city: "", phone: "", contactPerson: "", countryCode: "PL", email: "" },
            isEmpty: true,
            shipmentsRaports: [
                { service: "dpd", qty: 0, boxes: 0 },
                { service: "fedex", qty: 0, boxes: 0 },
                { service: "furgonetka", qty: 0, boxes: 0 },
                { service: "rhenus", qty: 0, boxes: 0 },
                { service: "geis", qty: 0, boxes: 0 },
                { service: "pp", qty: 0, boxes: 0 },
                { service: "marss", qty: 0, boxes: 0 },
                { service: "suus", qty: 0, boxes: 0 }
            ],
            isPickup: false,
            stockOrder: {},
            customer: {},
            setOrderStatusSent: true

        }
    },
    created: function() {
        if (this.$route.params.orderId) this.getOrder();
        if (this.$route.params.stockOrderId) this.getStockOrderData();
        if (this.$route.params.selectedBoxAndPallets) this.selectedBoxAndPallets = this.$route.params.selectedBoxAndPallets;

        this.getShipments();
    },
    destroyed: function() {
    },
    methods: {
        getShipments: async function() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;

            this.isLoading = true;
            const filters = {
                dateFrom: today + "T00:00:01",
                dateTo: today + "T23:59:59"
            }

            try {
                const tmpShip = await Api.shipments.search(filters);
                for (let i = 0; i < tmpShip.length; i++) {
                    const ship = tmpShip[i];
                    if (!ship.order) continue;
                    if (ship.service == 'dpd') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("dpd");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'fedex') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("fedex");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'furgonetka') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("furgonetka");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'rhenus') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("rhenus");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'geis') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("geis");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'pp') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("pp");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'marss') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("marss");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                    if (ship.service == 'suus') {
                        const idx = this.shipmentsRaports.map(s => s.service).indexOf("suus");
                        if (idx != -1) this.shipmentsRaports[idx].qty += 1;
                        if (idx != -1) this.shipmentsRaports[idx].boxes += ship.boxesQty;
                    }
                }
            } catch (error) {
                this.isError = true;
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się załadować raportu paczek' });
            }
            this.isLoading = false;
        },

        async onMultipleShipmentsCreated(shipments) {
            this.selectedCarrier = "";
            if (!this.order) return;

            if (this.setOrderStatusSent) await Api.order.setStatus(this.order._id, "sent");

            let i = 0;
            for (const boxOrPalleteId of this.selectedBoxAndPallets) {
                const box = (this.order.boxes || []).find(box => box._id == boxOrPalleteId);
                if (box) await Api.order.editBox(this.order._id, box.code, { shipmentId: shipments[i]._id });

                const pallete = (this.order.shippingPalletes || []).find(pallete => pallete._id == boxOrPalleteId);
                if (pallete) await Api.order.editPalette(this.order._id, pallete._id, { shipmentId: shipments[i]._id });

                i += 1;
                if (i >= shipments.length) i = 0;
            }

            this.$router.push({ path: `/order/${this.order._id}` });
        },

        async onShipmentCreated(shipment) {
            this.selectedCarrier = "";
            if (this.order) {
                for (const boxOrPalleteId of this.selectedBoxAndPallets) {
                    const box = (this.order.boxes || []).find(box => box._id == boxOrPalleteId);
                    if (box) await Api.order.editBox(this.order._id, box.code, { shipmentId: shipment._id });

                    const pallete = (this.order.shippingPalletes || []).find(pallete => pallete._id == boxOrPalleteId);
                    if (pallete) await Api.order.editPalette(this.order._id, pallete._id, { shipmentId: shipment._id });
                }

                if (this.setOrderStatusSent) {
                    let c = true;
                    if (!this.hasAllProductsPacked()) {
                        c = await this.$h.confirm(`<i class="material-icons md-18">report</i> Zamówienie <strong>nie jest</strong> w całości spakowane. Na pewno chcesz je oznaczyć jako wysłane?`);
                    }
                    if (c) await Api.order.setStatus(this.order._id, "sent");
                }

                this.$router.push({ path: `/order/${this.order._id}` });
            }
        },

        hasAllProductsPacked() {
            if (!this.order) return true;
            for (const cartPos of this.order.cart) {
                if (cartPos.packedQty < cartPos.qty) return false;
            }
            return true;
        },

        getOrder: async function() {
            this.isLoaded = true;
            try {
                this.order = await Api.order.getOrder(this.$route.params.orderId);
                this.isLoaded = false;
                if (this.order.deliveryAddressId) {
                    const address = this.order.customer.deliveryAddresses;
                    const idx = address.map(d => d._id + "").indexOf(this.order.deliveryAddressId + "");
                    if (idx != -1) {
                        Object.assign(this.deliveryAddress, address[idx]);
                        this.isEmpty = false;
                    }
                }
                this.setSender();
            } catch (e) {
                this.isLoaded = false;
                console.log("order errror", e);
            }
        },
        setSender: async function() {
            this.isProcessing = true;
            try {
                const data = await Api.shipments.getCofiguration();
                this.sender = data.sender;
                this.isProcessing = false;

                if (this.$route.query.carrier) {
                    this.selectedCarrier = this.$route.query.carrier;
                }
            } catch (e) {
                this.isProcessing = false;
                console.log("sender error", e);
            }
        },
        changeAdress: function(form, who, address) {
            this.$modal.show(ChangeAddressModal, {
                form: form,
                who: who,
                addressToChange: address,
                order: this.order
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            });
        },
        changeRecipent: function() {
            this.$modal.show(EditDelvieryModal, {
                delivery: this.deliveryAddress
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                    this.isEmpty = false;
                    // console.log('event', this.deliveryAddress);
                }
            });
        },
        // jest to pickup wiec ustawiamy wszytko
        async getStockOrderData() {
            const data = await Api.shipments.getCofiguration();
            this.deliveryAddress = this.setDeliveryAddress(data.sender);
            this.isEmpty = false;

            this.stockOrder = await Api.stock.getOrder(this.$route.params.stockOrderId);
            if (this.stockOrder && this.stockOrder.contractor && this.stockOrder.contractor.customerId) this.customer = await Api.customer.get(this.stockOrder.contractor.customerId);
            if (this.stockOrder && this.stockOrder.contractor) {
                if (this.stockOrder.contractor.deliveryAddressesId) {
                    const address = this.customer.deliveryAddresses.find(d => d._id == this.stockOrder.contractor.deliveryAddressesId);
                    if (address) this.sender = this.setAddress(address);
                    else this.sender = this.setSenderForPickup(this.stockOrder.contractor);
                } else {
                    this.sender = this.setSenderForPickup(this.stockOrder.contractor);
                }
            }

            this.isPickup = true;
            this.carrier = ["Geis", "DPD", "Marss"]
        },
        setDeliveryAddress(solis) {
            const r = { name: solis.company_name, street: solis.street, postCode: solis.postcode, city: solis.city, phone: solis.phone, contactPerson: solis.firstname + " " + solis.lastname, countryCode: "PL", email: solis.email }

            return r;
        },
        setSenderForPickup(contractor) {
            const adress = contractor.address;
            const arrAdress = adress.split(",");
            let zpiAndCityArr = [];
            if (arrAdress[1]) zpiAndCityArr = arrAdress[1].split(" ");
            const r = {
                company_name: contractor.name,
                street: arrAdress[0],
                postcode: zpiAndCityArr[1],
                city: zpiAndCityArr[2],
                countryCode: contractor.countryCode ? contractor.countryCode : "PL",
                phone: contractor.phone ? contractor.phone : "",
                contactPerson: contractor.contactPerson ? contractor.contactPerson : "",
                email: contractor.email ? contractor.email : ""
            }
            return r;
        },
        setAddress(addres) {
            const r = {
                company_name: addres.name,
                street: addres.street,
                postcode: addres.postCode,
                city: addres.city,
                countryCode: addres.countryCode ? addres.countryCode : "PL",
                phone: addres.phone ? addres.phone : "",
                email: addres.email ? addres.email : "",
                contactPerson: addres.contactPerson ? addres.contactPerson : ""
            }
            return r;
        }

    }
}
</script>
<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px;
  }
</style>
