<template>
    <div class="custom-view-wrapper">
        <p class="text-center text-muted" v-html="options.message"/>
        <button class="nav-link btn btn-xs blue " href="#" @click="cancel()" style="border-radius: 0px;">Cofnij</button>
        <button class="nav-link btn btn-xs blue pull-right" href="#" @click="proceed()" style="border-radius: 0px;">Potwierdź</button>
    </div>
</template>

<script>
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'

export default {
    name: 'Confirmation',
    mixins: [DialogMixin],
    components: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
