<template>
    <div class="box">
        <div class="box-body">
         <p class="primary-color _500">Utwórz listę odbioru - GEIS</p>
            <div class="form-group row m-b-0">
                <label class="col-sm-12 form-control-label">Email</label>
                <div class="col-sm-12">
                    <input class="form-control" type="email" v-model="Contact.Email">
                </div>
            </div>
            <div class="form-group row m-b-0">
                <label class="col-sm-12 form-control-label">Imię i nazwisko</label>
                <div class="col-sm-12">
                    <input class="form-control" type="text" v-model="Contact.FullName">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 form-control-label">Telefon</label>
                <div class="col-sm-12">
                    <input class="form-control" type="text" v-model="Contact.Phone">
                </div>
            </div>
            <div class="m-t-0 m-b custom-divider"/>
            <div class="form-group row">
                <label class="col-sm-8 form-control-label">Ilość palet</label>
                <div class="col-sm-4">
                    <input class="form-control" type="text" v-model="totalQty">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-8 form-control-label">Waga całkowita</label>
                <div class="col-sm-4">
                    <input class="form-control" type="text" v-model="totalWieght">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-12 form-control-label">Planowana data podjęcia</label>
                <div class="col-sm-12">
                    <div class="input-group">
                        <div class="datepicker-trigger">
                            <input
                                class="form-control form-control-sm p-x b-a"
                                type="text"
                                id="datepicker-trigger-new"
                                placeholder="Wybierz date"
                                :value="formatDate(dateOne)"
                            >

                            <AirbnbStyleDatepicker
                                :trigger-element-id="'datepicker-trigger-new'"
                                :mode="'single'"
                                :date-one="dateOne"
                                :monthsToShow="1"
                                :showShortcutsMenuTrigger="false"
                                @date-one-selected="val => { dateOne = val }"
                            />
                        </div>
                    </div>

                </div>

            </div>
            <a href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Utwórz</a>
        </div>
    </div>
</template>
<script>

import Api from "../../Api.js";
import format from 'date-fns/format';
export default {
    name: 'GeisPickUp',
    components: {

    },
    data: function () {
        return {
            Contact: {
                Email: "transport@marssfiltry.pl",
                FullName: "Patrycja Sobierska",
                Phone: "603633826"
            },
            totalQty: 0,
            totalWieght: 0,
            dateFormat: 'D MMM',
            dateOne: '',
            isProcessing: false,

        }
    },

    computed: {

    },

    created: function() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        this.dateOne = today;
    },

    destroyed: function() {
    },

    methods: {
        async save() {
            this.isProcessing = true;
            let data = {
                Contact: this.Contact,
                totalQty: this.totalQty,
                totalWieght: this.totalWieght,
                date: this.dateOne+"T00:00:00"
            }
            try {
              let res = await Api.shipments.geisCreatePickUp(data);
              this.isProcessing = false;
              this.$notify({ group: 'global', type: 'notification-success', text: 'Dodano pickup na dany dzień.' });
            } catch (e) {
                if (e.response.data.error) this.$notify({ group: 'global', type: 'notification-error', text: e.response.data.error });
                else this.$notify({ group: 'global', type: 'notification-error', text: "wystapił błąd."});
            }

        },
        formatDate(dateOne) {
            let formattedDates = '';
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            return formattedDates;
        }
    }
}
</script>
