<template>
    <div style="clear:both; margin-top: 8px; border: 1px dashed #ccc;" class="list-item">
        <img class="icon list-left p-r-0" :src="task.taskDef.iconUrl" >
        <div class="list-body">
            <div class="m-y-sm pull-right">
                <i class="material-icons md-24">assignment_turned_in</i> {{task.toMadeCount }}szt
            </div>
            <div class="m-y-sm m-x-sm pull-right" v-if="task.canBeStartedAfter && task.canBeStartedAfter.length">
                <a title="Wymaga ukonczenia poprzednich zadan"><i class="material-icons md-24">link</i> {{ task.canBeStartedAfter.length }}</a>
            </div>
            <strong>{{ task.taskDef.name }}</strong><br>
            <!-- Do zrobienia: {{task.toMadeCount }}<br/> -->
            <div v-if="task.sub_product">
                <div>
                    <span class="text-muted text-sm">
                        Generuje półprodukt <strong>{{ task.sub_product.code }}</strong> o cechach:
                        <span v-for="(v, k) in task.sub_product.features" :key="k"><br>{{k}}: {{v}}</span>
                    </span>

                </div>
            </div>
            <div v-else-if="produces">
                <div v-if="this.product" class="text-sm">
                    <span class="text-muted text-sm">Generuje półprodukt</span> {{ product.name }} (<span class="text-muted text-sm" v-for="(v, k) in product.featureMap" :key="k">{{k}}: {{v}},&nbsp;</span>)
                </div>
                <div v-else>
                    <span class="text-muted text-sm">Generuje półprodukt</span> {{ produces }}
                </div>
            </div>
            <div v-else>
                <span class="text-muted text-sm">Zadanie nie generuje systemowych półproduktów</span>
            </div>

            <div v-if="task.extraArgs">
                <span class="text-muted text-sm">Dodatkowe informacje: </span>
                <span class="text-sm" v-for="(v, k) in task.extraArgs" :key="k">{{k}}: {{v}},</span>
            </div>
        </div>
    </div>
</template>

<script>

import Api from '../../Api.js';

export default {
    name: 'TaskSummaryBox',

    props: {
        task: Object
    },

    data: function () {
        return {
            product: null
        }
    },

    created: async function() {
        if (!this.task.relations) this.task.relations = [];
        if (this.produces) {
            const r = await Api.product.search({ _id: [this.produces], featureMap: true });
            if (r.length) this.product = r[0];
        }
    },

    computed: {
        produces: function () {
            let rId = null;
            for (let i = 0; i < this.task.relations.length; i++) {
                const rel = this.task.relations[i];
                if (rel.flag == 'create-target') rId = rel.rId;
            }
            if (!rId) return null;
            return rId;
        }
    }
}
</script>
<style scoped lang="scss">
.icon {
  width: 40px;
  height: 40px;
}
</style>
