<template>
    <div class="row">
        <Spinner v-if="isLoaded"/>
        <div class="col-md-3">
            <Customer v-if="order._id" :order="order"/>
            <Deliver v-if="order._id" :order="order"/>
            <router-link class="nav-link text-muted hover-dark" to="/orders" v-if="!isLoaded">
                <span class="text-sm">Powrót do list zamówień</span>
            </router-link>
        </div>
        <div class="col-md-6">
            <OrderDetails v-if="order._id" :order="order"/>
            <Shipments v-if="order._id" :order="order" />
            <Boxes v-if="order._id" :order="order" />
        </div>
        <div class="col-md-3">
            <StockInformationForOrder v-if="order._id" :order="order" />
            <!-- <ProductionZPV3 v-if="order._id" :order="order" /> -->
            <RelatedOrders v-if="order._id" :order="order"  />
            <!-- <CustomerDate v-if="order._id" :order="order" /> -->
            <Notes v-if="order._id" :notes="order.notes" :orderId = "order._id"/>
            <PrintTemplates v-if="order._id" :orderId = "order._id" :production="order.production"/>
            <WFirma v-if="invoice.id" :invoice="invoice"/>

            <!-- <PackingTasks v-if="order._id" v-bind:order="order"></PackingTasks> -->
            <Files v-if="order._id" :attachments="order.attachments" :orderId = "order._id"/>
        </div>
    </div>
</template>

<script>
import StockInformationForOrder from './StockInformationForOrder.vue'
import Customer from './Customer.vue'
import RelatedOrders from './RelatedOrders.vue'
import Deliver from './Deliver.vue'
import Shipments from './Shipments.vue'
import OrderDetails from './OrderDetails.vue'
import Notes from './Notes.vue'
import PrintTemplates from './PrintTemplates.vue'
import WFirma from './WFirma.vue'
import Boxes from './Boxes.vue'
import CustomerDate from './CustomerDate.vue'
import ProductionZPV3 from './ProductionZPV3.vue'
import Files from './Files.vue'
import SystemSocket from '../../SystemSocket.js';
import EventBus from '../../event-bus.js';
import Spinner from '../Spinner.vue';
import Api from '../../Api.js';
export default {
    name: 'Order',
    components: {
        Notes,
        PrintTemplates,
        WFirma,
        Files,
        OrderDetails,
        Customer,
        Deliver,
        Boxes,
        CustomerDate,
        Spinner,
        Shipments,
        ProductionZPV3,
        RelatedOrders,
        StockInformationForOrder
    },
    data: function () {
        return {
            order: {},
            isLoaded: false,
            invoice: {}
        }
    },
    created: function() {
        this.getOrder();
        EventBus.$on('cartChanged', this.onCartChanged);
        SystemSocket.socket.on('object', this.onObjectEvent);
    },
    destroyed: function() {
        SystemSocket.socket.off('object', this.onObjectEvent);
        EventBus.$off('cartChanged', this.onCartChanged);
    },
    methods: {
        getOrder: function() {
            this.isLoaded = true;
            Api.order.getOrder(this.$route.params.id).then((order) => {
                this.isLoaded = false;
                this.order = order;
                document.title = order.name;
                this.getInvoiceByOrderId();
                Api.user.setManyNotificationsAsSeen({
                    "data.orderId": order._id
                });
            }).catch((reson) => {
                console.log('blad')
            })
        },

        async onObjectEvent(msg) {
            if (msg.object != 'order' || msg.verb != 'save') return;
            if (msg.data._id != this.order._id) return;

            const o = await Api.order.getOrder(this.order._id);
            Object.assign(this.order, o);
        },

        orderChange: function(o) {
            if (this.order._id + "" != o._id + "") return;
            Object.assign(this.order, o);
        },
        getInvoiceByOrderId: function() {
            Api.wFirma.getByOrderId(this.order._id).then((invoice) => {
                this.invoice = invoice;
            }).catch((error) => {
                console.warn(error);
            });
        }
    }
}
</script>
