<template>
    <div class="box m-b-0">
        <div class="box-header">
            <h3>Wybierz opcje eksportu</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>

        <div class="box-body" style="min-height: 100px;">
            <div class="form-group row">
                <label class="col-sm-4 col-md-3 form-control-label">Zakres dat</label>
                <div class="col-sm-8 col-md-9">
                    <date-picker
                        v-model="rangeDays"
                        range
                        format="DD/MM/YYYY"
                        :lang="lang"
                        placeholder="Zakres dat"
                        :confirm="false"
                    />
                </div>
            </div>

            <!-- <div class="form-group row">
                <label class="col-sm-5 form-control-label">Zakres dat</label>
                <div class="col-sm-7">
                    <div class="input-group" style="width:86%; padding-right: 16px; display: inline-block;">
                        <div class="datepicker-trigger">
                            <input
                                class="form-control form-control-sm p-x b-a m-r"
                                type="text"
                                id="datepicker-trigger-bilans"
                                placeholder="Zakres dat"
                                :value="formatDates(dateOne, dateTwo)"
                            >

                            <AirbnbStyleDatepicker
                                :trigger-element-id="'datepicker-trigger-bilans'"
                                :mode="'range'"
                                :date-one="dateOne"
                                :date-two="dateTwo"
                                :monthsToShow="1"
                                :showShortcutsMenuTrigger="false"
                                @date-one-selected="val => { dateOne = val }"
                                @date-two-selected="val => { dateTwo = val }"
                            />
                        </div>
                    </div>

                    <a class="pull-right m-t-xs text-muted" @click.prevent="clear">
                        <i class="material-icons md-24">highlight_off</i>
                    </a>
                </div>
            </div> -->
            <!-- <hr class=" m-y-md"/> -->
            <!-- <div class="form-group row">
                <label class="col-sm-5 form-control-label">Typ</label>
                <div class="col-sm-7">
                    <v-select placeholder="Typ" v-model="type"  :options="types" label="name" :reduce="t => t.code" />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-5 form-control-label">Kategoria</label>
                <div class="col-sm-7">
                    <v-select placeholder="Kategoria" v-model="category" :options="categories" />
                </div>
            </div> -->
            <!-- <div class="form-group row">
                <label class="col-sm-5 form-control-label">Grupuj</label>
                <div class="col-sm-7">
                    <v-select placeholder="Grupowanie" v-model="agregation" :options="agregations" label="name" :reduce="t => t.id" />
                </div>
            </div> -->
            <!-- <div class="form-group row m-t-md">
                <div class="col-sm-12">
                    <label class="md-check">
                        <input type="checkbox" v-model="withoutWarehouseMove">
                        <i class="blue m-r-xs"/>
                        <span class=" m-l-xs">Wyłącz w raporcie przesunięcia magazynowe</span>
                    </label>
                 </div>
            </div> -->
        </div>
        <div class="box-footer clearfix m-t">
            <a class="pull-right btn white b-a no-shadow text-muted none-radius btn-sm m-t-sm" :href="bilansLink" target="_blank" v-if="this.rangeDays && this.rangeDays[0]">
                <i class="material-icons md-20">import_export</i>
                Generuj CSV
            </a>&nbsp;
        </div>

    </div>

</template>
<script>
import Api from '../../../Api.js';
import Datepicker from 'vuejs-datepicker';
import format from 'date-fns/format';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import pl from 'vuejs-datepicker/dist/locale/translations/pl.js'
export default {
    name: '',
    props: {
    },
    components: {
        DatePicker,
    },
    data: function () {
        return {
            isProcessing: false,
            isLoading: false,
            types: [],
            categories: [],
            type: null,
            category: null,
            dateOne: "",
            dateTwo: "",
            dateFormat: 'D MMM',
            agregations: [
                { id: 'none', name: "Nie grupuj" },
                { id: 'weekly', name: "Tydzień" },
                { id: 'month', name: "Miesiąc" }
            ],
            agregation: 'none',
            withoutWarehouseMove: true,

            rangeDays: null,
            lang: {
                formatLocale: {
                    monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Padź', 'Lis', 'Gru']
                }
            },
            pl: pl

        }
    },
    computed: {
        bilansLink() {
            // if (!this.dateOne || !this.dateTwo) return;
            // const data = {
            //     dateFrom: this.dateOne + "T00:00:01",
            //     dateTo: this.dateTwo + "T23:59:59"
            // };
            
            
            // if (this.agregation) data.agregation = this.agregation;

            // return Api.shipments.getRaportCsvLink(data);

            if (!this.rangeDays[0] || !this.rangeDays[1]) return;
            const data = {
                dateFrom: this.formatDate(this.rangeDays[0]) + "T00:00:01",
                dateTo: this.formatDate(this.rangeDays[1]) + "T23:59:59",
            };

            return Api.shipments.getRaportCsvLink(data);
        }
    },
    created: async function() {
        try {
            this.categories = await Api.product.getAvailableCategories();
            this.types = await Api.product.types.getAll();
        } catch (error) {
            console.log("created error", error);
        }
        this.isLoading = false;
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        },
        formatDates(dateOne, dateTwo) {
            let formattedDates = ''
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            if (dateTwo) {
                formattedDates += ' - ' + format(dateTwo, this.dateFormat);
            }
            return formattedDates;
        },
        clear() {
            this.dateOne = null;
            this.dateTwo = null;
        },
         formatDate(d) {
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            const year = d.getFullYear();

            if (month.length < 2) { month = '0' + month; }
            if (day.length < 2) { day = '0' + day; }

            return [year, month, day].join('-');
        },
    }
}
</script>
