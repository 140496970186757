<template>
    <div class="box">
        <div class="box-header">
            <h6 class="text-muted _400 inline">Powiąż zamówienie {{order.name}}</h6>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <form class="form-horizontal">
                <div class="m-b-sm">
                    <p class="text-muted m-b-xs text-xs">Szukaj zamówienia:</p>
                    <div class="form-group l-h m-a-0">
                        <div class="input-group search-input" style="width: 100%">
                            <input class="form-control form-control-sm p-x b-a" placeholder="Szukaj..." type="text" v-model="query" @input="onSearch">
                            <a class="close-btn" href="#" @click.prevent="clear"><i class="material-icons md-16">close</i></a>
                        </div>
                    </div>
                    <div class="p-t p-l-lg" v-if="isProcessing"><rotate-square2 style="margin: 15px;" /></div>
                    <div class="p-t-lg" style="display: flex; justify-content: center; align-items: center;" v-if="loadingData"><rotate-square2 style="margin: 15px;" /></div>
                    <p class="text-muted m-t text-sm" v-if="orders.length == 0 && !loadingData">Nie znaleziono zamówienia pasującego do frazy: "{{query}}"</p>

                    <div class="m-t" v-if="orders.length > 0 && !loadingData">
                        <table class="table">
                            <tbody  class="primary-color">
                                <tr v-for="(orderPos, i) in sortedOrders" :key="orderPos._id">
                                    <td>
                                        <label class="md-check p-t-xs">
                                            <input type="checkbox" v-model="selected" :value="orderPos._id">
                                            <i class="blue m-r-xs"/>
                                        </label>
                                    </td>
                                    <td>
                                        <span class="_600">{{orderPos.name}}</span>
                                        <br>
                                        <span class="text-muted text-xs">({{orderPos.created | moment("DD/MM/YY  HH:mm")}})</span>
                                    </td>
                                    <td>
                                        <span v-if="orderPos.customer">{{orderPos.customer.name | truncate(56)}}</span><br>
                                        <span class="text-xs text-muted" v-if="orderPos.customer && orderPos.customer.postCode && orderPos.customer.city && orderPos.customer.street">
                                            {{orderPos.customer.street}}, {{orderPos.customer.postCode}} {{orderPos.customer.city}}
                                        </span>
                                    </td>
                                    <td>
                                        <OrderStatusSpan :order="orderPos" />
                                    </td>

                                    <td>
                                        <a class="col-md-12 text-blue text-right" href="" @click.prevent="relateOrders(orderPos)"><i class="material-icons md-20">add</i>
                                            Powiąż
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <a v-if="orders.length > 0 && !loadingData" class="col-md-12 text-blue text-right m-y" href="" @click.prevent="relateSelectedOrders"><i class="material-icons md-20">add</i>
            Powiąż wybrane
        </a>
    </div>
</template>

<script>
import Api from '../../../Api.js';
import EventBus from '../../../event-bus.js';
import { RotateSquare2 } from 'vue-loading-spinner';
import OrderStatusSpan from '../OrderStatusSpan.vue';
let timeoutHandler = null;

export default {
    name: 'EditDeliveryCustomerModal',
    props: {
        order: Object,
        relatedOrders: Array
    },
    components: {
        RotateSquare2,
        OrderStatusSpan
    },
    data: function () {
        return {
            isProcessing: false,
            orders: [],
            loadingData: false,
            query: '',
            selected: []
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    computed: {
        sortedOrders: function () {
            return [...this.orders].sort((a, b) => {
                var dateA = new Date(a.created), dateB = new Date(b.created);
                return dateB - dateA;
            });
        }
    },
    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            }
        },
        onSearch: function() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            if (this.query.length == 0) {
                this.orders.length = 0;
                return;
            }
            if (this.query.length < 3) return;
            timeoutHandler = setTimeout(() => {
                this.getOrders();
            }, 1000);
        },
        clear: function() {
            if (this.query.length == 0) return;
            this.query = '';
            this.orders.length = 0;
        },
        async getOrders() {
            this.loadingData = true;
            try {
                this.orders.length = 0;
                let findedOrders = await Api.order.search({ query: this.query, status: ['new', 'in-production', 'realized', 'ready-to-pack', 'pack', 'ready-to-send', 'sent'], limit: 10 });
                findedOrders = findedOrders.filter(o => o._id !== this.order._id);
                for (let i = 0; i < this.relatedOrders.length; i++) {
                    const relOrderId = this.relatedOrders[i];
                    findedOrders = findedOrders.filter(o => o._id !== relOrderId);
                }

                this.orders.push(...findedOrders);
            } catch (error) {
                console.log("er", error);
            }
            this.loadingData = false;
        },
        async relateOrders(orderToRelate) {
            if (!this.order.relatedOrders) this.order.relatedOrders = [];
            this.order.relatedOrders.push(orderToRelate._id);

            if (!orderToRelate.relatedOrders) orderToRelate.relatedOrders = [];
            orderToRelate.relatedOrders.push(this.order._id);

            this.isProcessing = true;

            try {
                await Api.order.save(this.order._id, { relatedOrders: this.order.relatedOrders });
                await Api.order.save(orderToRelate._id, { relatedOrders: orderToRelate.relatedOrders });

                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie powiązano.' });
                EventBus.$emit('newRelatedOrders');

                this.$emit('close');
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas powiązania.' });
                console.log("error");
            }
            this.isProcessing = false;
        },

        async relateSelectedOrders() {
            if (!this.order.relatedOrders) this.order.relatedOrders = [];
            this.order.relatedOrders.push(...this.selected);

            this.isProcessing = true;
            try {
                await Api.order.save(this.order._id, { relatedOrders: this.order.relatedOrders });
                for (let i = 0; i < this.selected.length; i++) {
                    const id = this.selected[i];
                    const releted = this.selected.filter(s => s != id);
                    releted.push(this.order._id)
                    await Api.order.save(id, { relatedOrders: releted });
                }

                this.$notify({ group: 'global', type: 'notification-success', text: 'Pomyślnie powiązano.' });
                EventBus.$emit('newRelatedOrders');

                this.$emit('close');
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Wystąpił bład podczas powiązania.' });
                console.log("error");
            }
            this.isProcessing = false;
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
