<template>
    <div class="box m-b-0 global-search">
        <div class="box-header">
            <h3>Wyszukaj w systemie</h3>
        </div>
        <div class="box-tool">
            <ul class="nav">
                <li class="nav-item inline icon-with-desc">
                    <a class="nav-link text-muted" @click="$emit('close')">
                        <i class="material-icons md-18">clear</i>
                        <span class="icon-desc">ESC</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-sm-12">
                    <span class="text-xs text-muted">Szukaj</span>
                    <input ref="globsearch" class="form-control form-control-sm" type="text" v-model="query" @input="onQueryChange" placeholder="Szukaj">
                    <span class="text-xs text-muted" v-if="results.length"><i class="material-icons md-18 primary-color">swap_vert</i> Użyj strzałek <code>góra/dół</code> żeby wybrać wynik wyszukiwania</span><br>
                    <span class="text-xs text-muted" v-if="results.length"><i class="material-icons md-18 primary-color">keyboard</i> Naciśnij <code>ENTER</code> żeby wybrać</span>
                </div>
            </div>

            <div class="row" style="min-height: 200px; max-height: 500px; overflow: scroll;">
                <div class="col-sm-12 text-center p-a-1" v-if="isLoading">
                    <Spinner />
                </div>
                <div class="col-sm-12 text-center p-a-1" v-else-if="query.length > 0 && results.length == 0">
                    <h4 class="text-muted">Brak wyników<br><i class="material-icons md-36">sentiment_very_dissatisfied</i></h4>
                </div>
                <div class="col-sm-12" v-else>
                    <div class="box search-result" v-for="(result, i) in results" :key="result.object._id">
                        <div class="box-body" @click="select(result)" :class="{ selected: selectedIdx == i }">
                            <div class="result-icon">
                                <i class="material-icons md-18 primary-color" v-if="result.type == 'order'">fact_check</i>
                                <i class="material-icons md-18 primary-color" v-if="result.type == 'product'">sell</i>
                            </div>
                            {{ result.object.name }}<br>
                            <span class="text-muted text-sm" v-if="result.type == 'order'">{{ result.object.customer.name }}</span>
                            <span class="text-muted text-sm" v-if="result.type == 'product'">{{ result.object.code }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Api from '../Api.js';
import Spinner from './Spinner.vue';
let timeoutHandler = null;

export default {
    name: 'GlobalSearchModal',
    props: {
        /**
         * Zakres wyszukiwań. Dostępne wartości
         *  - all
         *  - product
         *  - order
         */
        scope: {
            default: "all",
            required: false,
            type: String
        },

        /**
         * Możliwość przekazania dodatkowych filtrów do API szukania. Np podanie
         * { productTypes: ['filtr_kasetowy']} spowoduje, że wyszukiwarka będzie nam
         * zwracać tylko produkty z tym typem
         */
        filters: {
            default: () => {},
            required: false,
            type: Object
        },

        /**
         * Czy na start wykonać zapytanie do API? Ma sens tylko przy podaniu jakiś filtrów
         */
        initSearch: {
            default: false,
            required: false,
            type: Boolean
        }
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            query: "",
            isLoading: false,
            results: [],
            history: [],
            selectedIdx: -1
        }
    },
    mounted: function() {
        window.addEventListener('keydown', this.handleKeyUp);
    },
    destroyed: function() {
        window.removeEventListener('keydown', this.handleKeyUp);
    },
    async created() {
        const history = localStorage.getItem('search_history');
        if (history) {
            try {
                this.history = JSON.parse(history) || [];
                this.results.push(...this.history.filter(h => {
                    if (!this.scope || this.scope == 'all') return true;
                    return h.type == this.scope;
                }));
            } catch (error) {
                console.error(error);
            }
        }
        if (this.initSearch) this.search();
        setTimeout(() => {
            this.$refs.globsearch.focus();
        }, 300);
    },

    methods: {
        handleKeyUp: function(e) {
            if (e.keyCode == 27) { // ESC
                e.preventDefault();
                this.$emit('close');
            } else if (e.keyCode == 13 || e.which == 13) {
                if (this.selectedIdx >= 0) {
                    this.select(this.results[this.selectedIdx]);
                }
            } else if (e.keyCode == 38 || e.which == 38 || e.key == "ArrowUp") {
                if (this.selectedIdx > 0) this.selectedIdx -= 1;
            } else if (e.keyCode == 40 || e.which == 40 || e.key == "ArrowDown") {
                if (this.selectedIdx < this.results.length - 1) this.selectedIdx += 1;
            }
        },

        onQueryChange() {
            if (timeoutHandler) clearTimeout(timeoutHandler);
            timeoutHandler = setTimeout(() => {
                this.search();
            }, 1000);
        },

        async search() {
            this.isLoading = true;
            this.selectedIdx = -1;
            this.results.splice(0);

            const proms = [];

            if (this.scope == 'all' || this.scope == 'product') {
                const pp = Api.product.searchWithTotal(Object.assign({ query: this.query }, this.filters)).then(res => {
                    for (const product of res.data) {
                        this.results.push({
                            type: 'product',
                            object: product
                        });
                    }
                });
                proms.push(pp);
            }

            if (this.scope == 'all' || this.scope == 'order') {
                const op = Api.order.getOrders({ query: this.query }).then(res => {
                    for (const order of res.data) {
                        this.results.push({
                            type: 'order',
                            object: order
                        });
                    }
                });
                proms.push(op);
            }

            await Promise.all(proms);
            if (this.results.length > 0) this.selectedIdx = 0;

            this.isLoading = false;
        },

        select(result) {
            const sameInHistory = this.history.find(h => h.object._id == result.object._id);
            if (sameInHistory) {
                this.history.splice(this.history.indexOf(sameInHistory), 1);
            }

            this.history.unshift(result);
            if (this.history.length > 5) this.history.pop();
            localStorage.setItem('search_history', JSON.stringify(this.history));

            this.$emit('close', result);
        }
    }
}
</script>
