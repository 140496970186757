<template>
    <div class="box" v-if="!isLoading">
        <div class="box-header p-b-sm">
            <h2 class="title-color m-b-sm inline">Nadane przesyłki</h2>
            <!-- <div class="pull-right ">
                <a class="pull-left text-blue text-sm" @click.prevent="addShip">
                    Dodaj przesyłkę
                </a>
            </div> -->
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="box-body p-t-0">
            <p class="p-t-xs text-muted m-b-0" v-if="shipments.length == 0 && !isLoading">Brak nadanych.</p>
            <div class="row" v-if="isLoading">
                <div class="col-md-12">
                    <Spinner/>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-md-12" v-for="shipment in shipments" :key="shipment._id">
                    <div class="grey-box m-y-sm">
                        <div class="row p-y-sm p-x">
                            <div class="col-md-8">
                                <span class="label text-white m-r-xs none-radius inline prod-label c-yellow" v-if="shipment.status == 'waiting'">Oczekuje na wysłanie</span>
                                <span class="label text-white m-r-xs none-radius inline prod-label c-blue" v-else-if="shipment.status == 'sent'">Nadana</span>
                                <br>
                                <span class="_600">Nr. przesyłki:
                                    <a target="_blank" class="text-info" v-if="shipment.service =='pp'" :href="'https://emonitoring.poczta-polska.pl/?numer='+shipment.parcelNo">
                                        {{ shipment.parcelNo }}
                                    </a>
                                    <a target="_blank" class="text-info" v-if="shipment.service =='dpd'" :href="'https://tracktrace.dpd.com.pl/parcelDetails?p1='+shipment.parcelNo">
                                        {{ shipment.parcelNo }}
                                    </a>
                                    <a target="_blank" class="text-info" v-if="shipment.service =='fedex'" :href="'https://www.fedex.com/pl-pl/online/domestic-tracking.html#/preview?packages='+shipment.parcelNo+'&trackingKey=' + shipment.parcelNo">
                                        {{ shipment.parcelNo }}
                                    </a>
                                    <a target="_blank" class="text-info" v-if="shipment.service =='furgonetka'" :href="'https://furgonetka.pl/zlokalizuj/'+shipment.parcelNo">
                                        {{ shipment.parcelNo }}
                                    </a>
                                    <span class="text-black" v-if="shipment.service =='rhenus'">
                                        {{ shipment.parcelNo }}
                                    </span>
                                    <a target="_blank" class="text-info" v-if="shipment.service =='geis'" :href="'https://www.geis.pl/pl/sledzenie-przesylki?p='+shipment.parcelNo">
                                        {{ shipment.parcelNo }}
                                    </a>
                                     <span v-if="shipment.service =='suus'">{{shipment.parcelNo}}</span>
                                    <span v-if="shipment.service =='marss'">{{shipment.parcelNo}}</span>
                                    <span v-if="shipment.service =='other'">{{shipment.parcelNo}}</span>
                                    <span v-if="shipment.service =='dhl'">
                                        <a target="_blank" class="text-info"  :href="'https://www.dhl.com/pl-pl/home/tracking/tracking-parcel.html?submit=1&tracking-id='+shipment.parcelNo">
                                            {{ shipment.parcelNo }}
                                        </a>
                                        <a class="text-blue" v-if="shipment.attachments && shipment.attachments.length > 0" :href="Api.getFileUrl(shipment.attachments[0].key, shipment.attachments[0].name)" target="_blank">
                                            <i class="material-icons md-18">attach_file</i>
                                        </a>
                                    </span>
                                    <span v-if="shipment.service =='ups'">
                                        {{ shipment.parcelNo }}
                                        <a class="text-blue" v-if="shipment.attachments && shipment.attachments.length > 0" :href="Api.getFileUrl(shipment.attachments[0].key, shipment.attachments[0].name)" target="_blank">
                                            <i class="material-icons md-18">attach_file</i>
                                        </a>
                                    </span>
                                    <span v-if="shipment.service =='inpost'">

                                        <a target="_blank" class="text-info"  :href="'https://inpost.pl/sledzenie-przesylek?number='+shipment.parcelNo">
                                            {{ shipment.parcelNo }}
                                        </a>
                                        <a class="text-blue" v-if="shipment.attachments && shipment.attachments.length > 0" :href="Api.getFileUrl(shipment.attachments[0].key, shipment.attachments[0].name)" target="_blank">
                                            <i class="material-icons md-18">attach_file</i>
                                        </a>
                                    </span>
                                </span>
                                <p>
                                    <span class="text-muted">Przewoźnik:
                                        <span v-if=" shipment.service  == 'pp'">Poczta polska</span>
                                        <span v-if=" shipment.service  == 'other'">Inny</span>
                                        <span v-if="shipment.service  != 'pp' && shipment.service  != 'other'">{{ shipment.service }} </span>
                                    </span>
                                    <span class="text-muted" v-if="shipment.subservice"> ({{ shipment.subservice }})</span>
                                    <br>
                                    <span class="text-muted">Data utworzenia: {{ shipment._created|moment('Y-M-D HH:mm') }}</span>
                                    <br>
                                    <span v-if="shipment.carrierStatus" class="text-sm text-muted">
                                        <br>
                                        {{ shipment.carrierStatus.status }} - {{ shipment.carrierStatus.lastUpdate|moment('from') }}
                                    </span>
                                </p>
                            </div>

                            <div class="col-md-4">
                                <span class="_600">Odbiorca</span>
                                <a :class="{'disabled': isProcessing}" title="Generuj list przewozowy" class="nav-link btn btn-xs white none-radius pull-right" @click.prevnet="generateShipLabel(shipment._id)">
                                    <i class="material-icons md-18">local_printshop</i>
                                </a>
                                <br>
                                <span v-if="shipment.receiverAddress">{{ shipment.receiverAddress.name }}</span>
                                <br>
                                <span v-if="shipment.receiverAddress" class="text-muted">{{ shipment.receiverAddress.street }}, {{ shipment.receiverAddress.postCode }} {{ shipment.receiverAddress.city }}</span>
                                <br>
                                <span v-if="shipment.receiverAddress" class="text-muted">{{ shipment.receiverAddress.email }}</span>
                                <br>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Api from "../../Api.js";
import FileSaver from 'file-saver';
import Spinner from '../Spinner.vue';
import EventBus from '../../event-bus.js';
import AddShipmentModal from './Modals/AddShipmentModal.vue'
export default {
    name: 'Shipments',
    props: {
        order: Object
    },
    components: {
        Spinner,
        AddShipmentModal
    },
    data: function () {
        return {
            isLoading: false,
            isProcessing: false,
            shipments: [],
            Api: Api
        }
    },

    computed: {
    },

    created: function() {
        if (this.order) this.getShipments();
        EventBus.$on('orderShipmentCreated', this.orderShipmentCreated);
    },

    destroyed: function() {
        EventBus.$off('orderShipmentCreated', this.orderShipmentCreated);
    },

    methods: {
        orderShipmentCreated(shipment) {
            this.shipments.push(shipment);
        },
        getShipments: async function() {
            this.isLoading = true;
            try {
                const tmpShip = await Api.shipments.search({
                    order: this.order._id
                });
                for (let i = 0; i < tmpShip.length; i++) {
                    const shipment = tmpShip[i];
                    shipment.isSelected = false;
                }
                this.shipments = tmpShip;
            } catch (error) {
                this.$notify({ group: 'global', type: 'notification-error', text: 'Nie udało się załadować przesyłek do zamówienia' });
            }
            this.isLoading = false;
        },
        generateShipLabel: async function(id) {
            this.isProcessing = true;
            try {
                this.$notify({ group: 'global', type: 'notification-success', text: 'Trwa generowanie...' });
                const r = await Api.shipments.generateWaybills([id]);
                FileSaver.saveAs(r, 'etykieta.zip');
                this.isProcessing = false;
            } catch (error) {
                this.isProcessing = false;
                return this.$notify({ group: 'global', type: 'notification-error', text: 'Wystapił błąd.' });
            }
        },
        addShip() {
            this.$modal.show(AddShipmentModal, {
                order: this.order,
                shipments: this.shipments
            }, {
                draggable: true,
                clickToClose: false,
                resizable: true,
                height: 'auto',
                scrollable: true
            }, {
                closed: (event) => {
                }
            });
        }
    }
}
</script>
