<template>
    <div class="box">
        <div class="box-header">
            <h6 class="title-color inline">Ruchy magazynowe dla zamówienia</h6><br>
            <router-link :to="{ name: 'stock-history', query: { order: order._id }}">(Zobacz na magazynie)</router-link>
        </div>
        <div class="m-a-0 custom-divider"/>
        <div class="input-group search-input" style="width: 100%;">
            <input placeholder="Szukaj..." type="text" class="form-control form-control-sm b-a" style="width: 100%;" v-model="pCodeSearch">
            <a  href="#" class="close-btn" @click.prevent="pCodeSearch = ''">
                <i  class="material-icons md-16">close</i>
            </a>
        </div>
        <div style="height: 330px; overflow-y: auto;">
            <div class="list box" v-if="!loadingData">
                <div class="list-item" v-for="h in stockEntries" :key="h._id">
                    <div class="list-body">
                        <router-link  v-if="h.item" target="_blank" :to="'/product/'+h.item.product._id">
                            {{ h.item.product.code }}
                        </router-link>
                        <span
                            :class="{ 'c-green': h.eventType =='pz' || h.eventType == 'pw' || h.eventType == 'prod', 'c-red': h.eventType =='ww' || h.eventType =='wz' }"
                            class="label rounded m-r-xs">
                            <span style="font-size:12px;">
                                {{ h.eventType =='pz' || h.eventType == 'pw' ? '+' : '-' }}

                                <span v-if="h.item.product.unit == '100 szt.'">
                                     {{Math.round(h.item.value *100)}} szt.
                                </span>
                                <span v-else>
                                    {{h.item.value}} {{h.item.product.unit}}
                                </span>

                            </span>
                            <span  v-if="h.item.price">
                                <span style="font-size:12px;"> po {{h.item.price | money }}</span>
                            </span>
                        </span>

                        <span style="font-size:0.65rem" class="text-muted" v-if="h.item.product.unit == '100 szt.'">({{h.item.value}}x{{h.item.product.unit}})</span>

                        <span class="_500 pull-right" v-if="h.warehouse && warehousesMap[h.warehouse]">{{ warehousesMap[h.warehouse]}}</span>
                        <small class="block text-muted">
                            <span v-if="h.isAReservation" class="text-blue">Rezerwacja</span>
                            <span v-if="h.isAWarehouseMove">przesuniecie magazynowe</span>
                            <span class="pull-right">
                                <span v-if="h.authorType == 'user'">{{SystemUsersManager.getName(h.authorId)}}</span>
                                <span v-else-if="h.authorType == 'system'">System</span>
                                <span v-else-if="h.authorType == 'employee'">{{EmployeeManager.getName(h.authorId)}}</span>
                                |
                                <span > {{ h.createdAt | moment("DD/MM/YY  HH:mm") }}</span>
                            </span>
                        </small>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import Api from '../../Api.js';
import SystemUsersManager from '../../SystemUsersManager.js';
import EmployeeManager from '../../EmployeeManager.js';
import SystemSocket from '../../SystemSocket.js'
export default {
    name: 'StockInformationForOrder',
    props: {
        order: Object
    },
    data: function () {
        return {
            loadingData: false,
            totalFound: 0,
            stockData: [],
            SystemUsersManager: SystemUsersManager,
            EmployeeManager: EmployeeManager,
            warehouses: [],
            pCodeSearch: "",
            timeoutHandler: null
        }
    },
    components: {
    },

    created: async function() {
        this.getStockHistory();
        await SystemUsersManager.getSystemUsers();
        await EmployeeManager.getEmployees();
        SystemSocket.socket.on('object', this.onObjectEvent);
    },
    destroyed: function() {
        SystemSocket.socket.off('object', this.onObjectEvent);
    },
    computed: {
        warehousesMap: function () {
            const map = {}
            for (let i = 0; i < this.warehouses.length; i++) {
                const w = this.warehouses[i];
                map[w._id] = w.name;
            }
            return map;
        },
        stockEntries() {
            let rows = [];
            for (const stockDoc of this.stockData) {
                let i = 0;
                for (const item of stockDoc.items) {
                    if (!item.product) continue;
                    const row = Object.assign({
                        idx: i,
                        item
                    }, stockDoc);
                    rows.push(row);
                    i += 1;
                }
            }
            if (this.pCodeSearch.length) {
                rows = rows.filter(c => c.item && c.item.product && c.item.product.code.toUpperCase().indexOf(this.pCodeSearch.toUpperCase()) != -1)
            }

            return rows
        }
    },

    methods: {
        async getStockHistory() {
            this.loadingData = true;
            try {
                if (!this.warehouses.length) this.warehouses = await Api.stock.getWherhouses();
                const stock = await Api.stock.searchHistory({ productOrderId: this.order._id, populateProducts: true });
                this.totalFound = stock.total;
                this.stockData = stock.data;
            } catch (error) {
                this.$h.commonApiError(error, true);
            }
            this.loadingData = false;
        },
        onObjectEvent(msg) {
            if (msg.object == 'stock_event') {
                if (msg.data.productOrderId + "" != this.order._id + "") return;

                if (this.timeoutHandler) clearTimeout(this.timeoutHandler);
                this.timeoutHandler = setTimeout(() => {
                    this.getStockHistory();
                }, 2000);
            }
        }
    }
}
</script>
