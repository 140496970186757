<template>
  <div>
    <!-- okno modalne do dodawania definicji -->
    <div class="custom-modal modal fade" v-bind:class="{ in: isActive, todisplay: isActive }"  data-backdrop="true" aria-hidden="true">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-body p-lg" style="padding-left: 0px; padding-right: 0px;">
            <form class="form-horizontal m-b m-t">
              <div class="form-group row m-x-xs">
                <label class="col-sm-6 col-md-4 form-control-label text-right">Nazwa</label>
                <div class="col-sm-6 col-md-8">
                  <input class="form-control" type="text" v-model="singleDef.name"  style="max-height: 28px; min-height: 28px;">
                </div>
              </div>
              <div class="form-group row m-x-xs">
                <label class="col-sm-6 col-md-4 form-control-label text-right">Kod</label>
                <div class="col-sm-6 col-md-8">
                  <input class="form-control" type="text" v-model="singleDef.code"  style="max-height: 28px; min-height: 28px;">
                </div>
              </div>
              <div class="box-divider m-a-0"></div>
              <ul class="list-group no-border">
                <div class="l-gray">
                  <li class="list-group-item no-border">
                    <div class="row m-b-xs">
                      <span class="col-md-6 _600">Cecha</span>
                      <span class="col-md-6 _600">Właściwość</span>
                    </div>
                  </li>
                </div>
              </ul>
              <div class="box-divider m-a-0 m-b"></div>
              <div v-if="singleDef.featuresName" class="form-group row m-b-sm m-x-xs" 
              v-for="(feature, index) in singleDef.featuresName">
                <span v-if="!inArray(index)">
                  <span class="primary-color col-md-6">{{feature.desc}}</span>
                  <span class="col-md-5">{{feature.type}}</span>
                  <div class="col-sm-1 btn-rv">
                    <a title="Usuń" class="primary-color nav-link" v-on:click="removeSemiDefFeature(feature, index)">
                      <i class="material-icons md-18">highlight_off</i>
                    </a>
                  </div>
                </span>
              </div>

              <div class="form-group row m-b-sm m-x-xs" v-for="(input, index) in newFeatures">
                <div class="col-sm-12">
                  <select2 :options="featuresNames" class="form-control" v-model="input.id">
                  </select2>
                </div>
              </div>

                <a href="#" class="text-blue text-sm m-t-sm m-l" v-on:click.prevent="addNewFeaturesToSemiDef">
                  Dodaj cechę
                </a>
            </form>
          </div>
          <div class="modal-footer">
            <a href="#" class="pull-left text-blue text-sm" v-on:click="cancel">Cofnij</a>
            <a v-if="isNew" class="text-blue pull-right text-sm" v-on:click="addNewDefinition">Dodaj</a>
            <a v-if="!isNew" class="text-blue pull-right text-sm" v-on:click="saveChanges">Zapisz zmiany</a>
          </div>
        </div>
      </div>
    </div>
    <!-- okno modalne do dodawania definicji -->

    <div class="box-header d-gray">
      <h6 class="text-muted _400 inline">Definicje półproduktów</h6>
      <div class="pull-right minus-margin">
        <a class="btn btn-sm white none-radius" v-on:click="showModal">
          <span class="primary-color _400">Dodaj</span>
        </a>&nbsp;
        <a class="btn btn-sm white none-radius" v-on:click="removeDefs">
          <span class="primary-color _400">Usuń</span>
        </a>&nbsp;
      </div>
    </div>

    <div class="row display-flex" v-if="isReady && !isLoaded">
      <div class="col-md-4 col-sm-6" v-for="s in semiProductDefs">
        <div class="box m-a">
          <div class="box-header">
            <label class="md-check pull-right">
              <input type="checkbox" v-model="selected" :value="s._id">
              <i class="blue m-r-xs"></i>
            </label>
            <h6 class="text-muted _400">Nazwa: {{s.name}}</h6>
            <small>Kod: {{s.code}}</small>
          </div>
          <div class="box-divider m-a-0"></div>
          <ul class="list-group no-border">
            <div class="l-gray">
              <li class="list-group-item no-border">
                <div class="row m-b-xs">
                  <span class="col-md-6 _600">Cecha</span>
                  <span class="col-md-6 _600">Właściwość</span>
                </div>
              </li>
            </div>
          </ul>
          <div class="box-divider m-a-0"></div>
          <ul class="list-group no-border">
            <li class="list-group-item no-border p-b-lg">
              <div class="row m-b-xs" v-for="feature in s.featuresName">
                <span class=" primary-color col-md-6">{{feature.desc}}</span>
                <span class="col-md-6">{{feature.type}}</span>
              </div>
              <div class="box-divider m-a-0 p-t"></div>
              <a href="#" class="pull-right text-blue text-sm m-t m-r-xs" v-on:click="editSemiDef(s)">Edytuj</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p class="text-center text-muted p-y" v-if="!isLoaded && semiProductDefs.length == 0">Brak dodanych definicji</p>
    <Spinner v-if="isLoaded"></Spinner>
  </div>
</template>
<script>
import Api from '../../Api.js';
import Select2 from '../Select2.vue';
import Spinner from '../Spinner.vue';

export default {
  name: 'Semi',
  components: {
    Select2,
    Spinner
  },
  data: function () {
    return {
      isLoaded: true,
      isReady: false,
      types: [
        {id: 'boolean', text:'Boolean'},
        {id: 'text', text: 'Text'},
        {id: 'number', text: 'Number'}
      ],
      features:[],
      semiProductDefs: [],
      featuresNames: [],
      selected: [],
      positionToRemove: [],
      toRemove: [],
      newFeatures: [],
      isActive: false,
      isNew: false,
      singleDef: {}
    }
  },
  created: function() {
    this.getProductFeatures();
  },
  methods: {
    getProductFeatures: function() {
      Api.product.getProductsFeatures().then((features) => {
        features.forEach(f => {
          let o = {id: f._id, text: f.desc};
          this.featuresNames.push(o);
        })
        this.features = features;
        this.getSemiDefintions();
      })
    },
    getSemiDefintions: function() {
      this.isLoaded = true;
      Api.product.getSemiProductDef().then((defs) => {
        this.isLoaded = false;
        this.semiProductDefs = defs;
        this.setFeaturesNamesToDef();
      })
    },
    setFeaturesNamesToDef: function() {
      this.semiProductDefs.forEach(s => {
        s.featuresName = [];
        let features = s.availableFeatures;
        for (var i = features.length - 1; i >= 0; i--) {
          let idx = this.features.map(f => f._id+"").indexOf(features[i]+"");
          if (idx != -1) {
            s.featuresName.push(this.features[idx])
          }
        }
      });
      this.isReady = true;
    },
    saveChanges: function() {
      for (var i = this.toRemove.length - 1; i >= 0; i--) {
        let idx = this.singleDef.availableFeatures.indexOf(this.toRemove[i]);
        if (idx !=-1) {
          this.singleDef.availableFeatures.splice(idx, 1);
        }
      }

      this.newFeatures.forEach((n => {
        this.singleDef.availableFeatures.push(n.id)
      }))

      Api.product.modifySemiProductDef(this.singleDef).then((newSemiProductDef) => {
        let idx = this.semiProductDefs.map(s=> s._id+"").indexOf(newSemiProductDef._id+"");
        if (idx != -1) {
          this.semiProductDefs[idx] = newSemiProductDef;
          this.setFeaturesNamesToDef();
          this.$notify({group: 'global', type: 'notification-success', text: 'Pomyślnie zmieniono.'});
          this.cancel();
        }
      });
    },
    editSemiDef: function(s) {
      this.singleDef = s;
      this.isActive = true;
    },
    removeSemiDefFeature: function(f, index) {
      this.positionToRemove.push(index);
      this.toRemove.push(f._id)
    },
    addNewFeaturesToSemiDef: function() {
      this.newFeatures.push({})
    },
    cancel: function() {
      this.isActive = false;
      this.isNew = false;
      this.toRemove = [];
      this.positionToRemove = [];
      this.newFeatures = [];
      this.singleDef = {};
    },
    inArray: function(needle) {
      var length = this.positionToRemove.length;
      for(var i = 0; i < length; i++) {
          if(this.positionToRemove[i] == needle) return true;
      }
      return false;
    },
    showModal: function() {
      this.isNew = true;
      this.isActive = true;
    },
    addNewDefinition: function() {
      if (!this.singleDef.name || !this.singleDef.code) {
        this.$notify({group: 'global', type: 'notification-error', text: 'Aby dodać definicję należy wypełnić pola "Nazwa" i "Kod".'});
        return;
      }

      this.singleDef.availableFeatures = [];
      this.newFeatures.forEach((n => {
        this.singleDef.availableFeatures.push(n.id)
      }));

      Api.product.addNewSemiProductDef(this.singleDef).then((newDef) => {
        this.semiProductDefs.push(newDef);
        this.setFeaturesNamesToDef();
        this.$notify({group: 'global', type: 'notification-success', text: 'Pomyślnie dodano.'});
        this.cancel();

      });
    },
    removeDefs: function() {
      if (this.selected.length == 0) {
        this.$notify({group: 'global', type: 'notification-error', text: 'Brak wybranych do wykonanian tej akcji.'});
        return;
      }

      let promises = [];

      for (var i = this.selected.length - 1; i >= 0; i--) {
        let data = {_id: this.selected[i], isRemoved: true};

        let p = Api.product.modifySemiProductDef(data).then((removedDef) => {
          let idx = this.semiProductDefs.map(s=> s._id+"").indexOf(removedDef._id+"");
          if (idx != -1) {
            this.semiProductDefs.splice(idx, 1);
          }
        })
        promises.push(p);
        
      }
      Promise.all(promises).then((cos) =>{
        this.$notify({group: 'global', type: 'notification-success', text: 'Pomyślnie usunięto wybrane.'});
      });
    }
    
  }
}
</script>

<style lang="scss" scoped>
  .btn-rv {
    padding-left: 0px; 
    padding-top: 4px;
  }
  .nodisplay {
    display: none;
  }
  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }
  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }
</style>