import io from 'socket.io-client';
import config from './config.js';

function SystemSocket() {
    this.socket = io(config.apiUrl, {
        path: '/push'
    });
    this.token = null;

    this.socket.on('reconnect', (attemptNumber) => {
        if (this.token) this.socket.emit('login', this.token);
    });

    // this.socket.on('object', (msg) => {
    //     console.log(msg);
    // });
}

SystemSocket.prototype.setToken = function(token) {
    this.token = token;
    console.log('Setting token');
    this.socket.emit('login', token);
}

var instance = new SystemSocket();

export default instance;