<template>
    <div class="m-t">
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Zewnętrzny numer przesyłki</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="PartnerNumber" >
            </div>
            <label class="col-sm-3 form-control-label">Uwaga dla odbiorcy</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="Note" >
            </div>

        </div>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Reference</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="Reference" >
            </div>
            <label class="col-sm-3 form-control-label">Uwaga dla kierowcy</label>
            <div class="col-sm-3">
                <input class="form-control" type="text" v-model="NoteDriver" >
            </div>
        </div>
        <hr>
        <strong>Palety w przesyłce</strong>
        <div class="m-t" v-for="(paczka, index) in parcels" :key="index">
            <div class="row">
                <span class="pull-left m-x _600" style="margin-top:2.3rem;">#{{index+1}} </span>
                <div class="col-sm-3">
                    <label for=""/>
                    <div class="input-group m-t-sm">
                        <input type="text" class="form-control" v-model="paczka.Description" placeholder="Opis">
                        <span class="input-group-addon">opis</span>
                    </div>
                </div>
                <div class="col-sm-2">
                    <label for=""/>
                    <div class="input-group m-t-sm">
                        <input type="text" class="form-control" v-model="paczka.Type" placeholder="paleta">
                        <span class="input-group-addon">paleta</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Wysokość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.Height" placeholder="Wys [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Szerokość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.Width" placeholder="Szer [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1">
                    <label for="">Długość [m]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.Length" placeholder="Głębo [m]">
                        <span class="input-group-addon text-sm p-a-xs">m</span>
                    </div>
                </div>
                <div class="col-sm-1 ">
                    <label for="">Waga [kg]</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="paczka.Weight" placeholder="kg">
                        <span class="input-group-addon text-sm p-a-xs">kg</span>
                    </div>
                </div>
                <div class="col-sm-1" style="margin-top:1.8rem;">
                    <button type="button" class="btn white" tabindex="-1" @click="parcels.splice(index, 1)">Usuń</button>
                </div>
            </div>
        </div>

        <div class="form-group row m-t-md">
            <label class="col-sm-2 form-control-label">Dodaj palete</label>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(1.2,0.8,0.6,50,'HP')">HP półpaleta 120x80x60, 50kg</button>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-outline btn-xs rounded b-info text-info" @click.prevent="addNewParcel(1.2,0.8,1.2,50,'EP')">EP - Bezpowrotna paleta 120x80x120, 50kg</button>
            </div>

        </div>

        <!-- <div class="form-group row" v-if="order.shippingPalletes && order.shippingPalletes.length">
            <label class="col-sm-2 form-control-label">Pobierz palety z zamówienia</label>
            <div class="col-sm-2">
                <button type="button" class="btn white" @click="getOrderBoxes">Pobierz ({{ order.shippingPalletes.length }})</button>
            </div>
        </div> -->

        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Planowana data podjęcia</label>
            <div class="col-sm-3">
                <div class="input-group">
                    <div class="datepicker-trigger">
                        <input
                            class="form-control form-control-sm p-x b-a"
                            type="text"
                            id="datepicker-trigger"
                            placeholder="Wybierz date"
                            :value="formatDate(dateOne)"
                        >

                        <AirbnbStyleDatepicker
                            :trigger-element-id="'datepicker-trigger'"
                            :mode="'single'"
                            :date-one="dateOne"
                            :monthsToShow="1"
                            :showShortcutsMenuTrigger="false"
                            @date-one-selected="val => { dateOne = val }"
                        />
                    </div>
                </div>

            </div>

        </div>

        <hr>
        <div class="form-group row">
            <label class="col-sm-3 form-control-label">Opcje dodatkowe</label>
            <div class="col-sm-8">
                <p>
                    <label class="md-check">
                        <input type="checkbox" v-model="isCod" >
                        <i class="blue"/> Za pobraniem</label>
                </p>
                <p>
                    <label class="md-check">
                        <input  type="checkbox" v-model="isPoj">
                        <i class="blue"/> Ubezpieczenie</label>
                </p>
                <p>
                    <label class="md-check">
                        <input  type="checkbox" v-model="isVdl">
                        <i class="blue"/> Dokumenty zwrotne</label>
                </p>
            </div>
            <Spinner v-if="isProcessing"/>
        </div>
        <div class="form-group row" v-if="isCod">
            <label class="col-sm-3 form-control-label">Kwota pobrania</label>
            <div class="col-sm-3">
                <input type="text" class="form-control"  @change="parsePrice" placeholder="Kwota pobrania [zl]" v-model="codePrice">
            </div>
        </div>
        <div class="form-group row" v-if="isPoj">
            <label class="col-sm-3 form-control-label">Kwota ubezpieczenia</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" @change="parsePojPrice"  placeholder="Kwota ubezpieczenia [zl]" v-model="pojPrice">
            </div>
        </div>
        <a v-if="!isStockOrderPickup" href="#" class="btn info none-radius m-t" @click.prevent="save" :class="{disabled: isProcessing}">Zapisz</a>
        <a v-if="isStockOrderPickup" href="#" class="btn primary none-radius m-t" @click.prevent="createPickUp" :class="{disabled: isProcessing}">Zamów odbiór</a>

    </div>
</template>
<script>
import Spinner from '../../Spinner.vue';
import Api from '../../../Api.js';
import format from 'date-fns/format';

export default {
    name: 'CarrierRhenus',
    props: {
        order: Object,
        sender: Object,
        recipient: Object,
        stockOrder: Object,
        selectedBoxAndPallets: Array
    },
    components: {
        Spinner
    },
    data: function () {
        return {
            dateFormat: 'D MMM',
            dateOne: '',
            dateTwo: '',
            isLoaded: false,
            isProcessing: false,
            parcels: [],
            package: {
            },
            typePalet: [
                { v: "HP", label: 'HP - Półpaleta' },
                { v: "EP", label: 'EP - Bezpowrotna paleta' }
            ],
            PartnerNumber: "",
            Note: "",
            Reference: "",
            NoteDriver: "",
            isCod: false,
            codePrice: 0,
            isPoj: false,
            pojPrice: 0,
            isStockOrderPickup: false,
            isVdl: false
        }
    },
    created: function() {
        this.parcels.push({
            CountItems: 1,
            Description: "Filtry powietrza",
            Height: 1.2,
            Length: 1.2,
            Type: "EP",
            Weight: 50,
            Width: 0.8
        });

        this.setDates();

        if (Object.keys(this.order).length === 0 && Object.keys(this.stockOrder).length > 0) {
            this.isStockOrderPickup = true
        }

        if (this.selectedBoxAndPallets && this.selectedBoxAndPallets.length > 0) this.setBoxesAndPallets()
    },
    methods: {
        setDates: function() {
            var tommorow = new Date((new Date()).valueOf() + 1000 * 3600 * 24);
            var ddt = String(tommorow.getDate()).padStart(2, '0');
            var mmt = String(tommorow.getMonth() + 1).padStart(2, '0'); // January is 0!
            var yyyyt = tommorow.getFullYear();
            tommorow = yyyyt + '-' + mmt + '-' + ddt;
            this.dateOne = tommorow;
        },

        parsePrice: function() {
            this.codePrice = this.codePrice.replace(/,/g, '.');
        },
        parsePojPrice: function() {
            this.pojPrice = this.pojPrice.replace(/,/g, '.');
        },
        addNewParcel: function(height, width, depth, weight, type) {
            this.parcels.push({
                CountItems: 1,
                Description: "Filtry powietrza",
                Height: height,
                Length: depth,
                Type: type,
                Weight: weight,
                Width: width
            });
        },
        getOrderBoxes: function() {
            if (!this.order || !this.order.shippingPalletes || !this.order.shippingPalletes.length) { return this.$helpers.errorNotif("Brak spakowanych palet w zamówieniu"); }
            this.parcels.length = 0;
            for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                const box = this.order.shippingPalletes[i];
                this.parcels.push({
                    CountItems: 1,
                    Description: "Filtry powietrza",
                    Height: parseFloat(box.height / 1000).toFixed(2),
                    Length: parseFloat(box.length / 1000).toFixed(2),
                    Type: "EP",
                    Weight: box.mass,
                    Width: parseFloat(box.width / 1000).toFixed(2)
                });
            }
        },
        async createPickUp() {
            this.isProcessing = true;
            const data = this.setData();
            data.stockOrder = this.stockOrder._id;

            data.RequestObject.SenderAddress = {
                City: this.sender.city,
                Country: this.sender.countryCode ? this.sender.countryCode : "PL",
                Name: this.sender.company_name,
                Street: this.sender.street,
                ZipCode: this.sender.postcode
            }
            data.RequestObject.SenderContact = {
                Email: this.sender.email,
                FullName: this.sender.contactPerson,
                Phone: this.sender.phone
            }

            try {
                await Api.shipments.geisSent(data);
                this.$h.success('Zlecono odbiór przesyłki');
                this.$router.push({ path: `/shipments` });
            } catch (e) {
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        save: async function() {
            this.isProcessing = true;
            const data = this.setData();
            data.order = this.order._id;
            data.Contact = this.setSender();
            // console.log("data", data)
            try {
                const shipments = await Api.shipments.geisSent(data);
                // jeszcze do konca nie wiem czemu metoda sent zwraca tablice
                for (const shipment of shipments) this.$emit('shipment', shipment);
                this.$h.success('Dodano przesyłkę');
            } catch (e) {
                if (e.response.data.error) this.$h.errorNotif(e.response.data.error);
                else this.$h.commonApiError(e, true);
            }
            this.isProcessing = false;
        },
        setData: function() {
            const data = { };
            this.package.DeliveryAddress = this.setDeliveryAddress();
            this.package.DeliveryContact = this.setDeliveryContact();
            this.package.DistributionChannel = 2;
            this.package.ExportItems = {
                ExportItem: []
            };
            for (let i = 0; i < this.parcels.length; i++) {
                const paczka = this.parcels[i];
                paczka.CountItems = 1;
                paczka.Height = parseFloat((paczka.Height + "").replace(/,/g, '.'));
                paczka.Length = parseFloat((paczka.Length + "").replace(/,/g, '.'));
                paczka.Weight = parseFloat((paczka.Weight + "").replace(/,/g, '.'));
                paczka.Width = parseFloat((paczka.Width + "").replace(/,/g, '.'));

                this.package.ExportItems.ExportItem.push(paczka);
            }
            this.package.ExportServices = {
                ExportService: []
            };

            if (this.isCod) {
                const obj = {
                    Code: "COD",
                    Parameter_1: this.codePrice,
                    Parameter_2: "PLN"
                };
                this.package.ExportServices.ExportService.push(obj);
            }

            if (this.isPoj) {
                const obj = {
                    Code: "POJ",
                    Parameter_1: this.pojPrice,
                    Parameter_2: "PLN"
                };
                this.package.ExportServices.ExportService.push(obj);
            }

            if (this.isVdl) {
                const obj = {
                    Code: "VDL"
                };
                this.package.ExportServices.ExportService.push(obj);
            }

            this.package.PickUpDate = this.dateOne + "T00:00:00";
            if (this.PartnerNumber.length > 0) this.package.PartnerNumber = this.PartnerNumber;
            if (this.Note.length > 0) this.package.Note = this.Note;
            if (this.Reference.length > 0) this.package.Reference = this.Reference;
            if (this.NoteDriver.length > 0) this.package.NoteDriver = this.NoteDriver;

            data.RequestObject = this.package;

            return data;
        },
        setSender: function() {
            const r = {
                Email: this.sender.email,
                FullName: this.sender.firstname + " " + this.sender.lastname,
                Phone: this.sender.phone ? this.sender.phone : ""
            }

            return r;
        },
        setDeliveryAddress: function() {
            const r = {
                City: this.recipient.city,
                Country: this.recipient.countryCode ? this.recipient.countryCode : "PL",
                Name: this.recipient.name,
                Street: this.recipient.street,
                ZipCode: this.recipient.postCode
            };

            return r;
        },
        setDeliveryContact: function() {
            let phone = "";
            if (this.recipient.phone.length > 0) {
                phone = this.recipient.phone;
            }
            const r = {
                Email: this.recipient.email ? this.recipient.email : "",
                FullName: this.recipient.contactPerson ? this.recipient.contactPerson : this.recipient.name,
                Phone: phone
            }

            return r;
        },
        formatDate(dateOne) {
            let formattedDates = '';
            if (dateOne) {
                formattedDates = format(dateOne, this.dateFormat);
            }
            return formattedDates;
        },
        // jak wybrane zostały kartony to trzeba je ustawic lub palety - PALETY TLKO DLA RHENUS I GEISA
        setBoxesAndPallets() {
            this.parcels.length = 0;

            for (let i = 0; i < this.order.shippingPalletes.length; i++) {
                const box = this.order.shippingPalletes[i];
                const id = this.selectedBoxAndPallets.find(s => s == box._id);
                if (!id) continue;
                this.parcels.push({
                    CountItems: 1,
                    Description: "Filtry powietrza",
                    Height: parseFloat(box.height / 1000).toFixed(2),
                    Length: parseFloat(box.length / 1000).toFixed(2),
                    Type: "EP",
                    Weight: box.mass,
                    Width: parseFloat(box.width / 1000).toFixed(2)
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
  .app .select2-container .select2-selection--single {
    height: 44px!important;
  }
  .app .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px!important;
  }
</style>
